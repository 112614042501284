import { Feather } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { TopMenu, Spin, Text } from '_/components';
import ContainerWidthLimit, { getWebMaxWidth } from '_/components/ContainerWidthLimit';
import SwitchCompany from '_/components/TopMenu/SwitchCompany';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { usePushNotificationContext } from '_/hooks/PushNotificationProvider';
import { AppRoute, ProfileProps } from '_/routes/types';
import AllowedHostsDB from '_/services/sqlite/AllowedHostsDB';
import UsersZonesDB from '_/services/sqlite/UsersZonesDB';
import { signOut } from '_/store/modules/auth/actions';
import Constants from 'expo-constants';
import { LinearGradient } from 'expo-linear-gradient';
import * as MailComposer from 'expo-mail-composer';
import { transparentize } from 'polished';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  ScrollView,
  ImageBackground,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
  Platform,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import LogoutConfirmationModal from './LogoutConfirmationModal';
import SwitchLanguage from './SwitchLanguage';

export default function Profile() {
  const navigation = useNavigation<ProfileProps>();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const user = useSelector((state: any) => state.user);
  const company = useSelector((state: any) => state.company);
  const { token, loading, checkNotificationsPermissions, removeTokenFromDatabase } =
    usePushNotificationContext();

  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    loadStatus();
  }, [token]);

  async function loadStatus() {
    const storedStatus = await AsyncStorage.getItem('@w3lcome/notifications');

    if (storedStatus) {
      setStatus(storedStatus);
    }
  }

  const handleLogout = async () => {
    const existingUsersZonesDB = await AsyncStorage.getItem('@w3lcome/UsersZonesDB');

    if (existingUsersZonesDB === 'true' && Platform.OS !== 'web') {
      await UsersZonesDB.deleteTable();
      await AllowedHostsDB.deleteTable();
      await AsyncStorage.setItem('@w3lcome/UsersZonesDB', 'false');
    }

    await removeTokenFromDatabase();
    await AsyncStorage.getAllKeys()
      .then((keys) => {
        AsyncStorage.multiRemove(keys);
      })
      .catch(() => null);
    dispatch(signOut());
  };

  const onNotificationPress = useCallback(async () => {
    if (token) {
      await removeTokenFromDatabase();
    } else {
      await checkNotificationsPermissions();
    }
  }, [token]);

  const handleEditProfile = useCallback(() => {
    navigation.navigate(AppRoute.EDIT_PROFILE);
  }, []);

  const renderProfileHeader = useCallback(() => {
    return (
      <View style={styles.header}>
        <View style={styles.loadingView}>
          <Spin visible overlayColor="transparent" containerColor="transparent" />
        </View>
        <ImageBackground
          style={styles.picture}
          source={user.pictureUrl ? { uri: user.pictureUrl } : themes.images.avatarSquare}
        >
          <View style={styles.headerContent}>
            <LinearGradient
              colors={['transparent', transparentize(0.5, themes.colors.black)]}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                height: 140,
              }}
            />
            <Text style={styles.title}>{user.name}</Text>
            <Text style={styles.subTitle}>{user.email}</Text>
            {!!user.position && <Text style={styles.subTitle}>{user.position}</Text>}
          </View>
        </ImageBackground>
      </View>
    );
  }, [user]);

  const renderNotifications = useCallback(() => {
    return (
      <>
        {token && status !== 'denied' && !loading && (
          <Text style={[styles.notificationStatus, { color: themes.colors.success }]}>
            ({translate('profile.ENABLED')})
          </Text>
        )}
        {!token && status !== 'denied' && !loading && (
          <Text style={[styles.notificationStatus, { color: themes.colors.danger }]}>
            ({translate('profile.DISABLED')})
          </Text>
        )}
        {status === 'denied' && !loading && (
          <Text style={[styles.notificationStatus, { color: themes.colors.danger }]}>
            ({translate('profile.DENIED')})
          </Text>
        )}
        {loading && (
          <ActivityIndicator size="small" color={themes.colors.primary} style={{ marginLeft: 4 }} />
        )}
      </>
    );
  }, [token, loading, translate, status]);

  const renderSubMenu = useCallback(
    (title: string, subTitle: string, icon = 'chevron-right', withNotification = false) => {
      return (
        <View style={styles.inputGroup}>
          <View style={styles.inputGroupContent}>
            <Text style={[styles.menuTitle, icon === 'log-out' && { color: themes.colors.danger }]}>
              {title}
            </Text>
            {withNotification && renderNotifications()}
            <Text style={styles.menuSubTitle}>{subTitle}</Text>
          </View>
          <Feather name={icon} style={styles.menuIcon} />
        </View>
      );
    },
    [renderNotifications]
  );

  if (!user) return <View />;

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <TopMenu />
        <ScrollView showsVerticalScrollIndicator={false}>
          {renderProfileHeader()}
          <View style={styles.content}>
            <TouchableOpacity delayPressIn={50} onPress={handleEditProfile}>
              {renderSubMenu(
                translate('profile.settingsTitle'),
                translate('profile.settingsSubTitle')
              )}
            </TouchableOpacity>

            <TouchableOpacity delayPressIn={50} onPress={onNotificationPress}>
              {renderSubMenu(
                translate('profile.notificationsTitle'),
                translate('profile.notificationsSubTitle'),
                token ? 'toggle-right' : 'toggle-left',
                true
              )}
            </TouchableOpacity>

            {/* {['admin'].includes(role) && (
            <TouchableOpacity
              delayPressIn={50}
              onPress={() => navigation.navigate('PlanDetails')}
            >
              {renderSubMenu(
                translate('plan.planTitle'),
                translate('plan.planSubTitle')
              )}
            </TouchableOpacity>
          )} */}

            <TouchableOpacity
              delayPressIn={50}
              onPress={() => navigation.navigate(AppRoute.DELETE_ACCOUNT)}
            >
              {renderSubMenu(
                translate('profile.deleteAccountTitle'),
                translate('profile.deleteAccountSubTitle')
              )}
            </TouchableOpacity>

            <SwitchLanguage />
            <SwitchCompany
              content={renderSubMenu(
                translate('profile.switchProfileTitle'),
                translate('profile.switchProfileSubTitle')
              )}
            />
            <TouchableOpacity
              style={styles.inputGroup}
              onPress={() => {
                MailComposer.composeAsync({
                  recipients: [appConfig.helpEmail],
                  subject: translate('profile.help', {
                    AppName: appConfig.appName,
                    CompanyName: company.name,
                    HostEmail: user.email,
                  }),
                });
              }}
            >
              <Text style={[styles.menuTitle, { marginBottom: 8 }]}>
                {translate('profile.needHelp')}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity delayPressIn={50} onPress={() => setLogoutModalVisible(true)}>
              {renderSubMenu(
                translate('profile.logoutTitle'),
                translate('profile.logoutSubTitle'),
                'log-out'
              )}
            </TouchableOpacity>

            {!!Constants.expoConfig?.version && (
              <Text style={styles.version}>{`v. ${Constants.expoConfig?.version}`}</Text>
            )}
          </View>
        </ScrollView>

        <LogoutConfirmationModal
          handleLogout={async () => await handleLogout()}
          visible={logoutModalVisible}
          hideModal={() => setLogoutModalVisible(false)}
        />
      </View>
    </ContainerWidthLimit>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 24,
  },
  header: {
    backgroundColor: themes.colors.white,
    flex: 1,
    height: 400,
    ...(Platform.OS === 'web' ? { width: getWebMaxWidth() } : { width }),
  },
  picture: {
    backgroundColor: transparentize(0.5, themes.colors.black),
    flex: 1,
    flexDirection: 'column',
    resizeMode: 'cover',
    zIndex: 1000,
  },
  headerContent: {
    justifyContent: 'flex-end',
    flex: 1,
    padding: 24,
  },
  title: {
    color: themes.colors.white,
    fontFamily: fontFamily.bold,
    fontSize: 28,
    lineHeight: 28,
  },
  subTitle: {
    color: themes.colors.white,
    fontSize: 14,
  },
  inputGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: themes.colors.lightSecondary,
    justifyContent: 'space-between',
    marginBottom: 8,
    paddingVertical: 8,
    borderBottomWidth: 0.3,
  },
  inputGroupContent: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 12,
  },
  menuTitle: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginBottom: 4,
  },
  menuSubTitle: {
    color: themes.colors.grayPrimary,
    fontSize: 12,
    marginBottom: 8,
  },
  menuIcon: {
    marginLeft: 12,
    fontSize: 18,
    color: themes.colors.lightSecondary,
  },
  notificationStatus: {
    fontFamily: fontFamily.medium,
    fontSize: 10,
    lineHeight: 10,
    paddingBottom: 8,
  },
  version: {
    textAlign: 'center',
    color: themes.colors.grayPrimary,
    fontSize: 12,
    marginTop: 12,
  },
  loadingView: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },
});
