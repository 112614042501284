import { Feather } from '@expo/vector-icons';
import themes from '_/constants/themes';
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

interface CheckboxProps {
  checked?: boolean;
  handleChecked: () => void;
  children?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked = false, children, handleChecked }) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handleChecked} hitSlop={{ right: 30, left: 30 }}>
        <Feather style={styles.icon} name={checked ? 'check-square' : 'square'} />
      </TouchableOpacity>
      <View style={styles.children}>{children}</View>
    </View>
  );
};

export default Checkbox;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
  children: {
    marginLeft: 8,
  },
  icon: {
    fontSize: 20,
    color: themes.colors.darkTertiary,
  },
});
