import { Alert } from 'react-native';

export function showError(msg: string, title = 'Erro') {
  Alert.alert(
    title,
    msg,
    [
      {
        text: 'OK',
      },
    ],
    { cancelable: false }
  );
}
