export function toggleSearch() {
  return {
    type: '@settings/TOGGLE_SEARCH',
  };
}

export function changeSearchText(text) {
  return {
    type: '@settings/CHANGE_SEARCH_TEXT',
    payload: text,
  };
}

export function changeCameraType(cameraType) {
  return {
    type: '@settings/CHANGE_CAMERA_TYPE',
    payload: cameraType,
  };
}

export function changeQRCodeCameraType(cameraType) {
  return {
    type: '@settings/CHANGE_QRCODE_CAMERA_TYPE',
    payload: cameraType,
  };
}
