import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import { Spin, Header, QRCodeDisplay, InviteContainer } from '_/components/index';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import { AppRoute, PersonalInviteRouteProps } from '_/routes/types';
import { updatePersonalActiveInvites } from '_/store/modules/personal/actions';
import { addHours } from 'date-fns';
import { isBefore } from 'date-fns/esm';
import LottieView from 'lottie-react-native';
import { transparentize } from 'polished';
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView, SafeAreaView, Animated, StyleSheet, BackHandler } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { RectButton } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';

import check from '_/assets/check.json';

import { invitesApi, checkinApi } from '../../services/api';

interface PersonalInviteProps {
  route: RouteProp<any, any>;
}

export default function PersonalInvite({ route }: PersonalInviteProps) {
  const navigation = useNavigation<PersonalInviteRouteProps>();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const { loading } = useSelector((state: any) => state.loading);
  const [loadingInvite, setLoadingInvite] = useState(true);
  const { activeInvites } = useSelector((state: any) => state.personal);
  const animation = useRef<LottieView>(null);

  const [invite, setInvite] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [progress] = useState(new Animated.Value(0));

  const disableCheckinButton = useMemo(() => {
    if (invite?.customization?.timeToCheckinBeforeMeetingInHours) {
      return isBefore(
        addHours(new Date(), invite.customization.timeToCheckinBeforeMeetingInHours),
        new Date(invite.meeting?.start)
      );
    }
    return false;
  }, [invite]);

  const showCheckinButton = useMemo(() => {
    if (invite) {
      return (
        !invite.customization?.takePictureOnlyOnKiosk &&
        !!invite.customization?.timeToCheckinBeforeMeetingEnabled &&
        invite.authorizationStatus !== 'DENIED' &&
        !invite.checkinAt &&
        !!invite.expectedAt &&
        isBefore(new Date(), new Date(invite.expectedAt))
      );
    }
    return false;
  }, [invite]);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        navigation.navigate(AppRoute.PERSONAL_INVITES);
        return true;
      };

      const BackHandlerListener = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandlerListener.remove();
    }, [])
  );

  useEffect(() => {
    if (success) {
      animation.current?.play();
      Animated.timing(progress, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: true,
      }).start();

      setTimeout(() => {
        setSuccess(false);
      }, 2500);
    }
  }, [success]);

  useEffect(() => {
    const id = route.params?.id;

    invitesApi
      .getAInvite(id)
      .then((invite) => {
        setInvite(invite);
        setLoadingInvite(false);
      })
      .catch((error) => {
        setLoadingInvite(false);
        logSentryException({
          error,
          file: 'PersonalInvites.js',
          message: 'Error at loadPersonalInvites function',
        });
      });
  }, []); // eslint-disable-line

  async function handleCheckinPress() {
    if (disableCheckinButton) {
      showMessage({
        message: translate('error'),
        type: 'danger',
      });
      return;
    }

    try {
      const visit = await checkinApi.checkin({
        visitId: invite.id,
        meetingId: invite.meetingId,
      });

      const newlist = activeInvites.map((invit: any) => {
        if (invit.id === visit.id) {
          return {
            ...invit,
            ...visit,
          };
        }
        return invit;
      });

      setInvite({
        ...invite,
        ...visit,
      });

      setSuccess(true);
      dispatch(updatePersonalActiveInvites(newlist));
    } catch (error) {
      showMessage({
        message: translate('error'),
        type: 'danger',
      });
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <Spin visible={loading || loadingInvite} />
      <Header
        handleBack={() => {
          if (route.params?.goBack) {
            navigation.goBack();
          } else if (route.params?.backToInvites) {
            navigation.navigate(AppRoute.PERSONAL_INVITES);
          } else {
            navigation.goBack();
            navigation.goBack();
          }
        }}
        title={translate('personalInvite.mainTitle')}
      />

      <ScrollView
        style={styles.content}
        contentContainerStyle={{ paddingBottom: 48 }}
        showsHorizontalScrollIndicator={false}
      >
        <View style={styles.qrcodeContainer}>
          {!loadingInvite && <QRCodeDisplay invite={invite} />}
        </View>
        {!!showCheckinButton && (
          <RectButton
            style={{
              ...styles.checkinButton,
              ...(disableCheckinButton && styles.disabledCheckinButton),
            }}
            onPress={handleCheckinPress}
          >
            <Text
              style={{
                ...styles.checkinButtonText,
                ...(disableCheckinButton && styles.disabledCheckinText),
              }}
            >
              {translate('personalInvite.doCheckin')}
            </Text>
          </RectButton>
        )}
        {success && (
          <LottieView
            ref={animation}
            style={styles.lottieView}
            source={check}
            progress={progress}
            // OR find more Lottie files @ https://lottiefiles.com/featured
            // Just click the one you like, place that file in the 'assets' folder to the left, and replace the above 'require' statement
          />
        )}
        {!loadingInvite && (
          <InviteContainer
            meeting={invite.meeting}
            host={invite.host}
            meetingCompany={invite.company}
            meetingCompanyCustomization={invite.customization}
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 24,
    backgroundColor: themes.colors.white,
  },
  qrcodeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  checkinButton: {
    borderRadius: 4,
    backgroundColor: themes.colors.success,
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 12,
  },

  checkinButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: themes.colors.white,
  },
  disabledCheckinButton: {
    backgroundColor: transparentize(0.5, themes.colors.graySecondary),
  },
  disabledCheckinText: {
    color: transparentize(0.5, themes.colors.black),
  },
  lottieView: {
    width: 100,
    height: 100,
    backgroundColor: '#fff',
    alignSelf: 'center',
    marginBottom: 12,
  },
});
