import { UserModel } from '_/models/user';
import { action } from 'typesafe-actions';

import { PatchUserAPIData } from './types';

export const setUserInfoRequest = (user: UserModel) => {
  return action('@user/SET_USER_INFO_REQUEST', { user });
};

export const setUserInfoSuccess = (user: UserModel) => {
  return action('@user/SET_USER_INFO_SUCCESS', { user });
};

export const setUserInfoFailure = () => {
  return action('@user/SET_USER_INFO_FAILURE');
};

export const getUserInfoRequest = () => action('@user/GET_USER_INFO_REQUEST');

export const getUserInfoSuccess = (user: UserModel) =>
  action('@user/GET_USER_INFO_SUCCESS', { user });

export const getUserInfoFailure = () => action('@user/GET_USER_INFO_FAILURE');

export const updateProfileRequest = ({
  id,
  email,
  name,
  phone,
  position,
  countryCode,
  country,
  lang,
}: PatchUserAPIData) =>
  action('@user/UPDATE_PROFILE_REQUEST', {
    id,
    name,
    email,
    phone,
    position,
    countryCode,
    country,
    lang,
  });

export const updateProfileSuccess = ({
  id,
  email,
  name,
  phone,
  position,
  countryCode,
  country,
}: PatchUserAPIData) =>
  action('@user/UPDATE_PROFILE_SUCCESS', {
    id,
    email,
    name,
    phone,
    position,
    countryCode,
    country,
  });

export const updateProfileFailure = () => action('@user/UPDATE_PROFILE_FAILURE');

export function updateUserPictureRequest({
  id,
  uri,
  image,
}: {
  id: string;
  uri: string;
  image?: string;
}) {
  return {
    type: '@user/UPDATE_USER_PICTURE_REQUEST',
    payload: { id, uri, image },
  };
}

export function updateUserPictureSuccess({
  pictureUrl,
  pictureThumbUrl,
}: {
  pictureUrl: string;
  pictureThumbUrl: string | null;
}) {
  return {
    type: '@user/UPDATE_USER_PICTURE_SUCCESS',
    payload: { pictureUrl, pictureThumbUrl },
  };
}

export function updateUserPictureFailure() {
  return {
    type: '@user/UPDATE_USER_PICTURE_FAILURE',
  };
}

// export function updateUserLoginInfoRequest() {
//   return {
//     type: '@user/UPDATE_USER_LOGIN_INFO_REQUEST',
//   };
// }

// export function updateUserLoginInfoSuccess() {
//   return {
//     type: '@user/UPDATE_USER_LOGIN_INFO_SUCCESS',
//   };
// }

// export function updateUserLoginInfoFailure() {
//   return {
//     type: '@user/UPDATE_USER_LOGIN_INFO_FAILURE',
//   };
// }
