import { useNavigation } from '@react-navigation/native';
import { MeetingsModel } from '@w3lcome/types';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { AppRoute, PersonalInviteRouteProps } from '_/routes/types';
import moment from 'moment';
import React, { useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Animated, Dimensions, StyleSheet, TouchableOpacity } from 'react-native';

const { width } = Dimensions.get('screen');

interface PendingInviteCardProps {
  meeting: MeetingsModel;
  hideModal: () => void;
  index: number;
}

export default function PendingInviteCard({ index, meeting, hideModal }: PendingInviteCardProps) {
  const navigation = useNavigation<PersonalInviteRouteProps>();
  const animatedValue = useRef(new Animated.Value(0));
  const animatedOpacity = useRef(new Animated.Value(0));
  const { t: translate } = useTranslation();

  useEffect(() => {
    const delay = index * 200;
    Animated.sequence([
      Animated.delay(delay),
      Animated.parallel([
        Animated.spring(animatedValue.current, {
          toValue: 1,
          velocity: 0.1,
          useNativeDriver: true,
        }),
        Animated.timing(animatedOpacity.current, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }),
      ]),
    ]).start();
  }, [animatedValue, index]);

  const handleOpenPendingInvite = useCallback(() => {
    navigation.navigate(AppRoute.PERSONAL_MEETING, {
      id: meeting.id,
      isLink: false,
    });
    hideModal();
  }, [hideModal, meeting.id, navigation]);

  if (!meeting) return null;
  const date = moment(meeting?.start).tz(meeting?.timezone);

  return (
    <TouchableOpacity onPress={handleOpenPendingInvite}>
      <Animated.View
        style={[
          styles.container,
          {
            transform: [
              {
                translateX: animatedValue.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: [width, 0],
                }),
              },
            ],
            opacity: animatedOpacity.current.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 1],
            }),
          },
        ]}
      >
        <Text style={styles.title}>{translate('pendingInvites.subject')}</Text>
        <Text style={styles.info}>{meeting.title}</Text>

        <View style={styles.infoGroup}>
          <View style={styles.innerGroup}>
            <Text style={styles.title}>{translate('pendingInvites.from')}</Text>
            <Text style={styles.info}>{meeting.host?.name}</Text>
          </View>
          <View style={styles.innerGroup}>
            <Text style={styles.title}>{translate('pendingInvites.date')}</Text>
            <Text style={styles.info}>{moment(date).format('DD/MM/YYYY')}</Text>
          </View>
          <View>
            <Text style={styles.title}>{translate('pendingInvites.hour')}</Text>
            <Text style={styles.info}>{moment(date).format('HH:mm')}</Text>
          </View>
        </View>
      </Animated.View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderWidth: 1,
    borderRadius: 6,
    marginBottom: 10,
    borderColor: themes.colors.lightPrimary,
  },
  infoGroup: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: 8,
  },
  innerGroup: {
    marginRight: 16,
  },
  title: {
    fontFamily: fontFamily.light,
    textTransform: 'uppercase',
    fontSize: 10,
  },
  info: {
    fontFamily: fontFamily.bold,
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 16,
  },
});
