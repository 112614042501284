import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, Dimensions, StyleSheet, ActivityIndicator } from 'react-native';

const { width } = Dimensions.get('screen');

export default function LoadingInvite() {
  const { t: translate } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.title}>{translate('personalMeeting.receivedInvite')}</Text>
        <Image style={styles.image} source={themes.images.receivedInvite} />
        <Text style={styles.loading}>{translate('personalMeeting.loadingInvite')}</Text>
        <ActivityIndicator size="large" color={themes.colors.primary} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.white,
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 24,
    color: themes.colors.darkPrimary,
  },
  title: {
    fontFamily: fontFamily.medium,
    fontSize: 24,
    paddingVertical: 20,
  },
  loading: {
    fontFamily: fontFamily.regular,
    fontSize: 14,
    paddingVertical: 30,
    color: themes.colors.darkPrimary,
  },
  image: {
    width: '100%',
    height: width / 2,
    resizeMode: 'cover',
  },
});
