import { Feather } from '@expo/vector-icons';
import { HostsModel } from '@w3lcome/types';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import LineItem from './LineItem';

export default function LinesDisplay() {
  const isZoneAuthorizationEnabled = useSelector(
    (state: any) => state.company?.customization?.isZoneAuthorizationEnabled
  );

  const { t: translate } = useTranslation();

  const host: HostsModel = useSelector((state: any) => state.host);

  if (!isZoneAuthorizationEnabled) {
    return null;
  }

  function renderAllowedZones() {
    if (host.hostZones?.length === 0) {
      return (
        <View style={styles.container}>
          <Text style={styles.linesText}>
            <Feather name="map" size={16} />
            &nbsp; {translate('qrcode.emptyLines')}
          </Text>
        </View>
      );
    }
    return (
      <View>
        <Text style={styles.linesText}>
          <Feather name="map" size={16} />
          &nbsp; {translate('qrcode.allowedLines')}
        </Text>
        {host.hostZones?.map(({ zone }, index) => (
          <LineItem key={index}>{zone?.name || translate('qrcode.unidentifiedLine')}</LineItem>
        ))}
      </View>
    );
  }

  function renderHostZone() {
    if (!host.zone) {
      return null;
    }

    return (
      <View style={{ marginBottom: 20 }}>
        <Text style={styles.linesText}>
          <Feather name="map" size={16} />
          &nbsp; {translate('qrcode.driverLines')}
        </Text>
        <LineItem key={host.zone.id}>
          {host.zone.name || translate('qrcode.unidentifiedLine')}
        </LineItem>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {renderHostZone()}
      {renderAllowedZones()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingBottom: 40,
    paddingTop: 20,
    borderTopWidth: 1,
    borderColor: '#eee',
  },
  linesText: {
    fontSize: 18,
    fontFamily: fontFamily.medium,
    marginBottom: 20,
    alignSelf: 'center',
  },
});
