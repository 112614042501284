import { Feather } from '@expo/vector-icons';
import { Text } from '_/components';
import Input from '_/components/Input';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

interface EditableInfoCardProps {
  value?: string;
  label: string;
  onChangeText: (text: string) => void;
  handleSubmit: () => void;
  children?: React.ReactNode;
}

const EditableInfoCard: React.FC<EditableInfoCardProps> = ({
  label,
  value,
  children,
  onChangeText,
  handleSubmit: onSubmit,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t: translate } = useTranslation();

  const handleSubmit = () => {
    setIsEditing(false);
    onSubmit();
  };

  const handleEdit = () => {
    if (!isEditing) setIsEditing(true);
  };

  return (
    <View style={styles.card}>
      <View style={styles.cardText}>
        <Text style={styles.title}>{label}</Text>
        {isEditing ? (
          <Input
            label=""
            autoFocus
            returnKeyType="done"
            autoCapitalize="words"
            textContentType="name"
            autoCorrect={false}
            value={value}
            onBlur={handleSubmit}
            onSubmitEditing={handleSubmit}
            onChangeText={onChangeText}
          />
        ) : (
          <Text style={value && value.trim() ? styles.value : styles.placeholderText}>
            {value && value.trim() ? value : translate('visitor.insertNotes')}
          </Text>
        )}
        {children}
      </View>

      <View style={styles.cardIcon}>
        <TouchableOpacity
          hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
          onPress={handleEdit}
        >
          <Feather
            name={isEditing ? 'check' : 'edit-2'}
            style={isEditing ? styles.iconEditing : styles.icon}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default EditableInfoCard;

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
    marginBottom: 20,
  },
  cardText: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  cardIcon: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    fontSize: 14,
  },
  value: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginTop: 4,
    marginBottom: 8,
  },
  placeholderText: {
    color: themes.colors.lightSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginTop: 4,
    marginBottom: 8,
  },
  icon: {
    fontSize: 22,
    color: themes.colors.darkQuaternary,
    padding: 8,
  },
  iconEditing: {
    fontSize: 26,
    color: themes.colors.primary,
    padding: 8,
  },
});
