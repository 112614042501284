import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import { appConfig } from '_/config/app';
import { useCallback, useRef } from 'react';
import io from 'socket.io-client';

export function useSocket() {
  const socket = useRef<SocketIOClient.Socket>();

  const initSocket = useCallback((userId: string) => {
    const baseURL: string = appConfig.apiUrl as string;

    socket.current = io(`${baseURL}?apphostuserid=${userId}`, {
      transports: ['websocket'],
      upgrade: false,
    });

    const app = feathers();

    app.configure(socketio(socket.current));

    return app;
  }, []);

  const closeSocket = useCallback(() => {
    socket.current?.close();
  }, []);

  return { initSocket, closeSocket };
}
