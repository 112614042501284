import { CustomizationsModel } from '@w3lcome/types';

import api from './base';
import { GetListType } from './types/GetListType';

class CustomizationApi {
  public getCustomizationOfCurrentCompany = async (companyId: string) => {
    const { data } = await api.get<GetListType<CustomizationsModel>>(`/customizations`, {
      params: { companyId },
    });
    return data;
  };
}

export default new CustomizationApi();
