import React, { useMemo } from 'react';
import { View, useWindowDimensions, ViewProps, Platform, Dimensions } from 'react-native';

const { width: browserWidth, height: browserHeight } = Dimensions.get('window');

export const getWebMaxWidth = (width = browserWidth, height = browserHeight) => {
  const maxWidth = height + (width - height) / 2;
  const tabletWidth = 768;

  return Math.min(maxWidth, tabletWidth);
};

const ContainerWidthLimit: React.FC<ViewProps> = ({ children, style, ...rest }) => {
  const { width, height } = useWindowDimensions();

  const maxWidth = useMemo(() => {
    if (width > height || Platform.OS === 'web') {
      return getWebMaxWidth(width, height);
    }

    return width;
  }, [width, height]);

  return (
    <View {...rest} style={[{ flex: 1, marginHorizontal: 'auto', width: maxWidth }, style]}>
      {children}
    </View>
  );
};

export default ContainerWidthLimit;
