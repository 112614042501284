const themes = {
  colors: {
    primary: '#F47B2C',
    secondary: '#7E8082',
    warning: '#F2B10C',
    danger: '#E74B4B',
    success: '#2BC37F',
    defaultBlue: '#4D8AF0',
    white: '#ffffff',
    black: '#000000',
    darkPrimary: '#111111',
    darkSecondary: '#222222',
    darkTertiary: '#333333',
    darkQuaternary: '#444444',
    grayPrimary: '#666666',
    graySecondary: '#999999',
    lightPrimary: '#eeeeee',
    lightSecondary: '#dddddd',
    lightTertiary: '#cccccc',
    lightQuaternary: '#f4f6f9',
    lightBlue: '#8EA6B3',
    lightGreen: '#128C7E',
  },
  fonts: {
    bold: require('_/assets/invenzi/fonts/OpenSans-Bold.ttf'),
    light: require('_/assets/invenzi/fonts/OpenSans-Light.ttf'),
    medium: require('_/assets/invenzi/fonts/OpenSans-SemiBold.ttf'),
    regular: require('_/assets/invenzi/fonts/OpenSans-Regular.ttf'),
    semibold: require('_/assets/invenzi/fonts/OpenSans-SemiBold.ttf'),
  },
  images: {
    logo: require('_/assets/invenzi/logo.png'),
    noCompany: require('_/assets/invenzi/no-company.png'),
    emailVerification: require('_/assets/invenzi/emailVerification.png'),
    forgotPassword: require('_/assets/invenzi/forgot-password.png'),
    empty: require('_/assets/invenzi/empty.png'),
    bigCalendar: require('_/assets/invenzi/big-calendar.png'),
    intro1: require('_/assets/invenzi/intro1.png'),
    intro2: require('_/assets/invenzi/intro2.png'),
    intro3: require('_/assets/invenzi/intro3.png'),
    emailSent: require('_/assets/invenzi/email-sent.png'),
    loginImage: require('_/assets/invenzi/login-img.png'),
    packageImg: require('_/assets/invenzi/package.png'),
    spinner: require('_/assets/invenzi/spinner.png'),
    avatarSquare: require('_/assets/invenzi/avatar-square.png'),
    checkCircle: require('_/assets/invenzi/check-circle.png'),
    noMeeting: require('_/assets/invenzi/no-meeting.png'),
    qrcodeIcon: require('_/assets/qrcode-icon.png'),
    calendarIcon: require('_/assets/calendar-icon.png'),
    calendar: require('_/assets/calendar.png'),
    googleIcon: require('_/assets/google-icon.png'),
    switchCamera: require('_/assets/switchCamera.png'),
    qrCodeBorder: require('_/assets/qrCodeBorder.png'),
    splash: require('_/assets/invenzi/splash.png'),
    emptyInvites: require('_/assets/invenzi/emptyInvites.png'),
    receivedInvite: require('_/assets/invenzi/receivedInvite.png'),
    notFoundInvite: require('_/assets/invenzi/notFoundInvite.png'),
    meetingError: require('_/assets/invenzi/meetingError.png'),
    qrcodeFrame: require('_/assets/invenzi/qrcodeFrame.png'),
    qrcodeExpired: require('_/assets/invenzi/qrcodeExpired.png'),
  },
};

export default themes;
