import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { VisitsModel } from '@w3lcome/types';
import { Text } from '_/components';
import themes from '_/constants/themes';
import { useVisitsContext } from '_/hooks/VisitsProvider';
import { AppRoute, MeetingProps } from '_/routes/types';
import React, { useMemo } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import styles from './styles';

interface InviteeProp {
  invitee: VisitsModel;
}

export default function Invitee({ invitee }: InviteeProp) {
  const navigation = useNavigation<MeetingProps>();
  const { setSelectedVisit } = useVisitsContext();
  const { customization } = useSelector((state: any) => state.company);

  const primaryKey = useMemo<keyof VisitsModel>(
    () => customization.primaryKey && customization.primaryKey.toLowerCase(),
    [customization.primaryKey]
  );

  async function handleOpenVisitorCard() {
    setSelectedVisit(invitee);
    navigation.navigate(AppRoute.VISITOR);
  }

  return (
    <View style={styles.container}>
      <View style={styles.statusContainer}>
        {invitee.checkinAt ? (
          <Image source={themes.images.checkCircle} />
        ) : (
          <>
            {!invitee.isCanceled ? (
              <Feather name="check-circle" style={styles.icon} />
            ) : (
              <Feather name="divide-circle" style={styles.iconCanceled} />
            )}
          </>
        )}
      </View>
      <TouchableOpacity onPress={handleOpenVisitorCard} style={styles.dataContainer}>
        <>
          {!!invitee.name && <Text style={styles.name}>{invitee.name}</Text>}
          {!!invitee[primaryKey] && <Text style={styles.primaryKey}>{invitee[primaryKey]}</Text>}
        </>
      </TouchableOpacity>
    </View>
  );
}
