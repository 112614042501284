import AsyncStorage from '@react-native-async-storage/async-storage';
import { WAccessIntegrationsModel } from '@w3lcome/types';
import logSentryException from '_/helpers/logSentryException';
import { initI18n, translate } from '_/locales';
import { CompanyModel } from '_/models/company';
import { companyApi, customizationApi, userApi, waccessIntegrationApi } from '_/services/api';
import { GetListType } from '_/services/api/types/GetListType';
import { showMessage } from 'react-native-flash-message';
import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import { CustomizationResponse } from './types';
import { getHostOfTheActualCompanyRequest } from '../host/actions';
import { setLoading } from '../loading/actions';
// import { setPersonalProfile } from '../auth/actions';

export function* getUserCompanies() {
  try {
    yield put(setLoading());

    const { data } = yield call(companyApi.getCompanies);
    const companies: CompanyModel[] = data;

    yield put(setLoading());
    yield put(actions.getUserCompaniesSuccess(companies));
  } catch (error) {
    const message = error.response?.data?.message;
    yield call(logSentryException, {
      error: error?.response?.data,
      file: 'company/sagas.ts',
      message,
    });
    yield put(setLoading());
    yield put(actions.getUserCompaniesFailure());
  }
}

export function* setUserCurrentCompany({
  payload,
}: ActionType<typeof actions.setUserCurrentCompanyRequest>): Generator<any, void, any> {
  const userId: string = yield select((state) => state.user.id);

  try {
    yield put(setLoading());
    const { company, updateUser } = payload;
    const { id } = company;

    if (updateUser) {
      yield call(userApi.updateUser, {
        id: userId,
        currentCompanyId: id,
      });

      return;
    }

    yield put(
      getHostOfTheActualCompanyRequest({
        companyId: id as string,
        userId: userId as string,
      })
    );

    const { data }: CustomizationResponse = yield call(
      customizationApi.getCustomizationOfCurrentCompany,
      id as string
    );

    const { data: waccessIntegrationData }: GetListType<WAccessIntegrationsModel> = yield call(
      waccessIntegrationApi.getIntegration,
      id as string
    );

    // salvar na Tabela User, a sua currentCompanyId
    // yield call(userApi.setCurrentCompany, { id });

    // pegar dados do host

    // const companyInfo = {
    //   ...company,
    //   role,
    //   waccessIntegration: isInvenzi()
    //     ? true
    //     : informationsData.waccessIntegration || false,
    //   trialUntil: informationsData.trialUntil || '',
    //   waccessIntegrationOnlyQRCode:
    //     informationsData.waccessIntegrationOnlyQRCode || false,
    //   id,
    //   hostSector: sector || '',
    //   hideExpiredQRCode: informationsData.hideExpiredQRCode || false,
    // };

    yield put(setLoading());
    yield put(
      actions.setUserCurrentCompanySuccess({
        company,
        customization: data[0],
        waccessIntegration: waccessIntegrationData?.[0],
      })
    );

    if (data[0].translationId) {
      yield AsyncStorage.setItem('@w3lcome/translationId', data[0].translationId);
      initI18n();
      return;
    }

    const existingTranslationId = yield AsyncStorage.getItem('@w3lcome/translationId');
    if (existingTranslationId) {
      yield AsyncStorage.removeItem('@w3lcome/translationId');
      initI18n();
    }
  } catch (error) {
    logSentryException({
      error,
      file: 'company/sagas.ts',
      message: 'Error at setUserCurrentCompany function',
    });
    yield put(setLoading());
    yield put(actions.setUserCurrentCompanyFailure());
  }
}

// export function* syncStoreWithDatabase() {
//   try {
//     const result = yield select(state => state.company);

//     const companyFromStore = { ...result };
//     delete companyFromStore.hostSector;

//     const companyId = companyFromStore.id;

//     if (!companyId) return;

//     const companyRef = `companies/${companyId}/informations`;
//     const companyFromDB = yield call([fbService, 'getData'], companyRef);

//     const {
//       name,
//       planType,
//       pictureUrl,
//       trialUntil,
//       planIsActive,
//       waccessIntegration,
//       waccessIntegrationOnlyQRCode,
//       isisZoneAuthorizationEnabled,
//       hideExpiredQRCode,
//     } = companyFromDB.val();

//     const companyToUpdate = {
//       id: companyId,
//       name: name || '',
//       planType: planType || '',
//       pictureUrl: pictureUrl || '',
//       trialUntil: trialUntil || '',
//       planIsActive: planIsActive || false,
//       waccessIntegration: waccessIntegration || false,
//       waccessIntegrationOnlyQRCode: waccessIntegrationOnlyQRCode || false,
//       isisZoneAuthorizationEnabled: isisZoneAuthorizationEnabled || false,
//       hideExpiredQRCode: hideExpiredQRCode || false,
//     };

//     const objAreEqual = yield call(
//       isSameObjects,
//       companyToUpdate,
//       companyFromStore
//     );

//     if (!objAreEqual) {
//       yield put(syncCompanyWithDatabaseSuccess(companyToUpdate));
//     }
//   } catch (error) {
//     console.tron.log('File: company/sagas.js', error);
//     yield put(syncCompanyWithDatabaseFailure());
//   }
// }

export function* getWAccessIntegration() {
  const companyId: string = yield select((state) => state.company.id);
  yield put(setLoading());
  try {
    const { data: waccessIntegrationData }: GetListType<WAccessIntegrationsModel> = yield call(
      waccessIntegrationApi.getIntegration,
      companyId as string
    );

    yield put(
      actions.getWAccessIntegrationSuccess({
        waccessIntegration: waccessIntegrationData?.[0],
      })
    );
  } catch (error) {
    logSentryException({
      error,
      file: 'company/sagas.ts',
      message: 'Error at setUserCurrentCompany function',
    });
    yield put(setLoading());
  }
}

export default all([
  takeLatest('@company/GET_USER_COMPANIES_REQUEST', getUserCompanies),
  takeLatest('@company/SET_USER_CURRENT_COMPANY_REQUEST', setUserCurrentCompany),
  takeLatest('@company/GET_WACCESS_INTEGRATION_REQUEST', getWAccessIntegration),
  // takeLatest(
  //   '@company/SYNC_COMPANY_WITH_DATABASE_REQUEST',
  //   syncStoreWithDatabase
  // ),
]);
