import { Feather } from '@expo/vector-icons';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React, { ReactNode } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useDropdownList } from './useDropdownList';
interface DropdownListProps {
  title: string;
  children: ReactNode;
}

export function DropdownList({ children, title }: DropdownListProps) {
  const { handleTouchableOpacity, toggleExpand } = useDropdownList();

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.rowContainer} onPress={handleTouchableOpacity}>
        <View>
          <Text style={styles.title}>{title}</Text>
        </View>

        <Feather name={toggleExpand ? 'chevron-up' : 'chevron-down'} style={styles.icons} />
      </TouchableOpacity>
      {toggleExpand && children && children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  icons: {
    fontSize: 20,
    color: themes.colors.primary,
  },
  title: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
    fontSize: 12,
  },
});
