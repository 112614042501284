import { useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { AppRoute, PersonalMeetingNotFoundRouteProps } from '_/routes/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';

interface PersonalMeetingNotFoundProps {
  message?: string;
}

export default function PersonalMeetingNotFound({ message = '' }: PersonalMeetingNotFoundProps) {
  const navigation = useNavigation<PersonalMeetingNotFoundRouteProps>();
  const { t: translate } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Image source={themes.images.notFoundInvite} />
        <Text style={styles.text}>{translate(`personalMeeting.${message}`)}</Text>

        <TouchableOpacity
          style={styles.button}
          onPress={() => navigation.navigate(AppRoute.PERSONAL_INVITES)}
        >
          <Text style={styles.buttonText}>{translate('personalMeeting.goHome')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.white,
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 24,
    color: themes.colors.darkPrimary,
  },
  text: {
    fontFamily: fontFamily.medium,
    fontSize: 24,
    paddingVertical: 20,
    color: themes.colors.danger,
  },
  button: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    borderRadius: 6,
    backgroundColor: themes.colors.primary,
  },
  buttonText: {
    textAlign: 'center',
    color: themes.colors.white,
  },
});
