import { Feather } from '@expo/vector-icons';
import { MeetingTypesModel } from '@w3lcome/types';
import { Text } from '_/components';
import Input from '_/components/Input';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { lighten, transparentize } from 'polished';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import RNModalSelector, { IOption } from 'react-native-modal-selector';

interface ModalSelectorProps {
  value: string | number;
  onChange?(option: IOption): void;
  disabled?: boolean;
  meetingTypes: MeetingTypesModel[];
}

interface DataProps {
  key: string | number;
  label: string;
}

const ModalMeetingTypeSelector: React.FC<ModalSelectorProps> = ({
  value: valueFromProps,
  disabled = false,
  meetingTypes,
  onChange,
}) => {
  const { t: translate, i18n } = useTranslation();

  const valueMeetingType = useMemo(() => {
    const meetingTypeFound = meetingTypes?.find((t: MeetingTypesModel) => t.id === valueFromProps);

    const placeholders = meetingTypeFound?.placeholder;
    return (
      placeholders?.[i18n.language] ||
      meetingTypeFound?.placeholder?.[appConfig.lang.default] ||
      meetingTypeFound?.name
    );
  }, [valueFromProps]);

  const data: DataProps[] = useMemo(() => {
    const initData: DataProps[] = [];

    meetingTypes.forEach((type: MeetingTypesModel) => {
      const placeholders = type?.placeholder;

      const field = {
        key: type.id,
        label:
          placeholders?.[i18n.language] || type?.placeholder?.[appConfig.lang.default] || type.name,
      };

      initData.push(field);
    });

    return initData;
  }, [meetingTypes]);

  return (
    <RNModalSelector
      onChange={onChange}
      cancelStyle={styles.cancelStyle}
      overlayStyle={styles.overlayStyle}
      cancelText={translate('general.cancel')}
      cancelTextStyle={styles.cancelTextStyle}
      optionTextStyle={styles.optionTextStyle}
      sectionTextStyle={styles.sectionTextStyle}
      optionContainerStyle={styles.optionContainerStyle}
      disabled={disabled}
      data={data}
      renderItem={({ item }) => {
        return <Text>{item.label}</Text>;
      }}
    >
      <View
        style={[
          disabled && styles.inputGroupDisabled,
          !meetingTypes.length && { backgroundColor: lighten(0.3, themes.colors.danger) },
        ]}
      >
        <Input
          label=""
          style={[styles.formInput, styles.dropdownFormInput]}
          value={valueMeetingType}
          editable={!disabled}
          selectTextOnFocus
          icon={!disabled ? 'chevron-down' : ''}
          iconStyles={styles.icon}
          extraContentLeft={
            <>
              {disabled && (
                <Feather
                  name="lock"
                  style={[styles.icon, !meetingTypes.length && { color: themes.colors.danger }]}
                />
              )}
            </>
          }
        />
      </View>
    </RNModalSelector>
  );
};

export default ModalMeetingTypeSelector;

const styles = StyleSheet.create({
  formInput: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 18,
    paddingVertical: 8,
  },
  dropdownFormInput: {
    textAlign: 'center',
  },
  inputGroupDisabled: {
    paddingHorizontal: 8,
  },
  cancelStyle: {
    backgroundColor: themes.colors.white,
    padding: 16,
  },
  cancelTextStyle: {
    fontFamily: fontFamily.semibold,
  },
  sectionTextStyle: {
    fontWeight: 'bold',
    fontFamily: fontFamily.bold,
    padding: 6,
  },
  optionTextStyle: {
    color: themes.colors.darkTertiary,
    fontFamily: fontFamily.regular,
    padding: 4,
  },
  optionContainerStyle: {
    backgroundColor: themes.colors.white,
    maxHeight: 360,
  },
  overlayStyle: {
    justifyContent: 'center',
    backgroundColor: transparentize(0.3, themes.colors.black),
    flex: 1,
    padding: 40,
  },
  icon: {
    color: themes.colors.darkQuaternary,
    fontSize: 18,
  },
});
