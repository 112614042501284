import { WAccessIntegrationsModel } from '@w3lcome/types';

import api from './base';
import { GetListType } from './types/GetListType';

class WAccessIntegrationApi {
  public getIntegration = async (companyId: string) => {
    const { data } = await api.get<GetListType<WAccessIntegrationsModel>>(`/waccess-integrations`, {
      params: { companyId },
    });
    return data;
  };
}

export default new WAccessIntegrationApi();
