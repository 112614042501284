import { Text } from '_/components';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleSheet } from 'react-native';

export default function SignupTerms() {
  const { t: translate } = useTranslation();

  return (
    <Text style={styles.termsText} onPress={() => Linking.openURL(appConfig.privacyPolicyUrl)}>
      {translate('signup.terms')}{' '}
      <Text style={{ textDecorationLine: 'underline' }}>{translate('signup.privacyPolicy')}</Text>{' '}
      {translate('signup.and')}{' '}
      <Text
        style={{ textDecorationLine: 'underline' }}
        onPress={() => Linking.openURL(appConfig.termsConditionsUrl)}
      >
        {translate('signup.termsOfService')}
      </Text>
    </Text>
  );
}

const styles = StyleSheet.create({
  termsText: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.light,
    fontSize: 12,
  },
});
