import { MeetingLinksModel, MeetingLinksType } from '@w3lcome/types';
import { v4 as uuidv4 } from 'uuid';

import api from './base';
import { GetListType } from './types/GetListType';

export interface CreateAppointmentPayload {
  id: string;
  lang: string;
  picture: string;
  userId: string;
  visit: any;
  isLink: boolean;
}

class MeetingLinksApi {
  private appointmentsLink = '/appointments';
  private path = '/meeting-links';

  public getMeetingByLink = async (meetingId: string) => {
    const url = `${this.path}/${meetingId}`;

    const { data } = await api.get(url);

    return data;
  };

  public getLinksByMeeting = async (meetingId: string) => {
    const { data } = await api.get<GetListType<MeetingLinksModel>>(`${this.path}`, {
      params: { meetingId, linkType: MeetingLinksType.PUBLIC },
    });

    return data;
  };

  public createMeetingLink = async (body: any) => {
    const url = `${this.path}/`;

    const { data } = await api.post<MeetingLinksModel>(url, body);

    return data;
  };

  public createAppointment = async ({
    id,
    isLink,
    visit,
    lang,
    picture,
    userId,
  }: CreateAppointmentPayload) => {
    let visitPicture = '';
    if (!!picture && !picture.includes('https://')) {
      visitPicture = picture.replace(/^data:image\/jpeg;base64,/, '');
    }

    const body = {
      ...(isLink ? { meetingLinkId: id } : { meetingId: id }),
      visit: {
        ...visit,
        id: uuidv4(),
        userId,
        lang,
      },
      visitPicture,
    };

    const { data } = await api.post(`${this.appointmentsLink}`, body);

    return data;
  };
}

export default new MeetingLinksApi();
