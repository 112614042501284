import { InviteVisitModel } from '_/interfaces/InviteVisitModel';

import api from './base';

class InvitesApi {
  private invitesPath = '/invites';
  private pendingPath = '/pending';

  public getAInvite = async (id: string) => {
    const { data } = await api.get(`${this.invitesPath}/${id}`);

    return data;
  };

  public getActives = async () => {
    const { data } = await api.get(`${this.invitesPath}`, {
      params: { actives: true },
    });

    return data.invites as InviteVisitModel[];
  };

  public getPast = async ({ limit }: { limit: number }) => {
    const { data } = await api.get(`${this.invitesPath}`, {
      params: { past: true, limit },
    });

    return data.invites;
  };

  public getPending = async () => {
    const { data } = await api.get(`${this.invitesPath}${this.pendingPath}`);

    return data.pending;
  };
}

export default new InvitesApi();
