import { all, put, call, takeLatest, select } from 'redux-saga/effects';

import logSentryException from '../../../helpers/logSentryException';
import { meetingTypesApi } from '../../../services/api';
import * as actions from './actions';
import { MeetingTypesAPIResponseData } from './types';

export function* getMeetingTypes({ payload }: any) {
  try {
    const companyId: string = yield select((state) => state.company.id);

    const { params } = payload;

    const response: MeetingTypesAPIResponseData = yield call(
      meetingTypesApi.getMeetingTypes,
      {
        ...params,
        companyId,
      },
      {}
    );

    yield put(actions.getMeetingTypesSuccess(response));
  } catch (error) {
    const message = error.response?.data?.message;
    yield put(actions.getMeetingTypesFailure());
    yield call(logSentryException, {
      error: error.response.data,
      file: 'meetings/sagas.ts',
      message,
    });
  }
}

export default all([takeLatest('@meetings/GET_MEETING_TYPES_REQUEST', getMeetingTypes)]);
