import { Feather } from '@expo/vector-icons';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import * as Clipboard from 'expo-clipboard';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Share, Linking, TouchableOpacity, Image, Modal, StyleSheet } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import ImageViewer from 'react-native-image-zoom-viewer';

import Text from '../Text';

interface InfoCardProps {
  copy?: boolean;
  value?: string | null;
  share?: boolean;
  whatsapp?: boolean;
  title: string;
  img?: string;
  children?: React.ReactNode;
}

const InfoCard: React.FC<InfoCardProps> = ({
  title,
  value,
  copy = false,
  whatsapp = false,
  share = false,
  children,
  img,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t: translate } = useTranslation();

  function handleOpenWhatsApp() {
    const phone = `${value?.replace(/\D+/g, '')}`;
    Linking.openURL(`http://api.whatsapp.com/send?phone=${phone}`);
  }

  function handleCopyToClipboard() {
    if (!value) {
      return;
    }

    Clipboard.setString(value);
    showMessage({
      message: translate('visitor.copyToClipboard'),
      type: 'info',
    });
  }

  async function shareLink() {
    if (!value) {
      return;
    }

    try {
      const result = await Share.share({
        message: `Join our meeting by clicking on the following link: ${value}`,
      });

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
          showMessage({
            message: translate('visitor.meetingLinkSuccess'),
            type: 'success',
          });
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      showMessage({
        message: translate('visitor.meetingLink'),
        type: 'danger',
      });
    }
  }

  if (!value && !children) {
    return null;
  }

  return (
    <>
      <Modal visible={modalVisible} transparent>
        {!!img && <ImageViewer imageUrls={[{ url: img }]} onClick={() => setModalVisible(false)} />}
      </Modal>
      <View style={styles.card}>
        <View style={styles.cardText}>
          {!!title && <Text style={styles.title}>{title}</Text>}
          {!img && !!value && (
            <Text fontType="medium" style={styles.value}>
              {value}
            </Text>
          )}
          {!!img && !!value && (
            <TouchableOpacity style={styles.imageContainer} onPress={() => setModalVisible(true)}>
              <Text fontType="medium" style={styles.value}>
                {value}
              </Text>
              <Image style={styles.image} source={{ uri: img }} />
            </TouchableOpacity>
          )}
          {children}
        </View>
        {(copy || whatsapp || share) && (
          <View style={styles.cardIcon}>
            {copy && (
              <TouchableOpacity onPress={handleCopyToClipboard}>
                <Feather name="copy" style={styles.icon} />
              </TouchableOpacity>
            )}
            {whatsapp && (
              <TouchableOpacity style={styles.whatsapp} onPress={handleOpenWhatsApp}>
                <Image
                  source={require('../../assets/whatsapp.png')}
                  style={{ width: 24, height: 24 }}
                />
              </TouchableOpacity>
            )}
            {share && (
              <TouchableOpacity onPress={shareLink}>
                <Feather name="send" style={styles.icon} />
              </TouchableOpacity>
            )}
          </View>
        )}
      </View>
    </>
  );
};

export default InfoCard;

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
  },
  cardText: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  whatsapp: {
    marginLeft: 24,
  },
  cardIcon: {
    paddingLeft: 8,
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    fontSize: 14,
  },
  value: {
    color: themes.colors.darkSecondary,
    fontSize: 16,
    marginTop: 4,
    marginBottom: 8,
    marginRight: 8,
  },
  icon: {
    fontSize: 24,
    color: themes.colors.darkQuaternary,
  },
  imageContainer: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    width: '80%',
    justifyContent: 'space-between',
  },
  image: {
    width: 36,
    height: 36,
    borderRadius: 4,
    marginRight: 8,
  },
});
