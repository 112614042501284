export function getMeetingDetailsRequest(meeting) {
  return {
    type: '@meeting/GET_MEETING_DETAILS_REQUEST',
    payload: { meeting },
  };
}

export function getMeetingDetailsSuccess(meeting) {
  return {
    type: '@meeting/GET_MEETING_DETAILS_SUCCESS',
    payload: { meeting },
  };
}

export function createMeetingRequest(meeting) {
  return {
    type: '@meeting/CREATE_MEETING_REQUEST',
    payload: { meeting },
  };
}

export function updateMeetingRequest(meeting) {
  return {
    type: '@meeting/UPDATE_MEETING_REQUEST',
    payload: { meeting },
  };
}

export function removeMeetingRequest({ meetingId }) {
  return {
    type: '@meeting/REMOVE_MEETING_REQUEST',
    payload: { meetingId },
  };
}

export function createMeetingSuccess(meeting) {
  return {
    type: '@meeting/CREATE_MEETING_SUCCESS',
    payload: { meeting },
  };
}

export function updateMeetingSuccess(meeting) {
  return {
    type: '@meeting/UPDATE_MEETING_SUCCESS',
    payload: { meeting },
  };
}

export function removeMeetingSuccess(meeting) {
  return {
    type: '@meeting/REMOVE_MEETING_SUCCESS',
    payload: { meeting },
  };
}

export function newMeetingRequest() {
  return {
    type: '@meeting/NEW_MEETING_REQUEST',
  };
}

export function meetingFailure() {
  return {
    type: '@meeting/MEETING_FAILURE',
  };
}

export function createLinkRequest(limit, meetingId, companyId) {
  return {
    type: '@meeting/CREATE_LINK_REQUEST',
    payload: { limit, meetingId, companyId },
  };
}

export function createLinkSuccess(newLink) {
  return {
    type: '@meeting/CREATE_LINK_SUCCESS',
    payload: { newLink },
  };
}

export function createLinkFailure() {
  return {
    type: '@meeting/CREATE_LINK_FAILURE',
  };
}

export function loadMeetingsRequest(year) {
  return {
    type: '@meeting/LOAD_MEETINGS_REQUEST',
    payload: { year },
  };
}

export function loadMeetingsSuccess(markedDates, meetings) {
  return {
    type: '@meeting/LOAD_MEETINGS_SUCCESS',
    payload: { markedDates, meetings },
  };
}

export function loadMeetingsFailure() {
  return {
    type: '@meeting/LOAD_MEETINGS_FAILURE ',
  };
}
