import { Feather } from '@expo/vector-icons';
import { MeetingsModel } from '@w3lcome/types';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';

import PendingInviteCard from './PendingInviteCard';

interface PendingInvitesModalProps {
  visible: boolean;
  hideModal: () => void;
  meetings: MeetingsModel[];
}

export default function PendingInvitesModal({
  meetings,
  visible,
  hideModal,
}: PendingInvitesModalProps) {
  const { t: translate } = useTranslation();

  return (
    <Modal
      isVisible={visible}
      style={styles.modal}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={{ flex: 1 }}>
        <View style={styles.modalTitle}>
          <View style={styles.modalTitleView}>
            <Feather name="x" style={[styles.modalIcon, { opacity: 0 }]} />
            <Text style={styles.modalTitleText}>{translate('pendingInvites.pendingInvites')}</Text>
            <TouchableOpacity
              onPress={hideModal}
              hitSlop={{ top: 40, left: 40, bottom: 40, right: 40 }}
            >
              <Feather name="x" style={styles.modalIcon} />
            </TouchableOpacity>
          </View>
        </View>

        <ScrollView style={styles.scrollView}>
          {meetings.map((meeting, index) => (
            <PendingInviteCard
              index={index}
              key={meeting.id}
              meeting={meeting}
              hideModal={hideModal}
            />
          ))}
        </ScrollView>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
    marginTop: 130,
    paddingHorizontal: 24,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: themes.colors.white,
  },
  scrollView: {
    marginTop: 30,
  },
  modalTitleView: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalTitle: {
    marginTop: 24,
  },
  modalTitleText: {
    fontFamily: fontFamily.medium,
    fontSize: 20,
  },
  modalIcon: {
    fontSize: 20,
    color: themes.colors.darkQuaternary,
  },
});
