import { DeliveriesModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';

import api from './base';
import { GetListType } from './types/GetListType';

class DeliveriesApi {
  public getDelivery = async (deliveryId: string, params?: any) => {
    const { data } = await api.get<DeliveriesModel>(`/deliveries/${deliveryId}`, params);
    return data;
  };

  public getDeliveries = async (params: any, config?: AxiosRequestConfig) => {
    const { data } = await api.get<GetListType<DeliveriesModel>>(`/deliveries`, {
      ...config,
      params,
    });
    return data;
  };

  public getDeliveryHosts = async (params: any, config?: AxiosRequestConfig) => {
    const { data } = await api.get(`/delivery-hosts`, {
      ...config,
      params,
    });
    return data;
  };

  public update = async (deliveryId: string, values: Partial<DeliveriesModel>) => {
    const { data } = await api.patch<DeliveriesModel>(`/deliveries/${deliveryId}`, values);
    return data;
  };
}

export default new DeliveriesApi();
