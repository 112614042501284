import { Platform } from 'react-native';
import { compose, createStore, applyMiddleware, Reducer, Middleware } from 'redux';

import { AuthAction, AuthState } from './modules/auth/types';
import { CompanyAction, CompanyState } from './modules/company/types';
import { HostAction, HostState } from './modules/host/types';
import { MeetingTypesState, MeetingTypesAction } from './modules/meeting-types/types';
import { MeetingsAction, MeetingsState } from './modules/meetings/types';
import { PersonalAction, PersonalState } from './modules/personal/types';
import { UserAction, UserState } from './modules/user/types';

export interface StoreState {
  auth: AuthState;
  user: UserState;
  host: HostState;
  company: CompanyState;
  loading: any;
  settings: any;
  personal: PersonalState;
  meetings: MeetingsState;
  meetingTypes: MeetingTypesState;
}

export type StoreAction =
  | AuthAction
  | UserAction
  | CompanyAction
  | HostAction
  | PersonalAction
  | MeetingsAction
  | MeetingTypesAction;

export default (reducers: Reducer<StoreState, StoreAction>, middlewares: Middleware[]) => {
  const enhancer =
    __DEV__ && Platform.OS !== 'web'
      ? compose(console.tron.createEnhancer(), applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares);

  return createStore(reducers, enhancer);
};
