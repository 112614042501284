import { Feather } from '@expo/vector-icons';
import { TopMenu, RatingModal, DateRangePicker, PushNotificationsModal, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { DeliveryItem } from '_/components/DeliveryItem';
import { VisitItem } from '_/components/VisitItem';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { useDeliveriesContext } from '_/hooks/DeliveriesProvider';
import { useVisitsContext } from '_/hooks/VisitsProvider';
import { DataType } from '_/interfaces/DataType';
import { FormatedDeliveriesModel } from '_/interfaces/FormatedDeliveriesModel';
import { FormatedVisitsModel } from '_/interfaces/FormatedVisitsModel';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Image,
  ScrollView,
  RefreshControl,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';

export default function Home() {
  const [expanded, setExpanded] = useState(true);
  const { t: translate } = useTranslation();

  const { loading } = useSelector((state: any) => state.loading);

  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();

  const [refreshing, setRefreshing] = useState(false);

  const {
    getVisists,
    expectedVisits,
    visits,
    visitsToday,
    loading: loadingVisits,
    getCheckinAndExpectedVisits,
  } = useVisitsContext();

  const {
    deliveries,
    getDeliveries,
    getDeliveriesToday,
    deliveriesToday,
    loading: loadingDeliveries,
  } = useDeliveriesContext();

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    getDeliveriesToday(true);
    getCheckinAndExpectedVisits(true);
    const time = Math.floor(Math.random() * 1500) + 500;

    setTimeout(() => setRefreshing(false), time);
  }, [getDeliveriesToday, getCheckinAndExpectedVisits]);

  function renderHistory() {
    if (loadingDeliveries || loadingVisits) {
      return (
        <View style={styles.emptyContainer}>
          <ActivityIndicator size="large" color="#222" />
        </View>
      );
    }

    if ([...visits, ...deliveries].length > 0) {
      return (
        <ScrollView showsVerticalScrollIndicator={false}>
          {[...visits, ...deliveries].map((item) =>
            isVisit(item) ? (
              <VisitItem key={item.id} visit={item} />
            ) : (
              <DeliveryItem key={item.id} delivery={item} />
            )
          )}
        </ScrollView>
      );
    }

    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.emptyText}>{translate('home.selectDateMessage')}</Text>
        <Image source={themes.images.bigCalendar} style={styles.emptyImage} />
      </View>
    );
  }

  function handleExpandToggle() {
    setExpanded(!expanded);
  }

  function handleChangeDates(startDate: Date, endDate: Date) {
    setSelectedEndDate(endDate);
    setSelectedStartDate(startDate);
    getVisists({ startDate, endDate });
    getDeliveries({ startDate, endDate });
  }

  function isVisit(
    data: FormatedVisitsModel | FormatedDeliveriesModel
  ): data is FormatedVisitsModel {
    return data.type === DataType.visit;
  }

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <TopMenu />
        <View style={styles.content}>
          <ScrollView
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            showsVerticalScrollIndicator={false}
          >
            {expectedVisits?.length > 0 && (
              <View style={{ paddingTop: 12 }}>
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>{translate('home.scheduled')}</Text>
                  <View style={styles.rowContainer}>
                    <Text style={styles.lengthText}>{expectedVisits?.length}</Text>
                    <Feather name="user" style={styles.icons} />
                  </View>
                </View>

                <View
                  style={expanded ? expectedVisits?.length > 1 && { flex: 1 } : { height: 125 }}
                >
                  <ScrollView
                    nestedScrollEnabled
                    contentContainerStyle={{ flexGrow: 1 }}
                    showsVerticalScrollIndicator={false}
                  >
                    {expectedVisits.map((item, index) => {
                      if (!expanded && index > 0) {
                        return null;
                      }
                      return <VisitItem key={item.id} visit={item} />;
                    })}
                  </ScrollView>
                  {expectedVisits?.length > 1 && (
                    <TouchableOpacity
                      onPress={handleExpandToggle}
                      style={styles.expandButtonContainer}
                    >
                      <Text style={styles.expandButtonText}>
                        {expanded ? translate('home.hide') : translate('home.show')}
                      </Text>
                      <Feather
                        style={styles.iconChevron}
                        name={expanded ? 'chevron-up' : 'chevron-down'}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            )}

            {[...visitsToday, ...deliveriesToday].length > 0 && (
              <View style={{ marginTop: 16 }}>
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>{translate('home.today')}</Text>
                  <View style={styles.rowContainer}>
                    <View style={styles.rowContainer}>
                      <Text style={styles.lengthText}>{visitsToday.length}</Text>
                      <Feather name="user" style={styles.icons} />
                    </View>

                    <View style={[styles.rowContainer, { marginLeft: 8 }]}>
                      <Text style={styles.lengthText}>{deliveriesToday.length}</Text>
                      <Feather name="package" style={styles.icons} />
                    </View>
                  </View>
                </View>

                <ScrollView showsVerticalScrollIndicator={false}>
                  {[...visitsToday, ...deliveriesToday]
                    .sort((a, b) => +b.date - +a.date)
                    .map((item) =>
                      isVisit(item) ? (
                        <VisitItem key={item.id} visit={item} />
                      ) : (
                        <DeliveryItem key={item.id} delivery={item} />
                      )
                    )}
                </ScrollView>
              </View>
            )}
            <View style={{ marginTop: 16 }}>
              <View style={styles.titleContainer}>
                <Text style={styles.title}>{translate('home.history')}</Text>
                <DateRangePicker
                  selectedEndDate={selectedEndDate}
                  selectedStartDate={selectedStartDate}
                  handleChangeDates={handleChangeDates}
                />
              </View>
              {renderHistory()}
            </View>
          </ScrollView>
        </View>
        {!loading && !loadingVisits && !loadingDeliveries && <PushNotificationsModal />}
        <RatingModal />
      </View>
    </ContainerWidthLimit>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    flex: 1,
  },
  content: {
    flex: 1,
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
  },
  title: {
    color: themes.colors.darkSecondary,
    fontSize: 24,
    marginRight: 6,
  },
  lengthText: {
    color: themes.colors.darkSecondary,
    fontSize: 14,
    marginRight: 4,
  },
  expandButtonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 8,
  },
  expandButtonText: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.medium,
    fontSize: 12,
    marginRight: 8,
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingVertical: 56,
    paddingHorizontal: 12,
  },
  emptyImage: {
    resizeMode: 'contain',
    flex: 1,
  },
  emptyText: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginBottom: 24,
    textAlign: 'center',
  },
  icons: {
    fontSize: 12,
    color: themes.colors.darkSecondary,
  },
  iconChevron: {
    fontSize: 14,
    color: themes.colors.darkQuaternary,
  },
});
