import { combineReducers } from 'redux';

import { StoreState } from '../createStore';
import auth from './auth/reducer';
import company from './company/reducer';
// import waccess from './waccess/reducer';
import host from './host/reducer';
import loading from './loading/reducer';
import meetingTypes from './meeting-types/reducer';
import meeting from './meeting/reducer';
import meetings from './meetings/reducer';
import personal from './personal/reducer';
import settings from './settings/reducer';
import user from './user/reducer';

export default combineReducers<StoreState>({
  auth,
  user,
  company,
  host,
  loading,
  settings,
  // waccess,
  meeting,
  personal,
  meetings,
  meetingTypes,
});
