import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface useConfirmPasswordFieldProps {
  password: string;
  setPasswordConfirmed: (value: boolean) => void;
}

export function useConfirmPasswordField({
  password,
  setPasswordConfirmed,
}: useConfirmPasswordFieldProps) {
  const [confirmPassword, setConfirmPassword] = useState('');
  const { t: translate } = useTranslation();

  function onChangeText(text: string) {
    setConfirmPassword(text);
  }

  useEffect(() => {
    if (confirmPassword.trim() !== '' && password === confirmPassword) {
      setPasswordConfirmed(true);
    } else {
      setPasswordConfirmed(false);
    }
  }, [password, confirmPassword]);

  return {
    onChangeText,
    translate,
    confirmPassword,
  };
}
