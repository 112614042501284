import { Text } from '_/components';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleSheet } from 'react-native';

export default function SigninTerms() {
  const { t: translate } = useTranslation();

  return (
    <Text style={styles.termsText}>
      {translate('signin.signInTerms')}
      <Text
        style={[styles.termsText, { textDecorationLine: 'underline' }]}
        onPress={() => Linking.openURL(appConfig.termsConditionsUrl)}
      >
        {translate('signin.termsOfService')}
      </Text>{' '}
      {translate('signin.and')}
      <Text
        style={[styles.termsText, { textDecorationLine: 'underline' }]}
        onPress={() => Linking.openURL(appConfig.privacyPolicyUrl)}
      >
        {translate('signin.privacyPolicy')}
      </Text>{' '}
    </Text>
  );
}

const styles = StyleSheet.create({
  termsText: {
    textAlign: 'center',
    fontFamily: fontFamily.light,
    fontSize: 12,
    paddingTop: 12,
    color: themes.colors.graySecondary,
  },
});
