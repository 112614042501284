import { HostsModel, HostZonesModel } from '@w3lcome/types';
import { audios } from '_/assets/audios';
import { InfoCard, PictureContainer, Header, Text } from '_/components';
import Button from '_/components/Button';
import themes from '_/constants/themes';
import { Audio } from 'expo-av';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, SafeAreaView, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

interface EmployeeModalProps {
  host: HostsModel;
  handleClose: () => void;
  zonesAllowed: Partial<HostZonesModel>[] | undefined;
  isZoneAuthorized: boolean | null;
  message?: string;
}

export default function EmployeeModal({
  host,
  handleClose,
  zonesAllowed,
  isZoneAuthorized,
  message,
}: EmployeeModalProps) {
  const { t: translate } = useTranslation();

  const hostZone = useSelector((state: any) => state.host.zone);

  useEffect(() => {
    if (!hostZone) {
      return () => {};
    }

    const timer = setTimeout(() => {
      handleClose();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [hostZone, handleClose]);

  useEffect(() => {
    async function playSound() {
      if (!hostZone) {
        return () => {};
      }

      const soundObject = new Audio.Sound();
      if (isZoneAuthorized) {
        await soundObject.loadAsync(audios.success);
      } else {
        await soundObject.loadAsync(audios.error);
      }
      return soundObject.playFromPositionAsync(0);
    }

    playSound();

    return undefined;
  }, [hostZone, isZoneAuthorized]);

  function renderLineStatus() {
    if (!hostZone) return <View />;
    let allowed = false;

    if (isZoneAuthorized) {
      allowed = true;
    }

    const renderMessage =
      message || (allowed ? translate('employeeModal.allowed') : translate('employeeModal.denied'));

    return (
      <View
        style={[
          styles.statusView,
          {
            backgroundColor: allowed ? themes.colors.success : themes.colors.danger,
          },
        ]}
      >
        <Text style={styles.statusText}>{renderMessage}</Text>
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header handleBack={handleClose} title={translate('general.employee')} />
      <ScrollView>
        <PictureContainer pictureUrl={host?.pictureUrl} />

        <View style={styles.textContainer}>
          {renderLineStatus()}

          <InfoCard copy title={translate('general.name')} value={host.name} />
          <InfoCard title={translate('employeeModal.position')} value={host?.position} />
          <InfoCard title={translate('employeeModal.department')} value={host?.sector} />
          {!!zonesAllowed && (
            <InfoCard
              title={translate('employeeModal.lines')}
              value={zonesAllowed.map(({ zone }) => zone?.name || '').join(' - ')}
            />
          )}
        </View>
      </ScrollView>
      <View style={styles.closeView}>
        <Button onPress={handleClose}>
          <Text style={styles.closeBtnText}>{translate('general.close')}</Text>
        </Button>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  textContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 24,
    marginBottom: 60,
  },
  closeView: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: themes.colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    backgroundColor: themes.colors.white,
  },

  closeBtnText: {
    color: themes.colors.white,
  },
  statusText: {
    textAlign: 'center',
    color: themes.colors.white,
  },
  statusView: {
    padding: 8,
    borderRadius: 4,
    marginBottom: 24,
    marginHorizontal: 6,
  },
});
