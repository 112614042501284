import { AllowedHostsModel } from '_/models/allowedHosts';

import db from './SqliteDatabase';

const init = () => {
  db.transaction((tx) => {
    tx.executeSql(
      'CREATE TABLE IF NOT EXISTS allowed_hosts_table (id TEXT NOT NULL, cardNumber TEXT NOT NULL, source_name TEXT NOT NULL, eventdatetime TIMESTAMP NOT NULL, eventhwid INTEGER NOT NULL, PRIMARY KEY (id));'
    );
  });
};

const create = (allowedHost: AllowedHostsModel) => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'INSERT INTO allowed_hosts_table (id, cardNumber, source_name, eventdatetime, eventhwid) VALUES (?, ?, ?, ?, ?);',
        [
          allowedHost.id,
          allowedHost.cardNumber,
          allowedHost.source_name,
          `${allowedHost.eventdatetime}`,
          allowedHost.eventhwid,
        ],
        (_, { rowsAffected }) => {
          if (rowsAffected > 0) resolve(true);
          else
            reject(
              new Error(
                `Error inserting allowedHost for cardNumber: ${allowedHost.cardNumber} and source_name: ${allowedHost.source_name}`
              )
            );
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const deleteOne = (id: string, source_name: string) => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'DELETE FROM allowed_hosts_table WHERE id = ? AND source_name = ?;',
        [id, source_name],
        (_, { rowsAffected }) => {
          if (rowsAffected > 0) resolve(true);
          else
            reject(
              new Error(`Error removing hostZone for id: ${id} and source_name: ${source_name}`)
            );
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const all = (): Promise<AllowedHostsModel[]> => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'SELECT * FROM allowed_hosts_table;',
        [],
        (_, { rows }) => {
          const allowedHosts: AllowedHostsModel[] = [];
          for (let i = 0; i < rows.length; ++i) {
            const item = rows.item(i);
            allowedHosts.push(item);
          }
          resolve(allowedHosts);
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const deleteTable = () => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'DROP TABLE allowed_hosts_table;',
        [],
        (_, { rowsAffected }) => {
          resolve(rowsAffected);
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

export default {
  create,
  all,
  deleteTable,
  deleteOne,
  init,
};
