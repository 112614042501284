import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import i18n, { InitOptions, StringMap, TOptions } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next, TFunction } from 'react-i18next';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async (language: any) => {
    const persistedLocale = await AsyncStorage.getItem('@w3lcome/language');
    if (!persistedLocale) {
      return language(Localization.locale);
    }
    language(persistedLocale);
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

export async function initI18n() {
  const translationSheetId = await AsyncStorage.getItem('@w3lcome/translationId');

  const timestamp = new Date().getTime();

  const loadPath = translationSheetId
    ? `${Constants.expoConfig?.extra?.assetsURL}/locales/${translationSheetId}/app-host/{{lng}}/translation.json?timestamp=${timestamp}`
    : `${Constants.expoConfig?.extra?.assetsURL}/locales/app-host/{{lng}}/translation.json?timestamp=${timestamp}`;

  const initConfig: InitOptions = {
    fallbackLng: 'en',
    compatibilityJSON: 'v3',
    debug: false,
    backend: {
      loadPath,
      crossDomain: true,
    },
    cache: {
      enabled: true,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  };

  i18n
    .use(languageDetector as any)
    .use(initReactI18next)
    .use(Backend)
    .init(initConfig);
}

initI18n();

export const translate: TFunction<'translation', undefined> = (
  key: string,
  options?: string | TOptions<StringMap> | undefined
) => i18n.t(key, options);

export default i18n;
