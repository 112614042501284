import { DeliveriesModel, DeliveryHostsModel } from '@w3lcome/types';
import logSentryException from '_/helpers/logSentryException';
import { deliveriesApi } from '_/services/api';
import { endOfDay, startOfDay } from 'date-fns';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useUser } from './UserProvider';
import { useActiveAppState } from './useActiveAppState';
import { DataType } from '../interfaces/DataType';
import { FormatedDeliveriesModel } from '../interfaces/FormatedDeliveriesModel';

interface GetDeliveriesData {
  startDate?: Date;
  endDate?: Date;
}

interface DeliveriesData {
  deliveries: FormatedDeliveriesModel[];
  deliveriesToday: FormatedDeliveriesModel[];
  selectedDelivery: DeliveriesModel | undefined;
  setSelectedDelivery(data: DeliveriesModel | undefined): void;
  getDeliveries(value: GetDeliveriesData): void;
  updateDelivery(deliveryId: string, data: Partial<DeliveriesModel>): Promise<void>;
  getDeliveriesToday(showLoading?: boolean): void;
  loading: boolean;
}

const DeliveriesContext = createContext<DeliveriesData>({} as DeliveriesData);

type DeliveriesType = {
  children: React.ReactNode;
};

export const DeliveriesProvider: React.FC<DeliveriesType> = ({ children }) => {
  const [selectedDelivery, setSelectedDelivery] = useState<DeliveriesModel>();
  const [deliveries, setDeliveries] = useState<FormatedDeliveriesModel[]>([]);
  const [deliveriesToday, setDeliveriesToday] = useState<FormatedDeliveriesModel[]>([]);
  const [loading, setLoading] = useState(false);
  const { isActive } = useActiveAppState();

  const { id: hostId } = useSelector((state: any) => state.host);
  const companyId = useSelector((state: any) => state.company.id);

  const { feathersApp } = useUser();

  const getDeliveriesToday = useCallback(
    async (showLoading = false) => {
      const startOfDayDate = startOfDay(new Date());
      showLoading && setLoading(showLoading);

      try {
        const { data } = await deliveriesApi.getDeliveryHosts({
          hostId,
          'createdAt[$gte]': startOfDayDate,
          '$sort[createdAt]': -1,
        });

        if (data.length > 0) {
          const deliveryHostIdList = data.map((data: DeliveryHostsModel) => {
            return data.deliveryId;
          });

          const deliveriesResult = await deliveriesApi.getDeliveries({
            'id[$in]': deliveryHostIdList,
            'createdAt[$gte]': startOfDayDate,
            '$sort[createdAt]': -1,
          });

          const verifiedDeliveriesResult = deliveriesResult.data.map(
            (delivery: DeliveriesModel) => {
              return {
                ...delivery,
                date: delivery.createdAt,
                type: DataType.delivery,
              };
            }
          );

          setDeliveriesToday(verifiedDeliveriesResult);
          showLoading && setLoading(false);
          return;
        }

        setDeliveriesToday([]);
        showLoading && setLoading(false);
      } catch (error) {
        showLoading && setLoading(false);
        logSentryException({
          error,
          file: 'DeliveriesProvider.tsx',
          message: 'Error at getDeliveriesToday function',
        });
      }
    },
    [hostId]
  );

  useEffect(() => {
    if (isActive) {
      getDeliveriesToday();
    }
  }, [isActive, getDeliveriesToday]);

  const loadDeliveryCreated = useCallback(
    (data: DeliveriesModel) => {
      if (companyId !== data?.companyId) {
        return;
      }

      const formattedDelivery: FormatedDeliveriesModel = {
        ...data,
        date: data.createdAt,
        type: DataType.delivery,
      };

      setDeliveriesToday((prevDeliveries) => {
        const mergedDeliveries = [...prevDeliveries, formattedDelivery].sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        return mergedDeliveries;
      });
    },
    [companyId]
  );

  useEffect(() => {
    feathersApp?.service('deliveries').on('created', loadDeliveryCreated);
    feathersApp?.service('ipad/deliveries').on('created', loadDeliveryCreated);

    return () => {
      feathersApp?.service('deliveries').off('created', loadDeliveryCreated);
      feathersApp?.service('ipad/deliveries').off('created', loadDeliveryCreated);
    };
  }, [feathersApp, hostId, loadDeliveryCreated]);

  async function getDeliveries({ startDate, endDate }: GetDeliveriesData) {
    const dateStartOfDay = startOfDay(startDate as Date);
    const dateEndOfDay = endOfDay(endDate as Date);
    setLoading(true);

    const params =
      startDate && endDate
        ? {
            companyId,
            'createdAt[$gte]': dateStartOfDay,
            'createdAt[$lte]': dateEndOfDay,
            '$sort[createdAt]': -1,
          }
        : {
            companyId,
            '$sort[createdAt]': -1,
          };

    try {
      const { data } = await deliveriesApi.getDeliveries(params);

      const deliveriesArr = data.map((delivery: DeliveriesModel) => {
        return {
          ...delivery,
          date: delivery.createdAt,
          type: DataType.delivery,
        };
      });
      setDeliveries(deliveriesArr);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      logSentryException({
        error,
        file: 'DeliveriesProvider.tsx',
        message: 'Error at getDeliveries function',
      });
    }
  }

  function updateDeliveryLocally(delivery: DeliveriesModel) {
    setDeliveries((prevDeliveries) =>
      prevDeliveries.map((d) => (d.id === delivery.id ? { ...d, ...delivery } : d))
    );
    setDeliveriesToday((prevDeliveries) =>
      prevDeliveries.map((d) => (d.id === delivery.id ? { ...d, ...delivery } : d))
    );
    if (selectedDelivery?.id === delivery.id) {
      setSelectedDelivery((prevDelivery) =>
        prevDelivery ? { ...prevDelivery, ...delivery } : undefined
      );
    }
  }

  async function updateDelivery(deliveryId: string, data: Partial<DeliveriesModel>) {
    const updatedDelivery = await deliveriesApi.update(deliveryId, data);
    updateDeliveryLocally(updatedDelivery);
  }

  return (
    <DeliveriesContext.Provider
      value={{
        deliveries,
        selectedDelivery,
        setSelectedDelivery,
        getDeliveries,
        getDeliveriesToday,
        updateDelivery,
        deliveriesToday,
        loading,
      }}
    >
      {children}
    </DeliveriesContext.Provider>
  );
};

export function useDeliveriesContext() {
  const context = useContext(DeliveriesContext);

  if (!context) {
    throw new Error('useDeliveries must be used within a DeliveriesProvider');
  }

  return context;
}
