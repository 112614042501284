import feathers from '@feathersjs/feathers';
import { userApi } from '_/services/api';
import {
  getUserCompaniesRequest,
  setUserCurrentCompanyRequest,
} from '_/store/modules/company/actions';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSocket } from './useSocket';

interface UserContextData {
  feathersApp: feathers.Application | undefined;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

type UserType = {
  children: React.ReactNode;
};

export const UserProvider: React.FC<UserType> = ({ children }) => {
  const user = useSelector((state: any) => state.user);

  const dispatch = useDispatch();

  const [feathersApp, setFeathersApp] = useState<feathers.Application>();

  const { initSocket, closeSocket } = useSocket();

  useEffect(() => {
    if (user.id) {
      setFeathersApp(initSocket(user.id));
    } else {
      closeSocket();
    }

    return () => {
      closeSocket();
    };
  }, [user.id]);

  useEffect(() => {
    if (user.id) {
      dispatch(getUserCompaniesRequest());
      getCurrentCompany();
    }
  }, [user.id, dispatch]);

  const changeCurrentCompany = useCallback(
    (data?: { userId: string; currentCompanyId: string }) => {
      if (user.id === data?.userId && user.currentCompanyId !== data?.currentCompanyId) {
        const userCompany = user.companies.find(
          (company: any) => company.id === data?.currentCompanyId
        );
        dispatch(setUserCurrentCompanyRequest(userCompany, false));
      }
    },
    [user.companies, user.id, user.currentCompanyId, dispatch]
  );

  useEffect(() => {
    feathersApp?.service('users').on('patched', changeCurrentCompany);

    return () => {
      feathersApp?.service('users').off('patched', changeCurrentCompany);
    };
  }, [feathersApp, user, changeCurrentCompany]);

  async function getCurrentCompany() {
    const { currentCompanyId } = await userApi.getUser(user.id);

    const userCompany = user.companies.find((company: any) => company.id === currentCompanyId);
    dispatch(setUserCurrentCompanyRequest(userCompany, false));
  }

  return <UserContext.Provider value={{ feathersApp }}>{children}</UserContext.Provider>;
};

export function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
}
