import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';

import Text from '../Text';

interface DividerProps {
  dashed?: boolean;
  color?: string;
  borderColor?: string;
  orientation?: 'left' | 'center' | 'right';
  containerStyles?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

const Divider: React.FC<DividerProps> = ({
  dashed = false,
  orientation = 'center',
  color = transparentize(0.85, themes.colors.black),
  borderColor = '#e8e8e8',
  containerStyles,
  children,
}) => {
  return (
    <View style={[styles.container, containerStyles]}>
      <View
        style={[
          styles.line,
          { borderColor },
          dashed && styles.dashed,
          orientation === 'left' ? styles.shortWidth : { flex: 1 },
        ]}
      />
      {children && <Text style={[styles.text, { color }]}>{children}</Text>}
      <View
        style={[
          styles.line,
          { borderColor },
          dashed && styles.dashed,
          orientation === 'right' ? styles.shortWidth : { flex: 1 },
        ]}
      />
    </View>
  );
};

export default Divider;

const styles = StyleSheet.create({
  container: {
    height: 24,
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 6,
  },
  line: {
    height: 24,
    borderBottomWidth: 1,
    transform: [{ translateY: -12 }],
  },
  shortWidth: {
    width: 20,
  },
  dashed: {
    borderStyle: 'dashed',
  },
  text: {
    paddingHorizontal: 24,
    fontSize: 16,
  },
});
