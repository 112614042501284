export enum SystemRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum UserCompanyRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SECURITY = 'SECURITY',
  FRONTDESK = 'FRONTDESK',
  HOST = 'HOST',
  EMPLOYEE = 'EMPLOYEE',
}
