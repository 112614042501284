import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React, { ReactNode } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { ifIphoneX } from 'react-native-iphone-x-helper';

interface CustomTabBarProps {
  activeTintColor?: string;
  inactiveTintColor?: string;
  activeBackgroundColor?: string;
  inactiveBackgroundColor?: string;
  onTabPress: (data: any) => void;
  onTabLongPress: (data: any) => void;
  getAccessibilityLabel: (data: any) => string | undefined;
  navigation: any;
  renderIcon: (data: any) => ReactNode;
}

export default function CustomTabBar({
  navigation,
  renderIcon,
  onTabPress,
  onTabLongPress,
  getAccessibilityLabel,
  activeTintColor = themes.colors.darkPrimary,
  inactiveTintColor = themes.colors.darkQuaternary,
  activeBackgroundColor = transparentize(0.06, themes.colors.primary),
  inactiveBackgroundColor = themes.colors.white,
}: CustomTabBarProps) {
  const { routes, index: activeRouteIndex } = navigation.state;

  return (
    <View style={styles.shadow}>
      <View style={styles.container}>
        {routes.map((route: any, routeIndex: any) => {
          const isRouteActive = routeIndex === activeRouteIndex;
          const tintColor = isRouteActive ? activeTintColor : inactiveTintColor;
          const backgroundColor = isRouteActive ? activeBackgroundColor : inactiveBackgroundColor;

          return (
            <TouchableOpacity
              key={routeIndex}
              onPress={() => onTabPress({ route })}
              onLongPress={() => onTabLongPress({ route })}
              style={[styles.tabButton, { backgroundColor }]}
              accessibilityLabel={getAccessibilityLabel({ route })}
            >
              {renderIcon({ route, focused: isRouteActive, tintColor })}
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: ifIphoneX(64, 52),
  },
  tabButton: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingBottom: ifIphoneX(16, 0),
  },
  shadow: {
    shadowColor: themes.colors.black,
    backgroundColor: themes.colors.white,
    elevation: 20,
    shadowRadius: 2,
    shadowOpacity: 0.1,
    shadowOffset: { height: 0.5, width: 0 },
  },
});
