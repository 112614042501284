import { ZonesModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';

import api from './base';

class ZonesApi {
  public getZone = async (id: string, config?: AxiosRequestConfig): Promise<ZonesModel> => {
    const { data } = await api.get(`/zones/${id}`, {
      ...config,
    });

    return data;
  };
}

export default new ZonesApi();
