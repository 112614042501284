import { EventNotificationsModel } from '@w3lcome/types';
import { notificationEventsApi } from '_/services/api';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { useSelector } from 'react-redux';

import { useUser } from './UserProvider';

interface NotificationsData {
  getNotifications(): Promise<void>;
  notifications: EventNotificationsModel[];
  notificationsUnread: EventNotificationsModel[];
  setNotifications(value: EventNotificationsModel[]): void;
  setNotificationsUnread(value: EventNotificationsModel[]): void;
  loading: boolean;
  MarkAllAsRead(): void;
  updateNotificationsLocally(notification: EventNotificationsModel): void;
}

const NotificationsContext = createContext<NotificationsData>({} as NotificationsData);

type NotificationsType = {
  children: React.ReactNode;
};

export const NotificationsProvider: React.FC<NotificationsType> = ({ children }) => {
  const [notifications, setNotifications] = useState<EventNotificationsModel[]>([]);
  const [notificationsUnread, setNotificationsUnread] = useState<EventNotificationsModel[]>([]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const { feathersApp } = useUser();

  const companyId = useSelector((state: any) => state.company.id);
  const userId = useSelector((state: any) => state.user.id);

  const updateNotificationsLocally = useCallback(
    (notification: EventNotificationsModel) => {
      const notificationIndex = notifications.findIndex((e) => e.id === notification.id);
      const notificationUnreaded = notificationsUnread.find((e) => e.id === notification.id);

      if (notificationIndex >= 0) {
        if (notificationUnreaded && notification.readAt) {
          setNotificationsUnread((prevNotifs) =>
            prevNotifs.filter((e) => e.id !== notification.id)
          );
        }

        setNotifications((prevNotifs) => {
          const newNotifications = [...prevNotifs];
          newNotifications[notificationIndex] = notification;
          return newNotifications;
        });
      } else {
        if (!notification.readAt) {
          setNotificationsUnread((prevNotifs) => [notification, ...prevNotifs]);
        }

        setNotifications((prevNotifs) => [notification, ...prevNotifs]);
      }
    },
    [notifications, notificationsUnread]
  );

  const getNotifications = useCallback(async () => {
    if (!userId || !companyId) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await notificationEventsApi.getNotification(companyId, userId, {
        '$sort[createdAt]': -1,
        $limit: 100,
      });

      const notificationsUnread = data.filter((e) => !e.readAt);

      setNotifications(data);
      setNotificationsUnread(notificationsUnread);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage({
        message: t('informationIsNotAvailable'),
        type: 'danger',
      });
    }
  }, [companyId, userId]);

  async function MarkAllAsRead() {
    try {
      const notificationsUnreadIds: string[] = notificationsUnread.map((e) => e.id);
      await notificationEventsApi.patchtNotificationsUnread(notificationsUnreadIds);
      getNotifications();
    } catch (error) {
      showMessage({
        message: `${t('general.error')}. ${'personalMeeting.tryAgain'}`,
        type: 'danger',
      });
    }
  }

  useEffect(() => {
    getNotifications();
  }, [userId, companyId]);

  useEffect(() => {
    const realTimeNotifications = (data: EventNotificationsModel) => {
      if (data.companyId === companyId && data.userId === userId) {
        updateNotificationsLocally(data);
      }
    };

    feathersApp?.service('notification-events').on('created', realTimeNotifications);

    return () => {
      feathersApp?.service('notification-events').off('created', realTimeNotifications);
    };
  }, [feathersApp, userId, companyId, updateNotificationsLocally]);

  return (
    <NotificationsContext.Provider
      value={{
        getNotifications,
        notifications,
        setNotifications,
        setNotificationsUnread,
        notificationsUnread,
        loading,
        MarkAllAsRead,
        updateNotificationsLocally,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotificationsContext() {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }

  return context;
}
