import { useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import Button from '_/components/Button';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { AppRoute, SigninProps } from '_/routes/types';
import { setOnboardingScreen } from '_/store/modules/auth/actions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import SwiperFlatList from 'react-native-swiper-flatlist';
import { useDispatch } from 'react-redux';

export default function Intro() {
  const navigation = useNavigation<SigninProps>();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const illustrations = [
    {
      id: 'first',
      source: themes.images.intro1,
      message: translate('intro.message1'),
    },
    {
      id: 'second',
      source: themes.images.intro2,
      message: translate('intro.message2'),
    },
    {
      id: 'third',
      source: themes.images.intro3,
      message: translate('intro.message3'),
    },
  ];

  function handleSignup() {
    dispatch(setOnboardingScreen(false));
    navigation.navigate(AppRoute.SIGNOUT);
  }

  function handleSignin() {
    dispatch(setOnboardingScreen(false));
    navigation.navigate(AppRoute.SIGNIN);
  }

  return (
    <ContainerWidthLimit>
      <View style={{ flex: 1, marginTop: -50 }}>
        <View style={styles.container}>
          <View style={styles.content}>
            <SwiperFlatList
              autoplay
              autoplayLoop
              showPagination
              autoplayDelay={8}
              paginationDefaultColor="rgba(44, 54, 84, 0.2)"
              paginationStyle={{ marginBottom: 24 }}
              paginationStyleItem={{ width: 8, height: 8 }}
              paginationActiveColor={themes.colors.primary}
            >
              {illustrations.map((item) => (
                <View key={item.id} style={styles.flatListContent}>
                  <View style={styles.imageContainer}>
                    <Image source={item.source} style={styles.images} resizeMode="contain" />
                    <View>
                      <Text style={styles.messageText}>{item.message}</Text>
                    </View>
                  </View>
                </View>
              ))}
            </SwiperFlatList>
          </View>
          <View style={styles.bottomContainer}>
            <View style={styles.loginContainer}>
              <Image source={themes.images.logo} style={styles.logo} resizeMode="contain" />
              <Button buttonStyles={styles.btn} onPress={handleSignup}>
                <Text uppercase={false} style={[styles.btnText, { color: '#fff' }]}>
                  {translate('signup.createAccount')}
                </Text>
              </Button>
              <TouchableOpacity
                style={styles.btnSignin}
                onPress={handleSignin}
                hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <Text style={styles.btnSignInText}>
                  {translate('signup.alreadyHaveAccount')}{' '}
                  <Text style={{ fontFamily: fontFamily.medium }}>
                    {translate('signup.signin')}
                  </Text>
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </ContainerWidthLimit>
  );
}

Intro.navigationOptions = {
  header: null,
};

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    paddingTop: 50,
    backgroundColor: themes.colors.white,
    borderColor: themes.colors.lightPrimary,
    borderWidth: 1,
    borderBottomLeftRadius: 60,
  },
  flatListContent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
  },
  logo: {
    width: 160,
    height: 36,
    marginVertical: 8,
  },
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: width - 60,
  },
  images: {
    height: height * 0.35,
  },
  messageText: {
    color: themes.colors.darkQuaternary,
    textAlign: 'center',
    fontSize: 20,
    marginVertical: 30,
  },
  bottomContainer: {
    margin: 24,
  },
  loginContainer: {
    alignItems: 'center',
    marginBottom: 24,
  },
  btn: {
    marginTop: 24,
  },
  btnText: {
    textAlign: 'center',
    fontFamily: fontFamily.medium,
    flex: 1,
    fontSize: 18,
  },
  btnSignin: {
    padding: 5,
    marginTop: 16,
    alignSelf: 'stretch',
  },
  btnSignInText: {
    color: themes.colors.darkSecondary,
    alignSelf: 'center',
    fontFamily: fontFamily.light,
    fontSize: 14,
  },
});
