import { usePasswordStrength } from '_/hooks/usePasswordStrength';
import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type NativeSyntheticEvent,
  type TextInput,
  type TextInputSubmitEditingEventData,
} from 'react-native';

interface UsePasswordFieldProps {
  password: string;
  setPassword: (value: string) => void;
  confirmPasswordRef: RefObject<TextInput>;
}

export function usePasswordField({
  password,
  setPassword,
  confirmPasswordRef,
}: UsePasswordFieldProps) {
  const { t: translate } = useTranslation();
  const [showPasswordPopover, setShowPasswordPopover] = useState(false);
  const [error, setError] = useState<undefined | { validation: boolean; text: string }>(undefined);
  const { passwordConditions } = usePasswordStrength({ password });

  function onChangeText(text: string) {
    setPassword(text);
  }

  function onSubmitEditing(_: NativeSyntheticEvent<TextInputSubmitEditingEventData>) {
    confirmPasswordRef.current?.focus();
  }

  function onFocus() {
    setShowPasswordPopover(true);
  }

  function onBlur() {
    setShowPasswordPopover(false);
  }

  useEffect(() => {
    const newError = passwordConditions.find((condition: any) => !condition?.validation);
    if (newError && newError?.text !== error?.text) {
      setError(newError);
    } else if (!newError) {
      setError(undefined);
    }
  }, [password]);

  return {
    showPasswordPopover,
    error,
    onSubmitEditing,
    onChangeText,
    onFocus,
    onBlur,
    translate,
  };
}
