import { DiversityType } from '_/interfaces/PasswordDiversities.enum';
import { passwordStrength } from 'check-password-strength';

const isUUID = (str: string) => {
  const anyRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i;
  return anyRegex.test(str);
};

const isMD5 = (md5: string) => {
  const regex = /^[a-f0-9]{32}$/;
  return regex.test(md5);
};

const asyncForEach = async (array: any[], callback: any) => {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
};

function validEmail(str: string) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(str);
}

function isPasswordStrong(password: string) {
  const diversityTypes = Object.values(DiversityType);
  const { length: passwordLength, contains: passwordDiversities } = passwordStrength(password);

  return (
    passwordLength > 5 &&
    diversityTypes.every((diversity) => passwordDiversities.includes(diversity))
  );
}

export { isUUID, isMD5, asyncForEach, validEmail, isPasswordStrong };
