import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.black,
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    padding: 24,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
  },
  headerText: {
    color: themes.colors.white,
    fontFamily: fontFamily.medium,
    fontSize: 16,
  },
  icon: {
    fontSize: 24,
    color: themes.colors.white,
  },
});

export default styles;
