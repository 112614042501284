import Feather from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import { Text, Spin, TopMenu } from '_/components';
import Button from '_/components/Button';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { AppRoute, MeetingsProps } from '_/routes/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform, SafeAreaView, StyleSheet } from 'react-native';
import { TabView, TabBar, SceneMap } from 'react-native-tab-view';
import { useDispatch, useSelector } from 'react-redux';

import { newMeetingRequest } from '_/store/modules/meeting/actions';

import ActiveMeetings from './ActiveMeetings';
import PastMeetings from './PastMeetings';
import { UserCompanyRole } from '../../models/enums';

const { width } = Dimensions.get('window');

export default function Meetings() {
  const navigation = useNavigation<MeetingsProps>();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const { loading } = useSelector((state: any) => state.loading);
  const { role } = useSelector((state: any) => state.host);

  const [index, setIndex] = useState(0);

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      pressOpacity={0.5}
      pressColor={themes.colors.lightSecondary}
      activeColor={themes.colors.darkSecondary}
      inactiveColor={themes.colors.lightSecondary}
      indicatorStyle={{ backgroundColor: themes.colors.lightSecondary }}
      style={{
        backgroundColor: themes.colors.white,
        color: themes.colors.lightSecondary,
      }}
      labelStyle={{
        textTransform: 'capitalize',
        fontFamily: fontFamily.semibold,
        fontSize: 16,
      }}
    />
  );

  const renderScene = SceneMap({
    first: ActiveMeetings,
    second: PastMeetings,
  });

  const initialLayout = { width: Dimensions.get('window').width };

  function handleCreateMeeting() {
    dispatch(newMeetingRequest());
    navigation.navigate(AppRoute.EDIT_MEETING);
  }

  return (
    <ContainerWidthLimit>
      <SafeAreaView style={styles.container}>
        <Spin visible={loading} />
        <TopMenu />
        <TabView
          lazy
          onIndexChange={setIndex}
          renderScene={renderScene}
          renderTabBar={renderTabBar}
          initialLayout={initialLayout}
          navigationState={{
            index,
            routes: [
              { key: 'first', title: translate('meetings.activeMeetingsTitle') },
              { key: 'second', title: translate('meetings.pastMeetingsTitle') },
            ],
          }}
        />

        {[
          UserCompanyRole.ADMIN,
          UserCompanyRole.MANAGER,
          UserCompanyRole.FRONTDESK,
          UserCompanyRole.HOST,
        ].includes(role) && (
          <Button onPress={handleCreateMeeting} buttonStyles={styles.btnCreateMeeting}>
            <Feather name="plus" style={styles.icon} />
            <Text style={styles.text}>{translate('meetings.newMeeting')}</Text>
          </Button>
        )}
      </SafeAreaView>
    </ContainerWidthLimit>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    flex: 1,
  },
  btnCreateMeeting: {
    alignSelf: 'center',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 24,
    height: 44,
    borderRadius: 6,
    ...(Platform.OS === 'web' ? {} : { width: width - 48 }),
  },
  icon: {
    fontSize: 14,
    color: themes.colors.white,
  },
  text: {
    fontSize: 18,
    color: themes.colors.white,
    marginLeft: 8,
  },
});
