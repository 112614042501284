import api from '_/services/api/base';
import produce from 'immer';

import { AuthAction, AuthState } from './types';

const INITIAL_STATE: AuthState = {
  signed: false,
  accessToken: null,
  refreshToken: null,
  isFirstOpen: false,
  personal: false,
};

const auth = (state = INITIAL_STATE, action: AuthAction): AuthState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.signed = true;
        draft.accessToken = action.payload.accessToken;
        draft.refreshToken = action.payload.refreshToken;
        break;
      }
      case '@auth/SIGN_OUT': {
        api.defaults.headers.common['authorization'] = '';

        return {
          ...INITIAL_STATE,
          isFirstOpen: false,
        };
      }
      case '@auth/DELETE_ACCOUNT_SUCCESS': {
        return INITIAL_STATE;
      }
      case '@auth/SET_PERSONAL_PROFILE': {
        draft.personal = action.payload;
        break;
      }
      case '@auth/SET_ONBOARDING_SCREEN': {
        draft.isFirstOpen = action.payload.isFirstOpen;
        break;
      }
      default:
    }
  });
};

export default auth;
