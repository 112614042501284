import { useIsFocused } from '@react-navigation/native';
import getStaticQRCode from '_/helpers/getStaticQRCode';
import { getWAccessIntegrationRequest } from '_/store/modules/company/actions';
import * as Brightness from 'expo-brightness';
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HostInvenziQRCode from './HostInvenziQRCode';
import InvenziQRCode from './InvenziQRCode';
import StaticQRCode from './StaticQRCode';

interface QRCodeDisplayProps {
  invite?: any;
}

export default function QRCodeDisplay({ invite }: QRCodeDisplayProps) {
  const dispatch = useDispatch();
  const company = useSelector((state: any) => state.company);
  const hostId = useSelector((state: any) => state.host.id);

  const waccessIntegration = company.waccessIntegration;

  const isFocused = useIsFocused();

  const brightness = useRef(1);

  useEffect(() => {
    (async () => {
      const brightnessValue = await Brightness.getBrightnessAsync();
      Brightness.setBrightnessAsync(brightness.current);
      brightness.current = brightnessValue;

      if (isFocused) {
        Brightness.setBrightnessAsync(1);
      }
    })();
  }, [isFocused]);

  function getWaccessIntegration() {
    dispatch(getWAccessIntegrationRequest());
  }

  if (invite?.waccessIntegration) {
    return <InvenziQRCode invite={invite} />;
  }

  if (invite) {
    return (
      <StaticQRCode
        isCanceled={invite.isCanceled}
        qrCodeValue={getStaticQRCode(invite.id, 'visit')}
      />
    );
  }

  if (waccessIntegration) {
    return (
      <HostInvenziQRCode
        waccessIntegration={waccessIntegration}
        getWaccessIntegration={getWaccessIntegration}
      />
    );
  }

  return <StaticQRCode qrCodeValue={getStaticQRCode(hostId, 'host')} />;
}
