import { AppleAuthenticationScope } from 'expo-apple-authentication';
import Constants from 'expo-constants';

export const appConfig = {
  googleLoginConfig: {
    googleClientId: Constants.expoConfig?.extra?.google?.googleClientId as string,
    iosClientId: Constants.expoConfig?.extra?.google?.iosClientId as string,
    iosStandaloneAppClientId: Constants.expoConfig?.extra?.google
      ?.iosStandaloneAppClientId as string,
    androidClientId: Constants.expoConfig?.extra?.google?.androidClientId as string,
    androidStandaloneAppClientId: Constants.expoConfig?.extra?.google
      ?.androidStandaloneAppClientId as string,
    scopes: ['profile', 'email'],
  },
  microsoftConfig: {
    client_id: '',
    client_secret: '',
    redirect_uri: '',
    scope: 'User.Read',
  },
  appleLoginConfig: {
    requestedScopes: [AppleAuthenticationScope.FULL_NAME, AppleAuthenticationScope.EMAIL],
  },
  company: Constants.expoConfig?.extra?.company as string,
  sentryDNS: Constants.expoConfig?.extra?.sentryDSN as string,
  apiUrl: Constants.expoConfig?.extra?.apiUrl as string,
  appName: Constants.expoConfig?.name as string,
  dashboardUrl: Constants.expoConfig?.extra?.dashboardUrl as string,
  privacyPolicyUrl: Constants.expoConfig?.extra?.privacyPolicyUrl as string,
  termsConditionsUrl: Constants.expoConfig?.extra?.termsConditionsUrl as string,
  phone: {
    defaultCountryCode: Constants.expoConfig?.extra?.phone?.defaultCountryCode as string,
    defaultCountryName: Constants.expoConfig?.extra?.phone?.defaultCountryName as string,
    defaultCountryDial: Constants.expoConfig?.extra?.phone?.defaultCountryDial as string,
  },
  lang: {
    default: Constants.expoConfig?.extra?.lang.default,
  },
  helpEmail: Constants.expoConfig?.extra?.helpEmail,
  showMessageInputonCreateMeeting: Constants.expoConfig?.extra?.showMessageInputonCreateMeeting,
  showNotesInputonCreateMeeting: Constants.expoConfig?.extra?.showNotesInputonCreateMeeting,
  allowedHostZonesOfflineFlowEnabled:
    Constants.expoConfig?.extra?.allowedHostZonesOfflineFlowEnabled,
  preCheckinURL: Constants.expoConfig?.extra?.preCheckinURL,
};
