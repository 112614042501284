import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderBottomColor: themes.colors.lightPrimary,
    borderBottomWidth: 1,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 12,
  },
  imageContainer: {
    resizeMode: 'contain',
    width: 45,
    height: 45,
  },
  imgTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    marginTop: 6,
    marginLeft: 3,
  },
  dayText: {
    color: themes.colors.white,
    fontFamily: fontFamily.medium,
    fontSize: 14,
    lineHeight: 14,
  },
  monthText: {
    color: themes.colors.white,
    fontFamily: fontFamily.medium,
    fontSize: 12,
    lineHeight: 12,
    textTransform: 'capitalize',
  },
  infoContainer: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flex: 1,
    paddingHorizontal: 24,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 4,
  },
  borderedContainer: {
    borderColor: themes.colors.lightSecondary,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    borderWidth: 1,
    marginRight: 8,
    borderRadius: 2,
    paddingHorizontal: 8,
    paddingVertical: 1,
    marginBottom: 4,
  },
  timeTextView: {
    width: '18%',
    marginBottom: 4,
  },
  timeText: {
    color: themes.colors.darkQuaternary,
    textTransform: 'uppercase',
    fontSize: 10,
  },
  title: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 14,
    marginBottom: 4,
  },
  locationText: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.light,
    fontSize: 12,
    marginBottom: 1,
  },
  flag: {
    position: 'absolute',
    alignItems: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    backgroundColor: themes.colors.success,
    bottom: 0,
    height: 20,
    paddingHorizontal: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  flagText: {
    color: themes.colors.white,
    fontFamily: fontFamily.regular,
    fontSize: 10,
  },
  shadow: {
    shadowColor: themes.colors.success,
    elevation: 5,
    shadowRadius: 2,
    shadowOpacity: 0.8,
    shadowOffset: { width: 0, height: 12 },
  },
  darkQuaternary: {
    color: themes.colors.darkQuaternary,
    marginRight: 4,
  },
  lightPrimary: {
    color: themes.colors.lightPrimary,
  },
});

export default styles;
