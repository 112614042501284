import { Feather } from '@expo/vector-icons';
import { CompaniesModel, HostsModel, MeetingsModel } from '@w3lcome/types';
import Divider from '_/components/Divider/index';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { getDateLocale } from '_/helpers/getDateLocale';
import { format } from 'date-fns';
import * as Localization from 'expo-localization';
import moment from 'moment-timezone';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, Modal, TouchableOpacity, StyleSheet } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';

import Text from '../Text';

interface InviteContainerProps {
  meeting: Partial<MeetingsModel>;
  host: Partial<HostsModel>;
  meetingCompany: Partial<CompaniesModel>;
  meetingCompanyCustomization: any;
}

export default function InviteContainer({
  meeting,
  host,
  meetingCompany,
  meetingCompanyCustomization,
}: InviteContainerProps) {
  const meetingStart = moment.tz(meeting.start, meeting.timezone || Localization.timezone).toDate();
  const [modalVisible, setModalVisible] = useState(false);
  const { t: translate, i18n } = useTranslation();
  const locale = getDateLocale(i18n.language);

  const ShowLocation = useCallback(() => {
    if (!meeting?.meetingLocation?.name && !meeting?.location) {
      return <></>;
    }

    return (
      <View style={[styles.card, { marginBottom: 12 }]}>
        <View style={styles.iconContainer}>
          <Feather name="map-pin" style={styles.icon} />
        </View>
        <View style={styles.infoContainer}>
          {!!meeting?.meetingLocation?.name && (
            <TouchableOpacity
              style={{ display: 'flex', flexDirection: 'row' }}
              onPress={() => setModalVisible(true)}
            >
              <View style={styles.infoContainer}>
                {!!meeting?.meetingLocation?.name && <Text>{meeting.meetingLocation.name}</Text>}

                {!!meeting?.meetingLocation?.description && (
                  <Text style={styles.value}>{meeting.meetingLocation.description} </Text>
                )}
              </View>
              {!!meeting?.meetingLocation?.pictureUrl && (
                <Image style={styles.image} source={{ uri: meeting.meetingLocation.pictureUrl }} />
              )}
            </TouchableOpacity>
          )}

          {!!meeting?.location && <Text>{meeting.location}</Text>}
        </View>
      </View>
    );
  }, [meeting]);

  return (
    <View style={styles.container}>
      <Modal visible={modalVisible} transparent>
        {!!meeting?.meetingLocation?.pictureUrl && (
          <ImageViewer
            imageUrls={[{ url: meeting?.meetingLocation?.pictureUrl }]}
            onClick={() => setModalVisible(false)}
          />
        )}
      </Modal>

      <View style={styles.invitesContainer}>
        {(!!meetingCompany?.pictureUrl || !!meetingCompany?.name) && (
          <View style={styles.companyLogoContainer}>
            {!!meetingCompany?.pictureUrl && (
              <Image
                style={[
                  styles.companyLogo,
                  {
                    backgroundColor: meetingCompanyCustomization.backgroundColor,
                  },
                ]}
                source={{ uri: meetingCompany.pictureUrl }}
              />
            )}
            {!meetingCompany?.pictureUrl && (
              <Text style={styles.companyName}>{meetingCompany.name}</Text>
            )}
          </View>
        )}
        <View style={styles.card}>
          <View style={styles.infoContainer}>
            {meeting?.title && <Text style={styles.title}>{meeting.title}</Text>}
            {!!meeting?.message && (
              <Text style={[styles.value, styles.message]}>{meeting.message}</Text>
            )}
          </View>
        </View>

        <Divider />

        <View style={styles.card}>
          <View style={styles.iconContainer}>
            <Feather name="user" style={styles.icon} />
          </View>
          <View style={styles.infoContainer}>
            {!!host?.name && <Text style={styles.infoTitle}>{host.name}</Text>}
            {!!host?.sector && <Text style={styles.infoTitle}>{host.sector}</Text>}
          </View>
        </View>

        <Divider />

        <View style={styles.card}>
          <View style={styles.iconContainer}>
            <Feather name="clock" style={styles.icon} />
          </View>
          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle}>{format(meetingStart, 'PPP (iiii)', { locale })}</Text>
            <Text style={styles.value}>{format(meetingStart, 'p', { locale })}</Text>
            <Text style={styles.value}>
              {translate('personalInvite.timeZone')} {meeting.timezone}
            </Text>
          </View>
        </View>

        <Divider />

        <ShowLocation />

        <View style={styles.card}>
          <View style={styles.iconContainer}>
            <Feather name="home" style={styles.icon} />
          </View>
          <View style={styles.infoContainer}>
            {!!meetingCompany?.name && <Text style={styles.infoTitle}>{meetingCompany.name}</Text>}
            {!!meetingCompany?.address && (
              <Text style={styles.value}>{meetingCompany.address} </Text>
            )}
            {(!!meetingCompany?.city || !!meetingCompany?.state) && (
              <Text style={styles.value}>
                {meetingCompany?.city} <Text>{meetingCompany.state}</Text>
              </Text>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  invitesContainer: {
    padding: 12,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: themes.colors.lightPrimary,
  },
  companyLogoContainer: {
    overflow: 'hidden',
    borderRadius: 6,
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: themes.colors.white,
  },
  companyLogo: {
    height: 100,
    width: '100%',
    resizeMode: 'contain',
  },
  companyName: {
    textAlign: 'center',
    fontFamily: fontFamily.medium,
    fontSize: 20,
  },
  card: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: 8,
  },
  iconContainer: {
    height: '100%',
    marginRight: 12,
  },
  infoContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    fontFamily: fontFamily.medium,
    fontSize: 18,
    textAlign: 'center',
    color: themes.colors.darkSecondary,
    marginBottom: 8,
  },
  infoTitle: {
    fontFamily: fontFamily.medium,
    fontSize: 14,
    color: themes.colors.darkSecondary,
    paddingVertical: 2,
  },
  message: {
    textAlign: 'center',
  },
  value: {
    fontFamily: fontFamily.regular,
    fontSize: 12,
    color: themes.colors.darkQuaternary,
    paddingVertical: 2,
  },
  icon: {
    fontSize: 16,
    marginTop: 4,
  },
  image: {
    width: 36,
    height: 36,
    borderRadius: 4,
    marginRight: 8,
  },
});
