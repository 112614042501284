import { Text } from '_/components';
import themes from '_/constants/themes';
import React from 'react';
import { View, StyleSheet } from 'react-native';

interface GuestProps {
  guest: string;
}

export default function Guest({ guest }: GuestProps) {
  return (
    <View style={styles.container}>
      <View style={styles.dataContainer}>
        {!!guest && <Text style={styles.email}>{guest}</Text>}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    paddingVertical: 12,
  },
  dataContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1,
    marginLeft: 12,
  },
  email: {
    color: themes.colors.darkQuaternary,
    fontSize: 12,
    lineHeight: 14,
  },
});
