import { MeetingsModel } from '@w3lcome/types';
import logSentryException from '_/helpers/logSentryException';
import { useMeetingsContext } from '_/hooks/MeetingsProvider';
import moment from 'moment-timezone';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { useDispatch } from 'react-redux';

import { getMeetingDetailsRequest } from '_/store/modules/meeting/actions';

import EmptyMeetings from './EmptyMeetings';
import styles from './styles';
import MeetingCard from '../MeetingCard';

export default function ActiveMeetings() {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const [refreshing, setRefreshing] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);

  const { getMeetings, meetings, meetingsState, loadMoreMeetings } = useMeetingsContext();

  const activeMeetings = useMemo(
    () => meetings.filter((meeting: MeetingsModel) => moment(meeting.end).isSameOrAfter(moment())),
    [meetings]
  );

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    getMeetings({
      '$sort[start]': -1,
    });
    const time = Math.floor(Math.random() * 1500) + 500;
    setTimeout(() => setRefreshing(false), time);
  }, []);

  const loadMore = useCallback(async () => {
    try {
      setRefreshing(true);

      if (!reachedEnd) {
        if (meetings.length < meetingsState.total) {
          loadMoreMeetings({
            $limit: 1000,
            $skip: meetingsState.limit,
            '$sort[start]': -1,
          });
        }

        if (activeMeetings.length === 0) {
          setReachedEnd(true);
        }
      }
      setRefreshing(false);
    } catch (error) {
      logSentryException({
        error,
        file: 'ActiveMeetings.js',
        message: 'Error at loadMore function',
      });
      showMessage({
        message: translate('personalInvites.loadingError'),
        type: 'danger',
      });
      setRefreshing(false);
    }
  }, [meetings, meetingsState, activeMeetings]);

  function handleGetMeetingsDetails(meeting: MeetingsModel) {
    dispatch(getMeetingDetailsRequest(meeting));
  }

  return (
    <FlatList
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      onEndReached={loadMore}
      onEndReachedThreshold={0.2}
      ListEmptyComponent={<EmptyMeetings />}
      style={styles.container}
      contentContainerStyle={{ paddingBottom: 80 }}
      data={activeMeetings}
      keyExtractor={(item) => item.id}
      renderItem={({ item: meeting }) => (
        <MeetingCard meeting={meeting} getMeetingDetails={handleGetMeetingsDetails} />
      )}
    />
  );
}
