import { DiversityType } from '_/interfaces/PasswordDiversities.enum';
import { passwordStrength } from 'check-password-strength';
import { useTranslation } from 'react-i18next';

interface UsePasswordStrengthProps {
  password: string;
}

export function usePasswordStrength({ password }: UsePasswordStrengthProps) {
  const { t: translate } = useTranslation();

  const { contains: passwordDiversities, length: passwordLength } = passwordStrength(password);

  const passwordConditions = [
    {
      validation: passwordDiversities.includes(DiversityType.LOWERCASE),
      text: translate('passwordStrength.popover.atLeastLowercase'),
    },
    {
      validation: passwordDiversities.includes(DiversityType.UPPERCASE),
      text: translate('passwordStrength.popover.atLeastUppercase'),
    },
    {
      validation: passwordDiversities.includes(DiversityType.NUMBER),
      text: translate('passwordStrength.popover.atLeastNumber'),
    },
    {
      validation: passwordDiversities.includes(DiversityType.SYMBOL),
      text: translate('passwordStrength.popover.atLeastSpecial'),
    },
    {
      validation: passwordLength > 5,
      text: translate('passwordStrength.popover.atLeastLength'),
    },
  ];

  return {
    passwordConditions,
  };
}
