import { HostsModel, VisitsModel, ZonesModel } from '@w3lcome/types';

import db from './SqliteDatabase';

interface UsersZonesTable {
  zoneId: string;
  cardNumber: string;
  mifareCardNumber: string;
  userZone: UserZone;
  userId: string;
}

interface UserZone {
  user: HostsModel | VisitsModel;
  zone: ZonesModel | null | undefined;
}

const init = () => {
  db.transaction((tx) => {
    tx.executeSql(
      'CREATE TABLE IF NOT EXISTS users_zones_table (zoneId TEXT NOT NULL, userId TEXT NOT NULL, cardNumber TEXT, mifareCardNumber TEXT, userZone TEXT);'
    );
  });
};

const create = (user: UsersZonesTable) => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'INSERT INTO users_zones_table (zoneId, userId, cardNumber, mifareCardNumber, userZone) VALUES (?, ?, ?, ?, ?);',
        [
          user.zoneId,
          user.userId,
          user.cardNumber,
          user.mifareCardNumber,
          JSON.stringify(user.userZone),
        ],
        (_, { rowsAffected }) => {
          if (rowsAffected > 0) resolve(true);
          else
            reject(
              new Error(
                `Error inserting user for userId: ${user.userId} and zoneId: ${user.zoneId}`
              )
            );
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const deleteOne = (userId: string, zoneId: string) => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'DELETE FROM users_zones_table WHERE userId = ? AND zoneId = ?;',
        [userId, zoneId],
        (_, { rowsAffected }) => {
          if (rowsAffected > 0) resolve(true);
          else
            reject(
              new Error(`Error removing hostZone for userId: ${userId} and zoneId: ${zoneId}`)
            );
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const getOne = (cardNumber: string, zoneId: string): Promise<UsersZonesTable | null> => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'SELECT * FROM users_zones_table WHERE cardNumber = ? AND zoneId = ?;',
        [cardNumber, zoneId],
        (_, { rows }) => {
          if (rows.length > 0) {
            const item = rows.item(0);
            const userZone = JSON.parse(item.userZone);
            resolve({ ...item, userZone });
          } else {
            resolve(null);
          }
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const deleteAll = () => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'DELETE FROM users_zones_table;',
        [],
        (_, { rowsAffected }) => {
          if (rowsAffected >= 0) resolve(true);
          else reject(new Error('Error removing all records from the table'));
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const getOneMifareCardNumber = (
  mifareCardNumber: string,
  zoneId: string
): Promise<UsersZonesTable | null> => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'SELECT * FROM users_zones_table WHERE mifareCardNumber LIKE ? AND zoneId = ?;',
        [`%|${mifareCardNumber}|%`, zoneId],
        (_, { rows }) => {
          if (rows.length > 0) {
            const item = rows.item(0);
            const userZone = JSON.parse(item.userZone);
            resolve({ ...item, userZone });
          } else {
            resolve(null);
          }
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const all = (): Promise<UsersZonesTable[]> => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'SELECT * FROM users_zones_table;',
        [],
        (_, { rows }) => {
          const users = [];
          for (let i = 0; i < rows.length; ++i) {
            const item = rows.item(i);
            users.push(item);
          }
          resolve(users);
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const deleteTable = () => {
  return new Promise((resolve, reject) => {
    db.transaction((tx) => {
      tx.executeSql(
        'DROP TABLE users_zones_table;',
        [],
        (_, { rowsAffected }) => {
          resolve(rowsAffected);
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

export default {
  create,
  all,
  deleteTable,
  deleteOne,
  init,
  getOne,
  getOneMifareCardNumber,
  deleteAll,
};
