import { fontFamily, colors } from '_/config/theme';
import { FontsType } from '_/interfaces/FontsType';
import React from 'react';
import { Animated, StyleSheet, TextProps as TProps } from 'react-native';

interface TextProps extends Animated.AnimatedProps<TProps> {
  fontType?: keyof FontsType;
  size?: number;
  uppercase?: boolean;
  center?: boolean;
  color?: string;
  children?: React.ReactNode | string;
}

const Text: React.FC<TextProps> = ({
  children,
  fontType = 'regular',
  size,
  center,
  color,
  uppercase,
  style,
  ...rest
}) => {
  return (
    <Animated.Text
      {...rest}
      style={[
        styles.text,
        center && styles.center,
        uppercase && styles.uppercase,
        { fontFamily: fontFamily[fontType] },
        !!size && { fontSize: size, lineHeight: size * 1.5 },
        !!color && { color },
        style,
      ]}
    >
      {children}
    </Animated.Text>
  );
};

export default Text;

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamily.regular,
    fontSize: 14,
    color: colors.dark1,
  },
  center: {
    textAlign: 'center',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});
