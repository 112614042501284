import React, { useRef, useCallback, useEffect } from 'react';
import { Easing, Animated, TouchableOpacity, TouchableOpacityProps } from 'react-native';

export const AnimatedButton: React.FC<TouchableOpacityProps> = ({ children, ...rest }) => {
  const animatedValue = useRef(new Animated.Value(0));

  const handleAnimation = useCallback(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(animatedValue.current, {
          toValue: 0.8,
          duration: 500,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(animatedValue.current, {
          toValue: 1,
          duration: 500,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [animatedValue]);

  useEffect(() => {
    handleAnimation();
  }, [handleAnimation]);

  return (
    <Animated.View
      style={{
        transform: [
          {
            scale: animatedValue.current.interpolate({
              inputRange: [-1, 1],
              outputRange: [0.8, 1],
            }),
          },
        ],
      }}
    >
      <TouchableOpacity {...rest}>{children}</TouchableOpacity>
    </Animated.View>
  );
};
