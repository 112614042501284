// TODO: Reactotron not working on web (see reactotron-react-js package)

declare module global {
  interface Console {
    tron: any;
  }
}

console.tron = {
  log: () => null,
  warn: () => null,
  error: () => null,
};
