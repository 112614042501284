import { theme } from '_/constants';

export const languages = [
  {
    key: 0,
    code: 'pt-BR',
    lang: 'Português',
    flag: theme.images.brazilFlag,
  },
  {
    key: 1,
    code: 'en',
    lang: 'English',
    flag: theme.images.usFlag,
  },
  {
    key: 2,
    code: 'de',
    lang: 'Deutsch',
    flag: theme.images.deFlag,
  },
  {
    key: 3,
    code: 'es',
    lang: 'Español',
    flag: theme.images.esFlag,
  },
  {
    key: 4,
    code: 'fr',
    lang: 'Français',
    flag: theme.images.frFlag,
  },
  {
    key: 5,
    code: 'zh-Hans',
    lang: '中国人',
    flag: theme.images.zhFlag,
  },
];
