import { CompaniesModel } from '@w3lcome/types';
import logSentryException from '_/helpers/logSentryException';
import { translate } from '_/locales';
import { UserModel } from '_/models/user';
import { authApi, companyApi, userApi } from '_/services/api';
import api from '_/services/api/base';
import { StoreState } from '_/store/createStore';
import { isAfter } from 'date-fns';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Alert } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { all, put, take, select, putResolve, takeLatest } from 'redux-saga/effects';
import * as Effect from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { navigate } from '_/services/navigation';

import * as actions from './actions';
import { SignInAPIResponseData } from './types';
import { setUserCurrentCompanyRequest } from '../company/actions';
import { getHostOfTheActualCompanyRequest } from '../host/actions';
import { setLoading } from '../loading/actions';
import { setUserInfoRequest, setUserInfoSuccess } from '../user/actions';

// import AsyncStorage from '@react-native-community/async-storage';

// import Constants from 'expo-constants';

interface LoginWithGoogleResponse {
  user: UserModel;
  accessToken: string;
  refreshToken: string;
}

const call: any = Effect.call;

export function* signIn({ payload }: ActionType<typeof actions.signInRequest>) {
  try {
    yield put(setLoading());
    const { email, password } = payload;
    const response: SignInAPIResponseData = yield call(authApi.login, {
      email,
      password,
    });

    const { user, accessToken, refreshToken } = response;
    api.defaults.headers.common['authorization'] = `Bearer ${accessToken}`;

    yield putResolve(setUserInfoRequest(user));
    yield take('@user/SET_USER_INFO_SUCCESS');

    // yield call(Analytics.setUserId, user.id);
    const { emailVerified } = user;

    if (!emailVerified) {
      navigate('EmailConfirmationNav');
      yield take('@auth/VERIFY_EMAIL_SUCCESS');
    }

    if (user.currentCompanyId) {
      const company: CompaniesModel = yield call(companyApi.getCompany, user.currentCompanyId);

      if (company) {
        yield putResolve(setUserCurrentCompanyRequest(company));
        yield take('@company/SET_USER_CURRENT_COMPANY_SUCCESS');
        const { planIsActive, trialUntil } = company;
        if (!planIsActive) {
          if (trialUntil && isAfter(new Date(), trialUntil)) {
            navigate('Expired');
          }
        }
      } else {
        yield put(actions.setPersonalProfile(true));
      }
    } else {
      yield put(actions.setPersonalProfile(true));
    }

    yield put(setLoading());
    yield put(actions.signInSuccess({ accessToken, refreshToken }));
  } catch (error) {
    const message = error?.response?.data?.message || error?.message;
    yield call(logSentryException, {
      error: error?.response?.data,
      file: 'auth/sagas.ts',
      message,
    });
    yield put(setLoading());
    yield put(actions.signFailure());
    if (error?.response?.status === 430) {
      Alert.alert(
        translate('authSaga.migratedUserErrorTitle'),
        translate('authSaga.migratedUserErrorMessage')
      );
    } else {
      showMessage({
        message: translate('authSaga.authSignInError'),
        type: 'danger',
      });
    }
  }
}

export function* signUp({ payload }: ActionType<typeof actions.signUpRequest>) {
  try {
    yield put(setLoading());
    const { name, email, password, lang, phone, countryCode, cpf } = payload;
    yield call(authApi.createAccount, {
      name,
      email,
      password,
      lang,
      phone,
      countryCode,
      cpf,
    });

    yield put(setLoading());
    const response: SignInAPIResponseData = yield call(authApi.login, {
      email,
      password,
    });

    const { user, accessToken, refreshToken } = response;
    api.defaults.headers.common['authorization'] = `Bearer ${accessToken}`;

    yield putResolve(setUserInfoRequest(user));
    yield take('@user/SET_USER_INFO_SUCCESS');

    // yield call(Analytics.setUserId, user.id);
    const { emailVerified } = user;

    if (!emailVerified) {
      navigate('EmailConfirmationNav');
      yield take('@auth/VERIFY_EMAIL_SUCCESS');
    }

    if (user.currentCompanyId) {
      const company: CompaniesModel = yield call(companyApi.getCompany, user.currentCompanyId);

      if (company) {
        yield putResolve(setUserCurrentCompanyRequest(company));
        yield take('@company/SET_USER_CURRENT_COMPANY_SUCCESS');
        const { planIsActive, trialUntil } = company;
        if (!planIsActive) {
          if (trialUntil && isAfter(new Date(), trialUntil)) {
            navigate('Expired');
          }
        }
      } else {
        yield put(actions.setPersonalProfile(true));
      }
    } else {
      yield put(actions.setPersonalProfile(true));
    }

    yield put(setLoading());
    yield put(actions.signInSuccess({ accessToken, refreshToken }));

    yield put(setLoading());
    yield put(actions.signUpSuccess());
  } catch (error) {
    const message = error?.response?.data?.message;
    yield call(logSentryException, {
      error: error?.response?.data,
      file: 'auth/sagas.ts',
      message,
    });
    yield put(setLoading());
    yield put(actions.signFailure());

    if (message === 'Weak pasword') {
      showMessage({
        message: translate('authSaga.authErrorWeakPassword'),
        type: 'danger',
      });
    } else if (error?.response?.data?.errors[0]?.validatorKey === 'not_unique') {
      showMessage({
        message: translate('authSaga.authErrorEmailExists'),
        type: 'danger',
      });
    } else {
      showMessage({
        message: translate('authSaga.authCreateAccountError'),
        type: 'danger',
      });
    }
  }
}

export function* signInWithGoogle({ payload }: ActionType<typeof actions.signInWithGoogleRequest>) {
  try {
    yield put(setLoading());

    const { token } = payload;

    const response = yield call(authApi.loginWithGoogle, {
      accessToken: token,
    });

    const { user, accessToken, refreshToken }: LoginWithGoogleResponse = response;
    api.defaults.headers.common['authorization'] = `Bearer ${accessToken}`;

    yield putResolve(setUserInfoRequest(user));
    yield take('@user/SET_USER_INFO_SUCCESS');

    const { emailVerified } = user;

    if (!emailVerified) {
      navigate('EmailConfirmationNav');
      yield take('@auth/VERIFY_EMAIL_SUCCESS');
    }

    try {
      if (user?.currentCompanyId) {
        const { data: company } = yield call(companyApi.getCompany, user.currentCompanyId);

        if (company) {
          yield putResolve(setUserCurrentCompanyRequest(company));
          yield take('@company/SET_USER_CURRENT_COMPANY_SUCCESS');
          const { planIsActive, trialUntil } = company;
          if (!planIsActive) {
            if (trialUntil && isAfter(new Date(), trialUntil)) {
              navigate('Expired');
            }
          }
        } else {
          yield put(actions.setPersonalProfile(true));
        }
      } else {
        yield put(actions.setPersonalProfile(true));
      }

      yield put(actions.signInSuccess({ accessToken, refreshToken }));
      yield put(setLoading());
    } catch (error) {
      //erro de forbidden do backend
      console.log(error);
    }

    if (!user.currentCompanyId) {
      yield put(actions.setPersonalProfile(true));
    }

    yield put(actions.signInSuccess({ accessToken, refreshToken }));

    yield put(setLoading());
  } catch (error) {
    const { message, errors } = error?.response?.data || {};
    yield call(logSentryException, {
      error: error?.response?.data,
      file: 'auth/sagas.ts',
      message,
    });
    yield put(setLoading());
    yield put(actions.signFailure());

    if (errors?.[0]?.message === 'email must be unique') {
      showMessage({
        message: translate('authSaga.authErrorEmailExists'),
        type: 'danger',
      });
    } else {
      showMessage({
        message: translate('authSaga.authSignInError'),
        type: 'danger',
      });
    }
  }
}

export function* forgotPassword({ payload }: ActionType<typeof actions.forgotPasswordRequest>) {
  const { email, setIsModalVisible } = payload;
  try {
    yield put(setLoading());
    yield call(authApi.resetPasswordSend, email);

    yield put(setLoading());
    yield put(actions.forgotPasswordSuccess());
    navigate('PasswordSent');
  } catch (error) {
    yield call(logSentryException, {
      error,
      file: 'auth/sagas.ts',
      message: 'Error at forgotPassword function',
    });
    yield put(setLoading());
    yield put(actions.forgotPasswordFailure());
    setIsModalVisible(true);
  }
}

export function* deleteAccount() {
  try {
    const userId = yield select((state) => state.user.id);

    yield put(setLoading());

    yield call(userApi.deleteUser, userId);

    // yield put(deleteAccountSuccess());
    yield put(actions.signOut());
    yield put(setLoading());
    showMessage({
      message: translate('deleteAccount.success'),
      type: 'success',
    });
  } catch (error) {
    yield put(setLoading());
    // yield put(deleteAccountFailure());
    if (error?.code === 'auth/requires-recent-login') {
      showMessage({
        message: translate('deleteAccount.reautherror'),
        type: 'danger',
      });
    } else {
      showMessage({
        message: translate('deleteAccount.failure'),
        type: 'danger',
      });
    }
  }
}

export function* sendVerificationEmail({
  payload,
}: ActionType<typeof actions.sendVerificationEmailRequest>) {
  try {
    yield put(setLoading());

    const { email } = payload;
    yield call(authApi.sendVerificationEmail, email);

    showMessage({
      message: translate('userSaga.sendEmailAgainSuccess'),
      type: 'success',
    });
    yield put(setLoading());
    yield put(actions.sendVerificationEmailSuccess());
  } catch (error) {
    const message = error?.response?.data?.message;
    yield call(logSentryException, {
      error: error?.response?.data,
      file: 'auth/sagas.ts',
      message,
    });

    showMessage({
      message: translate('userSaga.sendEmailAgainFailure'),
      type: 'danger',
    });
    yield put(setLoading());
    yield put(actions.sendVerificationEmailFailure());
  }
}

export function* verifyEmail() {
  try {
    yield put(setLoading());
    const userId = yield select((state) => state.user.id);
    const response = yield call(userApi.getUser, userId);

    const user: UserModel = response;
    const { emailVerified } = user;

    if (emailVerified) {
      yield put(actions.verifyEmailSuccess());
      showMessage({
        message: translate('userSaga.verifyUserEmailSuccess'),
        type: 'success',
      });
      yield put(setLoading());
      return;
    }

    showMessage({
      message: translate('userSaga.verifyUserEmailWaiting'),
      type: 'warning',
    });

    yield put(setLoading());
  } catch (error) {
    const message = error?.response?.data?.message;
    showMessage({
      message: translate('userSaga.verifyUserEmailError'),
      type: 'danger',
    });
    yield put(setLoading());
    yield put(actions.verifyEmailFailure());
    yield call(logSentryException, {
      error: error?.response?.data,
      file: 'auth/sagas.ts',
      message,
    });
  }
}

export function* setToken({ payload }: { payload: StoreState }) {
  if (!payload) return;

  try {
    const { accessToken, refreshToken } = payload.auth;

    let verifiedAccessToken = accessToken;

    const { exp } = jwt_decode<JwtPayload>(accessToken as string);

    const expiredDate = new Date((exp || 0) * 1000);
    const now = new Date();

    if (!!payload.user.id && exp && now > expiredDate) {
      const { accessToken } = yield call(authApi.refreshToken, {
        id: payload.user.id,
        refreshToken,
      });

      verifiedAccessToken = accessToken;
    }

    const { exp: expRefresh } = jwt_decode<JwtPayload>(verifiedAccessToken as string);
    const refreshExpiredDate = new Date((expRefresh || 0) * 1000);

    if (verifiedAccessToken && now <= refreshExpiredDate) {
      api.defaults.headers.common['authorization'] = `Bearer ${verifiedAccessToken}`;
    } else {
      put(actions.signOut());
      return;
    }

    if (payload.user.id) {
      try {
        const { data: user } = yield call(userApi.getUser, payload.user.id as string);
        const company: CompaniesModel = yield call(companyApi.getCompany, user.currentCompanyId);
        yield putResolve(setUserCurrentCompanyRequest(company));
        yield put(setUserInfoSuccess(user));
      } catch (err) {}
    }

    if (!!payload.user.id && !!payload.company.id && payload.host.userId !== payload.user.id) {
      yield put(
        getHostOfTheActualCompanyRequest({
          companyId: payload.company.id as string,
          userId: payload.user.id as string,
        })
      );
    }
  } catch (error) {
    if (error?.message === 'Request failed with status code 401') {
      yield put(actions.signOut());
    }
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/FORGOT_PASSWORD_REQUEST', forgotPassword),
  takeLatest('@auth/SIGN_IN_WITH_GOOGLE_REQUEST', signInWithGoogle),
  takeLatest('@auth/DELETE_ACCOUNT_REQUEST', deleteAccount),
  takeLatest('@auth/SEND_VERIFICATION_EMAIL_REQUEST', sendVerificationEmail),
  takeLatest('@auth/VERIFY_EMAIL_REQUEST', verifyEmail),
  takeLatest('persist/REHYDRATE', setToken),
]);
