import { Feather } from '@expo/vector-icons';
import { useNetInfo } from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';
import { HostsModel, Lang } from '@w3lcome/types';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { handleVerifyCardLocalDB } from '_/helpers/cardVerificationLocalDB';
import { showError } from '_/helpers/showError';
import { QRCodesApiResponse } from '_/services/api/qrcodes';
import axios from 'axios';
import { Buffer } from 'buffer';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, Text, Modal } from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';

import EmployeeModal from '../QRCodeReader/EmployeeModal';

export interface HostQRcodeCreatedProps extends QRCodesApiResponse {
  message?: string;
}

export default function EntryReader() {
  const [hostQRcodeCreated, setHostQRcodeCreated] = useState<HostQRcodeCreatedProps>();
  const [cardNumber, setCardNumber] = useState('');
  const inputRef = useRef<TextInput>(null);

  const host = useSelector((state: any) => state.host);

  const { t: translate, i18n } = useTranslation();

  const navigation = useNavigation();

  const netInfo = useNetInfo();

  const isZoneAuthorizationEnabled = useSelector(
    (state: any) => state.company?.customization?.isZoneAuthorizationEnabled
  );

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 0);
  }, []);

  function handleClose() {
    setHostQRcodeCreated(undefined);
  }

  async function handleEntryFinished() {
    try {
      if (!cardNumber || !host.zoneId || !isZoneAuthorizationEnabled) {
        return;
      }

      if (netInfo.type === 'wifi') {
        await handleVerifyCardNumber(cardNumber);
        return;
      }

      await handleVerifyCardLocalDB(cardNumber, host, setHostQRcodeCreated, true);
    } catch (error) {
      showError(translate('qrCodeScan.readError'));
      handleClose();
    }
  }

  async function handleVerifyCardNumber(cardNumber: string) {
    try {
      const { username, password, endpointUrl, name } = host.zone;

      if (!endpointUrl || !password || !username) {
        return;
      }

      const credentials = Buffer.from(`${username}:${password}`, 'utf-8').toString('base64');

      const data = {
        cardnumber: cardNumber,
        source_name: name,
      };

      const { data: userAllowed } = await axios.post(`${endpointUrl}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${credentials}`,
        },
        timeout: 2000,
      });

      setHostQRcodeCreated({
        data: { name: userAllowed.CHName },
        isZoneAuthorized: userAllowed.strlanguage1?.toLowerCase() === 'access authorized',
        allowedZones: [{ zone: host.zone }],
        message: i18n.language === Lang.EN ? userAllowed.strlanguage1 : userAllowed.strlanguage2,
      });
    } catch (error) {
      await handleVerifyCardLocalDB(cardNumber, host, setHostQRcodeCreated, true);
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity
          hitSlop={{ top: 30, right: 30, bottom: 30, left: 30 }}
          onPress={() => navigation.goBack()}
        >
          <Feather name="arrow-left" style={styles.icon} />
        </TouchableOpacity>
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{translate('entryReader.title')}</Text>
        <Text style={styles.message}>{translate('entryReader.instructions')}</Text>
      </View>
      <View style={styles.content}>
        <View style={styles.inputContainer}>
          <TextInput
            ref={inputRef}
            style={styles.input}
            keyboardType="numeric"
            placeholder={translate('cardNumber')}
            placeholderTextColor="#fff"
            value={cardNumber}
            onChangeText={(value) => setCardNumber(value)}
            onSubmitEditing={handleEntryFinished}
          />
          <TouchableOpacity onPress={handleEntryFinished}>
            <Feather name="check" color="#fff" size={24} style={{ marginLeft: 8 }} />
          </TouchableOpacity>
        </View>
      </View>
      <Modal animationType="slide" visible={!!hostQRcodeCreated} onRequestClose={handleClose}>
        {!!hostQRcodeCreated && (
          <EmployeeModal
            host={hostQRcodeCreated.data as HostsModel}
            handleClose={handleClose}
            zonesAllowed={hostQRcodeCreated.allowedZones}
            isZoneAuthorized={hostQRcodeCreated.isZoneAuthorized}
            message={hostQRcodeCreated.message}
          />
        )}
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.black,
    alignItems: 'center',
  },
  inputContainer: {
    width: 320,
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    fontFamily: fontFamily.regular,
    fontSize: 18,
    borderRadius: 4,
    flex: 1,
    padding: 8,
    backgroundColor: '#1a1a1a',
    color: '#fff',
    fontWeight: 'bold',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    padding: 24,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 24,
    color: themes.colors.white,
  },
  titleContainer: {
    alignItems: 'center',
    marginTop: 12,
  },
  title: {
    fontSize: 28,
    fontFamily: fontFamily.bold,
    color: themes.colors.white,
    marginTop: 48,
    marginBottom: 6,
  },
  message: {
    fontSize: 16,
    color: themes.colors.white,
  },
});
