import { Feather } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import { Spin, TopMenu, Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Dimensions, InteractionManager, StyleSheet } from 'react-native';
import { TabView, TabBar, SceneRendererProps, NavigationState } from 'react-native-tab-view';
import { useSelector } from 'react-redux';

import { invitesApi } from '../../services/api';
import ActiveInvites from './ActiveInvites';
import PastInvites from './PastInvites';
import { AnimatedButton } from './PendingInvites/AnimatedButton';
import PendingInvitesModal from './PendingInvites/PendingInvitesModal';

export default function PersonalInvites() {
  const loading = useSelector((state: any) => state.loading.loading);
  const [loadingPendingInvites, setLoadingPendingInvites] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const { t: translate } = useTranslation();

  const [index, setIndex] = useState(0);

  const [meetings, setMeetings] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const getPendingInvites = useCallback(async (isMounted) => {
    try {
      setLoadingPendingInvites(true);
      const pending = await invitesApi.getPending();

      if (isMounted) {
        setMeetings(pending);
        setLoadingPendingInvites(false);
      }
    } catch (error) {
      logSentryException({
        error,
        file: 'PersonalInvites.js',
        message: 'Error at invitesGetPendingInvites function',
      });
      if (isMounted) {
        setLoadingPendingInvites(false);
      }
    }
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      let isMounted = true;

      const task = InteractionManager.runAfterInteractions(() => {
        if (!loadingPendingInvites) {
          getPendingInvites(isMounted);
        }
      });

      return () => {
        isMounted = false;
        task.cancel();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await getPendingInvites(true);
    setRefreshing(false);
  }, [getPendingInvites]);

  const count = meetings.length;

  const renderTabBar = (
    props: SceneRendererProps & {
      navigationState: NavigationState<{
        key: string;
        title: string | undefined;
      }>;
    }
  ) => (
    <TabBar
      {...props}
      pressOpacity={0.5}
      pressColor={themes.colors.lightSecondary}
      activeColor={themes.colors.darkSecondary}
      inactiveColor={themes.colors.lightSecondary}
      indicatorStyle={{ backgroundColor: themes.colors.lightSecondary }}
      style={{ backgroundColor: themes.colors.white }}
      labelStyle={{
        textTransform: 'capitalize',
        fontFamily: fontFamily.semibold,
        fontSize: 16,
      }}
    />
  );

  const renderScene = (
    props: SceneRendererProps & {
      route: {
        key: string;
        title: string | undefined;
      };
    }
  ) => {
    switch (props.route.key) {
      case 'first':
        return <ActiveInvites onRefresh={onRefresh} refreshing={refreshing} />;
      case 'second':
        return <PastInvites />;
      default:
        return null;
    }
  };

  const initialLayout = { width: Dimensions.get('window').width };

  return (
    <View style={styles.container}>
      <Spin visible={loading} />
      <TopMenu />

      {count > 0 && (
        <View style={styles.buttonContainer}>
          <AnimatedButton style={styles.button} onPress={() => setModalVisible(true)}>
            <Feather name="alert-triangle" style={styles.buttonIcon} />
            <Text style={styles.buttonText}>
              {translate(`pendingInvites.youHaveInvite.${count === 1 ? 'one' : 'other'}`, {
                count,
              })}
            </Text>
          </AnimatedButton>
        </View>
      )}

      <TabView
        lazy
        onIndexChange={setIndex}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        initialLayout={initialLayout}
        navigationState={{
          index,
          routes: [
            { key: 'first', title: translate('personalInvites.activeInvitesTitle') },
            { key: 'second', title: translate('personalInvites.pastInvitesTitle') },
          ],
        }}
      />

      <PendingInvitesModal
        meetings={meetings}
        visible={modalVisible}
        hideModal={() => setModalVisible(false)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.white,
  },
  buttonContainer: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: themes.colors.lightPrimary,
  },
  button: {
    alignSelf: 'stretch',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 50,
    borderRadius: 6,
    paddingHorizontal: 24,
    backgroundColor: themes.colors.primary,
  },
  buttonText: {
    fontFamily: fontFamily.medium,
    fontSize: 16,
    color: themes.colors.white,
  },
  buttonIcon: {
    fontSize: 16,
    marginRight: 10,
    color: themes.colors.white,
  },
});
