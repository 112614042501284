import Feather from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import {
  CustomFieldsModel,
  CustomFieldValuesModel,
  MeetingLocationsModel,
  MeetingsModel,
  MeetingTypesModel,
} from '@w3lcome/types';
import { Spin, Header, EmailTagInput, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import Input from '_/components/Input';
import WebDateTimePicker from '_/components/WebDateTimePicker';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { validEmail } from '_/helpers/functions';
import { getDateLocale } from '_/helpers/getDateLocale';
import { AppRoute, EditMeetingProps } from '_/routes/types';
import { hostsApi, meetingLocationsApi } from '_/services/api';
import { getMeetingTypesRequest } from '_/store/modules/meeting-types/actions';
import { format } from 'date-fns';
import * as Localization from 'expo-localization';
import moment from 'moment-timezone';
import { transparentize, lighten } from 'polished';
import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Keyboard,
  Platform,
  TextInput,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  Image,
  StyleSheet,
  TouchableWithoutFeedback,
  FlatList,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { useDispatch, useSelector } from 'react-redux';

import { updateMeetingRequest, createMeetingRequest } from '_/store/modules/meeting/actions';

import CustomFieldsInput from './CustomFields';
import ModalSelector from './ModalMeetingTypeSelector';
import ModalNumberSelector from './ModalNumberSelector';

interface CustomFieldWithError extends CustomFieldsModel {
  hasError?: boolean;
}

interface MeetingCustomFields extends MeetingsModel {
  customFields?: CustomFieldsModel[];
}

export default function EditMeeting() {
  const navigation = useNavigation<EditMeetingProps>();
  const dispatch = useDispatch();

  const companyId = useSelector((state: any) => state.company.id);
  const { defaultMeetingLocation, defaultMeetingTypeId } = useSelector((state: any) => state.host);
  const { loading } = useSelector((state: any) => state.loading);
  const meeting = useSelector((state: any) => state.meeting?.meeting);
  const { meetingTypes, meetingTypeLoaded } = useSelector((state: any) => state.meetingTypes);
  const { multiDayMeetingEnabled } = useSelector((state: any) => state.company.customization);
  const { i18n, t: translate } = useTranslation();

  const locale = getDateLocale(i18n.language);

  const [values, setValues] = useState<Partial<MeetingCustomFields>>({
    title: meeting?.title || '',
    message: meeting?.message || '',
    location: meeting?.location || '',
    notes: meeting?.notes || '',
    start: meeting?.start || 0,
    end: meeting?.end || 0,
    createdOn: meeting?.createdOn || 'MOBILE',
    timezone: meeting?.timezone || Localization.timezone,
    customFields: meeting?.customFieldValues || [],
    meetingLocationId: undefined,
    coHosts: meeting?.coHosts || [],
    guests: meeting?.guests || [],
  });

  const [limitOfLinkInvites, setLimitOfLinkInvites] = useState('');
  const [selectMeetingTypeId, setSelectMeetingTypeId] = useState<string | number>('');
  const [locations, setLocations] = useState<MeetingLocationsModel[]>([]);
  const [allLocations, setAllLocations] = useState<MeetingLocationsModel[]>([]);
  const [hasInputFocus, setHasInputFocus] = useState(false);

  const titleRef = useRef<TextInput>(null);
  const messageRef = useRef<TextInput>(null);
  const locationRef = useRef<TextInput>(null);
  const notesRef = useRef<TextInput>(null);

  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [currentDatePicker, setCurrentDatePicker] = useState('');

  const [location, setLocation] = useState('');
  const [disabledLocation, setDisabledLocation] = useState('');

  const customFields = useMemo(() => {
    if (meeting?.meetingType?.customFields) {
      return (
        meeting?.meetingType?.customFields?.filter(
          (f: CustomFieldsModel) => f.location === 'MEETING_HOST'
        ) || []
      );
    }

    if (meetingTypes) {
      const [meetingType] = meetingTypes.filter((type: any) => type.id === selectMeetingTypeId);

      const fields = meetingType?.customFields;

      if (fields) {
        return fields.filter((f: CustomFieldsModel) => f.location === 'MEETING_HOST') || [];
      }
    }
    return [];
  }, [meeting, selectMeetingTypeId, meetingTypes]);

  useEffect(() => {
    setDefaultMeetingLocation();
    dispatch(getMeetingTypesRequest());
    getMeetingLocations();
  }, []);

  useEffect(() => {
    if (meetingTypes || defaultMeetingTypeId) {
      const defaultId = meetingTypes?.some(
        (type: MeetingTypesModel) => type.id === defaultMeetingTypeId
      );
      let id = undefined;
      if (defaultId) {
        id = defaultMeetingTypeId;
      }
      setSelectMeetingTypeId(meeting?.meetingType?.id || id || meetingTypes?.[0]?.id);
    }
  }, [meetingTypes, defaultMeetingTypeId]);

  function setDefaultMeetingLocation() {
    if (meeting?.meetingLocation) {
      setValues({
        ...values,
        meetingLocationId: meeting.meetingLocation.id,
        location: null,
      });
      setLocation(meeting.meetingLocation.name);
      setDisabledLocation(meeting.meetingLocation.name);
    } else if (meeting?.location) {
      setValues({
        ...values,
        meetingLocationId: undefined,
        location: meeting.location,
      });
      setLocation(meeting.location);
    } else if (defaultMeetingLocation) {
      setValues({
        ...values,
        meetingLocationId: defaultMeetingLocation.id,
        location: null,
      });
      setLocation(defaultMeetingLocation.name);
      setDisabledLocation(defaultMeetingLocation.name);
    }
  }

  function showDatePicker() {
    setDatePickerVisibility(true);
  }

  function hideDatePicker() {
    setDatePickerVisibility(false);
  }

  function handleConfirm(date: Date) {
    setDatePickerVisibility(!isDatePickerVisible);
    const timezone = values.timezone || Localization.timezone;
    if (currentDatePicker === 'startDatePicker') {
      const start = moment(date).tz(timezone).toDate();

      setValues({
        ...values,
        start,
        // add 1 hour from start and set to end
        end: moment(date).add(1, 'hours').tz(timezone).toDate(),
      });
    } else {
      const start = values.start || moment().tz(timezone).toDate();
      let end = moment(date).tz(timezone).toDate();
      if (!multiDayMeetingEnabled) {
        end = moment(start)
          .tz(timezone)
          .set({ hour: date.getHours(), minute: date.getMinutes() })
          .toDate();
      }

      if (end < start) {
        return showMessage({
          message: translate('editMeeting.invalidDate'),
          type: 'danger',
        });
      }

      setValues({ ...values, end });
    }
    hideDatePicker();
  }

  function checkFields(
    fields: CustomFieldWithError[],
    values: CustomFieldValuesModel[] | undefined
  ): CustomFieldWithError[] {
    return fields.map((field) => {
      const data = values?.find(({ customFieldId }) => customFieldId === field.id)?.value;

      return {
        ...field,
        hasError: field.required && !field.disabled && !data,
      };
    });
  }

  function handleSubmit() {
    if (!meetingTypes?.length) {
      showMessage({
        message: translate('editMeeting.emptyList'),
        type: 'danger',
      });
      return;
    }

    if (moment().diff(values.start, 'days') > 0 || moment().diff(values.end, 'days') > 0) {
      showMessage({
        message: translate('editMeeting.invalidDate'),
        type: 'danger',
      });
      return;
    }

    if (!values.title) {
      showMessage({
        message: translate('editMeeting.titleRequired'),
        type: 'danger',
      });
      titleRef.current?.focus();
      return;
    }

    if (!values.start || !values.end) {
      showMessage({
        message: translate('editMeeting.datesRequired'),
        type: 'danger',
      });
      return;
    }

    if (values.end < values.start) {
      showMessage({
        message: translate('editMeeting.datesInvalid'),
        type: 'danger',
      });
      return;
    }

    const checkedFields = checkFields(
      customFields,
      values.customFields as unknown as CustomFieldValuesModel[]
    );

    if (checkedFields.some(({ hasError }) => hasError)) {
      showMessage({
        message: translate('editMeeting.fieldsRequired'),
        type: 'danger',
      });

      return;
    }

    if (meeting) {
      const meetingObj = {
        ...values,
        id: meeting.id,
        companyId,
        meetingTypeId: selectMeetingTypeId || null,
        lang: i18n.language,
      };

      dispatch(updateMeetingRequest(meetingObj));
    } else {
      const meetingObj = {
        ...values,
        limit: limitOfLinkInvites,
        companyId,
        meetingTypeId: selectMeetingTypeId || null,
        lang: i18n.language,
      };

      dispatch(createMeetingRequest(meetingObj));
    }
  }

  async function handleRemoveEmailTag(tag: string, isHost = false) {
    if (isHost) {
      if (meeting) {
        const [host] = await hostsApi.getHosts({ email: tag });

        if (host) {
          try {
            await hostsApi.deleteMeetingHosts(null, {
              hostId: host.id,
              meetingId: meeting.id,
            });
          } catch (error: any) {
            if (error.response.data.name !== 'NotFound') {
              showMessage({
                message: translate('meetingSaga.updateMeetingError'),
                type: 'danger',
              });
            }
          }
        }
      }

      const filteredHosts = values.coHosts?.filter((email: string) => email !== tag);

      setValues({
        ...values,
        coHosts: filteredHosts,
      });

      return;
    }

    const filteredGuests = values.guests?.filter((email: string) => email !== tag);

    setValues({
      ...values,
      guests: filteredGuests,
    });
  }

  const handleAddEmailTag = (tag: string, isHost = false) => {
    if (!validEmail(tag)) {
      showMessage({
        message: translate('editMeeting.invalidEmail'),
        type: 'danger',
      });
      return;
    }

    const targetArray = (isHost ? values.coHosts : values.guests) ?? [];

    const isEmailIncluded = targetArray.some((item: string) => item === tag);

    if (isEmailIncluded) {
      showMessage({
        message: translate('editMeeting.emailAlreadyIncluded'),
        type: 'danger',
      });
      return;
    }

    const setArray = isHost
      ? (coHosts: string[]) => setValues({ ...values, coHosts })
      : (guests: string[]) => setValues({ ...values, guests });

    setArray([...targetArray, tag]);
  };

  function handleBackNavigation() {
    navigation.navigate(AppRoute.MEETINGS);
  }

  const LocationList = useCallback(() => {
    if (!hasInputFocus) {
      return null;
    }

    return (
      <FlatList
        nestedScrollEnabled
        data={locations}
        keyExtractor={(item) => item.id}
        style={styles.list}
        renderItem={({ item }) => (
          <TouchableOpacity
            onPress={() => {
              setValues({
                ...values,
                meetingLocationId: item.id,
                location: null,
              });
              setHasInputFocus(false);
              setDisabledLocation(item.name);
              setLocation(item.name);
              setLocations(allLocations);
              notesRef.current?.focus();
            }}
          >
            <View style={styles.autocompleteButton}>
              {item.pictureUrl ? (
                <Image style={styles.avatar} source={{ uri: item.pictureUrl }} />
              ) : (
                <View style={styles.avatar}>
                  <Feather name="map-pin" style={styles.icon} />
                </View>
              )}

              <Text style={styles.autocompleteText}>{item.name}</Text>
              <Text numberOfLines={8} style={styles.autocompleteSmall}>
                {item.description}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      />
    );
  }, [locations, hasInputFocus]);

  async function searchApiLocation(search: string) {
    setValues({ ...values, location: search, meetingLocationId: undefined });
    setLocation(search);
    if (search) {
      const data = allLocations.filter(({ name }) =>
        name.toLowerCase().includes(search?.toLowerCase())
      );

      setLocations(data);
    } else {
      setLocations(allLocations);
    }
  }

  async function getMeetingLocations() {
    try {
      const { data } = await meetingLocationsApi.getMeetingLocations({
        $select: ['name', 'id', 'pictureUrl', 'description'],
        companyId,
      });

      setAllLocations(data);
      setLocations(data);
    } catch (error) {
      showMessage({
        message: translate('emailTag.searchHostError'),
        type: 'danger',
      });
    }
  }

  if (!meetingTypeLoaded) {
    return (
      <View style={styles.container}>
        <Spin visible />
      </View>
    );
  }

  const { coHosts, end, guests, message, notes, start, title } = values;

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <Spin visible={loading} />
        <Header
          handleBack={handleBackNavigation}
          title={
            meeting
              ? translate('editMeeting.editMeetingTitle')
              : translate('editMeeting.newMeetingTitle')
          }
        />
        <KeyboardAvoidingView
          style={styles.container}
          keyboardVerticalOffset={40}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
          <View style={styles.content}>
            <ScrollView
              keyboardDismissMode="interactive"
              keyboardShouldPersistTaps="always"
              showsVerticalScrollIndicator={false}
            >
              <TouchableWithoutFeedback
                onPress={() => {
                  if (Platform.OS !== 'web') Keyboard.dismiss();
                  setHasInputFocus(false);
                }}
              >
                <View>
                  {Platform.OS === 'web' ? (
                    <WebDateTimePicker
                      visible={isDatePickerVisible}
                      handleClose={hideDatePicker}
                      selectedDate={currentDatePicker === 'startDatePicker' ? start : end}
                      handleConfirm={handleConfirm}
                      locale={locale.code}
                      mode={
                        !multiDayMeetingEnabled && currentDatePicker === 'endDatePicker'
                          ? 'time'
                          : 'datetime'
                      }
                    />
                  ) : (
                    <DateTimePickerModal
                      date={
                        start
                          ? new Date(currentDatePicker === 'endDatePicker' && end ? end : start)
                          : moment().add(1, 'hour').toDate()
                      }
                      textColor={themes.colors.primary}
                      isVisible={isDatePickerVisible}
                      mode={
                        !multiDayMeetingEnabled && currentDatePicker === 'endDatePicker'
                          ? 'time'
                          : 'datetime'
                      }
                      onConfirm={handleConfirm}
                      onCancel={hideDatePicker}
                      locale={locale.code}
                      confirmTextIOS={translate('confirmButton')}
                      cancelTextIOS={translate('cancelButton')}
                    />
                  )}

                  <View style={styles.inputsContainer}>
                    <View style={{ flex: 1 }}>
                      <Text style={[styles.formLabel, { textAlign: 'center' }]}>
                        {translate('editMeeting.selectMeetingType')}
                      </Text>
                    </View>
                    <View style={styles.formInput}>
                      <ModalSelector
                        value={selectMeetingTypeId}
                        disabled={!!meeting || !selectMeetingTypeId}
                        meetingTypes={meetingTypes}
                        onChange={(option) => {
                          setSelectMeetingTypeId(option.key);
                        }}
                      />
                    </View>
                    {meetingTypeLoaded && !meetingTypes.length && (
                      <View style={{ flex: 1 }}>
                        <Text
                          style={{ textAlign: 'center', color: themes.colors.danger, fontSize: 14 }}
                        >
                          {translate('editMeeting.emptyList')}
                        </Text>
                      </View>
                    )}
                  </View>

                  <Input
                    label={translate('editMeeting.subject')}
                    returnKeyType="next"
                    autoCorrect
                    autoCapitalize="sentences"
                    ref={titleRef}
                    value={title}
                    style={styles.formInput}
                    placeholder={translate('editMeeting.subjectPlaceholder')}
                    onChangeText={(title) => setValues({ ...values, title })}
                    onSubmitEditing={Keyboard.dismiss}
                    required
                    icon="type"
                    containerStyle={{ marginBottom: 12 }}
                  />

                  <TouchableOpacity
                    style={styles.dateContainer}
                    onPress={() => {
                      setCurrentDatePicker('startDatePicker');
                      showDatePicker();
                    }}
                  >
                    <Input
                      label={translate('editMeeting.start')}
                      required
                      autoCorrect
                      icon="calendar"
                      pointerEvents="none"
                      style={styles.formInput}
                      editable={false}
                      value={
                        start ? `${format(new Date(start), 'dd MMMM yyyy H:mm', { locale })}` : ''
                      }
                      placeholder={translate('editMeeting.startPlaceholder')}
                      containerStyle={{ marginBottom: 12 }}
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.dateContainer}
                    onPress={() => {
                      setCurrentDatePicker('endDatePicker');
                      showDatePicker();
                    }}
                  >
                    <Input
                      label={translate('editMeeting.end')}
                      pointerEvents="none"
                      required
                      autoCorrect
                      style={styles.formInput}
                      editable={false}
                      value={end ? `${format(new Date(end), 'dd MMMM yyyy H:mm', { locale })}` : ''}
                      placeholder={translate('editMeeting.endPlaceholder')}
                      icon="calendar"
                      containerStyle={{ marginBottom: 12 }}
                    />
                  </TouchableOpacity>

                  <View style={styles.inputsContainer}>
                    {!disabledLocation && (
                      <View style={styles.listView}>
                        <LocationList />
                      </View>
                    )}

                    {!!disabledLocation && (
                      <View>
                        <Text style={styles.formLabel}>{translate('editMeeting.location')}</Text>
                        <View style={styles.formInput}>
                          <View style={styles.formInputTag}>
                            <Text numberOfLines={1}>{disabledLocation}</Text>
                            <Feather
                              name="x"
                              style={styles.closeIcon}
                              onPress={() => {
                                setValues({
                                  ...values,
                                  meetingLocationId: undefined,
                                  location: null,
                                });
                                setDisabledLocation('');
                                setLocation('');
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    )}
                    {!disabledLocation && (
                      <Input
                        label={translate('editMeeting.location')}
                        placeholder={translate('editMeeting.locationPlaceholder')}
                        ref={locationRef}
                        autoCorrect
                        onFocus={() => setHasInputFocus(true)}
                        autoCapitalize="sentences"
                        value={location}
                        style={styles.formInput}
                        onChangeText={searchApiLocation}
                        onSubmitEditing={() => notesRef.current?.focus()}
                        icon="map-pin"
                      />
                    )}
                  </View>

                  {appConfig.showMessageInputonCreateMeeting === 'true' && (
                    <Input
                      label={translate('editMeeting.message')}
                      autoCapitalize="sentences"
                      autoCorrect
                      ref={messageRef}
                      value={message || ''}
                      style={styles.formInput}
                      placeholder={translate('editMeeting.messagePlaceholder')}
                      onChangeText={(message) => setValues({ ...values, message })}
                      onSubmitEditing={() => locationRef.current?.focus()}
                      icon="align-left"
                      containerStyle={{ marginBottom: 12 }}
                    />
                  )}

                  {appConfig.showNotesInputonCreateMeeting === 'true' && (
                    <Input
                      label={translate('editMeeting.notes')}
                      autoCapitalize="sentences"
                      autoCorrect
                      ref={notesRef}
                      value={notes || ''}
                      style={styles.formInput}
                      placeholder={translate('editMeeting.notesPlaceholder')}
                      onChangeText={(notes) => setValues({ ...values, notes })}
                      onSubmitEditing={Keyboard.dismiss}
                      multiline
                      icon="info"
                    />
                  )}

                  <CustomFieldsInput
                    setCustomFieldsValues={(customFields) => setValues({ ...values, customFields })}
                    customFields={customFields}
                    customFieldsValues={values.customFields}
                  />

                  {!meeting && (
                    <View style={[styles.inputsContainer, styles.selectInputsContainer]}>
                      <View style={{ flex: 1 }}>
                        <Text style={[styles.formLabel, styles.selectFormLabel]}>
                          {translate('editMeeting.invitees')}
                        </Text>
                      </View>
                      <View style={styles.selectContainer}>
                        <View style={[styles.formInput, styles.selectFormInput]}>
                          <ModalNumberSelector
                            type="numbers"
                            value={limitOfLinkInvites}
                            onChange={(option) => setLimitOfLinkInvites(option.key)}
                          />
                        </View>
                      </View>
                    </View>
                  )}

                  <View style={styles.inputsContainer}>
                    <Text style={styles.formLabel}>{translate('Lista de Anfitriões')}</Text>
                    <View>
                      <EmailTagInput
                        emailsArray={coHosts ?? []}
                        removeEmailTag={(email) => handleRemoveEmailTag(email, true)}
                        onSubmitEditing={(email) => handleAddEmailTag(email, true)}
                        isHostList
                      />
                    </View>
                  </View>

                  <View style={styles.inputsContainer}>
                    <Text style={styles.formLabel}>{translate('editMeeting.inviteesList')}</Text>
                    <View>
                      <EmailTagInput
                        emailsArray={guests ?? []}
                        removeEmailTag={(email) => handleRemoveEmailTag(email)}
                        onSubmitEditing={(email) => handleAddEmailTag(email)}
                      />
                    </View>
                  </View>

                  <TouchableOpacity style={styles.button} onPress={handleSubmit}>
                    <Text style={styles.buttonText}>{translate('general.save')}</Text>
                  </TouchableOpacity>
                </View>
              </TouchableWithoutFeedback>
            </ScrollView>
          </View>
        </KeyboardAvoidingView>
      </View>
    </ContainerWidthLimit>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.white,
  },
  content: {
    flex: 1,
    padding: 24,
    paddingTop: 0,
    paddingBottom: 0,
  },
  inputsContainer: {
    marginBottom: 24,
  },
  selectInputsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectContainer: {
    alignItems: 'flex-end',
    borderBottomWidth: 0,
    flex: 1,
  },
  formLabel: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.medium,
    fontSize: 17,
  },
  selectFormLabel: {
    width: 200,
  },
  formInput: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 15,
    paddingVertical: 8,
  },
  formInputTag: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
    fontSize: 15,
    borderRadius: 4,
    borderWidth: 2,
    borderStyle: 'solid',
    padding: 6,
    borderColor: themes.colors.lightSecondary,
    backgroundColor: transparentize(0.7, themes.colors.lightQuaternary),
    paddingHorizontal: 8,
    marginHorizontal: 8,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectFormInput: {
    width: 100,
  },
  dateContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: themes.colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    marginBottom: 8,
    height: 44,
  },
  buttonText: {
    color: themes.colors.white,
  },
  icon: {
    color: themes.colors.lightSecondary,
    fontSize: 18,
  },
  closeIcon: {
    fontSize: 18,
    color: themes.colors.darkSecondary,
  },
  autocompleteButton: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    borderColor: themes.colors.lightSecondary,
    borderBottomWidth: 1,
  },
  autocompleteText: {
    color: themes.colors.darkSecondary,
    fontSize: 14,
    marginHorizontal: 12,
    maxWidth: '40%',
  },
  avatar: {
    zIndex: 1000,
    width: 42,
    height: 42,
    borderRadius: 100,
    backgroundColor: themes.colors.graySecondary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  autocompleteSmall: {
    flex: 1,
    fontSize: 12,
    color: themes.colors.graySecondary,
  },
  list: {
    borderColor: themes.colors.lightSecondary,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    maxHeight: 260,
  },
  listView: {
    position: 'absolute',
    bottom: 80,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: lighten(0.2, themes.colors.lightPrimary),
  },
});
