import AsyncStorage from '@react-native-async-storage/async-storage';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import * as Linking from 'expo-linking';
import { transparentize } from 'polished';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Platform, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import Modal from 'react-native-modal';

import Text from '../../Text';

interface DeniedNotificationsModalProps {
  visible?: boolean;
  hideModal: () => void;
}

export default function DeniedNotificationsModal({
  visible = false,
  hideModal,
}: DeniedNotificationsModalProps) {
  const openSettings = useCallback(async () => {
    try {
      if (Platform.OS === 'ios') {
        Linking.openURL('app-settings:');
      }

      Linking.openSettings();

      hideModal();
    } catch (error) {
      logSentryException({
        error,
        file: 'DeniedNotificationsModal.js',
        message: 'Error at openSettings function',
      });
      console.tron.log('File: DeniedNotificationsModal.js', error);
    }
  }, [hideModal]);

  const skipNotification = useCallback(async () => {
    try {
      await AsyncStorage.setItem('@w3lcome/notifications', 'skipped');
      hideModal();
    } catch (error) {
      logSentryException({
        error,
        file: 'NotificationsCheckModal.js',
        message: 'Error at skipNotification function',
      });
      console.tron.log('File: NotificationsCheckModal', error);
    }
  }, [hideModal]);

  const { t: translate } = useTranslation();

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onDismiss={hideModal}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>{translate('deniedNotificationsModal.title')}</Text>

          <Text style={styles.instruction}>
            {translate('deniedNotificationsModal.instruction')}
          </Text>

          <Text style={styles.notes}>{translate('deniedNotificationsModal.notes')}</Text>

          <View style={styles.btnContainer}>
            <TouchableOpacity onPress={skipNotification} style={styles.btnSkip}>
              <Text style={styles.btnSkipText}>{translate('deniedNotificationsModal.skip')}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.btn} onPress={openSettings}>
              <Text style={styles.btnReadyText}>
                {translate('deniedNotificationsModal.enable')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  container: {
    shadowColor: themes.colors.black,
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    elevation: 2,
    borderRadius: 4,
    width: width - 48,
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  content: {
    width: '100%',
    paddingVertical: 24,
    paddingHorizontal: 24,
  },
  title: {
    fontFamily: fontFamily.medium,
    fontSize: 18,
    marginBottom: 10,
  },
  instruction: {
    color: themes.colors.grayPrimary,
  },
  btnContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 10,
  },
  notes: {
    fontSize: 12,
    marginTop: 10,
    fontFamily: fontFamily.light,
    color: themes.colors.grayPrimary,
  },
  btn: {
    marginLeft: 10,
    borderRadius: 6,
    paddingVertical: 10,
    paddingHorizontal: 30,
    backgroundColor: themes.colors.primary,
  },
  btnReadyText: {
    color: themes.colors.white,
  },

  btnSkip: {
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  btnSkipText: {
    color: themes.colors.primary,
  },
});
