import Feather from '@expo/vector-icons/Feather';
import { MeetingsModel } from '@w3lcome/types';
import { Text } from '_/components';
import themes from '_/constants/themes';
import { getDateLocale } from '_/helpers/getDateLocale';
import { format } from 'date-fns';
import * as Localization from 'expo-localization';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, ImageBackground } from 'react-native';

import styles from './styles';

interface MeetingCardProps {
  meeting: MeetingsModel;
  getMeetingDetails: (meeting: MeetingsModel) => void;
}

export default function MeetingCard({ meeting, getMeetingDetails }: MeetingCardProps) {
  const { i18n, t: translate } = useTranslation();
  const locale = getDateLocale(i18n.language);

  const metingEndInTheSameDay = useMemo(() => {
    return moment(meeting.start).startOf('day').isSame(moment(meeting.end).startOf('day'));
  }, [meeting]);

  function convertTime(time: Date | string) {
    const convertedTime = moment(time).tz(
      meeting.timezone || Localization.timezone || 'America/Sao_Paulo'
    );

    return convertedTime;
  }

  function renderTodayTag() {
    const convertedTime = convertTime(meeting.start).startOf('day').unix();

    const today = moment().tz(Localization.timezone).startOf('day').unix();

    if (convertedTime === today) {
      return (
        <View style={styles.shadow}>
          <View style={styles.flag}>
            <Text style={styles.flagText}>{translate('general.today')}</Text>
          </View>
        </View>
      );
    }
    return null;
  }

  if (!meeting) {
    return null;
  }

  return (
    <TouchableOpacity style={styles.container} onPress={() => getMeetingDetails(meeting)}>
      <View style={styles.content}>
        <ImageBackground source={themes.images.calendar} style={styles.imageContainer}>
          <View style={styles.imgTextContainer}>
            <Text style={styles.dayText}>
              {format(convertTime(meeting.start).toDate(), 'dd', { locale })}
            </Text>
            <Text style={styles.monthText}>
              {format(convertTime(meeting.start).toDate(), 'MMM', { locale })}
            </Text>
          </View>
        </ImageBackground>

        <View style={styles.infoContainer}>
          <Text style={styles.title}>{meeting.title}</Text>

          {(!!meeting?.meetingLocation?.name || !!meeting?.location) && (
            <View style={styles.rowContainer}>
              <Feather name="map-pin" size={10} style={styles.darkQuaternary} />
              <Text style={styles.locationText}>
                {meeting?.meetingLocation?.name || meeting?.location}
              </Text>
            </View>
          )}

          {!!meeting?.start && (
            <View style={styles.rowContainer}>
              <View style={styles.timeTextView}>
                <Text style={styles.timeText}>{translate('meetings.start')} </Text>
              </View>
              <View style={styles.borderedContainer}>
                <Feather name="clock" size={8} style={styles.darkQuaternary} />
                <Text style={styles.timeText}>
                  {format(
                    convertTime(meeting.start).toDate(),
                    metingEndInTheSameDay ? 'p' : 'PPpp',
                    { locale }
                  )}
                </Text>
              </View>
            </View>
          )}

          {!!meeting?.end && (
            <View style={styles.rowContainer}>
              <View style={styles.timeTextView}>
                <Text style={styles.timeText}>
                  {translate('meetings.end')}
                  {` `}
                </Text>
              </View>

              <View style={styles.borderedContainer}>
                <Feather name="clock" size={8} style={styles.darkQuaternary} />
                <Text style={styles.timeText}>
                  {format(convertTime(meeting.end).toDate(), metingEndInTheSameDay ? 'p' : 'PPpp', {
                    locale,
                  })}
                </Text>
              </View>
            </View>
          )}
        </View>

        <View>
          <Feather name="chevron-right" size={18} style={styles.lightPrimary} />
        </View>
      </View>
      {renderTodayTag()}
    </TouchableOpacity>
  );
}
