import { action } from 'typesafe-actions';

import { MeetingTypesAPIResponseData } from './types';

export const getMeetingTypesRequest = (params?: any) =>
  action('@meetings/GET_MEETING_TYPES_REQUEST', {
    params,
  });

export const getMeetingTypesSuccess = ({ data, total, limit, skip }: MeetingTypesAPIResponseData) =>
  action('@meetings/GET_MEETING_TYPES_SUCCESS', {
    data,
    total,
    limit,
    skip,
  });

export const getMeetingTypesFailure = (params?: any) =>
  action('@meetings/GET_MEETING_TYPES_FAILURE', {
    params,
  });
