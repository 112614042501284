import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { subDays } from 'date-fns';
import { Moment } from 'moment';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, Dimensions, StyleSheet, Platform } from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import Modal from 'react-native-modal';

import { getMonths, getWeekDays } from './constants';
import Text from '../../Text';

const { width } = Dimensions.get('window');

interface CalendarModalProps {
  visible?: boolean;
  handleClose: (startDate?: Date, endDate?: Date) => void;
  selectedStartDate?: Date;
  selectedEndDate?: Date;
}

export default function CalendarModal({
  visible = false,
  handleClose,
  selectedStartDate,
  selectedEndDate,
}: CalendarModalProps) {
  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);
  const { t: translate } = useTranslation();

  const months = getMonths(translate);
  const weekDays = getWeekDays(translate);

  function onDateChange(date: Moment, type: 'START_DATE' | 'END_DATE' | undefined) {
    if (type === 'START_DATE') {
      setStartDate(date?.toDate());
      setEndDate(undefined);
    } else {
      setEndDate(date?.toDate());
    }
  }

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onBackdropPress={handleClose}
      onBackButtonPress={handleClose}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <View>
            <CalendarPicker
              startFromMonday
              showDayStragglers
              allowRangeSelection
              nextTitle=">"
              previousTitle="<"
              selectedDayColor={themes.colors.primary}
              selectedDayTextColor={themes.colors.white}
              todayBackgroundColor={themes.colors.secondary}
              months={months}
              scaleFactor={Platform.OS === 'web' ? 800 : 400}
              width={width - 40}
              height={width - 40}
              weekdays={weekDays}
              onDateChange={onDateChange}
              textStyle={styles.textStyle}
              selectedEndDate={selectedEndDate}
              selectedStartDate={selectedStartDate}
              monthYearHeaderWrapperStyle={styles.monthYearStyle}
              nextTitleStyle={[styles.headerBtn, { marginRight: 8 }]}
              previousTitleStyle={[styles.headerBtn, { marginLeft: 8 }]}
              maxDate={subDays(new Date(), 1)}
            />
            <TouchableOpacity style={styles.btn} onPress={() => handleClose(startDate, endDate)}>
              <Text style={styles.btnText}>{translate('general.filter')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  container: {
    overflow: 'hidden',
    shadowColor: themes.colors.black,
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    elevation: 2,
    borderRadius: 4,
    width: width - 48,
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  content: {
    paddingTop: 24,
  },
  btn: {
    alignSelf: 'center',
    backgroundColor: themes.colors.primary,
    marginTop: 36,
    borderRadius: 6,
    paddingVertical: 18,
    width: '100%',
    marginBottom: -1,
  },
  btnText: {
    color: themes.colors.white,
    fontFamily: fontFamily.regular,
    fontSize: 16,
    textAlign: 'center',
  },
  headerBtn: {
    borderWidth: 1,
    borderColor: themes.colors.primary,
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderRadius: 4,
  },
  textStyle: {
    fontSize: 14,
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
  },
  monthYearStyle: {
    padding: 8,
    borderBottomColor: themes.colors.lightPrimary,
    borderBottomWidth: 1,
  },
});
