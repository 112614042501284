import { Feather } from '@expo/vector-icons';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, Dimensions, StyleSheet, TextInput } from 'react-native';
import Modal from 'react-native-modal';

interface DenyConfirmationModalProps {
  visible?: boolean;
  hideModal?: () => void;
  denyVisit: (notes?: string) => void;
}

export default function DenyConfirmationModal({
  visible = false,
  hideModal,
  denyVisit,
}: DenyConfirmationModalProps) {
  const { t: translate } = useTranslation();

  const [reason, setReason] = useState('');

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>{translate('denyConfirmationModal.title')}</Text>
          <Text style={styles.description}>{translate('denyConfirmationModal.description')}</Text>
          <TextInput
            onChangeText={setReason}
            style={styles.modalInput}
            placeholder={translate('Reason')}
          />
        </View>
        <View style={styles.buttonsContainer}>
          <TouchableOpacity style={styles.btn} onPress={hideModal}>
            <Text>{translate('denyConfirmationModal.cancel')}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.btn, styles.btnDeny]} onPress={() => denyVisit(reason)}>
            <Text style={styles.btnText}>{translate('visitor.deny')}</Text>
            <Feather name="x" style={styles.icons} />
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  container: {
    overflow: 'hidden',
    shadowColor: themes.colors.black,
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    elevation: 2,
    borderRadius: 4,
    width: width - 48,
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  modalInput: {
    marginVertical: 12,
    width: '100%',
    fontSize: 16,
  },
  content: {
    padding: 24,
  },
  title: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
  },
  description: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    fontSize: 14,
  },
  buttonsContainer: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    width: '100%',
    backgroundColor: themes.colors.lightPrimary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btn: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 12,
    paddingVertical: 8,
    paddingHorizontal: 24,
  },
  btnText: {
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginRight: 8,
    color: themes.colors.white,
  },
  btnDeny: {
    backgroundColor: themes.colors.primary,
  },
  icons: {
    fontSize: 16,
    color: themes.colors.lightSecondary,
  },
});
