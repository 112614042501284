import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import Modal from 'react-native-modal';

interface LogoutConfirmationModalProps {
  visible: boolean;
  hideModal: () => void;
  handleLogout: () => void;
}

export default function LogoutConfirmationModal({
  visible,
  hideModal,
  handleLogout,
}: LogoutConfirmationModalProps) {
  const { t: translate } = useTranslation();

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>{translate('logoutConfirmationModal.title')}</Text>
        </View>
        <View style={styles.buttonsContainer}>
          <TouchableOpacity style={styles.btn} onPress={hideModal}>
            <Text>{translate('logoutConfirmationModal.cancel')}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.btn, styles.btnLogout]} onPress={handleLogout}>
            <Text style={styles.btnText}>{translate('logoutConfirmationModal.confirm')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.95, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  container: {
    overflow: 'hidden',
    shadowColor: themes.colors.black,
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    elevation: 2,
    borderRadius: 4,
    width: width - 48,
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  content: {
    padding: 24,
  },
  title: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
  },
  buttonsContainer: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    width: '100%',
    backgroundColor: themes.colors.lightSecondary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btn: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 12,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 24,
  },
  btnText: {
    fontFamily: fontFamily.medium,
    fontSize: 16,
    color: themes.colors.white,
  },
  btnLogout: {
    backgroundColor: themes.colors.primary,
  },
});
