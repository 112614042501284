import AsyncStorage from '@react-native-async-storage/async-storage';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import * as StoreReview from 'expo-store-review';
import { transparentize } from 'polished';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import Modal from 'react-native-modal';

import Text from '../Text';

export default function RatingModal() {
  const [visibleRatingModal, setVisibleRatingModal] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function getAppInitCount() {
      const alreadyRated = await AsyncStorage.getItem('alreadyRated');
      const appInitCount = await AsyncStorage.getItem('appInitCount');

      if (!alreadyRated) {
        if (!appInitCount) {
          let count = parseInt(appInitCount || '0', 10);

          count += 1;

          await AsyncStorage.setItem('appInitCount', count.toString());

          if (count >= 10) {
            setVisibleRatingModal(true);
            count = -20;
            await AsyncStorage.setItem('appInitCount', count.toString());
          }
        } else {
          await AsyncStorage.setItem('appInitCount', '1');
        }
      }
    }

    getAppInitCount();
  }, []);

  const hideModal = useCallback(() => {
    setVisibleRatingModal(false);
  }, []);

  const handleRateApp = useCallback(async () => {
    try {
      const isStoreReviewAvailable = await StoreReview.isAvailableAsync();
      if (isStoreReviewAvailable) {
        StoreReview.requestReview();
        await AsyncStorage.setItem('alreadyRated', 'true');
      }

      hideModal();
    } catch (error) {
      logSentryException({
        error,
        file: 'RatingModal.js',
        message: 'Error at handleRateApp function',
      });
      console.tron.log('File: RatingModal.js', error);
    }
  }, [hideModal]);

  return (
    <Modal
      isVisible={visibleRatingModal}
      style={styles.overlay}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.title}>{translate('ratingModal.title')}</Text>

          <Text style={styles.instruction}>{translate('ratingModal.instruction')}</Text>

          <Text style={styles.notes}>{translate('ratingModal.notes')}</Text>

          <View style={styles.btnContainer}>
            <TouchableOpacity onPress={hideModal} style={styles.btnSkip}>
              <Text style={styles.btnSkipText}>{translate('ratingModal.nope')}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.btn} onPress={handleRateApp}>
              <Text style={styles.btnReadyText}>{translate('ratingModal.sure')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  container: {
    shadowColor: themes.colors.black,
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    elevation: 2,
    borderRadius: 4,
    width: width - 48,
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  content: {
    width: '100%',
    paddingVertical: 24,
    paddingHorizontal: 24,
  },
  title: {
    fontFamily: fontFamily.medium,
    fontSize: 18,
    marginBottom: 10,
  },
  instruction: {
    color: themes.colors.grayPrimary,
  },
  btnContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 10,
  },
  notes: {
    fontSize: 12,
    marginVertical: 10,
    fontFamily: fontFamily.light,
    color: themes.colors.grayPrimary,
  },
  btn: {
    marginLeft: 10,
    borderRadius: 6,
    paddingVertical: 10,
    paddingHorizontal: 30,
    backgroundColor: themes.colors.primary,
  },
  btnReadyText: {
    color: themes.colors.white,
  },

  btnSkip: {
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  btnSkipText: {
    color: themes.colors.primary,
  },
});
