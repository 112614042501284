import Feather from '@expo/vector-icons/Feather';
import { CustomFieldValuesModel } from '@w3lcome/types';
import { Text, Spin, Header, InfoCard } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import React from 'react';
import {
  View,
  Platform,
  UIManager,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';

import { DropdownList } from './DropdownList/DropdownList';
import ExternalGuests from './ExternalGuests';
import Guest from './GuestList/Guest';
import InviteeList from './InviteeList';
import LinkList from './LinkList';
import MenuModal from './MenuModal';
import styles from './styles';
import { useMeeting } from './useMeeting';

if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const Meeting: React.FC = () => {
  const {
    visits,
    loading,
    hostName,
    menuModalVisible,
    hideModal,
    meeting,
    expanded,
    toggleExpanded,
    showModal,
    handleBack,
    headerTitle,
    subjectTitle,
    startDateTitle,
    endDateTitle,
    editMeetingHostTitle,
    editMeetingLocationTitle,
    editMeetingNotesTitle,
    editMeetingDescriptionTitle,
    meetingHideInfo,
    meetingShoweInfo,
    meetingGuestsTitle,
    meetingCoHostsTitle,
    isThereAnyGuest,
    guestsData,
    isThereAnyCoHost,
    coHostsData,
    startDateValue,
    endDateValue,
    language,
  } = useMeeting();

  return (
    <ContainerWidthLimit>
      <SafeAreaView style={styles.container}>
        <Spin visible={loading} />
        <Header
          handleBack={handleBack}
          title={headerTitle}
          rightItem={
            <TouchableOpacity
              hitSlop={{ top: 30, left: 30, bottom: 30, right: 30 }}
              onPress={showModal}
            >
              <Feather name="more-vertical" style={styles.iconMoreVertical} />
            </TouchableOpacity>
          }
        />
        <ScrollView
          style={styles.content}
          contentContainerStyle={{ paddingBottom: 48 }}
          showsHorizontalScrollIndicator={false}
        >
          <InfoCard copy title={subjectTitle ?? ''} value={meeting?.title} />
          <InfoCard copy title={startDateTitle ?? ''} value={startDateValue} />
          <InfoCard copy title={endDateTitle ?? ''} value={endDateValue} />
          {meeting?.customFieldValues &&
            meeting.customFieldValues.map((value: CustomFieldValuesModel) => (
              <InfoCard
                key={value.id}
                copy
                title={
                  (value.customField && value.customField?.placeholder?.[language]) ||
                  value.customField?.name ||
                  '-'
                }
                value={value.value}
              />
            ))}
          <InfoCard copy title={editMeetingHostTitle} value={hostName} />

          <InfoCard
            copy
            title={editMeetingLocationTitle}
            value={meeting?.meetingLocation?.name || meeting?.location}
            img={meeting?.meetingLocation?.pictureUrl}
          />

          <LinkList meeting={meeting} />
          {expanded && (
            <View>
              <InfoCard copy title={editMeetingNotesTitle} value={meeting?.notes} />
              <InfoCard copy title={editMeetingDescriptionTitle} value={meeting?.message} />

              <ExternalGuests visits={visits} />
            </View>
          )}

          {(!!meeting?.notes || !!meeting?.description || !!meeting?.visits) && (
            <TouchableOpacity onPress={toggleExpanded} style={styles.expandButtonContainer}>
              <Text style={styles.expandButtonText}>
                {expanded ? meetingHideInfo : meetingShoweInfo}
              </Text>
              <Feather style={styles.icon} name={expanded ? 'chevron-up' : 'chevron-down'} />
            </TouchableOpacity>
          )}

          {isThereAnyGuest && (
            <DropdownList title={meetingGuestsTitle}>
              {guestsData?.map((email: string) => (
                <Guest key={email} guest={email} />
              ))}
            </DropdownList>
          )}

          {isThereAnyCoHost && (
            <DropdownList title={meetingCoHostsTitle}>
              {coHostsData?.map((email: string) => (
                <Guest key={email} guest={email} />
              ))}
            </DropdownList>
          )}

          <InviteeList meeting={meeting} />
        </ScrollView>
        <MenuModal visible={menuModalVisible} hideModal={hideModal} />
      </SafeAreaView>
    </ContainerWidthLimit>
  );
};

export default Meeting;
