import { Feather } from '@expo/vector-icons/';
import themes from '_/constants/themes';
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

import Text from '../Text';

interface HeaderProps {
  handleBack?: () => void;
  title: string;
  rightItem?: any;
}

export default function Header({ handleBack, title, rightItem }: HeaderProps) {
  return (
    <View style={styles.header}>
      <View style={styles.innerHeader}>
        <TouchableOpacity
          hitSlop={{ top: 30, left: 30, bottom: 30, right: 30 }}
          onPress={handleBack}
        >
          <Feather name="arrow-left" style={[styles.headerIcon, styles.iconLeft]} />
        </TouchableOpacity>
        <View style={styles.headerTitle}>
          <Text fontType="semibold" style={styles.title}>
            {title}
          </Text>
        </View>
        {typeof rightItem !== 'boolean' && rightItem}
        {typeof rightItem == 'boolean' && (
          <View>
            <Feather name="arrow-right" style={[styles.headerIcon, styles.iconRight]} />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: themes.colors.white,
    height: 70,
    marginTop: 0,
  },
  innerHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    paddingLeft: 24,
    paddingRight: 24,
  },
  headerIcon: {
    fontSize: 24,
  },
  headerTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  title: {
    color: themes.colors.darkSecondary,
    fontSize: 22,
  },
  iconLeft: {
    color: themes.colors.darkSecondary,
  },
  iconRight: {
    color: themes.colors.white,
  },
});
