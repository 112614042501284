import Feather from '@expo/vector-icons/Feather';
import { MeetingLinksModel, MeetingsModel } from '@w3lcome/types';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { getDateLocale } from '_/helpers/getDateLocale';
import { format } from 'date-fns';
import * as Clipboard from 'expo-clipboard';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Share, TouchableOpacity, StyleSheet } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { useSelector } from 'react-redux';

interface LinkItemProps {
  link: MeetingLinksModel;
  hostName: string;
  meeting: MeetingsModel;
}

export default function LinkItem({ link, hostName, meeting }: LinkItemProps) {
  const company = useSelector((state: any) => state.company);
  const { i18n, t: translate } = useTranslation();

  const locale = getDateLocale(i18n.language);

  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  async function copyLink(meetingLink: string) {
    Clipboard.setString(meetingLink);

    showMessage({
      message: translate('meeting.copySuccess'),
      type: 'success',
    });
  }

  async function shareLink(meetingLink: string) {
    try {
      const result = await Share.share({
        message: `${translate('linkItem.shareMessage', {
          hostName,
          companyName: company.name,
          meetingSubject: meeting.title,
          meetingStart: `${format(new Date(meeting.start), 'PP', { locale })} ${translate(
            'editMeeting.at'
          )} ${format(new Date(meeting.start), 'p', { locale })}`,
          meetingEnd: `${format(new Date(meeting.end), 'PP', { locale })} ${translate(
            'editMeeting.at'
          )} ${format(new Date(meeting.end), 'p', { locale })}`,
          companyAddress: company.address ? `- ${company.address}` : '',
          companyComplement: company.complement ? `- ${company.complement}` : '',
          companyCity: company.city ? `- ${company.city}` : '',
          companyState: company.state ? `- ${company.state}` : '',
          companyCountry: `- ${company.country}`,
          meetingPlace: meeting?.meetingLocation?.name ?? meeting.location,
          meetingPlaceDescription: meeting?.meetingLocation?.description
            ? `- ${meeting?.meetingLocation?.description} `
            : '',
        })} ${meetingLink}`,
      });

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType

          showMessage({
            message: translate('meeting.shareSuccess'),
            type: 'success',
          });
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      showMessage({
        message: translate('meeting.shareError'),
        type: 'danger',
      });
    }
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={toggleExpanded}>
        <View style={{ ...styles.content, ...(link.usage >= link.limit ? styles.fullLink : {}) }}>
          <View style={styles.rowContainer}>
            <Feather
              name={expanded ? 'chevron-up' : 'chevron-down'}
              color={link.usage >= link.limit ? '#ddd' : '#222'}
              size={14}
            />
            <Text
              style={[styles.title, link.usage >= link.limit && styles.fullLinkText]}
              numberOfLines={1}
            >
              {link.link}
            </Text>
          </View>
          <TouchableOpacity
            disabled={link.usage >= link.limit}
            style={styles.shareContainer}
            onPress={() => copyLink(link.link)}
            hitSlop={{ top: 8, left: 8, bottom: 8, right: 8 }}
          >
            <Feather
              name="copy"
              style={[styles.icon, link.usage >= link.limit && styles.fullLinkIcon]}
            />
          </TouchableOpacity>
          <TouchableOpacity
            disabled={link.usage >= link.limit}
            style={styles.shareContainer}
            onPress={() => shareLink(link.link)}
            hitSlop={{ top: 8, left: 8, bottom: 8, right: 8 }}
          >
            <Feather
              name="send"
              style={[styles.icon, link.usage >= link.limit && styles.fullLinkIcon]}
            />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
      {expanded && (
        <View style={styles.details}>
          <View style={styles.badge}>
            <Text style={[styles.badgeText, link.usage >= link.limit && styles.fullLinkText]}>
              {translate('meeting.limit')}:{' '}
              <Text style={[styles.textBold, link.usage >= link.limit && styles.fullLinkText]}>
                {link.limit}
              </Text>
            </Text>
          </View>
          <View style={styles.badge}>
            <Text style={[styles.badgeText, link.usage >= link.limit && styles.fullLinkText]}>
              {translate('meeting.used')}:{' '}
              <Text style={[styles.textBold, link.usage >= link.limit && styles.fullLinkText]}>
                {link.usage}
              </Text>
            </Text>
          </View>
          <View style={styles.badge}>
            <Text style={[styles.badgeText, link.usage >= link.limit && styles.fullLinkText]}>
              {translate('meeting.created')}:{' '}
              <Text style={[styles.textBold, link.usage >= link.limit && styles.fullLinkText]}>
                {format(new Date(link.createdAt), 'P', { locale })}
              </Text>
            </Text>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: transparentize(0.8, themes.colors.lightPrimary),
    flex: 1,
    borderWidth: 1,
    borderRadius: 4,
    marginVertical: 4,
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 40,
    paddingLeft: 6,
  },
  rowContainer: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.light,
    flex: 1,
    fontSize: 12,
    marginHorizontal: 8,
  },
  details: {
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 15,
  },
  badge: {
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    borderColor: transparentize(0.8, themes.colors.lightPrimary),
    borderWidth: 2,
    borderRadius: 4,
    marginHorizontal: 6,
    paddingHorizontal: 4,
  },
  badgeText: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.light,
    fontSize: 10,
  },
  textBold: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 10,
  },
  fullLink: {
    color: themes.colors.lightSecondary,
  },
  shareContainer: {
    padding: 8,
  },
  icon: {
    fontSize: 18,
  },
  fullLinkIcon: {
    color: themes.colors.lightSecondary,
  },
  fullLinkText: {
    color: themes.colors.lightSecondary,
  },
});
