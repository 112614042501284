import { StackNavigationProp } from '@react-navigation/stack';

export enum AppRoute {
  HOME = 'Home',
  SIGNIN = 'Signin',
  SIGNOUT = 'Signup',
  FORGOT_PASSWORD = 'ForgotPassword',
  PASSWORD_SENT = 'PasswordSent',
  PAYMENT_ERROR = 'PaymentError',
  EMAIL_CONFIRMATION_NAV = 'EmailConfirmationNav',
  COMPANY_CONFIRMATION_NAV = 'CompanyConfirmationNav',
  VISITOR = 'Visitor',
  DELIVERY = 'Delivery',
  MEETINGS = 'Meetings',
  MEETING = 'Meeting',
  EDIT_MEETING = 'EditMeeting',
  QRCODE = 'QRCode',
  QRCODE_RENDER = 'QRCodeReader',
  PROFILE = 'Profile',
  EDIT_PROFILE = 'EditProfile',
  PLAN_DETAILS = 'PlanDetails',
  DELETE_ACCOUNT = 'DeleteAccount',
  NOTIFICATION = 'Notification',
  PERSONAL_INVITE = 'PersonalInvite',
  PERSONAL_MEETING = 'PersonalMeeting',
  PERSONAL_INVITES = 'PersonalInvites',
  PERSONAL_MEETING_ERROR = 'PersonalMeetingError',
  PERSONAL_MEETING_NOT_FOUND = 'PersonalMeetingNotFound"',
  PLAN_EXPIRED = 'PlanExpired"',
  NFC_READER = 'NFCReader',
  ENTRY_READER = 'EntryReader',
}

type RootStackParamList = {
  [AppRoute.HOME]: undefined;
  [AppRoute.SIGNIN]: undefined;
  [AppRoute.SIGNOUT]: undefined;
  [AppRoute.VISITOR]: undefined;
  [AppRoute.DELIVERY]: undefined;
  [AppRoute.PROFILE]: {
    screen: string;
  };
  [AppRoute.DELIVERY]: undefined;
  [AppRoute.PAYMENT_ERROR]: undefined;
  [AppRoute.DELETE_ACCOUNT]: undefined;
  [AppRoute.EDIT_MEETING]: undefined;
  [AppRoute.EDIT_PROFILE]: undefined;
  [AppRoute.MEETING]: undefined;
  [AppRoute.MEETINGS]: undefined;
  [AppRoute.NOTIFICATION]: undefined;
  [AppRoute.FORGOT_PASSWORD]?: { defaultEmail?: string };
  [AppRoute.PASSWORD_SENT]: undefined;
  [AppRoute.EMAIL_CONFIRMATION_NAV]: undefined;
  [AppRoute.COMPANY_CONFIRMATION_NAV]: undefined;
  [AppRoute.QRCODE]: undefined;
  [AppRoute.QRCODE_RENDER]: undefined;
  [AppRoute.PERSONAL_INVITE]?: { id: string; goBack?: boolean; backToInvites?: boolean };
  [AppRoute.PERSONAL_INVITES]: undefined;
  [AppRoute.PERSONAL_MEETING]?: { id: string; goBack?: boolean; isLink?: boolean };
  [AppRoute.PERSONAL_MEETING_ERROR]: undefined;
  [AppRoute.PERSONAL_MEETING_NOT_FOUND]: undefined;
  [AppRoute.PLAN_DETAILS]: undefined;
  [AppRoute.PLAN_EXPIRED]: undefined;
};

export type SigninProps = StackNavigationProp<RootStackParamList, AppRoute.SIGNIN>;

export type SignupProps = StackNavigationProp<RootStackParamList, AppRoute.SIGNOUT>;

export type VisitorProps = StackNavigationProp<RootStackParamList, AppRoute.VISITOR>;

export type DeliveryProps = StackNavigationProp<RootStackParamList, AppRoute.DELIVERY>;

export type ProfileProps = StackNavigationProp<RootStackParamList, AppRoute.PROFILE>;

export type DeleteAccountProps = StackNavigationProp<RootStackParamList, AppRoute.DELETE_ACCOUNT>;

export type EditMeetingProps = StackNavigationProp<RootStackParamList, AppRoute.EDIT_MEETING>;

export type MeetingProps = StackNavigationProp<RootStackParamList, AppRoute.MEETING>;

export type MeetingsProps = StackNavigationProp<RootStackParamList, AppRoute.MEETINGS>;

export type PlanDetailsProps = StackNavigationProp<RootStackParamList, AppRoute.PLAN_DETAILS>;

export type EditProfileProps = StackNavigationProp<RootStackParamList, AppRoute.EDIT_PROFILE>;

export type ForgotPasswordRouteProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.FORGOT_PASSWORD
>;

export type NotificationProps = StackNavigationProp<RootStackParamList, AppRoute.NOTIFICATION>;

export type PasswordSentProps = StackNavigationProp<RootStackParamList, AppRoute.PASSWORD_SENT>;

export type PersonalInviteRouteProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.PERSONAL_INVITE
>;

export type PersonalMeetingProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.PERSONAL_MEETING
>;

export type PersonalMeetingErrorRouteProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.PERSONAL_MEETING_ERROR
>;

export type PersonalMeetingNotFoundRouteProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.PERSONAL_MEETING_NOT_FOUND
>;

export type QRCodeReaderProps = StackNavigationProp<RootStackParamList, AppRoute.QRCODE_RENDER>;
