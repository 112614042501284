import * as Sentry from 'sentry-expo';

interface ExceptionProps {
  error?: string;
  file?: string;
  message?: string;
}

const logSentryException = ({ error, file, message }: ExceptionProps) => {
  if (__DEV__) {
    console.log(`Error:\n${error}\n- File:\n${file}\n- Message:\n${message}`);
  } else {
    Sentry.Native.withScope((scope) => {
      scope.setTag('File', file);
      scope.setTag('message', message);

      Sentry.Native.captureException(error);
    });
  }
};

export default logSentryException;
