import { Feather } from '@expo/vector-icons';
import { Spin, Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { deleteAccountRequest } from '_/store/modules/auth/actions';
import { transparentize } from 'polished';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TextInput, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import Modal from 'react-native-modal';
import { useSelector, useDispatch } from 'react-redux';

interface DeleteConfirmationModalProps {
  visible: boolean;
  hideModal: () => void;
}

export default function DeleteConfirmationModal({
  visible = false,
  hideModal,
}: DeleteConfirmationModalProps) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: any) => state.loading);
  const userEmail = useSelector((state: any) => state.user.email);
  const [email, setEmail] = useState('');
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (email.trim() !== '' && email === userEmail) {
      setEmailConfirmed(true);
    } else {
      setEmailConfirmed(false);
    }
  }, [email, userEmail]);

  function handleDeleteAccount() {
    dispatch(deleteAccountRequest());
  }

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <Spin visible={loading} />
        <View style={styles.content}>
          <Text style={styles.title}>{translate('deleteConfirmationModal.title')}</Text>
          <Text style={styles.description}>{translate('deleteConfirmationModal.description')}</Text>

          <Text style={styles.instructions}>
            {translate('deleteConfirmationModal.type')}
            <Text style={styles.bold}>{userEmail}</Text>
            {translate('deleteConfirmationModal.instructions')}
          </Text>

          <View style={styles.emailInputContainer}>
            <TextInput
              autoFocus
              placeholder="Email"
              returnKeyType="next"
              autoCapitalize="none"
              autoCompleteType="email"
              keyboardType="email-address"
              textContentType="emailAddress"
              value={email}
              autoCorrect={false}
              style={styles.emailInput}
              onChangeText={(text) => setEmail(text)}
              onSubmitEditing={handleDeleteAccount}
            />
            <View style={styles.iconContainer}>
              <Feather name="trash" style={styles.icon} />
            </View>
          </View>
        </View>
        <View style={styles.buttonsContainer}>
          <TouchableOpacity style={styles.btnCancel} onPress={hideModal}>
            <Text style={styles.btnCancelText}>{translate('general.cancel')}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            disabled={!emailConfirmed}
            style={emailConfirmed ? styles.btnDelete : [styles.btnDelete, styles.btnDeleteDisabled]}
            onPress={handleDeleteAccount}
          >
            <Text style={emailConfirmed ? styles.btnDeleteText : styles.btnDeleteTextDisabled}>
              {translate('general.delete')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const { width } = Dimensions.get('screen');

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  container: {
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 2,
    borderRadius: 6,
    width: width - 48,
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowColor: themes.colors.black,
    backgroundColor: themes.colors.white,
  },
  content: {
    padding: 24,
    marginHorizontal: 24,
  },
  title: {
    fontFamily: fontFamily.medium,
    fontSize: 16,
    color: themes.colors.darkSecondary,
  },
  description: {
    fontFamily: fontFamily.medium,
    fontSize: 12,
    color: themes.colors.darkQuaternary,
  },
  instructions: {
    fontFamily: fontFamily.regular,
    paddingTop: 12,
  },
  bold: {
    fontFamily: fontFamily.bold,
  },
  buttonsContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: 70,
    padding: 20,
    backgroundColor: themes.colors.lightPrimary,
  },
  btnCancel: {
    marginRight: 24,
  },
  btnCancelText: {
    fontFamily: fontFamily.medium,
  },
  btnDelete: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    borderRadius: 6,
    paddingVertical: 8,
    paddingHorizontal: 24,
    backgroundColor: themes.colors.danger,
  },
  btnDeleteText: {
    fontFamily: fontFamily.medium,
    color: themes.colors.white,
  },
  btnDeleteDisabled: {
    backgroundColor: themes.colors.lightSecondary,
  },
  btnDeleteTextDisabled: {
    color: themes.colors.lightTertiary,
  },
  emailInputContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: themes.colors.lightPrimary,
  },
  emailInput: {
    flexDirection: 'row',
    height: 50,
    width: '100%',
    paddingHorizontal: 8,
    fontFamily: fontFamily.regular,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 8,
  },
  icon: {
    borderRadius: 50,
    color: themes.colors.danger,
    padding: 2,
  },
});
