import { useNavigation } from '@react-navigation/native';
import { Spin, Header } from '_/components/index';
import { appConfig } from '_/config/app';
import { InviteMeetingModel } from '_/interfaces/InviteMeetingModel';
import { AppRoute, PersonalMeetingProps } from '_/routes/types';
import PersonalMeetingError from '_/screens/PersonalMeetingError';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, StyleSheet, Dimensions } from 'react-native';
import { WebView } from 'react-native-webview';
import { useDispatch, useSelector } from 'react-redux';

import { meetingsApi, meetingLinksApi } from '../../services/api';
import LoadingInvite from './LoadingInvite';

export default function PersonalMeeting({ route }: any) {
  const navigation = useNavigation<PersonalMeetingProps>();
  const dispatch = useDispatch();
  const [meeting, setMeeting] = useState<InviteMeetingModel>();
  const [loading, setLoading] = useState(true);
  const loadingInvite = useSelector((state: any) => state.loading.loading);
  const [error, setError] = useState<string>();
  const { id, isLink } = route.params;
  const { t: translate } = useTranslation();

  const accessToken = useSelector((state: any) => state.auth.accessToken);

  const { width, height } = Dimensions.get('window');

  useEffect(() => {
    async function loadInvitesGetMeeting() {
      try {
        const meeting = isLink
          ? await meetingLinksApi.getMeetingByLink(id)
          : await meetingsApi.getMeeting(id, { params: { detailed: true } });

        if (meeting) {
          setMeeting({ ...meeting, isLink, id });
        } else {
          setError('tryAgainLater');
        }

        setLoading(false);
      } catch (err) {
        if (err.details?.inviteId) {
          navigation.navigate(AppRoute.PERSONAL_INVITE, { id: err.details.inviteId });
          setLoading(false);
        } else {
          console.tron.log('error', err);
          setError(`${err.message}`);
          setLoading(false);
        }
      }
    }

    loadInvitesGetMeeting();
  }, [dispatch, id, isLink, navigation]);

  if (loading) {
    return <LoadingInvite />;
  }

  if (error) {
    return <PersonalMeetingError message={error} />;
  }

  if (!meeting?.meeting) {
    return null;
  }

  return (
    <SafeAreaView style={styles.container}>
      <Spin visible={loading || loadingInvite} />
      <Header
        handleBack={() =>
          route.params?.goBack
            ? navigation.goBack()
            : navigation.navigate(AppRoute.PERSONAL_INVITES)
        }
        title={translate('personalMeeting.mainTitle')}
      />

      <WebView
        scrollEnabled
        source={{
          uri: `${appConfig.preCheckinURL}/meeting/${meeting.id}?token=${accessToken}&hideHeader=true`,
        }}
        style={{ width, height }}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
