import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Lang } from '@w3lcome/types';
import { Spin, Checkbox, Text } from '_/components';
import Button from '_/components/Button';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import Input from '_/components/Input';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { AppRoute, SignupProps } from '_/routes/types';
import { signUpRequest } from '_/store/modules/auth/actions';
import { validate as validateEmail } from 'email-validator';
import Constants from 'expo-constants';
import * as Localization from 'expo-localization';
import { AsYouType } from 'libphonenumber-js';
import React, { useRef, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Platform,
  TextInput,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  StyleSheet,
} from 'react-native';
import CountryPicker from 'react-native-country-picker-modal';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmPasswordField } from './ConfirmPasswordField';
import { PasswordField } from './PasswordField';
import SignupTerms from './SignupTerms';

export default function Signup() {
  const navigation = useNavigation<SignupProps>();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: any) => state.loading);
  const { t: translate, i18n } = useTranslation();

  const [checked, setChecked] = useState(false);

  const [fullname, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('BR');
  const [countryCode, setCountryCode] = useState('+55');
  const [password, setPassword] = useState('');

  const [lang] = useState(Localization.locale as Lang);

  const [passwordConfirmed, setPasswordConfirmed] = useState(false);

  const fullnameRef = useRef<TextInput>(null);
  const emailRef = useRef<TextInput>(null);
  const phoneRef = useRef<TextInput>(null);
  const passwordRef = useRef<TextInput>(null);
  const confirmPasswordRef = useRef<TextInput>(null);

  const hasSpecialCharactersOrNumbers = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~/\d/]/;

  const setPasswordCallback = useCallback(
    (newPassword: string) => {
      setPassword(newPassword);
    },
    [setPassword]
  );

  const setPasswordConfirmedCallback = useCallback(
    (value: boolean) => {
      setPasswordConfirmed(value);
    },
    [setPasswordConfirmed]
  );

  const isNameValid = useMemo(() => {
    if (/[^\u0000-\u00ff]/.test(fullname)) {
      return true;
    }

    const isFullName = new RegExp(
      `^[a-zA-Zà-úÀ-Ú]([-']?[a-zA-Zà-úÀ-Ú]+)*( [a-zA-Zà-úÀ-Ú]([-']?[a-zA-Zà-úÀ-Ú]+)*)+$`
    );

    return isFullName.test(fullname.trim());
  }, [fullname]);

  const isEmailValid = useMemo(() => {
    return validateEmail(email);
  }, [email]);

  async function handleSubmit() {
    const formattedEmail = email.trim().toLowerCase();
    dispatch(
      signUpRequest({
        name: fullname,
        email: formattedEmail,
        password,
        lang,
        phone,
        countryCode,
      })
    );
  }

  function handlePhoneChange(text: string) {
    const tempPhone = new AsYouType(country as any).input(text);
    setPhone(tempPhone);
  }

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <Spin visible={loading} />
        <View style={styles.content}>
          <KeyboardAvoidingView
            enabled
            style={styles.formContainer}
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
          >
            <ScrollView keyboardShouldPersistTaps="handled" showsVerticalScrollIndicator={false}>
              <View style={styles.header}>
                <TouchableOpacity
                  onPress={() => navigation.navigate(AppRoute.SIGNIN)}
                  hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
                >
                  <Feather name="arrow-left" size={24} style={{ marginLeft: -4 }} />
                </TouchableOpacity>
              </View>
              <View style={styles.titleContainer}>
                <Text style={styles.headerTitle}>
                  {translate('signup.title')}
                  {'\n'}
                  <Text style={styles.headerDescription}>{translate('signup.instructions')}</Text>
                </Text>
              </View>
              <View style={styles.formContainer}>
                <Input
                  label={translate('signup.fullname')}
                  autoFocus
                  returnKeyType="next"
                  textContentType="name"
                  autoCapitalize="words"
                  keyboardType="default"
                  value={fullname}
                  ref={fullnameRef}
                  autoCorrect={false}
                  style={styles.formInput}
                  onChangeText={(text) => setFullName(text)}
                  onSubmitEditing={() => emailRef.current?.focus()}
                  icon={isNameValid ? 'check' : 'type'}
                  iconStyles={isNameValid && styles.iconSuccess}
                />

                {hasSpecialCharactersOrNumbers.test(fullname) && (
                  <Text style={styles.textError}>
                    *{translate('notAcceptNumbersAndSpecialCharacters')}
                  </Text>
                )}

                <Input
                  label={translate('signup.email')}
                  returnKeyType="next"
                  autoCapitalize="none"
                  keyboardType="email-address"
                  textContentType="emailAddress"
                  value={email}
                  ref={emailRef}
                  autoCorrect={false}
                  style={styles.formInput}
                  onChangeText={(text) => setEmail(text)}
                  onSubmitEditing={() => phoneRef.current?.focus()}
                  icon={isEmailValid ? 'check' : 'at-sign'}
                  iconStyles={isEmailValid && styles.iconSuccess}
                />

                {/* <Text style={styles.formLabel}>{translate('signup.phone')}</Text>
              <View style={styles.inputGroup}> */}

                <Input
                  label={translate('signup.phone')}
                  returnKeyType="next"
                  autoCapitalize="none"
                  keyboardType="phone-pad"
                  textContentType="telephoneNumber"
                  value={phone}
                  ref={phoneRef}
                  autoCorrect={false}
                  style={styles.formInput}
                  onChangeText={(text) => handlePhoneChange(text.replace(/[^0-9]/g, ''))}
                  onSubmitEditing={() => passwordRef.current?.focus()}
                  icon="smartphone"
                  extraContentLeft={
                    <>
                      {country !== null}
                      <CountryPicker
                        countryCode={country as any}
                        withFlag
                        withFilter
                        withCallingCode
                        translation={i18n.language === 'pt-BR' ? 'por' : 'cym'}
                        onSelect={(c) => {
                          setCountry(c.cca2);
                          setCountryCode(`+${c.callingCode[0]}`);
                        }}
                      />
                      {countryCode && <Text style={{ marginRight: 6 }}>{countryCode}</Text>}
                    </>
                  }
                />
                <PasswordField
                  password={password}
                  passwordRef={passwordRef}
                  confirmPasswordRef={confirmPasswordRef}
                  setPassword={setPasswordCallback}
                />

                <ConfirmPasswordField
                  password={password}
                  confirmPasswordRef={confirmPasswordRef}
                  setPasswordConfirmed={setPasswordConfirmedCallback}
                  passwordConfirmed={passwordConfirmed}
                />

                <View style={styles.termsContainer}>
                  <Checkbox checked={checked} handleChecked={() => setChecked(!checked)}>
                    <SignupTerms />
                  </Checkbox>
                </View>

                <Button
                  buttonStyles={styles.btn}
                  disabled={!checked || !passwordConfirmed || !isNameValid || !isEmailValid}
                  onPress={handleSubmit}
                >
                  <Text style={styles.btnText}>{translate('signup.createAccount')}</Text>
                </Button>
              </View>
              <TouchableOpacity
                style={styles.btnSignin}
                onPress={() => navigation.navigate(AppRoute.SIGNIN)}
                hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <Text style={styles.btnSignInText}>
                  {translate('signup.alreadyHaveAccount')}{' '}
                  <Text style={{ fontFamily: fontFamily.medium }}>
                    {translate('signup.signin')}
                  </Text>
                </Text>
              </TouchableOpacity>
            </ScrollView>
          </KeyboardAvoidingView>
        </View>
      </View>
    </ContainerWidthLimit>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 24,
    paddingTop: Platform.OS === 'ios' ? Constants.statusBarHeight : 24,
  },
  header: {
    marginBottom: 8,
  },
  titleContainer: {
    marginBottom: 32,
  },
  headerTitle: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 24,
    lineHeight: 32,
    paddingVertical: 8,
  },
  headerDescription: {
    color: themes.colors.darkQuaternary,
    fontSize: 16,
  },
  formContainer: {
    marginBottom: 16,
  },
  formInput: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 14,
    paddingVertical: 8,
  },
  btn: {
    marginTop: 24,
  },
  btnText: {
    textAlign: 'center',
    fontFamily: fontFamily.medium,
    flex: 1,
    fontSize: 18,
    color: themes.colors.white,
  },
  btnSignin: {
    padding: 5,
    marginTop: 8,
  },
  btnSignInText: {
    color: themes.colors.darkSecondary,
    alignSelf: 'center',
    fontFamily: fontFamily.light,
    fontSize: 14,
  },
  termsContainer: {
    marginTop: 12,
    paddingRight: 12,
  },
  iconSuccess: {
    fontSize: 18,
    color: themes.colors.success,
  },
  textError: {
    fontSize: 12,
    color: themes.colors.danger,
    alignSelf: 'flex-end',
    marginTop: -6,
  },
});
