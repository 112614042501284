import logSentryException from '_/helpers/logSentryException';
import { userApi } from '_/services/api';
import { showMessage } from 'react-native-flash-message';
import { all, put, call, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { navigate } from '_/services/navigation';

import { translate } from '../../../locales';
import { setLoading } from '../loading/actions';
import * as actions from './actions';

export function* setUserInfo({ payload }: ActionType<typeof actions.setUserInfoRequest>) {
  try {
    const { user } = payload;
    yield put(actions.setUserInfoSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.setUserInfoFailure());
  }
}

// export function* getUserInfo({ payload }) {
//   try {
//     yield put(setLoading());
//     const { id } = payload;

//     const usersRef = `users/${id}`;
//     const snapshot = yield call([fbService, 'getData'], usersRef);

//     if (!snapshot.exists()) {
//       yield call(signOut);
//     }

//     const userData = snapshot.val();

//     const userInfo = {
//       ...userData.informations,
//       id,
//       name: `${userData.informations.firstName} ${userData.informations.lastName}`,
//     };

//     yield put(setLoading());
//     yield put(getUserInfoSuccess(userInfo));
//   } catch (error) {
//     logSentryException({
//       error,
//       file: 'user/sagas.js',
//       message: 'Error at getUserInfo function',
//     });
//     yield put(setLoading());
//     yield put(getUserInfoFailure());
//   }
// }

export function* updateProfile({ payload }: ActionType<typeof actions.updateProfileRequest>) {
  try {
    yield put(setLoading());
    const { id, email, name, phone, position, countryCode, country, lang } = payload;

    yield call(userApi.updateUser, {
      id,
      name,
      email,
      phone,
      countryCode,
      country,
      position,
      lang,
    });

    showMessage({
      message: translate('userSaga.updateUserProfileSuccess'),
      type: 'success',
    });
    yield put(setLoading());
    yield put(
      actions.updateProfileSuccess({
        id,
        email,
        name,
        phone,
        position,
        countryCode,
        country,
      })
    );
    navigate('Profile');
  } catch (error) {
    const message = error?.response?.data?.message;
    yield call(logSentryException, {
      error,
      file: 'user/sagas.ts',
      message,
    });

    showMessage({
      message: translate('userSaga.updateUserProfileError'),
      type: 'danger',
    });
    yield put(setLoading());
    yield put(actions.updateProfileFailure());
  }
}

export function* updateUserPicture({
  payload,
}: ActionType<typeof actions.updateUserPictureRequest>) {
  try {
    yield put(setLoading());
    const { uri } = payload;
    const ext = uri.split('.').pop();
    const fileName = `picture.${ext}`;

    const metadata = {
      contentType: 'image/jpeg',
      cacheControl: 'public,max-age=7200',
    };
    const file = {
      name: fileName,
      type: metadata.contentType,
      uri,
    };

    const {
      pictureUrl,
      pictureThumbUrl,
    }: {
      pictureUrl: string;
      pictureThumbUrl: string | null;
    } = yield call(userApi.uploadAvatar, file);
    yield put(setLoading());
    yield put(
      actions.updateUserPictureSuccess({
        pictureUrl,
        pictureThumbUrl,
      })
    );
  } catch (error) {
    logSentryException({
      error,
      file: 'user/sagas.js',
      message: 'Error at updateUserPicture function',
    });
    yield put(setLoading());
    yield put(actions.updateUserPictureFailure());
    showMessage({
      message: translate('userSaga.updateUserPictureError'),
      type: 'danger',
    });
  }
}

// export function* updateUserLoginInfo() {
//   try {
//     const uid = yield select(state => state.user.id);

//     if (!uid) return;

//     const userLastLoginRef = `users/${uid}/lastLogin`;
//     const now = moment().unix();
//     yield call([fbService, 'setData'], userLastLoginRef, now);

//     const userLoginCountRef = `users/${uid}/loginCount`;
//     const func = c => (c || 0) + 1;
//     yield call([fbService, 'transaction'], userLoginCountRef, func);

//     yield put(updateUserLoginInfoSuccess());
//   } catch (error) {
//     console.tron.log('File: user/sagas.js', error);
//     yield put(updateUserLoginInfoFailure());
//   }
// }

export default all([
  takeLatest('@user/SET_USER_INFO_REQUEST', setUserInfo),
  // takeLatest('@user/GET_USER_INFO_REQUEST', getUserInfo),

  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@user/UPDATE_USER_PICTURE_REQUEST', updateUserPicture),
  // takeLatest('@user/UPDATE_USER_LOGIN_INFO_REQUEST', updateUserLoginInfo),
]);
