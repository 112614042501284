import isEnidma from '../helpers/isEnidma';
import isInvenzi from '../helpers/isInvenzi';
import enidma from './enidma.theme';
import invenzi from './invenzi.theme';
import w3lcome from './w3lcome.theme';

let theme = w3lcome;

if (isInvenzi()) {
  theme = invenzi;
}

if (isEnidma()) {
  theme = enidma;
}

export default theme;
