import { Feather } from '@expo/vector-icons';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, StyleSheet, SafeAreaView, TouchableOpacity } from 'react-native';

interface ExpiredModalProps {
  handleClose: () => void;
}

export default function ExpiredModal({ handleClose }: ExpiredModalProps) {
  const { t: translate } = useTranslation();

  return (
    <View style={{ flex: 1 }}>
      <SafeAreaView style={styles.container}>
        <View style={styles.topContainer}>
          <View>
            <Feather name="x" style={styles.iconHidden} />
          </View>

          <Image style={styles.logo} source={themes.images.logo} resizeMode="contain" />

          <TouchableOpacity onPress={handleClose}>
            <Feather name="x" style={styles.icon} />
          </TouchableOpacity>
        </View>

        <View style={styles.middleContainer}>
          <View style={styles.imageContainer}>
            <Image style={styles.image} source={themes.images.qrcodeExpired} resizeMode="contain" />
          </View>
          <Text style={styles.title}>
            {translate('expiredQRCodeModal.title')}{' '}
            <Text style={[styles.title, styles.redText]}>
              {translate('expiredQRCodeModal.expired')}!
            </Text>
          </Text>
          <Text style={styles.description}>{translate('expiredQRCodeModal.description')}</Text>
        </View>

        <TouchableOpacity style={styles.btn} onPress={handleClose}>
          <Text style={styles.btnText}>{translate('general.close')}</Text>
        </TouchableOpacity>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 24,
  },
  topContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  middleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 96,
    marginBottom: 72,
  },
  logo: {
    height: 40,
    width: 150,
  },
  icon: {
    fontSize: 24,
    color: themes.colors.black,
  },
  iconHidden: {
    color: themes.colors.white,
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    width: '100%',
    height: 170,
  },
  title: {
    color: themes.colors.darkSecondary,
    textAlign: 'center',
    fontFamily: fontFamily.medium,
    fontSize: 24,
    marginVertical: 12,
  },
  description: {
    color: themes.colors.darkQuaternary,
    textAlign: 'center',
    fontFamily: fontFamily.light,
    fontSize: 14,
    marginVertical: 8,
  },
  redText: {
    color: themes.colors.danger,
  },
  btn: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    borderRadius: 6,
    marginBottom: 32,
    backgroundColor: themes.colors.primary,
  },
  btnText: {
    color: themes.colors.white,
    fontFamily: fontFamily.medium,
    fontSize: 18,
  },
});
