import themes from '_/constants/themes';
import { getHours, getMinutes } from 'date-fns';
import moment, { Moment } from 'moment';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import Modal from 'react-native-modal';

import WebTimePicker from './WebTimePicker';
import { getWebMaxWidth } from '../ContainerWidthLimit';
import { getMonths, getWeekDays } from '../DateRangePicker/CalendarModal/constants';

interface WebDateTimePickerProps {
  visible: boolean;
  handleClose: () => void;
  selectedDate?: Date;
  handleConfirm: (date: Date) => void;
  locale?: string;
  mode?: 'date' | 'time' | 'datetime';
}

const WebDateTimePicker: React.FC<WebDateTimePickerProps> = ({
  visible,
  handleClose,
  selectedDate,
  handleConfirm,
  locale = 'en',
  mode = 'datetime',
}) => {
  moment.locale(locale);

  const [dateTime, setDateTime] = useState(moment(selectedDate || new Date()));

  const { t: translate } = useTranslation();

  const months = getMonths(translate);
  const weekDays = getWeekDays(translate);

  function onDateChange(date: Moment) {
    const newDateTime = moment(date).set({
      hour: getHours(dateTime.toDate()),
      minute: getMinutes(dateTime.toDate()),
    });

    setDateTime(newDateTime);
  }

  function onTimeChange(value: number, type: 'hours' | 'minutes') {
    const newDateTime = moment(dateTime).set(type, value);

    setDateTime(newDateTime);
  }

  function onConfirm() {
    handleConfirm(dateTime.toDate());
    handleClose();
  }

  return (
    <Modal
      style={styles.overlay}
      isVisible={visible}
      onBackdropPress={handleClose}
      onBackButtonPress={handleClose}
    >
      <View style={styles.container}>
        <View style={{ paddingTop: 16, width: '50%' }}>
          {(mode === 'date' || mode === 'datetime') && (
            <CalendarPicker
              startFromMonday
              showDayStragglers
              nextTitle=">"
              previousTitle="<"
              selectedDayColor={themes.colors.primary}
              selectedDayTextColor={themes.colors.white}
              todayBackgroundColor={themes.colors.secondary}
              months={months}
              scaleFactor={800}
              weekdays={weekDays}
              onDateChange={onDateChange}
              textStyle={styles.textStyle}
              monthYearHeaderWrapperStyle={styles.monthYearStyle}
              nextTitleStyle={[styles.headerBtn, { marginRight: getWebMaxWidth() / 2 }]}
              previousTitleStyle={[styles.headerBtn, { marginLeft: getWebMaxWidth() / 2 }]}
              minDate={new Date()}
              initialDate={dateTime.toDate()}
            />
          )}
          {(mode === 'time' || mode === 'datetime') && (
            <View style={styles.content}>
              <WebTimePicker value={dateTime.toDate()} type="hours" setTimeValue={onTimeChange} />
              <WebTimePicker value={dateTime.toDate()} type="minutes" setTimeValue={onTimeChange} />
            </View>
          )}
          <TouchableOpacity style={styles.btn} onPress={onConfirm}>
            <Text style={styles.btnText}>{translate('confirmButton')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  btn: {
    alignSelf: 'center',
    backgroundColor: themes.colors.primary,
    borderRadius: 6,
    margin: 16,
    padding: 16,
  },
  btnText: {
    color: themes.colors.white,
    fontSize: 16,
    paddingHorizontal: 8,
    textAlign: 'center',
  },
  container: {
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    borderRadius: 4,
    elevation: 2,
    justifyContent: 'center',
    overflow: 'hidden',
    shadowColor: themes.colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1.5,
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 16,
  },
  headerBtn: {
    borderColor: themes.colors.primary,
    borderRadius: 4,
    borderWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  monthYearStyle: {
    borderBottomColor: themes.colors.lightPrimary,
    borderBottomWidth: 1,
    padding: 8,
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    justifyContent: 'center',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 999,
  },
  textStyle: {
    color: themes.colors.darkSecondary,
    fontSize: 14,
  },
});

export default WebDateTimePicker;
