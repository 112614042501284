import { Feather } from '@expo/vector-icons';
import themes from '_/constants/themes';
import { getDateLocale } from '_/helpers/getDateLocale';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

import CalendarModal from './CalendarModal';
import Text from '../Text';

interface DateRangePickerProps {
  handleChangeDates: (startDate: Date, endDate: Date) => void;
  selectedStartDate?: Date;
  selectedEndDate?: Date;
}

export default function DateRangePicker({
  selectedStartDate,
  selectedEndDate,
  handleChangeDates,
}: DateRangePickerProps) {
  const [dateRangePickerModalVisible, setDateRangePickerModalVisible] = useState(false);
  const { t: translate, i18n } = useTranslation();

  const locale = getDateLocale(i18n.language);

  function handleCloseModal(startDate?: Date, endDate?: Date) {
    if (startDate && endDate) {
      handleChangeDates(startDate, endDate);
    }
    setDateRangePickerModalVisible(false);
  }

  return (
    <View>
      <TouchableOpacity style={styles.content} onPress={() => setDateRangePickerModalVisible(true)}>
        <View style={styles.dateContainer}>
          <Text>
            {selectedStartDate
              ? format(selectedStartDate ?? new Date(), 'P', { locale })
              : translate('home.startDate')}
          </Text>
        </View>
        <Feather name="arrow-right" size={16} />
        <View style={styles.dateContainer}>
          <Text>
            {selectedEndDate
              ? format(selectedEndDate ?? new Date(), 'P', { locale })
              : translate('home.endDate')}
          </Text>
        </View>
      </TouchableOpacity>
      <CalendarModal
        handleClose={handleCloseModal}
        selectedEndDate={selectedEndDate}
        selectedStartDate={selectedStartDate}
        visible={dateRangePickerModalVisible}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    borderColor: themes.colors.lightPrimary,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    borderWidth: 1,
  },
  dateContainer: {
    padding: 10,
    marginHorizontal: 5,
  },
});
