import { CompaniesModel, UsersModel } from '@w3lcome/types';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React, { useRef, useEffect } from 'react';
import { View, Image, Animated, TouchableOpacity, StyleSheet } from 'react-native';

import Text from '../../../Text';

interface SwitchCardProps {
  data: CompaniesModel | UsersModel;
  type: 'company' | 'user';
  handleSwitchProfile: (data: any, type: 'company' | 'user') => void;
}

export function SwitchCard({ data, type, handleSwitchProfile }: SwitchCardProps) {
  const animatedOpacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedOpacity, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true,
    }).start();
  }, [animatedOpacity]);

  return (
    <Animated.View key={data.id} style={{ opacity: animatedOpacity }}>
      <TouchableOpacity
        delayPressIn={50}
        style={styles.container}
        onPress={() => handleSwitchProfile(data, type)}
      >
        <View style={styles.content}>
          <View style={styles.imageContainer}>
            <Image
              resizeMode="contain"
              style={styles.image}
              source={
                data.pictureUrl
                  ? { uri: data.pictureUrl }
                  : type === 'company'
                  ? themes.images.logo
                  : themes.images.avatarSquare
              }
            />
          </View>
          <View style={styles.nameContainer}>
            <Text style={styles.name}>{data.name}</Text>
            {!!(data as CompaniesModel)?.location && (
              <Text style={styles.location}>{(data as CompaniesModel).location}</Text>
            )}
          </View>
        </View>
      </TouchableOpacity>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    backgroundColor: themes.colors.white,
    borderBottomColor: themes.colors.lightPrimary,
  },
  content: {
    padding: 24,
    alignItems: 'center',
    flexDirection: 'row',
  },
  imageContainer: {
    overflow: 'hidden',
    width: 60,
    height: 60,
    padding: 6,
    elevation: 4,
    shadowRadius: 8,
    borderRadius: 4,
    shadowOpacity: 0.05,
    shadowColor: themes.colors.black,
    backgroundColor: themes.colors.white,
    shadowOffset: { width: 0, height: 0 },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 2,
  },
  nameContainer: {
    justifyContent: 'center',
    flex: 1,
    marginLeft: 12,
  },
  name: {
    fontFamily: fontFamily.regular,
    fontSize: 16,
    color: themes.colors.darkSecondary,
  },
  location: {
    marginTop: 4,
    fontFamily: fontFamily.light,
    fontSize: 14,
    color: themes.colors.darkQuaternary,
  },
});
