import Feather from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import { Text, Divider } from '_/components';
import { getWebMaxWidth } from '_/components/ContainerWidthLimit';
import themes from '_/constants/themes';
import { AppRoute, EditMeetingProps } from '_/routes/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import Modal from 'react-native-modal';

import DeleteModal from '../DeleteModal';

interface MenuModalProps {
  visible?: boolean;
  hideModal: () => void;
}

export default function MenuModal({ visible = false, hideModal }: MenuModalProps) {
  const navigation = useNavigation<EditMeetingProps>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { t: translate } = useTranslation();

  function handleEditMeeting() {
    navigation.navigate(AppRoute.EDIT_MEETING);
    hideModal();
  }

  function handleDeleteMeeting() {
    setDeleteModalVisible(true);
  }

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <TouchableOpacity style={styles.rowContainer} onPress={handleEditMeeting}>
            <Feather name="edit-2" size={16} style={{ marginRight: 8 }} />
            <Text>{translate('general.edit')}</Text>
          </TouchableOpacity>

          <Divider />

          <TouchableOpacity style={styles.rowContainer} onPress={handleDeleteMeeting}>
            <Feather name="trash" size={16} style={{ marginRight: 8 }} />
            <Text>{translate('general.delete')}</Text>
          </TouchableOpacity>
        </View>
      </View>
      <DeleteModal visible={deleteModalVisible} hideModal={() => setDeleteModalVisible(false)} />
    </Modal>
  );
}

const styles = StyleSheet.create({
  overlay: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    position: 'absolute',
    right: Platform.OS === 'web' ? getWebMaxWidth() / 2 : 16,
    top: 60,
    zIndex: 1000,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    shadowColor: themes.colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    padding: 20,
    borderRadius: 4,
    backgroundColor: themes.colors.white,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
