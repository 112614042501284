import AsyncStorage from '@react-native-async-storage/async-storage';
import { useRef, useEffect, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

export function useActiveAppState() {
  const currentState = useRef(AppState.currentState);
  const [isActive, setActive] = useState(true);

  async function removeAppTimeInBackground() {
    await AsyncStorage.removeItem('@w3lcome/appTimeInBackground');
  }

  useEffect(() => {
    removeAppTimeInBackground();

    async function handleChange(state: AppStateStatus) {
      if (state === 'active' && currentState.current !== 'active') {
        setActive(true);
      } else {
        setActive(false);
        if (currentState.current === 'active') {
          await AsyncStorage.setItem('@w3lcome/appTimeInBackground', `${new Date()}`);
        }
      }

      currentState.current = state;
    }

    const appStateListener = AppState.addEventListener('change', handleChange);

    return () => {
      appStateListener.remove();
    };
  }, []);

  return {
    isActive,
  };
}
