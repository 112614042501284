import { CustomFieldsModel, CustomFieldType } from '@w3lcome/types';
import { Text } from '_/components';
import Input from '_/components/Input';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Keyboard, StyleSheet } from 'react-native';
import ModalSelector, { IOption } from 'react-native-modal-selector';

interface CustomFieldsProps {
  customFields: CustomFieldsModel[];
  customFieldsValues?: any[];
  setCustomFieldsValues(customFieldsValues: any[]): void;
}

const CustomFields: React.FC<CustomFieldsProps> = ({
  customFields,
  customFieldsValues = [],
  setCustomFieldsValues,
}) => {
  const { t: translate, i18n } = useTranslation();

  function onSelectorChange(option: IOption) {
    const customFieldValue = {
      value: option.value,
      label: option.label,
      customFieldId: option.customFieldId,
    };

    const customFieldArr = [...customFieldsValues];

    const index = customFieldsValues.findIndex((f) => f.customFieldId === option.customFieldId);

    if (index >= 0) {
      customFieldArr[index] = customFieldValue;
    } else {
      customFieldArr.push(customFieldValue);
    }

    setCustomFieldsValues(customFieldArr);
  }

  function onChangeText({
    value,
    field,
    index,
  }: {
    value: string;
    field: CustomFieldsModel;
    index: number;
  }) {
    const customFieldValue = {
      value,
      customFieldId: field.id,
    };

    const customFieldArr = [...customFieldsValues];

    if (index >= 0) {
      customFieldArr[index] = customFieldValue;
    } else {
      customFieldArr.push(customFieldValue);
    }
    setCustomFieldsValues(customFieldArr);
  }

  function loadDropdownOptions(field: CustomFieldsModel) {
    const options = field.options?.map((op) => {
      const option = {
        key: op.id,
        label:
          op.localizedName?.[i18n.language] ||
          op.localizedName?.[appConfig.lang.default] ||
          op.name,
        value:
          op.localizedName?.[i18n.language] ||
          op.localizedName?.[appConfig.lang.default] ||
          op.name,
        localizedName: op.localizedName,
        customFieldId: field.id,
      };

      return option;
    });
    return options;
  }

  function renderField(field: CustomFieldsModel, index: number) {
    switch (field.type.toLocaleUpperCase()) {
      case CustomFieldType.DROPDOWN: {
        return (
          <View key={field.id} style={styles.inputsContainer}>
            <View style={{ borderBottomWidth: 0, flex: 1 }}>
              <View style={styles.formInput}>
                <ModalSelector
                  onChange={onSelectorChange}
                  style={styles.inputsContainer}
                  cancelStyle={styles.cancelStyle}
                  overlayStyle={styles.overlayStyle}
                  cancelText={translate('general.cancel')}
                  cancelTextStyle={styles.cancelTextStyle}
                  optionTextStyle={styles.optionTextStyle}
                  sectionTextStyle={styles.sectionTextStyle}
                  optionContainerStyle={styles.optionContainerStyle}
                  data={loadDropdownOptions(field) || []}
                  renderItem={({ item }) => {
                    return <Text>{item.label}</Text>;
                  }}
                >
                  <Input
                    label={
                      field.placeholder?.[i18n.language] ||
                      field.placeholder?.[appConfig.lang.default] ||
                      field.name
                    }
                    style={(styles.formInput, styles.dropdownFormInput)}
                    placeholder={
                      field.placeholder?.[i18n.language] ||
                      field.placeholder?.[appConfig.lang.default] ||
                      field.name
                    }
                    placeholderTextColor={themes.colors.graySecondary}
                    value={customFieldsValues?.[index]?.value || ''}
                    icon="info"
                    required={field.required}
                  />
                </ModalSelector>
              </View>
            </View>
          </View>
        );
      }
      case CustomFieldType.BOOLEAN: {
        return (
          <View key={field.id} style={styles.inputsContainer}>
            <Input
              label={
                field.placeholder?.[i18n.language] ||
                field.placeholder?.[appConfig.lang.default] ||
                field.name
              }
              isSwitch
              checked={customFieldsValues[index]?.value === 'true'}
              onCheckChange={(e) => onChangeText({ value: `${e}`, field, index })}
            />
          </View>
        );
      }
      default: {
        return (
          <View key={field.id} style={styles.inputsContainer}>
            <Input
              label={
                field.placeholder?.[i18n.language] ||
                field.placeholder?.[appConfig.lang.default] ||
                field.name
              }
              autoCapitalize="sentences"
              autoCorrect={false}
              value={customFieldsValues[index]?.value || ''}
              style={styles.formInput}
              placeholder={
                field.placeholder?.[i18n.language] ||
                field.placeholder?.[appConfig.lang.default] ||
                field.name
              }
              required={field.required}
              onChangeText={(value) => onChangeText({ value, field, index })}
              onSubmitEditing={Keyboard.dismiss}
              icon="info"
            />
          </View>
        );
      }
    }
  }

  return (
    <>
      {customFields &&
        customFields.map((field) => {
          const index = customFieldsValues.findIndex((f) => f.customFieldId === field.id);

          return renderField(field, index);
        })}
    </>
  );
};

export default CustomFields;

const styles = StyleSheet.create({
  inputsContainer: {
    marginBottom: 12,
  },
  formInput: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 15,
    paddingVertical: 8,
  },
  dropdownFormInput: {
    textAlign: 'center',
  },
  cancelStyle: {
    backgroundColor: themes.colors.white,
    padding: 16,
  },
  cancelTextStyle: {
    fontFamily: fontFamily.semibold,
  },
  sectionTextStyle: {
    fontWeight: 'bold',
    fontFamily: fontFamily.bold,
    padding: 6,
  },
  optionTextStyle: {
    color: themes.colors.darkTertiary,
    fontFamily: fontFamily.regular,
    padding: 4,
  },
  optionContainerStyle: {
    backgroundColor: themes.colors.white,
    maxHeight: 360,
  },
  overlayStyle: {
    justifyContent: 'center',
    backgroundColor: transparentize(0.3, themes.colors.black),
    flex: 1,
    padding: 40,
  },
});
