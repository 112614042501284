import { PasswordStrengthPopover } from '_/components';
import Input from '_/components/Input';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { type RefObject } from 'react';
import { StyleSheet } from 'react-native';

import { usePasswordField } from './usePasswordField';

interface PasswordFieldProps {
  password: string;
  setPassword: (value: string) => void;
  passwordRef: RefObject<any>;
  confirmPasswordRef: RefObject<any>;
}

export function PasswordField({
  password,
  passwordRef,
  confirmPasswordRef,
  setPassword,
}: PasswordFieldProps) {
  const { onChangeText, onSubmitEditing, onFocus, onBlur, showPasswordPopover, error, translate } =
    usePasswordField({
      confirmPasswordRef,
      setPassword,
      password,
    });

  return (
    <PasswordStrengthPopover password={password} isVisible={showPasswordPopover}>
      <Input
        label={translate('signup.password')}
        secureTextEntry
        returnKeyType="go"
        autoCapitalize="none"
        textContentType="password"
        underlineColorAndroid="transparent"
        value={password}
        ref={passwordRef}
        autoCorrect={false}
        style={{
          ...styles.formInput,
          ...(error ? styles.errorInput : {}),
        }}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmitEditing}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </PasswordStrengthPopover>
  );
}

const styles = StyleSheet.create({
  formInput: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 14,
    paddingVertical: 8,
  },
  errorInput: {
    borderBottomWidth: 1,
    borderBottomColor: themes.colors.danger,
  },
});
