import { action } from 'typesafe-actions';

import { SignInSuccessData, SignInAPIRequestData, SignUpAPIRequestData } from './types';

export const signInRequest = ({ email, password }: SignInAPIRequestData) =>
  action('@auth/SIGN_IN_REQUEST', { email, password });

export const signInSuccess = ({ accessToken, refreshToken }: SignInSuccessData) =>
  action('@auth/SIGN_IN_SUCCESS', {
    accessToken,
    refreshToken,
  });

export const signUpRequest = ({
  cpf,
  lang,
  name,
  email,
  phone,
  password,
  countryCode,
}: SignUpAPIRequestData) =>
  action('@auth/SIGN_UP_REQUEST', {
    cpf,
    lang,
    name,
    email,
    phone,
    password,
    countryCode,
  });

export const signUpSuccess = () => action('@auth/SIGN_UP_SUCCESS');

export const forgotPasswordRequest = (
  email: string,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => action('@auth/FORGOT_PASSWORD_REQUEST', { email, setIsModalVisible });

export const forgotPasswordSuccess = () => action('@auth/FORGOT_PASSWORD_SUCCESS');

export const forgotPasswordFailure = () => action('@auth/FORGOT_PASSWORD_FAILURE');

export const signFailure = () => action('@auth/SIGN_FAILURE');

export const signOut = () => action('@auth/SIGN_OUT');

export const setOnboardingScreen = (isFirstOpen: boolean) =>
  action('@auth/SET_ONBOARDING_SCREEN', { isFirstOpen });

// TODO: type payload
export const setPersonalProfile = (payload: any) => action('@auth/SET_PERSONAL_PROFILE', payload);

export const deleteAccountRequest = () => action('@auth/DELETE_ACCOUNT_REQUEST');

export const deleteAccountSuccess = () => action('@auth/DELETE_ACCOUNT_SUCCESS');

export const deleteAccountFailure = () => action('auth/DELETE_ACCOUNT_FAILURE');

export const signInWithGoogleRequest = (token: string) =>
  action('@auth/SIGN_IN_WITH_GOOGLE_REQUEST', { token });

export const verifyEmailRequest = (email: string) =>
  action('@auth/VERIFY_EMAIL_REQUEST', { email });

export const verifyEmailSuccess = () => action('@auth/VERIFY_EMAIL_SUCCESS');

export const verifyEmailFailure = () => action('@auth/VERIFY_EMAIL_FAILURE');

export const sendVerificationEmailRequest = (email: string) =>
  action('@auth/SEND_VERIFICATION_EMAIL_REQUEST', { email });

export const sendVerificationEmailSuccess = () => action('@auth/SEND_VERIFICATION_EMAIL_SUCCESS');

export const sendVerificationEmailFailure = () => action('@auth/SEND_VERIFICATION_EMAIL_FAILURE');
