import Input from '_/components/Input';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React, { type RefObject } from 'react';
import { StyleSheet } from 'react-native';

import { useConfirmPasswordField } from './useConfirmPasswordField';

interface ConfirmPasswordFieldProps {
  password: string;
  confirmPasswordRef: RefObject<any>;
  setPasswordConfirmed: (value: boolean) => void;
  passwordConfirmed: boolean;
}

export function ConfirmPasswordField({
  password,
  confirmPasswordRef,
  setPasswordConfirmed,
  passwordConfirmed,
}: ConfirmPasswordFieldProps) {
  const { confirmPassword, onChangeText, translate } = useConfirmPasswordField({
    setPasswordConfirmed,
    password,
  });

  return (
    <Input
      label={translate('signup.confirmPassword')}
      secureTextEntry
      returnKeyType="go"
      autoCapitalize="none"
      textContentType="password"
      underlineColorAndroid="transparent"
      autoCorrect={false}
      value={confirmPassword}
      style={{
        ...styles.formInput,
        ...(!passwordConfirmed ? styles.errorInput : {}),
      }}
      ref={confirmPasswordRef}
      onChangeText={onChangeText}
      icon={passwordConfirmed ? 'check' : ''}
      iconStyles={styles.iconSuccess}
    />
  );
}

const styles = StyleSheet.create({
  formInput: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 14,
    paddingVertical: 8,
  },
  errorInput: {
    borderBottomWidth: 1,
    borderBottomColor: themes.colors.danger,
  },
  iconSuccess: {
    fontSize: 18,
    color: themes.colors.success,
  },
});
