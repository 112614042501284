import { HostZonesModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';

import api from './base';

class HostZonesApi {
  public getHostsZones = async (
    params: any,
    config?: AxiosRequestConfig
  ): Promise<HostZonesModel[]> => {
    const { data } = await api.get(`/host-zones`, {
      ...config,
      params,
    });

    return data.data;
  };
}

export default new HostZonesApi();
