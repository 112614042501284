import { Feather } from '@expo/vector-icons';
import { VisitsModel } from '@w3lcome/types';
import Input from '_/components/Input';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/enidma.theme';
import { useVisitsContext } from '_/hooks/VisitsProvider';
import { format } from 'date-fns';
import { transparentize } from 'polished';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  View,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';
import Popover from 'react-native-popover-view';

export interface DelayVisitCardProps {
  visit: VisitsModel;
}

const MINUTES_OPTIONS = [1, 10, 15, 20, 25, 30];

const DelayVisitCard: React.FC<DelayVisitCardProps> = ({ visit }) => {
  const { t: translateFunction } = useTranslation();
  const { delayVisit } = useVisitsContext();

  const [showDelayModal, setShowDelayModal] = useState(false);
  const [minutesToDelay, setMinutesToDelay] = useState<number | null>(null);
  const [minutesOpen, setMinutesOpen] = useState(false);
  const delayInputRef = useRef<TextInput & TextInputProps>(null);

  const handleChooseDelayTime = (minutes: number) => {
    setMinutesToDelay(minutes);
    setMinutesOpen(false);
    setShowDelayModal(true);
  };

  const handleCancelDelay = () => {
    setShowDelayModal(false);
    setMinutesToDelay(null);
  };

  async function handleSaveDelay() {
    setShowDelayModal(false);

    try {
      if (!minutesToDelay) {
        showMessage({ message: 'Time to delay visit is required.', type: 'danger' });
        return;
      }

      await delayVisit(visit, minutesToDelay, delayInputRef.current?.value);
      showMessage({
        message: translateFunction('visitor.visitDelayedSuccess', { minutes: minutesToDelay }),
        type: 'success',
      });
    } catch (error) {
      showMessage({
        message: (error as Error).message || translateFunction('Error'),
        type: 'danger',
      });
    } finally {
      setMinutesToDelay(null);
    }
  }

  const DelayReasonModal = () => (
    <Modal animationType="slide" transparent visible={showDelayModal}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>{translateFunction('visitor.delayVisit')}</Text>
          <Text style={styles.modalDescription}>
            {translateFunction('visitor.delayVisitExplanation', { minutes: minutesToDelay })}
          </Text>
          <Input
            label={translateFunction('visitor.delayReason')}
            ref={delayInputRef}
            style={styles.modalInput}
          />
          <View style={styles.modalActions}>
            <TouchableOpacity
              onPress={handleCancelDelay}
              style={[styles.modalButton, styles.modalButtonCancel]}
            >
              <Text style={styles.modalButtonText}>{translateFunction('cancelButton')}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={handleSaveDelay}
              style={[styles.modalButton, styles.modalButtonConfirm]}
            >
              <Text style={styles.modalButtonText}>{translateFunction('confirmButton')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View>
          <Text style={styles.title}>{translateFunction('visitor.delayVisit')}</Text>
          {visit.timeToDelayVisit && (
            <Text>{`${translateFunction('visitor.delayUntil')} ${format(
              new Date(visit.timeToDelayVisit),
              'HH:mm'
            )}`}</Text>
          )}
        </View>
        <View>
          <Popover
            arrowSize={{ width: 0, height: 0 }}
            isVisible={minutesOpen}
            onRequestClose={() => setMinutesOpen(false)}
            popoverStyle={styles.popover}
            from={
              <TouchableOpacity style={styles.btn} onPress={() => setMinutesOpen(true)}>
                <Text style={styles.btnText}>{translateFunction('visitor.delayDuration')}</Text>
                <Feather name={minutesOpen ? 'chevron-up' : 'chevron-down'} size={20} />
              </TouchableOpacity>
            }
          >
            <View style={styles.optionsBox}>
              {MINUTES_OPTIONS.map((minutes) => (
                <TouchableOpacity
                  key={minutes}
                  onPress={() => handleChooseDelayTime(minutes)}
                  style={styles.option}
                >
                  <Text>{`${minutes} ${translateFunction('visitor.delayMinutes')}`}</Text>
                </TouchableOpacity>
              ))}
            </View>
          </Popover>
        </View>

        {showDelayModal && <DelayReasonModal />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    fontSize: 14,
  },
  popover: {
    minWidth: 120,
  },
  btn: {
    alignItems: 'center',
    borderColor: themes.colors.darkQuaternary,
    borderWidth: 1,
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 12,
    paddingVertical: 4,
  },
  btnText: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    fontSize: 16,
  },
  optionsBox: {
    backgroundColor: themes.colors.white,
    borderRadius: 4,
    elevation: 4,
    shadowColor: themes.colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  option: {
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8,
    shadowColor: themes.colors.lightQuaternary,
    shadowOffset: { width: 2, height: 1 },
    shadowRadius: 4,
    borderColor: themes.colors.lightQuaternary,
    borderWidth: Platform.OS !== 'web' ? 0.25 : 0,
  },
  modalContainer: {
    alignItems: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    flex: 1,
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: themes.colors.white,
    borderRadius: 8,
    padding: 16,
    width: '80%',
  },
  modalTitle: {
    color: themes.colors.black,
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  modalDescription: {
    color: themes.colors.black,
    fontSize: 16,
    marginBottom: 16,
  },
  modalInput: {
    marginBottom: 8,
  },
  modalActions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalButton: {
    alignItems: 'center',
    borderRadius: 8,
    padding: 8,
    width: '45%',
  },
  modalButtonCancel: {
    backgroundColor: themes.colors.danger,
  },
  modalButtonConfirm: {
    backgroundColor: themes.colors.success,
  },
  modalButtonText: {
    color: themes.colors.white,
    fontSize: 16,
  },
});

export default DelayVisitCard;
