import api from './base';

class CheckinApi {
  public checkin = async (params: any) => {
    const { data } = await api.post('/early/checkin', params);
    return data;
  };
}

export default new CheckinApi();
