import { Feather } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { useNotificationsContext } from '_/hooks/NotificationsProvider';
import { AppRoute, ProfileProps } from '_/routes/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, SafeAreaView, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import SwitchCompany from './SwitchCompany';
import Text from '../Text';

const LIMIT_OF_NOTIFICATIONS = 99;

export default function TopMenu() {
  const { navigate } = useNavigation<ProfileProps>();
  const route = useRoute();

  const isPersonal = useSelector((state: any) => state.auth.personal);
  const user = useSelector((state: any) => state.user);
  const { name: companyName, hostSector } = useSelector((state: any) => state.company);
  const { t: translate } = useTranslation();

  const { notificationsUnread } = useNotificationsContext();

  function handleShowNotification() {
    if (route.name === AppRoute.PROFILE) {
      navigate(AppRoute.NOTIFICATION);
      return;
    }
    navigate(AppRoute.NOTIFICATION);
  }

  function renderProfile() {
    if (isPersonal) {
      return (
        <>
          <View style={styles.rowContainer}>
            <Text style={styles.companyName}>{user.name}</Text>
            <Feather name="chevron-down" style={styles.iconChevronDown} />
          </View>
          <Text style={styles.subtitle}>{translate('switchCompany.visitorProfile')}</Text>
        </>
      );
    }

    return (
      <>
        <View style={styles.rowContainer}>
          <Text style={styles.companyName}>{companyName || appConfig.company}</Text>
          <Feather name="chevron-down" style={styles.iconChevronDown} />
        </View>
        {hostSector ? (
          <Text style={styles.subtitle}>{hostSector}</Text>
        ) : (
          <Text style={styles.subtitle}>{translate('switchCompany.hostProfile')}</Text>
        )}
      </>
    );
  }

  return (
    <SafeAreaView style={styles.shadow}>
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          <SwitchCompany buttonStyles={styles.titleContainer} content={renderProfile()} />

          <TouchableOpacity
            hitSlop={{ top: 30, left: 30, bottom: 30, right: 30 }}
            onPress={handleShowNotification}
            style={[styles.notificationsContainer, styles.shadow]}
          >
            <Feather name="bell" style={styles.notifications} />
            {notificationsUnread?.length > 0 && (
              <View
                style={
                  notificationsUnread?.length > LIMIT_OF_NOTIFICATIONS
                    ? styles.notificationCountWrapperWithPlus
                    : styles.notificationCountWrapperWithoutPlus
                }
              >
                <Text numberOfLines={1} style={styles.notificationCountText}>
                  {notificationsUnread?.length > LIMIT_OF_NOTIFICATIONS &&
                    `${LIMIT_OF_NOTIFICATIONS}+`}
                  {notificationsUnread?.length <= LIMIT_OF_NOTIFICATIONS &&
                    notificationsUnread?.length}
                </Text>
              </View>
            )}
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'center',
    height: 80,
    paddingHorizontal: 24,
    backgroundColor: themes.colors.white,
  },
  innerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  notificationsContainer: {
    width: 38,
    height: 38,
    borderRadius: 30,
    paddingVertical: 12,
    paddingHorizontal: 12,
    backgroundColor: themes.colors.primary,
  },
  companyName: {
    fontFamily: fontFamily.medium,
    fontSize: 22,
    lineHeight: 22,
    color: themes.colors.darkSecondary,
  },
  subtitle: {
    fontFamily: fontFamily.regular,
    fontSize: 13,
    color: themes.colors.graySecondary,
  },
  shadow: {
    elevation: 2,
    shadowRadius: 2,
    shadowOpacity: 0.1,
    shadowColor: themes.colors.black,
    backgroundColor: themes.colors.white,
    shadowOffset: { height: 0.5, width: 0 },
  },
  notifications: {
    fontSize: 14,
    color: themes.colors.darkTertiary,
  },
  iconChevronDown: {
    fontSize: 22,
    color: themes.colors.lightTertiary,
  },
  notificationCountWrapperWithoutPlus: {
    marginLeft: 8,
    marginTop: -2,
    backgroundColor: themes.colors.secondary,
    width: 22,
    height: 22,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationCountWrapperWithPlus: {
    marginLeft: 5,
    marginTop: -3,
    backgroundColor: themes.colors.secondary,
    width: 32,
    height: 32,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationCountText: {
    fontSize: 14,
    color: themes.colors.darkTertiary,
    position: 'absolute',
    textAlign: 'center',
    flexWrap: 'nowrap',
  },
});
