import produce from 'immer';

import { AuthAction } from '../auth/types';
import { CompanyAction } from '../company/types';
import { UserAction, UserState } from './types';

const INITIAL_STATE: UserState = {
  id: null,
  rg: null,
  cpf: null,
  name: null,
  email: null,
  role: null,
  lang: null,
  phone: null,
  section: null,
  position: null,
  country: null,
  countryCode: null,
  emailVerified: false,
  googleId: null,
  microsoftId: null,
  currentCompanyId: null,
  pictureUrl: null,
  pictureThumbUrl: null,
  company: null,
  // imageLoading: false,
  companies: [],
  createdAt: null,
};

export default function user(
  state = INITIAL_STATE,
  action: UserAction | AuthAction | CompanyAction
): UserState {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@user/SET_USER_INFO_SUCCESS': {
        draft.id = action.payload.user.id;
        draft.rg = action.payload.user.rg;
        draft.cpf = action.payload.user.cpf;
        draft.name = action.payload.user.name;
        draft.email = action.payload.user.email;
        draft.role = action.payload.user.role;
        draft.lang = action.payload.user.lang;
        draft.phone = action.payload.user.phone;
        draft.section = action.payload.user.section;
        draft.position = action.payload.user.position;
        // draft.country = action.payload.user.country;
        draft.countryCode = action.payload.user.countryCode;
        draft.emailVerified = action.payload.user.emailVerified;
        draft.googleId = action.payload.user.googleId;
        draft.microsoftId = action.payload.user.microsoftId;
        draft.currentCompanyId = action.payload.user.currentCompanyId;
        draft.pictureUrl = action.payload.user.pictureUrl;
        draft.pictureThumbUrl = action.payload.user.pictureThumbUrl;
        draft.company = action.payload.user.company;
        draft.createdAt = action.payload.user.createdAt;
        break;
      }
      case '@company/GET_USER_COMPANIES_SUCCESS': {
        draft.companies = action.payload.companies;
        break;
      }
      case '@company/SET_USER_CURRENT_COMPANY_SUCCESS': {
        draft.currentCompanyId = action.payload.company.id;
        break;
      }
      // case '@user/GET_USER_INFO_SUCCESS': {
      //   Object.entries(action.payload.userInfo).forEach(([k, v]) => {
      //     draft[k] = v;
      //   });
      //   break;
      // }
      case '@user/UPDATE_PROFILE_SUCCESS': {
        draft.id = action.payload.id;
        draft.email = action.payload.email;
        draft.name = action.payload.name;
        draft.position = action.payload.position;
        draft.phone = action.payload.phone;
        draft.country = action.payload.country;
        draft.countryCode = action.payload.countryCode;
        break;
      }
      case '@user/UPDATE_USER_PICTURE_REQUEST': {
        draft.imageLoading = true;
        break;
      }
      case '@user/UPDATE_USER_PICTURE_SUCCESS': {
        draft.pictureUrl = action.payload.pictureUrl;
        draft.pictureThumbUrl = action.payload.pictureThumbUrl;
        draft.imageLoading = false;
        break;
      }
      case '@user/UPDATE_USER_PICTURE_FAILURE': {
        draft.imageLoading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
