import { Feather } from '@expo/vector-icons';
import { MeetingLinksModel, MeetingsModel } from '@w3lcome/types';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import LinkItem from './LinkItem';
import LinkModal from './LinkModal';
import { meetingLinksApi } from '../../../services/api';

interface LinkListProps {
  meeting: MeetingsModel;
}

export default function LinkList({ meeting }: LinkListProps) {
  const host = useSelector((state: any) => state.host);
  const [meetingLinks, setMeetingLinks] = useState<MeetingLinksModel[]>([]);
  const { t: translate } = useTranslation();

  const [expandedLinks, setExpandedLinks] = useState(true);
  const [linkModalVisible, setLinkModalVisible] = useState(false);

  function toggleExpandedLinks() {
    setExpandedLinks(!expandedLinks);
  }

  const shouldDisplayHostLinks =
    meeting?.hostId === host.id || meeting?.coHosts.includes(host?.email);

  useEffect(() => {
    (async () => {
      if (!shouldDisplayHostLinks) return;
      const { data } = await meetingLinksApi.getLinksByMeeting(meeting?.id);
      setMeetingLinks(data);
    })();
  }, [meeting]);

  if (!shouldDisplayHostLinks) {
    return;
  }

  function renderLinkItems() {
    return (
      <View>
        {meetingLinks.length > 0 &&
          meetingLinks.map((link) => (
            <LinkItem key={link.id} link={link} hostName={host.name} meeting={meeting} />
          ))}
        <TouchableOpacity style={styles.btnNewLink} onPress={() => setLinkModalVisible(true)}>
          <Text style={styles.btnNewLinkText}>{translate('linkModal.title')}</Text>
        </TouchableOpacity>
      </View>
    );
  }

  function renderLinksView() {
    if (meetingLinks.length > 0) {
      return (
        <TouchableOpacity
          onPress={toggleExpandedLinks}
          style={[styles.rowContainer, { justifyContent: 'space-between' }]}
        >
          <Text style={styles.title}>{translate('meeting.links')}</Text>
          <Feather name={expandedLinks ? 'chevron-up' : 'chevron-down'} />
        </TouchableOpacity>
      );
    }
    return null;
  }

  return (
    <View style={styles.container}>
      {renderLinksView()}
      {expandedLinks && renderLinkItems()}

      <LinkModal visible={linkModalVisible} hideModal={() => setLinkModalVisible(false)} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 12,
  },
  title: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
    fontSize: 12,
  },
  btnNewLink: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: themes.colors.primary,
    padding: 12,
    marginVertical: 8,
    borderRadius: 4,
  },
  btnNewLinkText: {
    color: themes.colors.white,
    fontFamily: fontFamily.medium,
    fontSize: 14,
  },
});
