import './src/config/ReactotronConfig';
import './src/helpers/base64';
import { fontFiles, fontFamily } from '_/config/theme';
import { endOfDay, getUnixTime } from 'date-fns';
import { Asset } from 'expo-asset';
import Constants from 'expo-constants';
import * as Font from 'expo-font';
import * as Linking from 'expo-linking';
import { EventType } from 'expo-linking';
import * as ExpoSplashScreen from 'expo-splash-screen';
import React, { useState, useEffect, useCallback } from 'react';
import { I18nextProvider } from 'react-i18next';
import { LogBox, AppState, AppStateStatus, StyleSheet, View } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from 'sentry-expo';

import AppNavigator from './src/AppNavigator';
import { SplashScreen } from './src/components/index';
import { appConfig } from './src/config/app';
import themes from './src/constants/themes';
import logSentryException from './src/helpers/logSentryException';
import AppProvider from './src/hooks/AppProvider';
import i18n from './src/locales';
import { store, persistor } from './src/store';
import { handleOpenUrlRequest } from './src/store/modules/personal/actions';
import { AppProps } from 'App';

LogBox.ignoreLogs([
  'Setting a timer for a long period of time',
  'Possible Unhandled Promise Rejection',
]);

if (appConfig.sentryDNS) {
  Sentry.init({
    dsn: appConfig.sentryDNS,
    enableInExpoDevelopment: false,
    debug: false,
    release: Constants.expoConfig?.version,
  });
}

export default function AppContextApi({ skipLoadingScreen = false }: AppProps) {
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [date, setDate] = useState<number>();

  const [initialised, setInitialised] = useState(false);

  async function getInitialURL() {
    // const url = await Linking.getInitialURL();
    const { path, queryParams } = await Linking.parseInitialURLAsync();

    if (!path) return;

    const [route, id] = path.split('/');

    if (route !== 'meeting') return;

    const isLink = queryParams?.type === 'link';

    if (id) {
      store.dispatch(handleOpenUrlRequest(id, isLink));
    }
    setInitialised(true);
  }

  if (!initialised) {
    getInitialURL();
  }

  useEffect(() => {
    function handleOpenURL(event: EventType) {
      const { path, queryParams } = Linking.parse(event.url);

      if (!path) return;

      const [route, id] = path.split('/');

      if (route !== 'meeting') return;

      const isLink = queryParams?.type === 'link';

      if (id) {
        store.dispatch(handleOpenUrlRequest(id, isLink));
      }
    }

    const linking = Linking.addEventListener('url', handleOpenURL);

    return () => {
      linking.remove();
    };
  }, []);

  useEffect(() => {
    // Sometimes the user do not close the app completely
    // So it's necessary to check if it's another day
    // If so, update the state and all the app will update
    function handleAppStateChange(state: AppStateStatus) {
      if (state === 'active') {
        const now = getUnixTime(endOfDay(new Date()));
        if (now !== date) {
          setDate(now);
        }
      }
    }

    handleAppStateChange('active');
    const appStateListener = AppState.addEventListener('change', handleAppStateChange);

    return () => appStateListener.remove();
  }, [date]);

  async function loadResourcesAsync() {
    await Asset.loadAsync(Object.values(themes.images));
    return Font.loadAsync(fontFiles);
  }

  function handleLoadingError(error: any) {
    logSentryException({
      error,
      file: 'App.js',
      message: 'Error at AppLoading',
    });
    console.tron.warn(error);
  }

  function handleFinishLoading() {
    setIsLoadingComplete(true);
  }

  useEffect(() => {
    async function prepare() {
      try {
        await loadResourcesAsync();
      } catch (error) {
        handleLoadingError(error);
      } finally {
        handleFinishLoading();
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (isLoadingComplete && skipLoadingScreen) {
      await ExpoSplashScreen.hideAsync();
    }
  }, [isLoadingComplete, skipLoadingScreen]);

  if (!isLoadingComplete && !skipLoadingScreen) {
    return null;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <AppProvider>
        <PersistGate persistor={persistor} loading={<SplashScreen loading={isLoadingComplete} />}>
          <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
            <AppNavigator />
            <FlashMessage
              floating
              position="top"
              duration={5000}
              titleStyle={styles.titleStyle}
              textStyle={styles.textStyle}
            />
          </View>
        </PersistGate>
      </AppProvider>
    </I18nextProvider>
  );
}

const styles = StyleSheet.create({
  titleStyle: {
    fontFamily: fontFamily.bold,
    fontSize: 16,
    lineHeight: 16,
    textAlign: 'center',
  },
  textStyle: {
    fontFamily: fontFamily.regular,
    fontSize: 14,
    lineHeight: 16,
    paddingTop: 6,
    textAlign: 'center',
  },
});
