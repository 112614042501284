import { CompaniesModel, CustomizationsModel, WAccessIntegrationsModel } from '@w3lcome/types';
import { CompanyModel } from '_/models/company';
import { action } from 'typesafe-actions';

export const getUserCompaniesRequest = () => action('@company/GET_USER_COMPANIES_REQUEST');

export const getUserCompaniesSuccess = (companies: CompanyModel[]) =>
  action('@company/GET_USER_COMPANIES_SUCCESS', { companies });

export const getUserCompaniesFailure = () => action('@company/GET_USER_COMPANIES_FAILURE');

export const setUserCurrentCompanyRequest = (
  company: CompanyModel | CompaniesModel,
  updateUser?: boolean
) => action('@company/SET_USER_CURRENT_COMPANY_REQUEST', { company, updateUser });

export const setUserCurrentCompanySuccess = ({
  company,
  customization,
  waccessIntegration,
}: {
  company: CompanyModel | CompaniesModel;
  customization: CustomizationsModel;
  waccessIntegration?: WAccessIntegrationsModel;
}) =>
  action('@company/SET_USER_CURRENT_COMPANY_SUCCESS', {
    company,
    customization,
    waccessIntegration,
  });

export const setUserCurrentCompanyFailure = () =>
  action('@company/SET_USER_CURRENT_COMPANY_FAILURE');

export const clearCompany = () => action('@company/CLEAR_COMPANY');

export const getWAccessIntegrationRequest = () =>
  action('@company/GET_WACCESS_INTEGRATION_REQUEST');

export const getWAccessIntegrationSuccess = ({
  waccessIntegration,
}: {
  waccessIntegration: WAccessIntegrationsModel;
}) => action('@company/GET_WACCESS_INTEGRATION_SUCCESS', { waccessIntegration });

// export const syncCompanyWithDatabaseRequest = () => {
//   return {
//     type: '@company/SYNC_COMPANY_WITH_DATABASE_REQUEST',
//   };
// };

// export const syncCompanyWithDatabaseSuccess = companyInfo => {
//   return {
//     type: '@company/SYNC_COMPANY_WITH_DATABASE_SUCCESS',
//     payload: companyInfo,
//   };
// };

// export const syncCompanyWithDatabaseFailure = () => {
//   return {
//     type: '@company/SYNC_COMPANY_WITH_DATABASE_FAILURE',
//   };
// };
