import { Feather } from '@expo/vector-icons';
import theme from '_/constants/themes';
import { usePasswordStrength } from '_/hooks/usePasswordStrength';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from 'react-native';
import Popover, { PopoverMode, PopoverPlacement } from 'react-native-popover-view';

import Divider from '../Divider';

type PasswordStrengthProps = {
  password: string;
  children: React.ReactNode;
  isVisible: boolean;
};

const LeftIcon = ({ validation }: { validation: boolean }) => (
  <Feather name={validation ? 'check-circle' : 'alert-circle'} />
);

const PasswordStrengthPopover: React.FC<PasswordStrengthProps> = ({
  children,
  password,
  isVisible,
}) => {
  const successColor = theme.colors.success;
  const warningColor = theme.colors.danger;
  const getColor = (validation: boolean) => (validation ? successColor : warningColor);
  const { t: translate } = useTranslation();
  const { passwordConditions } = usePasswordStrength({ password });

  const CheckPassword: React.FC = () => (
    <View>
      {passwordConditions.map(({ validation, text }, ind) => (
        <Text key={ind} style={{ color: getColor(validation) }}>
          <LeftIcon validation={validation} /> {text}
        </Text>
      ))}
    </View>
  );

  return (
    <Popover
      popoverStyle={{ backgroundColor: theme.colors.lightPrimary, padding: 8 }}
      mode={PopoverMode.TOOLTIP}
      isVisible={isVisible}
      placement={PopoverPlacement.TOP}
      from={<View>{children}</View>}
    >
      <Text style={{ fontWeight: '500' }}>{translate('passwordStrength.popover.title')}</Text>
      <Divider containerStyles={{ height: 4 }} borderColor={theme.colors.lightSecondary} />
      <CheckPassword />
    </Popover>
  );
};

export default PasswordStrengthPopover;
