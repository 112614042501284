import Feather from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import { VisitAuthorizationStatusType } from '@w3lcome/types';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { useVisitsContext } from '_/hooks/VisitsProvider';
import { FormatedVisitsModel } from '_/interfaces/FormatedVisitsModel';
import { AppRoute, VisitorProps } from '_/routes/types';
import DenyConfirmationModal from '_/screens/Visitor/DenyConfirmationModal';
import * as Localization from 'expo-localization';
import moment from 'moment-timezone';
import { transparentize } from 'polished';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';

import Text from '../Text';

interface VisitItemProps {
  visit: FormatedVisitsModel;
}

export function VisitItem({ visit }: VisitItemProps) {
  const navigation = useNavigation<VisitorProps>();
  const [authorizationLoading, setAuthorizationLoading] = useState(false);
  const { allowDenyVisit, setSelectedVisit } = useVisitsContext();
  const { t: translate } = useTranslation();

  const [showVisitDenialModal, setShowVisitDenialModal] = useState(false);

  const isExpected = useMemo(() => {
    return !!visit.expectedAt && !visit.checkinAt;
  }, [visit]);

  if (!visit) {
    return null;
  }

  const defaultColor = themes.colors.defaultBlue;
  const waitingColor = themes.colors.warning;
  const allowedColor = themes.colors.success;
  const deniedColor = themes.colors.danger;

  function handleOpen() {
    setSelectedVisit(visit);
    navigation.navigate(AppRoute.VISITOR);
  }

  function convertTime(time: Date) {
    const convertedTime = moment(time).tz(Localization.timezone || 'America/Sao_Paulo');
    return convertedTime;
  }

  function renderStatus() {
    const { authorizationStatus = '' } = visit;

    if (authorizationStatus === VisitAuthorizationStatusType.AWAITING) {
      return (
        <View style={styles.shadow}>
          <View style={[styles.status, { backgroundColor: waitingColor }]}>
            <View style={styles.rowContainer}>
              <Feather name="alert-triangle" style={styles.iconTriangle} />
              <Text style={styles.statusText}>{translate('home.waiting')}</Text>
            </View>
          </View>
        </View>
      );
    }

    return null;
  }

  function renderStatusIcon() {
    const { authorizationStatus = '' } = visit;

    switch (authorizationStatus) {
      case VisitAuthorizationStatusType.DENIED:
        return <Feather name="x-square" style={[styles.iconStatus, { color: deniedColor }]} />;
      case VisitAuthorizationStatusType.ALLOWED:
        return <Feather name="check-square" style={[styles.iconStatus, { color: allowedColor }]} />;
      default:
        if (isExpected) {
          return (
            <Feather name="check-square" style={[styles.iconStatus, { color: defaultColor }]} />
          );
        }
        return null;
    }

    // if (type === 'invitee') {
    //   const { status = '' } = data;

    //   switch (status) {
    //     case 'canceled':
    //       return <Feather name="x-square" style={[styles.iconStatus, { color: deniedColor }]} />;
    //     case 'arrived':
    //       return (
    //         <Feather name="check-square" style={[styles.iconStatus, { color: allowedColor }]} />
    //       );
    //     case 'confirmed':
    //       return (
    //         <Feather name="check-square" style={[styles.iconStatus, { color: defaultColor }]} />
    //       );
    //     default:
    //       return null;
    //   }
    // }
  }

  async function handleVisitAccessAllowed() {
    try {
      setAuthorizationLoading(true);

      const status = VisitAuthorizationStatusType.ALLOWED;
      await allowDenyVisit(visit.id, status);

      showMessage({
        message: translate('visitSaga.allowAccessSuccess'),
        backgroundColor: themes.colors.success,
      });
    } catch (_) {
      showMessage({
        message: translate('visitSaga.allowAccessError'),
        backgroundColor: themes.colors.danger,
      });
    }
    setAuthorizationLoading(false);
  }

  async function handleVisitAccessDenied(inputNotes?: string | null) {
    try {
      setAuthorizationLoading(true);
      const status = VisitAuthorizationStatusType.DENIED;

      const visitorNoteDenied = inputNotes?.trim() ? inputNotes : null;

      await allowDenyVisit(visit.id, status, visitorNoteDenied);

      setShowVisitDenialModal(false);
      showMessage({
        message: translate('visitSaga.denyAccessSuccess'),
        backgroundColor: themes.colors.success,
      });
    } catch (_) {
      showMessage({
        message: translate('visitSaga.denyAccessError'),
        backgroundColor: themes.colors.danger,
      });
    }
    setAuthorizationLoading(false);
  }

  function renderAuthorizationButtons() {
    const { authorizationStatus = '' } = visit;

    if (authorizationLoading) {
      return (
        <View style={styles.rowContainer}>
          <ActivityIndicator animating />
        </View>
      );
    }

    if (authorizationStatus === VisitAuthorizationStatusType.AWAITING) {
      return (
        <View style={styles.rowContainer}>
          <TouchableOpacity onPress={() => setShowVisitDenialModal(true)}>
            <Feather name="x-square" style={[styles.actionsButtons, { color: deniedColor }]} />
          </TouchableOpacity>
          <TouchableOpacity onPress={handleVisitAccessAllowed}>
            <Feather name="check-square" style={[styles.actionsButtons, { color: allowedColor }]} />
          </TouchableOpacity>
        </View>
      );
    }

    return (
      <View>
        <Feather name="chevron-right" style={styles.iconChevronRight} />
      </View>
    );
  }

  function getInitials(fullname?: string | null) {
    if (!fullname) {
      return 'X';
    }

    const names = fullname.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  function renderFullnameView() {
    const initials = getInitials(visit.name);
    return (
      <View style={[styles.fullnameContainer, { backgroundColor: defaultColor }]}>
        <Text style={styles.fullname}>{initials}</Text>
      </View>
    );
  }

  function renderImage() {
    return (
      <View style={styles.imageContainerShadow}>
        {visit.pictureThumbUrl ? (
          <ImageBackground style={styles.imageContainer} source={{ uri: visit.pictureThumbUrl }}>
            {isExpected && <View style={styles.imageFulfil} />}
          </ImageBackground>
        ) : (
          renderFullnameView()
        )}
      </View>
    );
  }

  function renderInfoContainer() {
    const isToday = moment(visit.checkinAt || visit.expectedAt).isSame(moment(), 'D');

    return (
      <View style={styles.infoContainer}>
        {visit && visit.name !== '' && (
          <View style={styles.rowContainer}>
            <Text
              numberOfLines={1}
              size={16}
              style={[styles.text, isExpected && styles.textExpectedState]}
            >
              {visit.name || translate('visitor.anonymous')}
            </Text>
            {renderStatusIcon()}
          </View>
        )}
        <Text style={styles.subText} size={12}>
          {!isExpected &&
            !!visit.checkinAt &&
            (isToday
              ? convertTime(visit.checkinAt).fromNow()
              : moment(visit.checkinAt).format('lll'))}
          {isExpected &&
            (isToday
              ? moment(visit.expectedAt).format('LT')
              : moment(visit.expectedAt).format('lll'))}
          {isExpected && !!visit.meeting?.title && ` - `}
          {isExpected && visit?.meeting?.title}
        </Text>
      </View>
    );

    // if (type === 'invitee') {
    //   return (
    //     <View style={styles.infoContainer}>
    //       {data && data.name !== '' && (
    //         <View style={styles.rowContainer}>
    //           <Text numberOfLines={1} style={styles.text}>
    //             {data.name}
    //           </Text>
    //           {renderStatusIcon()}
    //         </View>
    //       )}
    //       <Text style={styles.subText}>
    //         {convertTime(data.expectedDate).format('HH:mm')} - {data.meeting.title}
    //       </Text>
    //     </View>
    //   );
    // }
  }

  return (
    <>
      <TouchableOpacity delayPressIn={50} style={styles.container} onPress={handleOpen}>
        <View style={styles.content}>
          {renderImage()}
          {renderInfoContainer()}
          {!isExpected && renderAuthorizationButtons()}
        </View>
        {!isExpected && renderStatus()}
      </TouchableOpacity>

      {showVisitDenialModal && (
        <DenyConfirmationModal
          visible={showVisitDenialModal}
          hideModal={() => setShowVisitDenialModal(false)}
          denyVisit={handleVisitAccessDenied}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    flex: 1,
    paddingHorizontal: 24,
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: themes.colors.lightPrimary,
  },
  imageContainer: {
    resizeMode: 'cover',
    width: 60,
    height: 60,
    borderRadius: 4,
    overflow: 'hidden',
  },
  imageFulfil: {
    backgroundColor: transparentize(0.7, themes.colors.white),
    width: '100%',
    height: '100%',
  },
  imageContainerShadow: {
    shadowColor: themes.colors.black,
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    width: 60,
    height: 60,
    elevation: 4,
    shadowRadius: 8,
    borderRadius: 4,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
  },
  infoContainer: {
    alignItems: 'flex-start',
    flex: 1,
    paddingHorizontal: 24,
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  text: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
  },
  textExpectedState: {
    color: transparentize(0.3, themes.colors.darkSecondary),
  },
  subText: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.light,
    fontSize: 12,
  },
  status: {
    position: 'absolute',
    alignItems: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    bottom: 0,
    height: 18,
    paddingHorizontal: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  statusText: {
    color: themes.colors.white,
    fontFamily: fontFamily.regular,
    fontSize: 12,
    marginLeft: 6,
    height: '100%',
  },
  shadow: {
    shadowColor: themes.colors.black,
    backgroundColor: themes.colors.white,
    elevation: 5,
    shadowRadius: 2,
    shadowOpacity: 0.8,
    shadowOffset: { width: 0, height: 12 },
  },
  iconStatus: {
    fontSize: 14,
    marginLeft: 6,
  },
  fullnameContainer: {
    height: 60,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  fullname: {
    color: themes.colors.white,
    fontFamily: fontFamily.light,
    fontSize: 24,
  },
  actionsButtons: {
    fontSize: 35,
    marginHorizontal: 6,
    opacity: 0.4,
  },
  iconTriangle: {
    fontSize: 14,
    color: themes.colors.white,
  },
  iconChevronRight: {
    fontSize: 18,
    color: themes.colors.lightPrimary,
  },
});
