import { Feather } from '@expo/vector-icons';
import { useNetInfo } from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';
import { HostsModel, Lang, VisitsModel } from '@w3lcome/types';
import { Spin } from '_/components';
import { appConfig } from '_/config/app';
import { handleVerifyCardLocalDB } from '_/helpers/cardVerificationLocalDB';
import { showError } from '_/helpers/showError';
import { QRCodeReaderProps } from '_/routes/types';
import { qrcodesApi } from '_/services/api';
import { QRCodesApiResponse } from '_/services/api/qrcodes';
import axios from 'axios';
import bigInt from 'big-integer';
import { Buffer } from 'buffer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Modal, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { decodeQRCode } from '_/helpers/totp';

import EmployeeModal from './EmployeeModal';
import ExpiredModal from './ExpiredModal';
import InviteeModal from './InviteeModal';
import QRCodeScanner from './QRCodeScanner';
import styles from './styles';
import { HostQRcodeCreatedProps } from '../EntryReader';

export default function QRCodeReader() {
  const navigation = useNavigation<QRCodeReaderProps>();
  const { t: translate, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [visitQRcodeCreated, setVisitQRcodeCreated] = useState<QRCodesApiResponse>();
  const [hostQRcodeCreated, setHostQRcodeCreated] = useState<HostQRcodeCreatedProps>();
  const [scannerEnabled, setScannerEnabled] = useState(true);
  const [expiredQRCode, setExpiredQRCode] = useState(false);

  const host = useSelector((state: any) => state.host);

  const netInfo = useNetInfo();

  const isZoneAuthorizationEnabled = useSelector(
    (state: any) => state.company?.customization?.isZoneAuthorizationEnabled
  );

  // const readQRCodeOnlyVisitorsOfDay = useSelector(
  //   (state: any) => state.company?.customization?.readQRCodeOnlyVisitorsOfDay
  // );

  function handleClose() {
    setVisitQRcodeCreated(undefined);
    setHostQRcodeCreated(undefined);
    setExpiredQRCode(false);
    setLoading(false);
    setScannerEnabled(true);
  }

  async function handleQRCodeScannedInvenzi(qrCode: string) {
    try {
      if (!host.zoneId || !isZoneAuthorizationEnabled) {
        return;
      }

      if (!/^[0-9]+$/.test(qrCode)) {
        showError(translate('qrCodeScan.invalidFormat'));
        return;
      }

      const binaryValue = bigInt(qrCode).toString(2);

      const cardNumber = binaryValue.length > 40 ? decodeQRCode(qrCode) : qrCode;

      if (netInfo.type === 'wifi') {
        await handleVerifyCardNumber(cardNumber);
        return;
      }

      await handleVerifyCardLocalDB(cardNumber, host, setHostQRcodeCreated);
    } catch (error) {
      showError(translate('qrCodeScan.readError'));
      clearQRCodeData();
    }
  }

  async function handleVerifyCardNumber(cardNumber: string) {
    try {
      const { username, password, endpointUrl, name } = host.zone;

      if (!endpointUrl || !password || !username) {
        return;
      }

      const credentials = Buffer.from(`${username}:${password}`, 'utf-8').toString('base64');

      const data = {
        cardnumber: cardNumber,
        source_name: name,
      };

      const { data: userAllowed } = await axios.post(`${endpointUrl}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${credentials}`,
        },
        timeout: 2000,
      });

      setHostQRcodeCreated({
        data: { name: userAllowed.CHName },
        isZoneAuthorized: userAllowed.strlanguage1?.toLowerCase() === 'access authorized',
        allowedZones: [{ zone: host.zone }],
        message: i18n.language === Lang.EN ? userAllowed.strlanguage1 : userAllowed.strlanguage2,
      });
    } catch (error) {
      await handleVerifyCardLocalDB(cardNumber, host, setHostQRcodeCreated);
    }
  }

  async function handleQRCodeScanned(qrCode: string) {
    setScannerEnabled(false);
    setLoading(true);

    // [w3lcome:, "", "host" || "visit", id]
    const qrcodeSplit = qrCode.split('/');
    const qrcodeCompany = qrcodeSplit[0]?.toLocaleLowerCase().replaceAll(':', '');
    const qrcodeType = qrcodeSplit[2]?.toLocaleLowerCase();

    try {
      if (qrcodeCompany !== appConfig.company.toLowerCase()) {
        showError(translate('qrCodeScan.readError'));
        setLoading(false);
        return;
      }

      const { data } = await qrcodesApi.create(qrCode);

      if (qrcodeType === 'host') {
        setHostQRcodeCreated(data);
      } else if (qrcodeType === 'visit') {
        setVisitQRcodeCreated(data);
      } else {
        showError(translate('qrCodeScan.readError'));
      }

      setLoading(false);
    } catch (error) {
      showError(translate('qrCodeScan.readError'));
      clearQRCodeData();
      setLoading(false);
    }
  }

  function clearQRCodeData() {
    setVisitQRcodeCreated(undefined);
    setHostQRcodeCreated(undefined);
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity
          hitSlop={{ top: 30, right: 30, bottom: 30, left: 30 }}
          onPress={() => navigation.goBack()}
        >
          <Feather name="arrow-left" style={styles.icon} />
        </TouchableOpacity>
      </View>
      <Spin visible={loading} />
      <QRCodeScanner
        isEnabled={scannerEnabled}
        handleQRCodeScanned={
          appConfig.allowedHostZonesOfflineFlowEnabled === 'true'
            ? handleQRCodeScannedInvenzi
            : handleQRCodeScanned
        }
      />
      <Modal
        animationType="slide"
        visible={!!visitQRcodeCreated || !!hostQRcodeCreated || expiredQRCode}
        onRequestClose={handleClose}
      >
        {!!visitQRcodeCreated && (
          <InviteeModal
            invitee={visitQRcodeCreated.data as VisitsModel}
            handleClose={handleClose}
          />
        )}
        {!!hostQRcodeCreated && (
          <EmployeeModal
            host={hostQRcodeCreated.data as HostsModel}
            handleClose={handleClose}
            zonesAllowed={hostQRcodeCreated.allowedZones}
            isZoneAuthorized={hostQRcodeCreated.isZoneAuthorized}
            message={hostQRcodeCreated.message}
          />
        )}
        {expiredQRCode && <ExpiredModal handleClose={handleClose} />}
      </Modal>
    </View>
  );
}
