import { MeetingTypesModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';

import api from './base';
import { GetListType } from './types/GetListType';

class MeetingTypesApi {
  public getMeetingTypes = async (params: any, config?: AxiosRequestConfig) => {
    try {
      const { data } = await api.get<GetListType<MeetingTypesModel>>(`/meeting-types`, {
        ...config,
        params,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

export default new MeetingTypesApi();
