import { Lang } from '@w3lcome/types';
import { enUS, ptBR, de, zhCN } from 'date-fns/locale';

export function getDateLocale(lang: Lang | string | null = Lang.EN) {
  switch (lang) {
    case Lang.PTBR:
      return ptBR;
    case Lang.ZHHANS:
      return zhCN;
    case Lang.DE:
      return de;
    default:
      return enUS;
  }
}
