import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LanguageContextData {
  language: string;
  changeLanguage(item: string): void;
}

const LanguageContext = createContext<LanguageContextData>({} as LanguageContextData);

type LanguageType = {
  children: React.ReactNode;
};

export const LanguageProvider: React.FC<LanguageType> = ({ children }) => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState('en');

  async function getLanguage(): Promise<void> {
    const storedLanguage = await AsyncStorage.getItem('@w3lcome/language');

    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }

  useEffect(() => {
    getLanguage();
  }, []);

  const changeLanguage = async (item: string) => {
    i18n.changeLanguage(item);
    await AsyncStorage.setItem('@w3lcome/language', item);
    getLanguage();
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function useLanguage() {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within an LanguageProvider');
  }

  return context;
}
