import { AxiosRequestConfig } from 'axios';

import api from './base';

interface NotificationTokenData {
  deviceId: string;
  token: string;
  userId: string;
  type: string;
}

class NotificationTokens {
  public async getAllNotificationTokens(params: any, config?: AxiosRequestConfig) {
    const { data } = await api.get('/notification-tokens', {
      ...config,
      params: {
        ...params,
      },
    });
    if (data) {
      return data;
    } else {
      return undefined;
    }
  }

  // mudar o id para deviceId: string;
  public async getNotificationTokenById(id: string) {
    const { data } = await api.get(`/notification-tokens/${id}`);
    return data;
  }

  public async createNotificationToken({ deviceId, token, userId, type }: NotificationTokenData) {
    const { data } = await api.post('/notification-tokens', {
      token,
      userId,
      type,
      deviceId,
    });

    return data;
  }

  // mudar o id para deviceId: string;
  public async updateNotificationToken({ deviceId, token, userId, type }: NotificationTokenData) {
    await api.patch(`/notification-tokens/${deviceId}`, {
      token,
      userId,
      type,
    });
  }

  // mudar o id para deviceId: string;
  public async deleteNotificationToken(id: string) {
    try {
      await api.delete(`/notification-tokens/${id}`);
    } catch (error) {
      console.log(error);
    }
  }
}

export default new NotificationTokens();
