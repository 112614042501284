import { Picker } from '@react-native-picker/picker';
import { getHours, getMinutes } from 'date-fns';
import React from 'react';
import { StyleSheet } from 'react-native';

import { colors, sizes } from '_/constants/theme';

type timeType = 'hours' | 'minutes';

interface IWebTimePicker {
  value: Date;
  disabled?: boolean;
  type: timeType;
  setTimeValue: (time: number, type: timeType) => void;
}

const minutes = [...Array(60).keys()];
const hours = [...Array(24).keys()];

const timeOptions = {
  hours: hours.map((value) => ({ label: `${value}`, value })),
  minutes: minutes.map((value) => ({ label: `${value}`.padStart(2, '0'), value })),
};

const WebTimePicker = ({ value, disabled, type, setTimeValue }: IWebTimePicker) => {
  return (
    <Picker
      selectedValue={type === 'hours' ? getHours(value) : getMinutes(value)}
      enabled={!disabled}
      mode="dropdown"
      style={styles.inputWeb}
      onValueChange={(v) => setTimeValue(Number(v), type)}
    >
      {timeOptions[type].map((option) => (
        <Picker.Item
          key={option.value}
          label={option.label}
          value={option.value}
          style={styles.pickerItem}
        />
      ))}
    </Picker>
  );
};

const styles = StyleSheet.create({
  inputWeb: {
    borderColor: colors.white,
    borderRadius: 8,
    borderWidth: 1,
    fontSize: sizes.title,
    fontWeight: '600',
    margin: 4,
    padding: 8,
    shadowColor: colors.black,
    shadowOpacity: 0.25,
    shadowRadius: 1.5,
  },
  pickerItem: {
    fontSize: sizes.title,
    fontWeight: '600',
  },
});

export default WebTimePicker;
