import { CustomizationsModel, VisitCheckType, VisitsModel } from '@w3lcome/types';
import { InfoCard, PictureContainer, StatusView, Header, Spin, Text } from '_/components';
import Button from '_/components/Button';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import * as Localization from 'expo-localization';
import moment from 'moment-timezone';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, SafeAreaView, StyleSheet, Alert } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { useSelector } from 'react-redux';

import visitsApi from '../../services/api/visits';

interface InviteeModalProps {
  invitee: VisitsModel;
  handleClose: () => void;
}

export default function InviteeModal({ invitee: inviteeData, handleClose }: InviteeModalProps) {
  const [loading, setLoading] = useState(false);
  const [invitee, setInvitee] = useState(inviteeData);
  const { t: translate } = useTranslation();
  const { takePictureOnlyOnKiosk }: CustomizationsModel =
    useSelector((state: any) => state.company?.customization) || {};

  async function handleCheckin() {
    if (takePictureOnlyOnKiosk) {
      return Alert.alert(
        translate('visitModal.deniedAction'),
        translate('visitModal.missingPicture'),
        [{ text: translate('visitModal.understand') }]
      );
    }

    setLoading(true);
    try {
      const res = await visitsApi.checkinVisit({
        ...invitee,
        checkinAt: new Date(),
        checkinType: VisitCheckType.MOBILE,
      });

      setInvitee({ ...invitee, ...res });

      showMessage({
        message: translate('visitModal.successCheckin'),
        type: 'success',
      });
    } catch (error) {
      console.tron.log('File: QRCodeReader/inviteeModal.js', error);
      logSentryException({
        error,
        file: 'InviteeModal.js',
        message: 'Error at handleCheckin function',
      });
      showMessage({
        message: translate('visitModal.failedCheckin'),
        type: 'danger',
      });
    }
    setLoading(false);
  }

  function isInviteExpired() {
    const now = moment();
    const endAt = moment(invitee.meeting?.end);
    return (!!invitee.meeting?.end && now >= endAt) || (!invitee.meeting && invitee.checkoutAt);
  }

  function getInviteStatus() {
    if (isInviteExpired()) {
      return 'expired';
    }

    if (invitee.isCanceled) {
      return 'canceled';
    }

    if (invitee.authorizationStatus) {
      return invitee.authorizationStatus;
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header handleBack={handleClose} title={translate('general.invitee')} />
      <Spin visible={loading} />
      <ScrollView contentContainerStyle={styles.content}>
        <PictureContainer pictureUrl={invitee.pictureUrl} type="visit" />

        <View style={styles.textContainer}>
          <StatusView
            containerStyles={{ marginBottom: 24 }}
            type="invitee"
            status={getInviteStatus()}
            name={invitee.authorizedBy?.name}
          />

          <InfoCard copy title={translate('general.name')} value={invitee.name} />
          <InfoCard
            title={translate('general.expectedAt')}
            value={
              invitee.expectedAt &&
              moment(invitee.expectedAt)
                .tz(invitee.meeting?.timezone || Localization.timezone || 'America/Sao_Paulo')
                .format('DD/MM/YYYY - HH:mm')
            }
          />
          <InfoCard
            title={translate('general.checkin')}
            value={
              invitee.checkinAt &&
              moment(invitee.checkinAt)
                .tz(invitee.meeting?.timezone || Localization.timezone || 'America/Sao_Paulo')
                .format('DD/MM/YYYY - HH:mm')
            }
          />

          {invitee.host && (
            <InfoCard title={translate('general.host')} value={invitee.host?.name} />
          )}

          {!!invitee.meeting && (
            <InfoCard title={translate('general.meeting')} value={invitee.meeting?.title} />
          )}

          {!!invitee.meeting && (
            <InfoCard
              title={translate('general.meetingLocation')}
              value={invitee.meeting?.location}
            />
          )}

          {!!invitee.meeting && (
            <InfoCard title={translate('general.meetingNotes')} value={invitee.meeting?.notes} />
          )}

          {!!invitee.notes && <InfoCard title={translate('meeting.notes')} value={invitee.notes} />}

          {!invitee.checkinAt && !invitee.isCanceled && (
            <View style={{ marginTop: 12 }}>
              <Button
                outline
                disabled={!!isInviteExpired()}
                onPress={handleCheckin}
                buttonStyles={[
                  {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                  !!isInviteExpired() && styles.disabled,
                ]}
              >
                <Text
                  style={[{ color: themes.colors.primary }, isInviteExpired() && styles.disabled]}
                >
                  {translate('general.checkinVisitor')}
                </Text>
              </Button>
            </View>
          )}
        </View>
      </ScrollView>
      <View style={styles.closeView}>
        <Button onPress={handleClose}>
          <Text style={styles.closeBtnText}>{translate('general.close')}</Text>
        </Button>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    paddingHorizontal: 12,
    paddingBottom: 24,
  },
  textContainer: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 24,
    marginBottom: 60,
  },
  closeView: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: themes.colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    backgroundColor: themes.colors.white,
  },

  closeBtnText: {
    color: themes.colors.white,
  },
  disabled: {
    color: '#ccc',
    borderColor: '#ccc',
  },
  canceledTag: {
    backgroundColor: transparentize(0.9, themes.colors.danger),
    marginTop: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    borderRadius: 8,
  },
});
