import base32Encode from 'base32-encode';
import bigInt from 'big-integer';
import { Buffer } from 'buffer';
import OTP from 'otp-client';

const UINT_32_MAX = 2 ** 32;

function encodeUInt64(num) {
  const buf = Buffer.alloc(8);

  const top = Math.floor(num / UINT_32_MAX);
  const rem = num - top * UINT_32_MAX;

  buf.writeUInt32LE(rem, 0);
  buf.writeUInt32LE(top, 4);
  return buf;
}

// Only used to test
// function decodeUInt64(buf) {
//   const rem = buf.readUInt32LE(0);
//   const top = buf.readUInt32LE(0 + 4);
//   return top * UINT_32_MAX + rem;
// }

export function getTOTPKey(key, cardNumber) {
  const keyBuffer = Buffer.from(key, 'hex');
  const keyBase32 = base32Encode(keyBuffer, 'RFC4648', {
    padding: false,
  }).substring(0, 19);

  const cardNumberBuffer = encodeUInt64(cardNumber);
  const cardNumberBase32 = base32Encode(cardNumberBuffer, 'RFC4648', {
    padding: false,
  });
  return keyBase32 + cardNumberBase32;
}

export function getTOTPValue(secret, period, date) {
  const options = {
    period,
    algorithm: 'sha1',
    digits: 6,
  };

  let now = new Date();

  if (date) {
    now = new Date(date);
  }

  const epoch = now.getTime() - now.getTimezoneOffset() * 60000;
  options.epoch = epoch;

  const otp = new OTP(secret, options);
  const totpValue = otp.getToken();
  const nextTick = otp.getTimeUntilNextTick();

  return { totpValue, nextTick, epoch: options.epoch };
}

export function getQRCode(totpValue, cardNumber) {
  return bigInt(totpValue).shiftLeft(40).or(cardNumber).toString();
}

export function getTOTPQRCodeValue(key, cardNumber, step, date = null) {
  const totpKey = getTOTPKey(key, cardNumber);
  const { totpValue, nextTick, epoch } = getTOTPValue(totpKey, step, date);
  const qrcodeValue = getQRCode(totpValue, cardNumber);

  return { qrcodeValue, totpValue, totpKey, nextTick, epoch };
}

export function decodeQRCode(qrcodeValue) {
  const qrcodeBigInt = bigInt(qrcodeValue);

  const cardNumber = qrcodeBigInt.and(0xffffffffff);
  return cardNumber.toString();
}
