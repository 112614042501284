import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { usePushNotificationContext } from '_/hooks/PushNotificationProvider';
import { transparentize } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import Modal from 'react-native-modal';

import Spin from '../../Spin';
import Text from '../../Text';

interface NotificationsCheckModalProps {
  visible: boolean;
  hideModal: () => void;
}

export default function NotificationsCheckModal({
  visible = false,
  hideModal,
}: NotificationsCheckModalProps) {
  const { checkNotificationsPermissions, skipNotificationModal, loading } =
    usePushNotificationContext();
  const { t: translate } = useTranslation();

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onDismiss={hideModal}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Spin visible={loading} />
          <Text style={styles.title}>{translate('notificationsCheckModal.title')}</Text>

          <Text style={styles.instruction}>{translate('notificationsCheckModal.instruction')}</Text>

          <Text style={styles.notes}>{translate('notificationsCheckModal.notes')}</Text>

          <View style={styles.btnContainer}>
            <TouchableOpacity onPress={skipNotificationModal} style={styles.btnSkip}>
              <Text style={styles.btnSkipText}>{translate('notificationsCheckModal.skip')}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.btn} onPress={checkNotificationsPermissions}>
              <Text style={styles.btnReadyText}>{translate('notificationsCheckModal.enable')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  container: {
    shadowColor: themes.colors.black,
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    elevation: 2,
    borderRadius: 4,
    width: width - 48,
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  content: {
    width: '100%',
    paddingVertical: 24,
    paddingHorizontal: 24,
  },
  title: {
    fontFamily: fontFamily.medium,
    fontSize: 18,
    marginBottom: 10,
  },
  instruction: {
    color: themes.colors.grayPrimary,
  },
  btnContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 10,
  },
  notes: {
    fontSize: 12,
    marginTop: 10,
    fontFamily: fontFamily.light,
    color: themes.colors.grayPrimary,
  },
  btn: {
    marginLeft: 10,
    borderRadius: 6,
    paddingVertical: 10,
    paddingHorizontal: 30,
    backgroundColor: themes.colors.primary,
  },
  btnReadyText: {
    color: themes.colors.white,
  },

  btnSkip: {
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  btnSkipText: {
    color: themes.colors.primary,
  },
});
