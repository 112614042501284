import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React from 'react';
import { Animated, View, StyleSheet } from 'react-native';

const SPIN_DURATION = 1000;

interface SpinProps {
  visible?: boolean;
  overlayColor?: string;
  containerColor?: string;
}

export default function Spin({
  visible = false,
  overlayColor = transparentize(0.2, themes.colors.black),
  containerColor = themes.colors.white,
}: SpinProps) {
  if (!visible) return null;

  const spinValue = new Animated.Value(0);
  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 1,
      duration: SPIN_DURATION,
      useNativeDriver: true,
    }),
    { iterations: -1 }
  ).start();

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  return (
    <View style={[styles.overlay, { backgroundColor: overlayColor }]}>
      <View style={[styles.container, { backgroundColor: containerColor }]}>
        <Animated.Image
          source={themes.images.spinner}
          style={[styles.spinner, { transform: [{ rotate: spin }] }]}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  overlay: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    borderRadius: 12,
    zIndex: 1001,
  },
  spinner: {
    height: 50,
    width: 50,
    zIndex: 1002,
  },
});
