import { Feather } from '@expo/vector-icons';
import themes from '_/constants/themes';
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

import Text from '../../Text';

interface EmailTagProps {
  email: string;
  removeTag: (email: string) => void;
}

export default function EmailTag({ email, removeTag }: EmailTagProps) {
  function handleRemove() {
    removeTag(email);
  }

  return (
    <View style={styles.container}>
      <View style={styles.tagContainer}>
        <Text style={styles.email}>{email}</Text>
      </View>
      <TouchableOpacity style={styles.button} onPress={handleRemove}>
        <Feather name="x" style={styles.icon} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: themes.colors.lightPrimary,
  },
  tagContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    paddingVertical: 10,
    borderRadius: 4,
    marginVertical: 2,
  },
  email: {
    color: themes.colors.darkQuaternary,
    fontSize: 14,
    marginRight: 6,
  },
  button: {
    padding: 4,
    borderRadius: 2,
    backgroundColor: themes.colors.white,
  },
  icon: {
    color: themes.colors.primary,
    fontSize: 14,
    borderRadius: 50,
  },
});
