export function getMonths(translate: (key: string) => string) {
  return [
    translate('months.jan'),
    translate('months.feb'),
    translate('months.mar'),
    translate('months.apr'),
    translate('months.may'),
    translate('months.jun'),
    translate('months.jul'),
    translate('months.aug'),
    translate('months.sep'),
    translate('months.oct'),
    translate('months.nov'),
    translate('months.dec'),
  ];
}

export function getWeekDays(translate: (key: string) => string) {
  return [
    translate('week.mon'),
    translate('week.tue'),
    translate('week.wed'),
    translate('week.thu'),
    translate('week.fri'),
    translate('week.sat'),
    translate('week.sun'),
  ];
}
