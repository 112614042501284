import { produce } from 'immer';

import { MeetingTypesAction, MeetingTypesState } from './types';

const INITIAL_STATE: MeetingTypesState = {
  meetingTypes: [],
  total: 0,
  limit: 0,
  skip: 0,
  meetingTypeLoaded: false,
};

const meetings = (state = INITIAL_STATE, action: MeetingTypesAction): MeetingTypesState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@meetings/GET_MEETING_TYPES_SUCCESS': {
        draft.meetingTypes = action.payload.data.sort((a, b) => a.sort - b.sort || 0);
        draft.total = action.payload.total;
        draft.limit = action.payload.limit;
        draft.skip = action.payload.skip;
        draft.meetingTypeLoaded = true;
        break;
      }
      case '@meetings/GET_MEETING_TYPES_FAILURE': {
        draft.meetingTypeLoaded = true;
        break;
      }
      default:
    }
  });
};

export default meetings;
