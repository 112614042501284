import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { VisitAuthorizationStatusType } from '@w3lcome/types';
import { getDateLocale } from '_/helpers/getDateLocale';
import { AppRoute, MeetingProps } from '_/routes/types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackHandler, LayoutAnimation } from 'react-native';
import { useSelector } from 'react-redux';
import moment from 'moment';

export function useMeeting() {
  const navigation = useNavigation<MeetingProps>();

  const { meeting } = useSelector((state: any) => state?.meeting);
  const { loading } = useSelector((state: any) => state?.loading);
  const host = useSelector((state: any) => state?.host);
  const { i18n, t: translate } = useTranslation();

  const locale = getDateLocale(i18n?.language);

  const [expanded, setExpanded] = useState(false);
  const [menuModalVisible, setMenuModalVisible] = useState(false);

  const hideModalCallback = useCallback(
    (value: boolean) => {
      setMenuModalVisible(value);
    },
    [setMenuModalVisible]
  );

  const externalGuests = useSelector((state: any) => state?.meeting?.externalGuests);

  const visits = externalGuests ? objToArray(externalGuests) : [];

  const headerTitle = translate('meeting.mainTitle');
  const subjectTitle = translate('editMeeting.subject');
  const startDateTitle = translate('editMeeting.start');
  const endDateTitle = translate('editMeeting.end');

  const editMeetingHostTitle = translate('editMeeting.host');
  const editMeetingLocationTitle = translate('editMeeting.location');
  const editMeetingNotesTitle = translate('editMeeting.notes');
  const editMeetingDescriptionTitle = translate('editMeeting.description');

  const meetingHideInfo = translate('meeting.hideInfo');
  const meetingShoweInfo = translate('meeting.showInfo');

  const meetingGuestsTitle = `${translate('meeting.guests')} (${meeting?.guests?.length})`;
  const meetingCoHostsTitle = `${translate('meeting.cohost')} (${meeting?.coHosts?.length})`;

  const isThereAnyGuest = meeting?.guests?.length > 0;
  const guestsData = meeting?.guests;

  const isThereAnyCoHost = meeting?.coHosts?.length > 0;
  const coHostsData = meeting?.coHosts;

  const startDateValue = moment(meeting?.start).format('lll');

  const endDateValue = moment(meeting?.end).format('lll');
  function toggleExpanded() {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setExpanded(!expanded);
  }

  function objToArray(obj: any) {
    const emailsArr: {
      id: string;
      email: string;
      status: VisitAuthorizationStatusType;
    }[] = [];
    Object.keys(obj).forEach((key) =>
      emailsArr.push({
        id: key,
        email: obj[key]?.email,
        status: VisitAuthorizationStatusType.AWAITING,
      })
    );
    return emailsArr;
  }

  function hideModal() {
    hideModalCallback(false);
  }

  function showModal() {
    hideModalCallback(true);
  }

  function handleBack() {
    navigation.goBack();
  }

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        navigation.navigate(AppRoute?.MEETINGS);
        return true;
      };

      const BackHandlerListener = BackHandler?.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandlerListener?.remove();
    }, [])
  );

  return {
    locale,
    visits,
    loading,
    hostName: host?.name,
    menuModalVisible,
    hideModal,
    meeting,
    expanded,
    toggleExpanded,
    showModal,
    handleBack,
    headerTitle,
    subjectTitle,
    startDateTitle,
    endDateTitle,
    editMeetingHostTitle,
    editMeetingLocationTitle,
    editMeetingNotesTitle,
    editMeetingDescriptionTitle,
    meetingHideInfo,
    meetingShoweInfo,
    meetingGuestsTitle,
    meetingCoHostsTitle,
    isThereAnyGuest,
    guestsData,
    isThereAnyCoHost,
    coHostsData,
    startDateValue,
    endDateValue,
    language: i18n?.language,
  };
}
