import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Divider, Spin, Text } from '_/components';
import Button from '_/components/Button';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import Input from '_/components/Input';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { AppRoute, SigninProps } from '_/routes/types';
import { signInRequest, signInWithGoogleRequest } from '_/store/modules/auth/actions';
import * as AuthSession from 'expo-auth-session/providers/google';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Image,
  Platform,
  TextInput,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  StyleSheet,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import SigninTerms from './SigninTerms';

export default function Signin() {
  const navigation = useNavigation<SigninProps>();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const reduxEmail = useSelector((state: any) => state?.auth?.email);

  const [email, setEmail] = useState(reduxEmail || '');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { loading } = useSelector((state: any) => state.loading);

  const [request, response, promptAsync] = AuthSession.useIdTokenAuthRequest({
    clientId: appConfig.googleLoginConfig.googleClientId,
    androidClientId: appConfig.googleLoginConfig.androidClientId,
    iosClientId: appConfig.googleLoginConfig.iosClientId,
  });

  useEffect(() => {
    if (response?.type === 'success' && response.authentication?.accessToken) {
      signInWithGoogleAsync(response.authentication?.accessToken);
    }
  }, [response]);

  const passwordRef = useRef<TextInput>(null);

  function togglePasswordVisibility() {
    setPasswordVisible(!passwordVisible);
  }

  async function handleSubmitLogin() {
    const formattedEmail = email.trim().toLowerCase();
    dispatch(signInRequest({ email: formattedEmail, password }));
  }

  async function signInWithGoogleAsync(token: string) {
    dispatch(signInWithGoogleRequest(token));
  }

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <Spin visible={loading} />
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.content}>
            <KeyboardAvoidingView
              enabled
              style={styles.formContainer}
              behavior={Platform.OS === 'ios' ? 'position' : undefined}
            >
              <Image style={styles.logo} source={themes.images.logo} resizeMode="contain" />

              <View style={styles.imageContainer}>
                <Image
                  resizeMode="contain"
                  source={themes.images.loginImage}
                  style={styles.loginImage}
                />
              </View>

              <Input
                label={translate('signin.email')}
                returnKeyType="next"
                autoCapitalize="none"
                autoCompleteType="email"
                keyboardType="email-address"
                textContentType="emailAddress"
                placeholder="Email"
                value={email}
                autoCorrect={false}
                style={styles.formInput}
                onChangeText={(text) => setEmail(text)}
                onSubmitEditing={() => passwordRef.current?.focus()}
                icon="at-sign"
              />

              <Input
                label={translate('signin.password')}
                returnKeyType="go"
                autoCapitalize="none"
                textContentType="password"
                underlineColorAndroid="transparent"
                placeholder="******"
                value={password}
                ref={passwordRef}
                autoCorrect={false}
                style={styles.formInput}
                onSubmitEditing={handleSubmitLogin}
                secureTextEntry={!passwordVisible}
                onChangeText={(text) => setPassword(text)}
                extraContentRight={
                  <TouchableOpacity
                    activeOpacity={0.8}
                    onPress={() => togglePasswordVisibility()}
                    hitSlop={{ top: 30, left: 30, bottom: 30, right: 30 }}
                  >
                    <Feather style={styles.iconsInput} name={passwordVisible ? 'eye' : 'eye-off'} />
                  </TouchableOpacity>
                }
              />

              <TouchableOpacity
                style={styles.btnForgotPassword}
                onPress={() =>
                  navigation.navigate(AppRoute.FORGOT_PASSWORD, {
                    defaultEmail: reduxEmail,
                  })
                }
              >
                <Text uppercase={false} style={styles.btnForgotPasswordText}>
                  {translate('signin.forgotPassword')}
                </Text>
              </TouchableOpacity>
            </KeyboardAvoidingView>
            <Button leftIcon="mail" leftIconStyles={styles.iconMail} onPress={handleSubmitLogin}>
              <Text uppercase={false} style={[styles.btnText, styles.btnMailText]}>
                {translate('signin.signInEmail')}
              </Text>
            </Button>
            <TouchableOpacity
              style={styles.btnSignUp}
              onPress={() => navigation.navigate(AppRoute.SIGNOUT)}
              hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
            >
              <Text uppercase={false} style={styles.btnSignUpText}>
                {translate('signin.newUser')}{' '}
                <Text style={[styles.btnSignUpText, { fontFamily: fontFamily.medium }]}>
                  {translate('signin.signUp')}
                </Text>
              </Text>
            </TouchableOpacity>
            <Divider containerStyles={{ marginVertical: 24 }}>{translate('signin.or')}</Divider>
            <Button
              disabled={!request}
              outline
              onPress={() => promptAsync()}
              buttonStyles={[styles.btnGoogle]}
            >
              <Image
                resizeMode="contain"
                source={themes.images.googleIcon}
                style={{ width: 18, height: 18 }}
              />
              <Text uppercase={false} style={[styles.btnText, styles.btnGoogleText]}>
                {translate('signin.signInGoogle')}
              </Text>
            </Button>

            <SigninTerms />
          </View>
        </ScrollView>
      </View>
    </ContainerWidthLimit>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 24,
  },
  imageContainer: {
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 32,
  },
  logo: {
    alignSelf: 'center',
    width: 120,
    height: 32,
    marginVertical: 24,
  },
  loginImage: {
    width: '100%',
    height: 110,
  },
  formContainer: {
    flexDirection: 'column',
    marginBottom: 24,
  },

  formInput: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 16,
    paddingVertical: 8,
  },

  btnGoogle: {
    borderColor: themes.colors.lightSecondary,
    backgroundColor: themes.colors.white,
  },
  btnForgotPassword: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  btnForgotPasswordText: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.medium,
    fontSize: 12,
  },
  btnText: {
    textAlign: 'center',
    fontFamily: fontFamily.medium,
    flex: 1,
    fontSize: 18,
  },
  btnSignUp: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    marginTop: 24,
    borderWidth: 1,
    borderColor: themes.colors.lightSecondary,
    borderRadius: 4,
  },
  btnSignUpText: {
    color: themes.colors.darkSecondary,
    alignSelf: 'center',
    fontFamily: fontFamily.light,
    fontSize: 18,
  },
  iconMail: {
    fontSize: 18,
    color: themes.colors.white,
  },
  iconsInput: {
    fontSize: 18,
    color: themes.colors.lightSecondary,
  },
  btnGoogleText: {
    color: themes.colors.darkSecondary,
  },
  btnMailText: {
    color: themes.colors.white,
  },
});
