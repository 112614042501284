import { appConfig } from '_/config/app';

import countryCodes from './countryCodes';

const defaultCountryInfo = {
  code: appConfig.phone.defaultCountryCode || 'BR',
  name: appConfig.phone.defaultCountryName || 'Brazil',
  dial: appConfig.phone.defaultCountryDial || '+55',
};

const getCountryInfoFromCountryName = (countryName?: string) => {
  const filtered = countryCodes.filter((count) => count.name === countryName);
  if (filtered.length > 0) {
    return filtered[0];
  }
  return defaultCountryInfo;
};

const getCountryInfoFromCountryDial = (countryDial?: string) => {
  const filtered = countryCodes.filter(
    (count) => count?.dial?.replace('+', '') === countryDial?.replace('+', '')
  );
  if (filtered.length > 0) {
    return filtered[0];
  }
  return defaultCountryInfo;
};

export { defaultCountryInfo };
export { getCountryInfoFromCountryName, getCountryInfoFromCountryDial };
