import { Feather } from '@expo/vector-icons';
import { RouteProp, useNavigation } from '@react-navigation/native';
import { Spin, Text } from '_/components';
import Button from '_/components/Button';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import Input from '_/components/Input';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { AppRoute, ForgotPasswordRouteProps } from '_/routes/types';
import { forgotPasswordRequest } from '_/store/modules/auth/actions';
import Constants from 'expo-constants';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Image,
  Platform,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  StyleSheet,
  Alert,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

export type ForgotPasswordProps = {
  route: RouteProp<any, any>;
};

export default function ForgotPassword({ route }: ForgotPasswordProps) {
  const navigation = useNavigation<ForgotPasswordRouteProps>();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const { defaultEmail = '' } = route.params as { defaultEmail: string };
  const { loading } = useSelector((state: any) => state.loading);
  const [email, setEmail] = useState(defaultEmail);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  async function handleSubmit() {
    dispatch(forgotPasswordRequest(email, setIsAlertVisible));
  }

  useEffect(() => {
    if (isAlertVisible) {
      Alert.alert(
        translate('notifications.AlertPasswordRecover.title'),
        translate('notifications.AlertPasswordRecover.content'),
        [
          {
            text: translate('general.cancel'),
            onPress: () => {
              setIsAlertVisible(!isAlertVisible);
            },
            style: 'cancel',
          },
          {
            text: translate('general.register'),
            onPress: () => {
              setIsAlertVisible(!isAlertVisible);
              navigation.navigate(AppRoute.SIGNOUT);
            },
          },
        ]
      );
    }
  }, [isAlertVisible]);

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <Spin visible={loading} />
        <KeyboardAvoidingView
          enabled
          style={{ flex: 1 }}
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        >
          <View style={styles.content}>
            <ScrollView keyboardShouldPersistTaps="handled" showsVerticalScrollIndicator={false}>
              <View style={styles.header}>
                <TouchableOpacity onPress={() => navigation.navigate(AppRoute.SIGNIN)}>
                  <Feather name="arrow-left" size={24} />
                </TouchableOpacity>
              </View>

              <View style={styles.middleContainer}>
                <View style={styles.imageContainer}>
                  <Image
                    style={styles.image}
                    source={themes.images.forgotPassword}
                    resizeMode="contain"
                  />
                </View>
                <Text fontType="medium" style={styles.title}>
                  {translate('forgotPassword.title')}
                </Text>
                <Text style={styles.description}>{translate('forgotPassword.instructions')}</Text>
              </View>

              <Input
                label={translate('signin.email')}
                returnKeyType="next"
                autoCapitalize="none"
                autoCompleteType="email"
                keyboardType="email-address"
                textContentType="emailAddress"
                value={email}
                autoCorrect={false}
                style={styles.formInput}
                onSubmitEditing={handleSubmit}
                onChangeText={(text) => setEmail(text)}
                icon="at-sign"
              />

              <Button onPress={handleSubmit} disabled={email.length === 0}>
                <Text uppercase={false} style={styles.btnSendText}>
                  {translate('forgotPassword.send')}
                </Text>
              </Button>
            </ScrollView>
          </View>
        </KeyboardAvoidingView>
      </View>
    </ContainerWidthLimit>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 24,
    paddingTop: Platform.OS === 'ios' ? Constants.statusBarHeight : 24,
  },
  header: {
    marginBottom: 12,
  },
  middleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 40,
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    width: '100%',
    height: 250,
  },
  title: {
    color: themes.colors.darkSecondary,
    textAlign: 'center',
    fontSize: 24,
    marginVertical: 16,
  },
  description: {
    color: themes.colors.darkQuaternary,
    textAlign: 'center',
    fontFamily: fontFamily.light,
    fontSize: 14,
    marginVertical: 8,
  },
  formInput: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 14,
    paddingVertical: 8,
  },
  btnSendText: {
    fontFamily: fontFamily.medium,
    fontSize: 18,
    color: themes.colors.white,
  },
});
