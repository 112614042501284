import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    paddingVertical: 12,
  },
  statusContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  dataContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1,
    marginLeft: 12,
  },
  name: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    lineHeight: 16,
  },
  primaryKey: {
    color: themes.colors.darkQuaternary,
    fontSize: 12,
    lineHeight: 14,
  },
  icon: {
    fontSize: 25,
    color: themes.colors.lightPrimary,
  },
  iconCanceled: {
    fontSize: 25,
    color: themes.colors.danger,
  },
});
