import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/w3lcome.theme';
import { languages } from '_/helpers/languages';
import { useLanguage } from '_/hooks/LanguageContext';
import { Lang } from '_/models/enums';
import { userApi } from '_/services/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View, Image, StyleSheet, ImageSourcePropType } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import Modal from 'react-native-modal';
import { useSelector } from 'react-redux';

const SwitchLanguage: React.FC = () => {
  const id = useSelector((state: any) => state.user.id);
  const [visible, setVisible] = useState(false);
  const { t: translate, i18n } = useTranslation();
  const { changeLanguage } = useLanguage();

  async function handleSwitchLanguage(lang: string) {
    changeLanguage(lang);
    try {
      await userApi.updateUser({ id, lang: lang as Lang });
      showMessage({
        message: translate('userSaga.updateUserProfileSuccess'),
        type: 'success',
      });
    } catch (error) {
      showMessage({
        message: translate('userSaga.updateUserProfileError'),
        type: 'danger',
      });
    }
    handleCloseModal();
  }

  function handleCloseModal() {
    setVisible(false);
  }

  const currentlang = languages.find((e) => e.code === i18n.language);

  return (
    <>
      <Modal
        isVisible={visible}
        onBackdropPress={handleCloseModal}
        onBackButtonPress={handleCloseModal}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalTitle}>{translate('profile.selectLanguage')}</Text>
          <View>
            {languages.map(({ lang, code, flag, key }) => (
              <TouchableOpacity
                key={key}
                style={styles.optionsButton}
                onPress={() => handleSwitchLanguage(code)}
              >
                {renderFlag(flag)}
                <Text
                  style={[
                    styles.optionsText,
                    i18n.language.startsWith(code.padStart(2)) && styles.selectedOption,
                  ]}
                >
                  {lang}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </Modal>

      <TouchableOpacity delayPressIn={50} onPress={() => setVisible(true)}>
        <View style={styles.inputGroup}>
          <View style={styles.inputGroupContent}>
            <Text style={styles.menuTitle}>{translate('profile.selectLanguage')}</Text>
            <Text style={styles.menuSubTitle}>
              {translate('profile.selectLanguageDescription')}
            </Text>
          </View>
          {renderFlag(currentlang?.flag)}
        </View>
      </TouchableOpacity>
    </>
  );
};

function renderFlag(flag: ImageSourcePropType) {
  return <Image style={styles.flag} source={flag} />;
}

export { renderFlag };

export default SwitchLanguage;

const styles = StyleSheet.create({
  modalContainer: {
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: themes.colors.white,
    maxWidth: '80%',
    width: 600,
    alignSelf: 'center',
    paddingVertical: 12,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 12,
    textAlign: 'center',
  },
  selectedOption: {
    fontWeight: 'bold',
  },
  optionsButton: {
    flexDirection: 'row',
    padding: 8,
    alignItems: 'center',
    marginVertical: 6,
  },
  flag: {
    width: 32,
    height: 32,
    marginRight: 12,
  },
  optionsText: {
    fontSize: 16,
  },
  inputGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: themes.colors.lightSecondary,
    justifyContent: 'space-between',
    marginBottom: 8,
    paddingVertical: 8,
    borderBottomWidth: 0.3,
  },
  inputGroupContent: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 12,
  },
  menuTitle: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginBottom: 4,
  },
  menuSubTitle: {
    color: themes.colors.grayPrimary,
    fontSize: 12,
    marginBottom: 8,
  },
});
