import { useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { getDateLocale } from '_/helpers/getDateLocale';
import { DeleteAccountProps } from '_/routes/types';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { Header } from '../../components';

export default function DeleteAccount() {
  const navigation = useNavigation<DeleteAccountProps>();

  const user = useSelector((state: any) => state.user);
  const { t: translate, i18n } = useTranslation();
  const locale = getDateLocale(i18n.language);

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <Header
          handleBack={() => navigation.goBack()}
          title={translate('profile.deleteAccountTitle')}
        />

        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
          <View style={styles.content}>
            <Text style={styles.h1}>{translate('deleteAccount.accountInfo')}</Text>
            <View style={styles.info}>
              <Text style={styles.bold}>{translate('deleteAccount.fullName')}</Text>
              <Text style={styles.infoText}>{user.name}</Text>
            </View>

            <View style={styles.info}>
              <Text style={styles.bold}>{translate('deleteAccount.email')}</Text>
              <Text style={styles.infoText}>{user.email}</Text>
            </View>

            <View style={styles.info}>
              <Text style={styles.bold}>{translate('deleteAccount.createdAt')}</Text>
              <Text style={styles.infoText}>
                {!!user.createdAt && format(new Date(user.createdAt), 'P', { locale })}
              </Text>
            </View>
          </View>
        </ScrollView>
      </View>
    </ContainerWidthLimit>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.lightQuaternary,
  },
  scrollView: {
    flex: 1,
  },
  content: {
    alignItems: 'center',
    margin: 24,
    padding: 24,
    borderRadius: 12,
    backgroundColor: themes.colors.white,
  },
  h1: {
    fontFamily: fontFamily.bold,
    fontSize: 16,
    padding: 8,
  },
  info: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    paddingVertical: 8,
  },
  bold: {
    fontFamily: fontFamily.medium,
  },
  infoText: {
    fontFamily: fontFamily.light,
  },
});
