import { Feather } from '@expo/vector-icons';
import { Spin, Text } from '_/components';
import Button from '_/components/Button';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { usePushNotificationContext } from '_/hooks/PushNotificationProvider';
import { signOut } from '_/store/modules/auth/actions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';

export default function CompanyConfirmation() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: any) => state.loading);
  const { removeTokenFromDatabase } = usePushNotificationContext();
  const { t: translate } = useTranslation();

  function handleCheckUserCompany() {
    // TODO: Essa função não existe
    // dispatch(checkCompanyRequest());
  }

  async function handleLogout() {
    await removeTokenFromDatabase();
    dispatch(signOut());
  }

  return (
    <View style={styles.container}>
      <Spin visible={loading} />

      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.topContainer}>
          <View style={styles.btnLogout}>
            <Feather name="log-out" style={styles.icon} />
            <Text uppercase={false} style={{ marginLeft: 5, opacity: 0 }}>
              {translate('companyConfirmation.logout')}
            </Text>
          </View>

          <Image style={styles.logo} source={themes.images.logo} resizeMode="contain" />

          <TouchableOpacity style={styles.btnLogout} onPress={handleLogout}>
            <Feather name="log-out" size={18} />
            <Text uppercase={false} style={styles.btnLogoutText}>
              {translate('companyConfirmation.logout')}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={styles.middleContainer}>
          <View style={styles.imageContainer}>
            <Image style={styles.image} source={themes.images.noCompany} resizeMode="contain" />
          </View>
          <Text fontType="medium" style={styles.title}>
            {translate('companyConfirmation.title')}
          </Text>
          <Text style={styles.description}>{translate('companyConfirmation.registerCompany')}</Text>
        </View>

        <Button buttonStyles={styles.btnCompany} onPress={handleCheckUserCompany}>
          <Text uppercase={false}>{translate('companyConfirmation.alreadyCreated')}</Text>
        </Button>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
  },
  topContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  middleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 80,
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    width: '100%',
    height: 250,
  },
  title: {
    color: themes.colors.darkSecondary,
    textAlign: 'center',
    fontSize: 24,
    marginVertical: 16,
  },
  description: {
    color: themes.colors.darkQuaternary,
    textAlign: 'center',
    fontFamily: fontFamily.light,
    fontSize: 14,
    marginVertical: 8,
  },
  btnLogout: {
    color: themes.colors.darkQuaternary,
    alignItems: 'center',
    flexDirection: 'row',
  },
  btnLogoutText: {
    color: themes.colors.darkQuaternary,
    fontSize: 18,
    marginLeft: 5,
  },
  btnCompany: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: 22,
    width: 150,
  },
  icon: {
    color: themes.colors.white,
    fontSize: 18,
  },
});
