import { useFocusEffect } from '@react-navigation/native';
import { PushNotificationsModal } from '_/components';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import { InviteVisitModel } from '_/interfaces/InviteVisitModel';
import { updatePersonalActiveInvites } from '_/store/modules/personal/actions';
import React, { useState } from 'react';
import { View, FlatList, RefreshControl, ActivityIndicator, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { invitesApi } from '../../../services/api';
import EmptyInvites from '../EmptyCard/index';
import { InviteCard } from '../InviteCard';

interface ActiveInvitesProps {
  refreshing: boolean;
  onRefresh: () => void;
}

export default function ActiveInvites({ refreshing, onRefresh }: ActiveInvitesProps) {
  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state.user.id);
  const { activeInvites } = useSelector((state: any) => state.personal);
  const loading = useSelector((state: any) => state.loading.loading);
  const [loadingPersonalInvites, setLoadingPersonalInvites] = useState(true);

  async function loadPersonalActiveInvites() {
    try {
      const activeInvites = await invitesApi.getActives();

      setLoadingPersonalInvites(false);
      dispatch(updatePersonalActiveInvites(activeInvites));
    } catch (error) {
      setLoadingPersonalInvites(false);
      logSentryException({
        error,
        file: 'ActiveInvite.tsx',
        message: 'Error at loadPersonalActiveInvites function',
      });
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      if (!userId) return;
      loadPersonalActiveInvites();
    }, [userId])
  );

  function renderItems(invite: InviteVisitModel) {
    if (!invite) return null;

    return <InviteCard invite={invite} />;
  }

  if (loading || loadingPersonalInvites) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size="large" color={themes.colors.primary} />
      </View>
    );
  }

  return (
    <>
      {loading || loadingPersonalInvites ? null : (
        <FlatList
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                loadPersonalActiveInvites();
                onRefresh();
              }}
            />
          }
          data={activeInvites}
          extraData={activeInvites}
          style={styles.content}
          keyExtractor={(item) => String(item.id)}
          contentContainerStyle={{ paddingTop: 20, paddingBottom: 80 }}
          renderItem={({ item: invite }) => renderItems(invite)}
          ListEmptyComponent={<EmptyInvites />}
        />
      )}
      {!loading && !loadingPersonalInvites && <PushNotificationsModal />}
    </>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: themes.colors.white,
  },
});
