import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';

interface ExternalGuestVisit {
  id: string;
  email: string;
  status: string;
}

interface ExternalGuestsProps {
  visits: ExternalGuestVisit[];
}

export default function ExternalGuests({ visits }: ExternalGuestsProps) {
  const { t: translate } = useTranslation();

  return (
    <View>
      {!!visits.length && <Text style={styles.title}>{translate('inviteesEmail.title')}</Text>}
      {visits.map((visit) => (
        <Text key={visit.id} style={styles.value}>
          {visit.email}
        </Text>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    fontSize: 12,
  },
  value: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginTop: 4,
    marginBottom: 8,
  },
});
