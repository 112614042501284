import { HostsModel, VisitsModel, HostZonesModel } from '@w3lcome/types';
import { AxiosResponse } from 'axios';

import api from './base';

export interface QRCodesApiResponse {
  data: Partial<HostsModel> | Partial<VisitsModel>;
  isZoneAuthorized: boolean | null;
  allowedZones?: Partial<HostZonesModel>[];
}
class QRCodesApi {
  public create = async (qrcode: string): Promise<AxiosResponse<QRCodesApiResponse>> => {
    const data = await api.post<QRCodesApiResponse>('/qrcodes', { qrcode });

    return data;
  };
}

export default new QRCodesApi();
