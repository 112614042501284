import { useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import Button from '_/components/Button';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { AppRoute, PasswordSentProps } from '_/routes/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, StyleSheet } from 'react-native';

export default function PasswordSent() {
  const navigation = useNavigation<PasswordSentProps>();
  const { t: translate } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.middleContainer}>
        <View style={styles.imageContainer}>
          <Image style={styles.image} source={themes.images.emailSent} resizeMode="contain" />
        </View>
        <Text fontType="medium" style={styles.title}>
          {translate('passwordSent.title')}
        </Text>
        <Text style={styles.description}>{translate('passwordSent.instructions')}</Text>
      </View>

      <Button buttonStyles={styles.btnOk} onPress={() => navigation.navigate(AppRoute.SIGNIN)}>
        <Text uppercase={false} style={styles.btnOKText}>
          {translate('general.ok')}
        </Text>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    flex: 1,
    padding: 24,
  },
  middleContainer: {
    marginBottom: 56,
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    width: '100%',
    height: 250,
  },
  title: {
    color: themes.colors.darkSecondary,
    textAlign: 'center',
    fontSize: 24,
    marginVertical: 16,
  },
  description: {
    color: themes.colors.darkQuaternary,
    textAlign: 'center',
    fontFamily: fontFamily.light,
    fontSize: 14,
    marginVertical: 8,
  },
  btnOk: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnOKText: {
    fontFamily: fontFamily.medium,
    fontSize: 18,
    color: themes.colors.white,
  },
});
