import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { getDateLocale } from '_/helpers/getDateLocale';
import { InviteVisitModel } from '_/interfaces/InviteVisitModel';
import { AppRoute, PersonalInviteRouteProps } from '_/routes/types';
import { format, isBefore } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';

interface InviteCardProps {
  invite: InviteVisitModel;
}

export function InviteCard({ invite }: InviteCardProps) {
  const { t: translate, i18n } = useTranslation();
  const locale = getDateLocale(i18n.language);
  const navigation = useNavigation<PersonalInviteRouteProps>();
  let backgroundColor = themes.colors.defaultBlue;
  // if (invite.status === 'canceled') {
  //   backgroundColor = themes.colors.danger;
  // }
  const showTag = useMemo(() => {
    if (invite) {
      return (
        !!invite.customization?.timeToCheckinBeforeMeetingEnabled &&
        !!invite.meeting?.start &&
        isBefore(new Date(), invite.meeting?.start)
      );
    }
    return false;
  }, [invite]);

  if (invite.checkinAt) {
    backgroundColor = themes.colors.success;
  }

  function getStatus(visit: InviteVisitModel) {
    if (visit.checkinAt) {
      return 'arrived';
    }

    if (visit.authorizationStatus === 'DENIED') {
      return 'canceled';
    }

    if (visit.expectedAt) {
      return 'waitingCheckin';
    }

    return 'none';
  }

  return (
    <TouchableOpacity
      delayPressIn={20}
      onPress={() =>
        navigation.navigate(AppRoute.PERSONAL_INVITE, {
          id: invite.id,
          backToInvites: true,
        })
      }
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.leftContainer}>
            {!!invite.meeting?.title && (
              <View style={styles.titleContainer}>
                <Text style={styles.title}>{invite.meeting.title}</Text>
              </View>
            )}
            <View style={styles.infoContainer}>
              {(!!invite.meeting?.location || !!invite.meeting?.meetingLocation?.name) && (
                <View style={styles.row}>
                  <Feather name="map-pin" style={styles.icon} />
                  <Text style={styles.location}>
                    {invite.meeting.location || invite.meeting?.meetingLocation?.name}
                  </Text>
                </View>
              )}

              {!!invite.name && (
                <View style={styles.row}>
                  <Feather name="user" style={styles.icon} />
                  <Text style={styles.name}>{invite.name}</Text>
                </View>
              )}
            </View>
          </View>
          <View style={styles.rightContainer}>
            <View style={styles.innerRightContainer}>
              <Image
                resizeMode="contain"
                source={themes.images.qrcodeIcon}
                style={{ width: 24, height: 24 }}
              />
              {!!invite.meeting?.start && (
                <Text style={styles.time}>
                  {format(new Date(invite.meeting?.start), 'HH:mm', { locale })}
                </Text>
              )}
              {!!invite.expectedAt && (
                <Text style={styles.expectedDate}>
                  {format(new Date(invite.expectedAt), 'P', { locale })}
                </Text>
              )}
            </View>
            {showTag && (
              <View style={[styles.statusTag, styles[getStatus(invite)]]}>
                <Text style={styles.statusText}>
                  {translate(`personalInvites.${getStatus(invite)}`)}
                </Text>
              </View>
            )}
            {!!invite.authorizationStatus && !invite.isCanceled && (
              <View style={[styles.statusContainer, { backgroundColor }]}>
                <Text style={styles.status}>
                  {translate(`personalInvites.${invite.authorizationStatus}`)}
                </Text>
              </View>
            )}
            {invite.isCanceled && (
              <View style={[styles.statusContainer, { backgroundColor: themes.colors.danger }]}>
                <Text style={styles.status}>{translate('visitor.canceledVisit')}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
  },
  content: {
    flexDirection: 'row',
    borderRadius: 6,
    marginVertical: 8,
    paddingVertical: 6,
    paddingRight: 6,
    paddingLeft: 16,
    backgroundColor: themes.colors.white,
    borderColor: themes.colors.lightPrimary,
    borderWidth: 1,
  },
  leftContainer: {
    justifyContent: 'center',
    flex: 1,
    marginRight: 16,
  },
  rightContainer: {
    overflow: 'hidden',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: themes.colors.lightPrimary,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 8,
  },
  infoContainer: {
    paddingTop: 12,
  },
  titleContainer: {
    paddingBottom: 8,
    borderBottomWidth: 1,
    borderBottomColor: themes.colors.lightPrimary,
  },
  title: {
    fontFamily: fontFamily.medium,
    fontSize: 14,
  },
  name: {
    fontFamily: fontFamily.light,
    fontSize: 13,
  },
  location: {
    fontFamily: fontFamily.light,
    fontSize: 13,
  },
  time: {
    fontFamily: fontFamily.bold,
    fontSize: 13,
    paddingTop: 5,
  },
  expectedDate: {
    fontFamily: fontFamily.medium,
    fontSize: 13,
  },
  status: {
    fontFamily: fontFamily.medium,
    fontSize: 13,
    color: themes.colors.white,
  },
  icon: {
    fontSize: 13,
    marginRight: 5,
    color: themes.colors.darkPrimary,
  },
  innerRightContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 92,
    padding: 10,
  },
  statusContainer: {
    alignSelf: 'stretch',
    alignItems: 'center',
    borderTopWidth: 1,
    paddingVertical: 4,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopColor: themes.colors.lightPrimary,
  },
  statusTag: {
    padding: 4,
    marginHorizontal: 6,
    marginBottom: 6,
    borderRadius: 4,
  },
  // eslint-disable-next-line
  waitingCheckin: {
    backgroundColor: themes.colors.defaultBlue,
  },
  // eslint-disable-next-line
  canceled: {
    backgroundColor: themes.colors.danger,
  },
  // eslint-disable-next-line
  arrived: {
    backgroundColor: themes.colors.success,
  },
  // eslint-disable-next-line
  none: {},
  statusText: {
    color: themes.colors.lightPrimary,
    fontSize: 12,
    fontWeight: 'bold',
  },
});
