import { usePushNotificationContext } from '_/hooks/PushNotificationProvider';
import React from 'react';

import DeniedNotificationsModal from './DeniedNotificationsModal';
import NotificationsCheckModal from './NotificationsCheckModal';

export default function PushNotificationsModal() {
  const {
    visibleNotificationsCheckModal,
    setVisibleNotificationsCheckModal,
    visibleDeniedNotificationsModal,
    setVisibleDeniedNotificationsModal,
  } = usePushNotificationContext();

  return (
    <>
      {visibleNotificationsCheckModal && (
        <NotificationsCheckModal
          visible={visibleNotificationsCheckModal}
          hideModal={() => setVisibleNotificationsCheckModal(false)}
        />
      )}
      {visibleDeniedNotificationsModal && (
        <DeniedNotificationsModal
          visible={visibleDeniedNotificationsModal}
          hideModal={() => setVisibleDeniedNotificationsModal(false)}
        />
      )}
    </>
  );
}
