import { Feather } from '@expo/vector-icons';
import { VisitAuthorizationStatusType, DeliveryPickupStatusType } from '@w3lcome/types';
import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, ViewStyle, StyleProp } from 'react-native';

import Text from '../Text';

interface StatusViewProps {
  name?: string;
  shadow?: boolean;
  rounded?: boolean;
  status?: VisitAuthorizationStatusType | DeliveryPickupStatusType | string;
  containerStyles?: StyleProp<ViewStyle>;
  type?: 'visit' | 'delivery' | 'invitee';
  isVisitCanceled?: boolean;
}

export default function StatusView({
  containerStyles,
  type,
  status,
  name,
  rounded = false,
  shadow = false,
  isVisitCanceled,
}: StatusViewProps) {
  const { t: translate } = useTranslation();

  if (!isVisitCanceled) {
    if (!type || !status) {
      return null;
    }
  }

  const isVisitCanceledStatus = isVisitCanceled || status === 'canceled';

  let backgroundColor = themes.colors.warning;
  switch (status) {
    case VisitAuthorizationStatusType.ALLOWED:
    case DeliveryPickupStatusType.PICKEDUP:
      backgroundColor = themes.colors.success;
      break;
    case VisitAuthorizationStatusType.DENIED:
    case DeliveryPickupStatusType.PROBLEM:
      backgroundColor = themes.colors.danger;
      break;
  }

  if (isVisitCanceledStatus) {
    backgroundColor = transparentize(0.9, themes.colors.danger);
  }

  function renderStatus() {
    if (isVisitCanceledStatus) {
      return (
        <>
          <Feather name="slash" size={14} style={[styles.icon, { color: themes.colors.danger }]} />
          <Text style={[styles.statusText, { color: themes.colors.danger }]} fontType="medium">
            {translate('visitor.canceledVisit')}
          </Text>
        </>
      );
    }

    if (type === 'visit') {
      return (
        <>
          {status === VisitAuthorizationStatusType.ALLOWED && (
            <Feather name="check" style={styles.icon} />
          )}
          {status === VisitAuthorizationStatusType.DENIED && (
            <Feather name="x" style={styles.icon} />
          )}
          {status === VisitAuthorizationStatusType.AWAITING && (
            <Feather name="alert-triangle" style={styles.icon} />
          )}
          <Text style={styles.statusText}>
            {status === VisitAuthorizationStatusType.ALLOWED &&
              translate(`visitModal.${name ? 'allowedBy' : 'allowed'}`, { name })}
            {status === VisitAuthorizationStatusType.DENIED &&
              translate(`visitModal.${name ? 'blockedBy' : 'blocked'}`, { name })}
            {status === VisitAuthorizationStatusType.AWAITING &&
              translate('visitModal.awaitingApproval')}
          </Text>
        </>
      );
    }

    if (type === 'delivery') {
      return (
        <>
          {status === DeliveryPickupStatusType.PICKEDUP && (
            <Feather name="check" style={styles.icon} />
          )}
          {status === DeliveryPickupStatusType.PROBLEM && <Feather name="x" style={styles.icon} />}
          {status === DeliveryPickupStatusType.AWAITING && (
            <Feather name="alert-triangle" style={styles.icon} />
          )}
          <Text style={styles.statusText}>
            {status === DeliveryPickupStatusType.PROBLEM && translate('delivery.problem')}
            {status === DeliveryPickupStatusType.PICKEDUP && translate('delivery.pickedup')}
            {status === DeliveryPickupStatusType.AWAITING && translate('delivery.awaiting')}
          </Text>
        </>
      );
    }

    if (type === 'invitee') {
      return (
        <>
          {status === VisitAuthorizationStatusType.ALLOWED && (
            <Feather name="check" style={styles.icon} />
          )}
          {status === VisitAuthorizationStatusType.DENIED && (
            <Feather name="x" style={styles.icon} />
          )}
          {status === VisitAuthorizationStatusType.AWAITING && (
            <Feather name="alert-triangle" style={styles.icon} />
          )}
          <Text style={styles.statusText}>
            {status === VisitAuthorizationStatusType.ALLOWED &&
              translate(`visitModal.${name ? 'allowedBy' : 'allowed'}`, { name })}
            {status === VisitAuthorizationStatusType.DENIED &&
              translate(`visitModal.${name ? 'blockedBy' : 'blocked'}`, { name })}
            {status === VisitAuthorizationStatusType.AWAITING &&
              translate('visitModal.awaitingApproval')}
            {status === 'expired' && translate('inviteeModal.expired')}
          </Text>
        </>
      );
    }

    return null;
  }

  return (
    <View
      style={[
        styles.status,
        { backgroundColor },
        shadow && !isVisitCanceledStatus && styles.shadow,
        rounded && { borderRadius: 6, marginHorizontal: 24 },
        containerStyles,
      ]}
    >
      {renderStatus()}
    </View>
  );
}

const styles = StyleSheet.create({
  status: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: themes.colors.primary,
    height: 32,
  },
  statusText: {
    color: themes.colors.white,
    fontSize: 14,
    marginLeft: 8,
  },
  shadow: {
    overflow: 'hidden',
    shadowColor: themes.colors.black,
    marginTop: 8,
    elevation: 5,
    shadowRadius: 8,
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 12 },
  },
  icon: {
    fontSize: 12,
    color: themes.colors.white,
  },
});
