import { VisitZonesAccessModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';

import api from './base';

class VisitZonesAccessApi {
  public getVisitsZonesAccess = async (
    params: any,
    config?: AxiosRequestConfig
  ): Promise<VisitZonesAccessModel[]> => {
    const { data } = await api.get(`/visit-zone-access`, {
      ...config,
      params,
    });

    return data.data;
  };
}

export default new VisitZonesAccessApi();
