import aesjs from 'aes-js';
import { addMinutes } from 'date-fns';

function getUTCTime() {
  const moment = new Date();
  const now = addMinutes(moment, moment.getTimezoneOffset());
  let total = now.getSeconds();
  total += now.getMinutes() * 60;
  total += now.getHours() * 60 * 60;
  total += now.getDate() * 60 * 60 * 24;
  total += (now.getMonth() + 1) * 60 * 60 * 24 * 32;
  total += (now.getFullYear() % 100) * 60 * 60 * 24 * 32 * 12;
  return total;
}

function changeEndianness(str: string) {
  const result: any[] = [];
  let length = str.length - 2;
  while (length >= 0) {
    result.push(str.substring(length, length + 2));
    length -= 2;
  }
  return result.join('');
}

function qrCodeV1(key: string, cardNumber: number) {
  const fixedBytes = '4CC71911';
  const time = changeEndianness(getUTCTime().toString(16));
  const card = changeEndianness(cardNumber.toString(16).padStart(16, '0'));
  const code = fixedBytes + time + card;
  const codeBytes = aesjs.utils.hex.toBytes(code);
  const keyBytes = aesjs.utils.hex.toBytes(key);
  const aesEcb = new aesjs.ModeOfOperation.ecb(keyBytes); // eslint-disable-line
  const encryptedBytes = aesEcb.encrypt(codeBytes);
  const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

  return encryptedHex;
}

export { qrCodeV1, getUTCTime, changeEndianness };
