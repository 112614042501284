import produce from 'immer';

import { PersonalState } from './types';

const INITIAL_STATE: PersonalState = {
  activeInvites: null,
  pastInvites: null,
};

export default function personal(
  state = INITIAL_STATE,
  action: { type: any; payload: { activeInvites: null; pastInvites: null } }
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@personal/UPDATE_PERSONAL_ACTIVE_INVITES': {
        draft.activeInvites = action.payload.activeInvites;
        break;
      }
      case '@personal/RESET_PERSONAL_PAST_INVITES_ARRAY': {
        draft.pastInvites = [];
        break;
      }
      case '@personal/ADD_PERSONAL_PAST_INVITES_ARRAY': {
        draft.pastInvites = action.payload.pastInvites;
        break;
      }
      case '@auth/SIGN_OUT': {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
