import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Text from '../Text';
import styles from './styles';

interface RenderCanceledVisitProps {
  isCanceled: boolean | undefined;
}

const RenderCanceledVisit: React.FC<RenderCanceledVisitProps> = ({ isCanceled }) => {
  const { t } = useTranslation();

  if (isCanceled) {
    return (
      <View style={[styles.warningContainer, { backgroundColor: themes.colors.danger }]}>
        <Text style={styles.warningText}>{t('qrCodeDisabled')}</Text>
      </View>
    );
  }
  return null;
};

export default RenderCanceledVisit;
