import { Feather } from '@expo/vector-icons';
import { colors, fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import RequiredField from '_/screens/EditMeeting/CustomFields/requiredField';
import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import {
  TextInputProps,
  StyleSheet,
  TextInput,
  View,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  StyleProp,
  ViewStyle,
  TextStyle,
  Switch,
} from 'react-native';

import Text from '../Text';

interface InputProps extends TextInputProps {
  label: string;
  icon?: string;
  containerStyle?: StyleProp<ViewStyle>;
  labelSize?: number;
  required?: boolean;
  iconStyles?: StyleProp<TextStyle>;
  extraContentRight?: JSX.Element;
  extraContentLeft?: JSX.Element;
  isSwitch?: boolean;
  checked?: boolean;
  onCheckChange?: (value: boolean) => void;
}

interface InputRef {
  focus(): void;
}

const Input: React.ForwardRefRenderFunction<InputRef, InputProps> = (
  {
    label,
    containerStyle,
    iconStyles,
    labelSize,
    value,
    style,
    icon,
    required,
    extraContentRight,
    extraContentLeft,
    onFocus,
    onBlur,
    isSwitch,
    checked,
    onCheckChange,
    children,
    ...rest
  },
  ref
) => {
  const [isFieldActive, setIsFieldActive] = useState(false);
  const inputElementRef = useRef<TextInput & TextInputProps>(null);

  useImperativeHandle(ref, () => ({
    focus() {
      inputElementRef.current?.focus();
    },
    value: inputElementRef.current?.value,
  }));

  const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setIsFieldActive(true);
    onFocus?.(e);
  };

  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    if (isFieldActive && !value?.trim()) {
      setIsFieldActive(false);
    }
    onBlur?.(e);
  };

  if (isSwitch) {
    return (
      <View style={[styles.container, styles.switchContainer, containerStyle]}>
        <Text style={[styles.label, { flex: 1 }]}>{label}:</Text>
        <Switch
          style={{ marginLeft: 12 }}
          trackColor={{ false: colors.light0, true: colors.primary }}
          thumbColor={checked ? colors.primary : colors.light0}
          value={checked}
          onValueChange={onCheckChange}
        />
      </View>
    );
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.innerContainer}>
        <Text style={styles.label}>{label}</Text>
        {required && <RequiredField />}
      </View>
      <View style={styles.content}>
        {!!extraContentLeft && extraContentLeft}
        <TextInput
          {...rest}
          clearButtonMode="while-editing"
          autoCorrect={rest?.autoCorrect ?? false}
          ref={inputElementRef}
          value={value}
          style={[styles.input, style]}
          underlineColorAndroid="transparent"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        {!!icon && <Feather name={icon as any} style={[styles.icon, iconStyles]} />}

        {!!extraContentRight && extraContentRight}
      </View>
    </View>
  );
};

export default forwardRef(Input);

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    borderBottomColor: themes.colors.lightSecondary,
    marginBottom: 16,
    borderBottomWidth: 1,
  },

  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.medium,
    fontSize: 18,
  },
  icon: {
    fontSize: 18,
    color: themes.colors.lightSecondary,
  },

  input: {
    color: themes.colors.darkSecondary,
    flex: 1,
    fontSize: 16,
    paddingVertical: 8,
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
