import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer } from 'redux-persist';

export default (reducers: any) => {
  const config = {
    key: 'root',
    storage: AsyncStorage,
    whitelist: [
      'auth',
      'user',
      'company',
      'host',
      // 'visit',
      // 'delivery',
      // 'waccess',
      // 'meeting',
    ],
    blacklist: ['loading', 'home', 'notification', 'settings'],
  };

  const persistedReducer = persistReducer(config, reducers);
  return persistedReducer;
};
