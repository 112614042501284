import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Spin, Header, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import Input from '_/components/Input';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { getCountryInfoFromCountryDial } from '_/helpers/formatPhoneNumber';
import { EditProfileProps } from '_/routes/types';
import { updateProfileRequest, updateUserPictureRequest } from '_/store/modules/user/actions';
import Constants from 'expo-constants';
import * as ImageManipulator from 'expo-image-manipulator';
import { SaveFormat } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import { AsYouType } from 'libphonenumber-js';
import { transparentize } from 'polished';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Alert,
  Platform,
  TextInput,
  ScrollView,
  ImageBackground,
  TouchableOpacity,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  StyleSheet,
} from 'react-native';
import CountryPicker from 'react-native-country-picker-modal';
import { useDispatch, useSelector } from 'react-redux';

import DeleteConfirmationModal from '../DeleteAccount/DeleteConfirmationModal';

export default function EditProfile() {
  const navigation = useNavigation<EditProfileProps>();
  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation();

  const user = useSelector((state: any) => state.user);
  const { loading } = useSelector((state: any) => state.loading);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [values, setValues] = useState({
    name: user.name || '',
    email: user.email || '',
    phone: user.phone || '',
    countryCode: user.countryCode || '55',
    countryLetters: user.countryLetters,
    country: user.country || 'BR',
    pictureUrl: user.pictureUrl || '',
    pictureThumbUrl: user.pictureThumbUrl || '',
    position: user.position || '',
  });

  useEffect(() => {
    const { code, dial, name } = getCountryInfoFromCountryDial(user.countryCode);

    setValues({
      ...values,
      countryCode: dial,
      country: name,
      countryLetters: code,
    });
  }, [user]);

  const phoneRef = useRef<TextInput>(null);
  const positionRef = useRef<TextInput>(null);

  useEffect(() => {
    getPermissionAsync();
  }, []);

  function handleSubmit() {
    dispatch(
      updateProfileRequest({
        id: user.id,
        name: values.name,
        email: values.email,
        phone: values.phone,
        countryCode: values.countryCode,
        country: values.country,
        position: values.position,
      })
    );
  }

  function handlePhoneChange(text: string) {
    const tempPhone = new AsYouType(values.countryLetters).input(text);
    setValues({ ...values, phone: tempPhone });
  }

  function handleNavigateBack() {
    navigation.goBack();
  }

  async function handleChangePicture() {
    const result = await ImagePicker.launchImageLibraryAsync({
      quality: 1,
      base64: true,
      aspect: [4, 3],
      allowsEditing: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.cancelled) {
      const resizedPhoto = await ImageManipulator.manipulateAsync(
        result.uri,
        [{ resize: { width: 1024 } }], // resize to width of 1024 and preserve aspect ratio
        { compress: 0.7, format: SaveFormat.JPEG }
      );

      dispatch(
        updateUserPictureRequest({
          id: user.id,
          image: result.base64,
          uri: resizedPhoto.uri,
        })
      );
    }
  }

  async function getPermissionAsync() {
    if (Constants.platform?.ios) {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status !== 'granted') {
        Alert.alert(
          'Permission needed',
          'Sorry, we need camera roll permissions to make this work!'
        );
      }
    }
  }

  function renderImage() {
    return (
      <TouchableWithoutFeedback onPress={handleChangePicture}>
        <View>
          <ImageBackground
            resizeMode="cover"
            style={styles.avatar}
            source={
              user.pictureThumbUrl || user.pictureUrl
                ? { uri: user.pictureThumbUrl || user.pictureUrl }
                : themes.images.avatarSquare
            }
          >
            <View style={styles.avatarOverflow}>
              <Feather name="repeat" style={styles.iconRepeat} />
            </View>
          </ImageBackground>
        </View>
      </TouchableWithoutFeedback>
    );
  }

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <Header handleBack={handleNavigateBack} title={translate('editProfile.title')} />
        <Spin visible={loading} />
        <KeyboardAvoidingView
          enabled
          style={{ flex: 1 }}
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        >
          <View style={styles.content}>
            <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
              <View style={styles.pictureContainer}>
                <View style={styles.avatarBorder}>
                  <View style={styles.loadingView}>
                    <Spin visible overlayColor="transparent" containerColor="transparent" />
                  </View>
                  {renderImage()}
                </View>
                <View style={styles.pictureInfo}>
                  <Text style={styles.fullName}>{values.name}</Text>
                  <Text style={styles.email}>{values.email}</Text>
                </View>
              </View>

              <Input
                label={translate('signup.fullname')}
                returnKeyType="next"
                textContentType="name"
                autoCapitalize="words"
                value={values.name}
                autoCorrect={false}
                style={styles.formInput}
                onChangeText={(name) => setValues({ ...values, name })}
                onSubmitEditing={() => positionRef.current?.focus()}
                icon="type"
                containerStyle={{ marginBottom: 28 }}
              />

              <Input
                label={translate('editProfile.jobTitle')}
                returnKeyType="next"
                textContentType="name"
                autoCapitalize="words"
                value={values.position}
                ref={positionRef}
                autoCorrect={false}
                style={styles.formInput}
                onChangeText={(position) => setValues({ ...values, position })}
                onSubmitEditing={() => phoneRef.current?.focus()}
                icon="briefcase"
                containerStyle={{ marginBottom: 28 }}
              />

              <Input
                label={translate('signup.phone')}
                returnKeyType="next"
                autoCapitalize="none"
                keyboardType="phone-pad"
                textContentType="telephoneNumber"
                ref={phoneRef}
                autoCorrect={false}
                value={values.phone}
                style={styles.formInput}
                onChangeText={(phone) => handlePhoneChange(phone)}
                icon="smartphone"
                extraContentLeft={
                  <>
                    {values.country !== null}
                    <CountryPicker
                      withFlag
                      withFilter
                      withCallingCode
                      translation={i18n.language.startsWith('pt') ? 'por' : 'cym'}
                      countryCode={values.countryLetters}
                      onSelect={(c) => {
                        const { name } = getCountryInfoFromCountryDial(`+${c.callingCode[0]}`);
                        setValues({
                          ...values,
                          country: name,
                          countryLetters: c.cca2,
                          countryCode: `+${c.callingCode[0]}`,
                        });
                      }}
                    />
                  </>
                }
              />

              <TouchableOpacity style={styles.saveButton} onPress={handleSubmit}>
                <Text style={styles.saveButtonText}>{translate('general.save')}</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.deleteButton}
                onPress={() => setDeleteModalVisible(true)}
              >
                <Feather name="trash" style={styles.deleteButtonIcon} />
                <Text style={styles.deleteButtonText}>
                  {translate('deleteAccount.deleteAccount')}
                </Text>
              </TouchableOpacity>
            </ScrollView>

            <DeleteConfirmationModal
              visible={deleteModalVisible}
              hideModal={() => setDeleteModalVisible(false)}
            />
          </View>
        </KeyboardAvoidingView>
      </View>
    </ContainerWidthLimit>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.white,
  },
  content: {
    flex: 1,
    paddingHorizontal: 24,
  },
  pictureContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  pictureInfo: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  fullName: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.bold,
    fontSize: 18,
  },
  email: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    fontSize: 14,
    marginBottom: 24,
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 4,
    overflow: 'hidden',
  },
  avatarOverflow: {
    flex: 1,
    backgroundColor: transparentize(0.3, themes.colors.black),
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarBorder: {
    shadowColor: themes.colors.black,
    backgroundColor: themes.colors.white,
    width: 120,
    height: 120,
    marginTop: 8,
    elevation: 5,
    borderRadius: 6,
    shadowRadius: 8,
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 12 },
  },
  loadingView: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
  },
  formInput: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 14,
    paddingVertical: 8,
  },
  iconRepeat: {
    fontSize: 32,
    color: themes.colors.white,
  },
  saveButton: {
    marginVertical: 8,
    backgroundColor: themes.colors.primary,
    borderRadius: 6,
    alignItems: 'center',
    paddingVertical: 8,
  },
  saveButtonText: {
    color: themes.colors.white,
    fontSize: 16,
  },
  deleteButton: {
    marginVertical: 32,
    alignSelf: 'stretch',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 50,
    borderRadius: 6,
    backgroundColor: themes.colors.danger,
  },
  deleteButtonText: {
    fontFamily: fontFamily.medium,
    fontSize: 16,
    color: themes.colors.white,
  },
  deleteButtonIcon: {
    fontSize: 14,
    marginRight: 6,
    color: themes.colors.white,
  },
});
