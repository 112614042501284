import { produce } from 'immer';

const INITIAL_STATE = {
  meeting: null,
  externalGuests: null,
};

export default function meeting(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@meeting/NEW_MEETING_REQUEST': {
        draft.meeting = null;
        break;
      }
      case '@meeting/CREATE_MEETING_SUCCESS': {
        draft.meeting = action.payload.meeting;
        draft.externalGuests = action.payload.meeting.visits;
        break;
      }
      case '@meeting/UPDATE_MEETING_SUCCESS': {
        draft.meeting = action.payload.meeting;
        draft.externalGuests = action.payload.meeting.visits;
        break;
      }
      case '@meeting/REMOVE_MEETING_SUCCESS': {
        draft.meeting = null;
        draft.externalGuests = null;
        break;
      }
      case '@meeting/GET_MEETING_DETAILS_SUCCESS': {
        draft.meeting = action.payload.meeting;
        draft.externalGuests = action.payload.meeting.visits;
        break;
      }
      case '@meeting/CREATE_LINK_SUCCESS': {
        draft.meeting.newLinks = {
          ...state.meeting.newLinks,
          newLinks: action.payload.newLink,
        };
        break;
      }
      case '@auth/SIGN_OUT': {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
