import { Spin, TopMenu, Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { getDateLocale } from '_/helpers/getDateLocale';
import { format, isBefore } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

export default function PlanExpired() {
  const { trialUntil, planIsActive } = useSelector((state: any) => state.company);
  const companyName = useSelector((state: any) => state.company.name);
  const { loading } = useSelector((state: any) => state.loading);
  const { t: translate, i18n } = useTranslation();
  const locale = getDateLocale(i18n.language);

  function renderView() {
    if (!planIsActive && isBefore(trialUntil, new Date())) {
      return (
        <View style={styles.planContainer}>
          <Text style={styles.title}>{translate('plan.error')}</Text>

          <Text style={styles.expireText}>
            {translate('plan.companyPlan', { companyName })}{' '}
            <Text style={styles.daysRemainingText}>{translate('plan.expired')}</Text>
          </Text>

          <Text style={styles.instructionsText}>
            {translate('plan.instructions', {
              expireDate: format(trialUntil, 'Pp', { locale }),
            })}
          </Text>
        </View>
      );
    }

    return null;
  }

  return (
    <View style={styles.container}>
      <TopMenu />
      <Spin visible={loading} />
      <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
        <View style={styles.content}>{renderView()}</View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.lightQuaternary,
  },
  scrollView: {
    flex: 1,
  },
  content: {
    alignItems: 'center',
    backgroundColor: themes.colors.white,
    margin: 24,
    padding: 24,
    borderRadius: 12,
  },
  planContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: themes.colors.danger,
    textAlign: 'center',
    fontFamily: fontFamily.medium,
    padding: 16,
    fontSize: 20,
  },
  expireText: {
    textAlign: 'center',
    paddingTop: 24,
  },
  instructionsText: {
    textAlign: 'center',
    paddingTop: 24,
    paddingBottom: 40,
    color: themes.colors.darkQuaternary,
    fontSize: 12,
    fontFamily: fontFamily.light,
  },
  daysRemainingText: {
    color: themes.colors.warning,
    fontFamily: fontFamily.bold,
    fontSize: 14,
  },
});
