import { appConfig } from '_/config/app';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Dimensions, ImageBackground } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import QRCode from 'react-native-qrcode-svg';

import { getTOTPQRCodeValue } from '_/helpers/totp';

import Spin from '../Spin';
import Text from '../Text';
import RenderCanceledVisit from './RenderCanceledVisit';
import { qrCodeV1 } from './helpers';
import styles from './styles';

const { width } = Dimensions.get('screen');

interface InvenziQRCodeProps {
  invite: any;
}

export default function InvenziQRCode({ invite }: InvenziQRCodeProps) {
  const [seconds, setSeconds] = useState('-');
  const [loading, setLoading] = useState(false);
  const [errorQRCode, setErrorQRCode] = useState(false);
  const [currentQRCode, setCurrentQRCode] = useState(appConfig.company);
  const { t: translate } = useTranslation();

  const timerId = useRef<number>();
  const validUntilEndUnix = useRef(0);

  const generateQRCode = useCallback(() => {
    function stopTimer() {
      clearInterval(timerId.current);
    }

    function updateSeconds() {
      const now = +parseInt(`${new Date().getTime() / 1000}`, 10);
      const s = Math.round(validUntilEndUnix.current - now);
      setSeconds(`${s}`);
      return s;
    }

    function setTimer() {
      stopTimer();

      updateSeconds();

      timerId.current = setInterval(() => {
        if (validUntilEndUnix.current === 0) {
          stopTimer();
          return;
        }

        const s = updateSeconds();

        if (s < 1) {
          stopTimer();
          generateQRCode();
        }
      }, 1000);
    }

    try {
      const {
        aesKey: cardKey,
        qrCodeDuration: cardDuration,
        totpEnabled,
        staticVisitorQRCode,
      } = invite.waccessIntegration;

      const cardNumber = invite.cardNumber;

      if (!cardKey || !cardNumber || !cardDuration) {
        throw new Error('fieldsError');
      }

      if (staticVisitorQRCode) {
        setErrorQRCode(false);
        setCurrentQRCode(`${cardNumber}`);
        return;
      }

      if (totpEnabled) {
        const { qrcodeValue, nextTick } = getTOTPQRCodeValue(cardKey, cardNumber, cardDuration);
        setErrorQRCode(false);
        setCurrentQRCode(qrcodeValue);
        const now = +parseInt(`${new Date().getTime() / 1000}`, 10);

        validUntilEndUnix.current = now + nextTick + 1;
        setTimer();
      } else {
        setErrorQRCode(false);
        const qrcodeValue = qrCodeV1(cardKey, cardNumber);
        setCurrentQRCode(qrcodeValue);
        const now = +parseInt(`${new Date().getTime() / 1000}`, 10);
        validUntilEndUnix.current = now + cardDuration;
        setTimer();
      }
    } catch (error) {
      stopTimer();
      setErrorQRCode(true);
      setLoading(false);
      if (error.message !== 'fieldsError') {
        showMessage({
          message: translate('general.error'),
          description: translate(`qrCodeDisplay.generatingQRCode`),
          backgroundColor: themes.colors.danger,
        });
        logSentryException({
          error,
          file: 'InvenziQRCodeDisplay.js',
          message: 'Error at generateQRCode function',
        });
      }
    }
  }, [invite]);

  useEffect(() => {
    if (!invite.isCanceled) {
      generateQRCode();
    }

    return () => {
      clearInterval(timerId.current);
    };
  }, [generateQRCode, invite.isCanceled]);

  useEffect(() => {
    if (errorQRCode) {
      generateQRCode();
    }
  }, [errorQRCode, generateQRCode, invite]);

  function renderRemainingQRCodeTime() {
    return (
      <>
        <Text style={styles.subtitle}>
          {translate('qrCodeDisplay.validFor')}{' '}
          <Text style={[styles.bold, !errorQRCode && +seconds <= 3 && styles.red]}>
            {!errorQRCode && +seconds > 0 ? seconds : '-'} {translate('qrCodeDisplay.seconds')}
          </Text>
        </Text>
      </>
    );
  }

  function renderWarning() {
    if (errorQRCode && !loading) {
      return (
        <View style={styles.warningContainer}>
          <Text style={styles.warningText}>{translate('qrCodeDisplay.qrcodeWarning')}</Text>
        </View>
      );
    }
    return null;
  }

  return (
    <>
      <ImageBackground style={styles.qrCode} source={themes.images.qrcodeFrame}>
        {renderWarning()}
        <RenderCanceledVisit isCanceled={invite.isCanceled && !loading} />

        <Spin visible={loading} overlayColor="transparent" />
        <QRCode
          size={width / 1.8}
          value={errorQRCode || loading ? `${appConfig.company}` : `${currentQRCode}`}
          color={errorQRCode || loading ? themes.colors.lightTertiary : themes.colors.darkPrimary}
          backgroundColor="transparent"
        />
      </ImageBackground>

      <View style={{ marginTop: 10, marginBottom: 20 }}>{renderRemainingQRCodeTime()}</View>
    </>
  );
}
