import { Text } from '_/components';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import { InviteVisitModel } from '_/interfaces/InviteVisitModel';
import {
  addPersonalPastInvitesArray,
  resetPersonalPastInvitesArray,
} from '_/store/modules/personal/actions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, FlatList, ActivityIndicator, StyleSheet } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { useDispatch, useSelector } from 'react-redux';

import { invitesApi } from '../../../services/api';
import EmptyInvites from '../EmptyCard/index';
import { InviteCard } from '../InviteCard';

export default function PastInvites() {
  const dispatch = useDispatch();
  const { pastInvites } = useSelector((state: any) => state.personal);
  const [loading, setLoading] = useState(false);
  const { t: translate } = useTranslation();

  const [refreshing, setRefreshing] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);

  useEffect(() => {
    loadPage();

    return () => {
      dispatch(resetPersonalPastInvitesArray());
    };
  }, []); // eslint-disable-line

  async function loadPage() {
    try {
      setReachedEnd(false);
      setLoading(true);
      setRefreshing(true);

      const limit = pastInvites?.invites?.length || null;

      const invites = await invitesApi.getPast({ limit });

      dispatch(addPersonalPastInvitesArray(invites));
      setLoading(false);
      setRefreshing(false);
    } catch (error) {
      logSentryException({
        error,
        file: 'PastInvites.js',
        message: 'Error at loadPage function',
      });
      showMessage({
        message: translate('personalInvites.loadingError'),
        type: 'danger',
      });
      setLoading(false);
      setRefreshing(false);
    }
  }

  async function loadMore() {
    try {
      setRefreshing(true);
      if (!reachedEnd) {
        const limit = pastInvites?.invites?.length || 0;

        const invites = await invitesApi.getPast({ limit });

        dispatch(addPersonalPastInvitesArray([...pastInvites, ...invites]));

        if (invites.length === 0) {
          setReachedEnd(true);
        }
      }
      setRefreshing(false);
    } catch (error) {
      logSentryException({
        error,
        file: 'PastInvites.js',
        message: 'Error at loadMore function',
      });
      showMessage({
        message: translate('personalInvites.loadingError'),
        type: 'danger',
      });
      setLoading(false);
      setRefreshing(false);
    }
  }

  function renderItems(invite: InviteVisitModel) {
    if (!invite) return null;

    return <InviteCard invite={invite} />;
  }

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size="large" color={themes.colors.primary} />
      </View>
    );
  }

  function renderFooter() {
    if (reachedEnd) {
      return (
        <Text style={{ fontSize: 24, fontWeight: 'bold' }}>
          {translate('personalInvites.endReached')}
        </Text>
      );
    }

    if (refreshing && !loading) {
      return <ActivityIndicator size="large" color="#2C3654" />;
    }

    return null;
  }

  return (
    <>
      {loading ? null : (
        <FlatList
          data={pastInvites}
          extraData={pastInvites}
          style={styles.content}
          onEndReached={() => loadMore()}
          onEndReachedThreshold={0.2}
          onRefresh={loadPage}
          refreshing={refreshing}
          keyExtractor={(item) => String(item.id)}
          contentContainerStyle={{ paddingTop: 20, paddingBottom: 80 }}
          renderItem={({ item: invite }) => renderItems(invite)}
          ListEmptyComponent={<EmptyInvites />}
          ListFooterComponent={renderFooter}
          ListFooterComponentStyle={{
            flex: 1,
            justifyContent: 'center',
            alignSelf: 'center',
            padding: 50,
          }}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: themes.colors.white,
  },
});
