import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, StyleSheet } from 'react-native';

export default function EmptyMeetings() {
  const { t: translate } = useTranslation();

  return (
    <View style={styles.container}>
      <Image resizeMode="contain" style={styles.image} source={themes.images.noMeeting} />
      <Text style={styles.text}>{translate('meeting.onlyYou')}</Text>
      <Text style={styles.bold}>{translate('meeting.enjoy')}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 36,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 250,
    height: 200,
  },
  text: {
    marginTop: 36,
    marginBottom: 4,
  },
  bold: {
    fontFamily: fontFamily.bold,
  },
});
