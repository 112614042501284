import { action } from 'typesafe-actions';

import { MeetingsAPIResponseData } from './types';

export const getMeetingsRequest = (params: any) =>
  action('@meetings/GET_MEETINGS_REQUEST', {
    params,
  });

export const getMeetingsSuccess = ({ data, total, limit, skip }: MeetingsAPIResponseData) =>
  action('@meetings/GET_MEETINGS_SUCCESS', {
    data,
    total,
    limit,
    skip,
  });

export const loadMoreMeetingsRequest = (params: any) =>
  action('@meetings/LOAD_MORE_MEETINGS_REQUEST', {
    params,
  });

export const loadMoreMeetingsSuccess = ({ data, total, limit, skip }: MeetingsAPIResponseData) =>
  action('@meetings/LOAD_MORE_MEETINGS_SUCCESS', {
    data,
    total,
    limit,
    skip,
  });
