import { CompaniesModel } from '@w3lcome/types';

import api from './base';
import { GetListType } from './types/GetListType';

class CompanyApi {
  public getCompanies = async () => {
    const { data } = await api.get<GetListType<CompaniesModel>>('/companies');
    return data;
  };

  public getCompany = async (companyId: string) => {
    const { data } = await api.get<CompaniesModel>(`/companies/${companyId}`);
    return data;
  };
}

export default new CompanyApi();
