import { Text } from '_/components';
import themes from '_/constants/themes';
import React from 'react';
import { StyleSheet } from 'react-native';

const RequiredField: React.FC = () => {
  return (
    <Text fontType="bold" style={styles.asterisk}>
      *
    </Text>
  );
};

export default RequiredField;

const styles = StyleSheet.create({
  asterisk: {
    fontSize: 18,
    color: themes.colors.danger,
    marginLeft: 8,
  },
});
