import { DeliveryPickupStatusType, VisitAuthorizationStatusType } from '@w3lcome/types';
import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import {
  View,
  Image,
  Animated,
  TouchableWithoutFeedback,
  StyleSheet,
  Dimensions,
} from 'react-native';
import {
  HandlerStateChangeEvent,
  PinchGestureHandler,
  PinchGestureHandlerEventPayload,
  State,
} from 'react-native-gesture-handler';
import Modal from 'react-native-modal';

import Spin from '../Spin';
import StatusView from '../StatusView';

interface PictureContainerProps {
  name?: string;
  status?: VisitAuthorizationStatusType | DeliveryPickupStatusType;
  isVisitCanceled?: boolean;
  pictureUrl?: string | null;
  type?: 'visit' | 'delivery' | 'invitee';
}

export default function PictureContainer({
  pictureUrl,
  type,
  status,
  name,
  isVisitCanceled,
}: PictureContainerProps) {
  const [visible, setVisible] = useState(false);

  const scale = new Animated.Value(1);

  const onZoomEvent = Animated.event([{ nativeEvent: { scale } }], {
    useNativeDriver: true,
  });

  function onZoomStateChange(event: HandlerStateChangeEvent<PinchGestureHandlerEventPayload>) {
    if (event.nativeEvent.oldState === State.ACTIVE) {
      Animated.spring(scale, {
        toValue: 1,
        useNativeDriver: true,
      }).start();
    }
  }

  function renderImage() {
    return (
      <View style={styles.pictureContainer}>
        <View style={styles.avatarBorder}>
          <View style={styles.loadingView}>
            <Spin visible overlayColor="transparent" containerColor="transparent" />
          </View>
          <StatusView
            isVisitCanceled={isVisitCanceled}
            type={type}
            status={status}
            name={name}
            shadow
            containerStyles={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}
          />
          <TouchableWithoutFeedback onPress={() => setVisible(true)}>
            <Image style={styles.avatar} source={{ uri: pictureUrl! }} />
          </TouchableWithoutFeedback>
        </View>
      </View>
    );
  }

  if (!pictureUrl) {
    return (
      <StatusView
        isVisitCanceled={isVisitCanceled}
        type={type}
        status={status}
        name={name}
        rounded
        shadow
      />
    );
  }

  return (
    <>
      {renderImage()}
      <Modal
        isVisible={visible}
        style={styles.overlay}
        onBackdropPress={() => setVisible(false)}
        onBackButtonPress={() => setVisible(false)}
      >
        <PinchGestureHandler onGestureEvent={onZoomEvent} onHandlerStateChange={onZoomStateChange}>
          <Animated.Image
            style={[styles.picture, { transform: [{ scale }] }]}
            source={{ uri: pictureUrl }}
          />
        </PinchGestureHandler>
      </Modal>
    </>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  pictureContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    zIndex: 1000,
    width: width - 48,
    height: width - 48,
  },
  avatarBorder: {
    overflow: 'hidden',
    shadowColor: themes.colors.black,
    backgroundColor: themes.colors.white,
    marginTop: 8,
    elevation: 5,
    borderRadius: 6,
    shadowRadius: 8,
    width: width - 48,
    height: width - 48,
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 12 },
  },
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  picture: {
    borderRadius: 8,
    width: width - 24,
    height: width + 24,
    backgroundColor: themes.colors.white,
  },
  loadingView: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});
