import { Feather, FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { EventNotificationsModel, NotificationEventsEnum } from '@w3lcome/types';
import { Header, Spin, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import themes from '_/constants/themes';
import { getDateLocale } from '_/helpers/getDateLocale';
import { useDeliveriesContext } from '_/hooks/DeliveriesProvider';
import { useNotificationsContext } from '_/hooks/NotificationsProvider';
import { useVisitsContext } from '_/hooks/VisitsProvider';
import { AppRoute, NotificationProps } from '_/routes/types';
import { deliveriesApi, meetingsApi, notificationEventsApi, visitsApi } from '_/services/api';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { useDispatch, useSelector } from 'react-redux';

import { getMeetingDetailsRequest } from '_/store/modules/meeting/actions';

const Notification: React.FC = () => {
  const navigation = useNavigation<NotificationProps>();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const auth = useSelector((state: any) => state.auth);

  const { i18n, t } = useTranslation();
  const locale = getDateLocale(i18n.language);

  const { setSelectedVisit } = useVisitsContext();
  const { setSelectedDelivery } = useDeliveriesContext();

  const {
    getNotifications,
    notifications,
    notificationsUnread,
    loading,
    MarkAllAsRead,
    updateNotificationsLocally,
  } = useNotificationsContext();

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(async () => {
    await getNotifications();

    setRefreshing(false);
  }, [getNotifications]);

  async function getVisit(notification: EventNotificationsModel) {
    try {
      if (auth.personal && notification.metadata?.meetingId) {
        navigation.navigate(AppRoute.PERSONAL_INVITE, {
          id: notification.metadata?.visitId,
          goBack: true,
        });
        return;
      }

      const { data } = await visitsApi.getVisits({ id: notification.metadata?.visitId });

      setSelectedVisit(data[0]);
      navigation.navigate(AppRoute.VISITOR);
    } catch (error) {
      showMessage({
        message: t('informationIsNotAvailable'),
        type: 'danger',
      });
    }
  }

  async function getMeeting(notification: EventNotificationsModel) {
    try {
      if (auth.personal) {
        navigation.navigate(AppRoute.PERSONAL_MEETING, {
          id: notification.metadata?.meetingId,
          goBack: true,
        });
        return;
      }
      const meeting = await meetingsApi.getMeeting(notification.metadata?.meetingId);
      dispatch(getMeetingDetailsRequest(meeting));
    } catch (error) {
      showMessage({
        message: t('informationIsNotAvailable'),
        type: 'danger',
      });
    }
  }

  async function getDelivery(notification: EventNotificationsModel) {
    try {
      const delivery = await deliveriesApi.getDelivery(notification.metadata?.deliveryId);
      setSelectedDelivery(delivery);
      navigation.navigate(AppRoute.DELIVERY);
    } catch (error) {
      showMessage({
        message: t('informationIsNotAvailable'),
        type: 'danger',
      });
    }
  }

  async function handleNotification(notification: EventNotificationsModel) {
    if (!notification.readAt) {
      notification.readAt = new Date();
      await notificationEventsApi.patchtNotification(notification);

      updateNotificationsLocally(notification);
    }

    switch (notification.type) {
      case NotificationEventsEnum.VISIT_CHECKIN_TO_HOST:
      case NotificationEventsEnum.VISIT_CHECKOUT_TO_HOST:
      case NotificationEventsEnum.VISIT_ALLOWED:
      case NotificationEventsEnum.VISIT_DEINED:
      case NotificationEventsEnum.VISIT_PRE_REGISTRATION:
      case NotificationEventsEnum.VISIT_CARD_NUMBER_ASSIGNED:
      case NotificationEventsEnum.VISIT_MEETING_TYPE_APPROVE_PRE_CHECKIN_WITH_ACCOUNT:
      case NotificationEventsEnum.VISIT_MEETING_TYPE_APPROVE_PRE_CHECKIN_WITHOUT_ACCOUNT:
      case NotificationEventsEnum.VISIT_MEETING_TYPE_NEED_APPROVE_CHECKIN_WITHOUT_ACCOUNT:
      case NotificationEventsEnum.VISIT_MEETING_TYPE_NEED_APPROVE_CHECKIN_WITH_ACCOUNT:
      case NotificationEventsEnum.VISIT_PRE_REGISTRATION_TO_HOST:
        getVisit(notification);
        break;

      case NotificationEventsEnum.MEETING_CREATED:
      case NotificationEventsEnum.MEETING_UPDATED:
      case NotificationEventsEnum.MEETING_DELETED:
      case NotificationEventsEnum.MEETING_CREATED_TO_HOST:
      case NotificationEventsEnum.MEETING_UPDATED_TO_HOST:
      case NotificationEventsEnum.MEETING_DELETED_TO_HOST:
        getMeeting(notification);
        break;

      case NotificationEventsEnum.DELIVERED_PACKAGE_TO_HOST:
        getDelivery(notification);
        break;
    }
  }

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <Header
          handleBack={() => navigation.goBack()}
          title={`${t('Notifications')} ${
            notificationsUnread?.length > 0 ? `(${notificationsUnread?.length})` : ''
          }`}
        />
        <Spin visible={loading} />
        {notificationsUnread.length > 0 && (
          <View style={styles.contentMarkAllAsRead}>
            <TouchableOpacity style={styles.buttonMarkAllAsRead} onPress={MarkAllAsRead}>
              <Text size={12} fontType="semibold" color="white" style={{ marginTop: 2 }}>
                {t('MarkAllAsRead')}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <ScrollView
          style={styles.scrollView}
          showsVerticalScrollIndicator={false}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        >
          {notifications.map((notification, index) => (
            <TouchableOpacity
              key={index}
              style={styles.box}
              onPress={() => handleNotification(notification)}
            >
              <View style={{ flex: 1 }}>
                <Text size={18} fontType={notification.readAt === null ? 'bold' : 'medium'}>
                  {t(`${notification.title}`, {
                    visitName: notification.metadata?.visitName,
                    meetingName: notification.metadata?.meetingName,
                  })}
                </Text>
                <Text size={14} fontType={notification.readAt === null ? 'semibold' : 'regular'}>
                  {t(`${notification.content}`, {
                    visitName: notification.metadata?.visitName,
                    meetingName: notification.metadata?.meetingName,
                    userName: user.name,
                    message: notification.metadata?.message,
                  })}
                </Text>
                <View style={styles.time}>
                  <Text size={14} color={themes.colors.grayPrimary}>
                    {format(new Date(notification.createdAt), 'PP', { locale })}
                  </Text>
                  <FontAwesome name="circle" style={styles.circle} />
                  <Text size={14} color={themes.colors.grayPrimary}>
                    {format(new Date(notification.createdAt), 'p', { locale })}
                  </Text>
                </View>
              </View>
              <Feather name="chevron-right" style={styles.icon} />
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    </ContainerWidthLimit>
  );
};

export default Notification;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.lightQuaternary,
  },
  scrollView: {
    flex: 1,
  },
  box: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingVertical: 12,
    backgroundColor: themes.colors.white,
    marginBottom: 1.5,
  },
  icon: {
    marginLeft: 12,
    fontSize: 18,
    color: themes.colors.lightTertiary,
  },
  time: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 6,
  },
  circle: {
    fontSize: 5,
    color: themes.colors.graySecondary,
    marginHorizontal: 6,
  },
  contentMarkAllAsRead: {
    backgroundColor: themes.colors.white,
    paddingBottom: 8,
    alignItems: 'flex-end',
    paddingHorizontal: 24,
  },
  buttonMarkAllAsRead: {
    backgroundColor: themes.colors.primary,
    borderRadius: 4,
    paddingHorizontal: 8,
    maxWidth: '50%',
    alignItems: 'center',
  },
});
