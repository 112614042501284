import logSentryException from '_/helpers/logSentryException';
import { meetingsApi } from '_/services/api';
import { all, put, call, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { MeetingsAPIResponseData } from './types';

export function* getMeetings({ payload }: any) {
  try {
    const { params } = payload;
    const response: MeetingsAPIResponseData = yield call(meetingsApi.getMeetings, params, {});

    yield put(actions.getMeetingsSuccess(response));
  } catch (error) {
    const message = error.response?.data?.message;
    yield call(logSentryException, {
      error: error.response?.data,
      file: 'meetings/sagas.ts',
      message,
    });
  }
}

export function* loadMoreMeetings({ payload }: any) {
  try {
    const { params } = payload;

    const response: MeetingsAPIResponseData = yield call(meetingsApi.getMeetings, params, {});

    yield put(actions.loadMoreMeetingsSuccess(response));
  } catch (error) {
    const message = error.response?.data?.message;
    yield call(logSentryException, {
      error: error.response?.data,
      file: 'meetings/sagas.ts',
      message,
    });
  }
}

export default all([
  takeLatest('@meetings/GET_MEETINGS_REQUEST', getMeetings),
  takeLatest('@meetings/LOAD_MORE_MEETINGS_REQUEST', loadMoreMeetings),
]);
