import { Feather } from '@expo/vector-icons';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { QRCodeReaderProps } from '_/routes/types';
import { BarCodeEvent, BarCodeScanner, requestPermissionsAsync } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import { CameraType } from 'expo-camera/build/Camera.types';
import { transparentize } from 'polished';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, View, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { useDispatch, useSelector } from 'react-redux';

import { changeQRCodeCameraType } from '_/store/modules/settings/actions';

interface QRCodeScannerProps {
  handleQRCodeScanned: (data: string) => void;
  isEnabled: boolean;
}

export default function QRCodeScanner({ handleQRCodeScanned, isEnabled }: QRCodeScannerProps) {
  const [scanned, setScanned] = useState(false);
  const [hasCameraPermission, setHasCameraPermission] = useState<boolean | null>(null);

  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { t: translate } = useTranslation();
  const { qrCodeCameraType } = useSelector((state: any) => state.settings);
  const navigation = useNavigation<QRCodeReaderProps>();

  async function openSettings() {
    await Linking.openSettings();
    navigation.goBack();
  }

  async function getPermissionsAsync() {
    const { status } = await requestPermissionsAsync();
    setHasCameraPermission(status === 'granted');
  }

  async function handleBarCodeScanned({ data }: BarCodeEvent) {
    setScanned(true);
    handleQRCodeScanned(data);
    setTimeout(() => setScanned(false), 1000);
  }

  useEffect(() => {
    getPermissionsAsync();
  }, []);

  function handleCameraType() {
    dispatch(
      changeQRCodeCameraType(
        qrCodeCameraType === CameraType.back ? CameraType.front : CameraType.back
      )
    );
  }

  const GrantPermissionButton = () => (
    <TouchableOpacity style={styles.settingsButton} onPress={openSettings}>
      <Feather name="settings" style={styles.settingsIcon} />
      <Text>{translate('qrcodeScanner.openSettings')}</Text>
    </TouchableOpacity>
  );

  if (hasCameraPermission === null) {
    return (
      <View style={styles.messageContainer}>
        <Feather name="camera-off" style={styles.cameraIcon} />
        <Text fontType="medium" style={styles.requestMsg}>
          {translate('qrcodeScanner.cameraRequest')}
        </Text>
        <GrantPermissionButton />
      </View>
    );
  }

  if (hasCameraPermission === false) {
    return (
      <View style={styles.messageContainer}>
        <Feather name="camera-off" style={styles.cameraIcon} />
        <Text fontType="medium" style={styles.requestMsg}>
          {translate('qrcodeScanner.permissionDenied')}
        </Text>
        <GrantPermissionButton />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.cameraContainer}>
        {isFocused && (
          <Camera
            onBarCodeScanned={scanned || !isEnabled ? undefined : handleBarCodeScanned}
            type={qrCodeCameraType}
            barCodeScannerSettings={{
              barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
            }}
            style={[
              StyleSheet.absoluteFillObject,
              {
                opacity: scanned || !isEnabled ? 0.5 : 1,
              },
            ]}
          />
        )}

        <View style={styles.layerTop}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>QR Code</Text>
            <Text style={styles.message}>{translate('qrcodeScanner.instructions')}</Text>
          </View>
        </View>
        <View style={styles.layerCenter}>
          <View style={styles.layerLeft} />
          <Svg width="280" height="280" viewBox="0 0 280 280" fill="none">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 0H0V12C0 5.37262 5.37256 0 12 0ZM268 0C274.627 0 280 5.37256 280 12V0H268ZM280 268C280 274.627 274.627 280 268 280H280V268ZM12 280C5.37256 280 0 274.627 0 268V280H12Z"
              fill="black"
              fillOpacity="0.5"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26 20C22.6863 20 20 22.6863 20 26V50L22.8977 28.0604C23.253 25.37 25.37 23.253 28.0604 22.8977L50 20H26ZM20 254C20 257.314 22.6863 260 26 260H50L28.0604 257.102C25.37 256.747 23.253 254.63 22.8977 251.94L20 230V254ZM254 20C257.314 20 260 22.6863 260 26V50L257.102 28.0604C256.747 25.37 254.63 23.253 251.94 22.8977L230 20H254ZM254 260C257.314 260 260 257.314 260 254V230L257.102 251.94C256.747 254.63 254.63 256.747 251.94 257.102L230 260H254Z"
              fill="white"
              fillOpacity="0.8"
            />
          </Svg>

          <View style={styles.layerRight} />
        </View>
        <View style={styles.layerBottom}>
          <TouchableOpacity style={styles.iconBtn} onPress={handleCameraType}>
            <Image source={themes.images.switchCamera} style={styles.icon} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const opacity = transparentize(0.5, themes.colors.black);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  messageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cameraContainer: {
    flex: 1,
    backgroundColor: themes.colors.black,
  },
  titleContainer: {
    alignItems: 'center',
    marginTop: 12,
  },
  title: {
    fontSize: 28,
    fontFamily: fontFamily.bold,
    color: themes.colors.white,
    marginTop: 48,
    marginBottom: 6,
  },
  message: {
    fontSize: 16,
    color: themes.colors.white,
  },
  requestMsg: {
    color: themes.colors.white,
    margin: 8,
    textAlign: 'center',
    width: '80%',
  },
  iconBtn: {
    backgroundColor: transparentize(0.2, themes.colors.black),
    borderRadius: 8,
    borderColor: transparentize(0.85, themes.colors.white),
    borderWidth: 1,
    padding: 12,
    top: 24,
  },
  icon: {
    width: 32,
    height: 32,
    opacity: 0.85,
  },
  layerTop: {
    flex: 2,
    backgroundColor: opacity,
    alignItems: 'center',
    justifyContent: 'center',
  },
  layerCenter: {
    flexDirection: 'row',
  },
  layerLeft: {
    flex: 1,
    backgroundColor: opacity,
  },
  layerRight: {
    flex: 1,
    backgroundColor: opacity,
  },
  layerBottom: {
    flex: 2,
    backgroundColor: opacity,
    alignItems: 'center',
  },
  settingsButton: {
    alignItems: 'center',
    backgroundColor: themes.colors.lightPrimary,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 12,
    borderRadius: 6,
    marginTop: 8,
  },
  settingsIcon: {
    fontSize: 16,
    marginRight: 8,
    color: themes.colors.darkQuaternary,
  },
  cameraIcon: {
    fontSize: 24,
    margin: 8,
    color: themes.colors.warning,
  },
});
