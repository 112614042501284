import { HostsModel } from '@w3lcome/types';
import { action } from 'typesafe-actions';

import { GetActualHostRequestInt } from './types';

export function getHostOfTheActualCompanyRequest({ companyId, userId }: GetActualHostRequestInt) {
  return action('@host/GET_HOST_OF_THE_ACTUAL_COMPANY_REQUEST', {
    companyId,
    userId,
  });
}

export function getHostOfTheActualCompanySuccess(host: HostsModel) {
  return action('@host/GET_HOST_OF_THE_ACTUAL_COMPANY_SUCCESS', {
    host,
  });
}

export function getHostOfTheActualCompanyFailure() {
  return action('@host/GET_HOST_OF_THE_ACTUAL_COMPANY_FAILURE');
}
