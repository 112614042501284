import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, ImageBackground, Platform } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import { useSelector } from 'react-redux';

import RenderCanceledVisit from './RenderCanceledVisit';
import styles from './styles';
import { getWebMaxWidth } from '../ContainerWidthLimit';
import Text from '../Text';

const { width } = Dimensions.get('screen');

interface StaticQRCodeProps {
  qrCodeValue: string;
  isCanceled?: boolean;
}

export default function StaticQRCode({ isCanceled, qrCodeValue }: StaticQRCodeProps) {
  const user = useSelector((state: any) => state.user);
  const company = useSelector((state: any) => state.company);
  const { t: translate } = useTranslation();

  const fontSize = Math.max(Math.min(450 / user.name.length, 40), 20);

  return (
    <>
      <Text style={[styles.name, { fontSize }]}>{user.name}</Text>
      <Text style={styles.subtitle}>
        {translate('general.company')} <Text style={styles.bold}>{company.name}</Text>
      </Text>
      <ImageBackground style={styles.qrCode} source={themes.images.qrCodeBorder}>
        <RenderCanceledVisit isCanceled={isCanceled} />
        <QRCode
          size={Platform.OS === 'web' ? getWebMaxWidth() * 0.6 : width / 1.8}
          value={qrCodeValue}
          color={themes.colors.darkPrimary}
          backgroundColor={themes.colors.white}
        />
      </ImageBackground>
    </>
  );
}
