import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 24,
  },
  btnEditMeeting: {
    shadowColor: themes.colors.black,
    position: 'absolute',
    right: 20,
    bottom: 20,
    elevation: 10,
    shadowRadius: 10,
    borderRadius: 50,
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 0 },
  },
  expandButtonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    padding: 16,
  },
  expandButtonText: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.medium,
    fontSize: 12,
    marginRight: 8,
  },
  icon: {
    fontSize: 14,
    color: themes.colors.darkQuaternary,
  },
  iconMoreVertical: {
    fontSize: 24,
    color: themes.colors.darkSecondary,
  },
});

export default styles;
