import { produce } from 'immer';

import { MeetingsAction, MeetingsState } from './types';

const INITIAL_STATE: MeetingsState = {
  meetings: [],
  total: 0,
  limit: 0,
  skip: 0,
};

const meetings = (state = INITIAL_STATE, action: MeetingsAction): MeetingsState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@meetings/GET_MEETINGS_SUCCESS': {
        draft.meetings = action.payload.data;
        draft.total = action.payload.total;
        draft.limit = action.payload.limit;
        draft.skip = action.payload.skip;
        break;
      }
      case '@meetings/LOAD_MORE_MEETINGS_SUCCESS': {
        draft.meetings = draft.meetings.concat(action.payload.data);
        draft.total = action.payload.total;
        draft.limit = draft.limit + action.payload.limit;
        draft.skip = action.payload.skip;
        break;
      }
      default:
    }
  });
};

export default meetings;
