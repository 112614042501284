import themes from '_/constants/themes';
import React from 'react';
import { View, ImageBackground, StyleSheet } from 'react-native';

import Spin from '../Spin/index';

interface SplashScreenProps {
  loading: boolean;
}

export default function SplashScreen({ loading }: SplashScreenProps) {
  return (
    <View style={styles.container}>
      <ImageBackground style={styles.background} source={themes.images.splash}>
        <Spin visible={loading} />
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
  },
});
