import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, StyleSheet } from 'react-native';

export default function EmptyInvites() {
  const { t: translate } = useTranslation();

  return (
    <View style={styles.container}>
      <Image resizeMode="contain" style={styles.image} source={themes.images.emptyInvites} />

      <Text style={styles.emptyTitle}>{translate('personalInvites.emptyInvitesTitle')}</Text>
      <Text style={styles.emptyMsg}>{translate('personalInvites.emptyInvitesMsg')}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 24,
  },
  image: {
    width: '100%',
  },
  emptyTitle: {
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginTop: 8,
    paddingVertical: 18,
    textAlign: 'center',
  },
  emptyMsg: {
    fontFamily: fontFamily.light,
    fontSize: 12,
    textAlign: 'center',
  },
});
