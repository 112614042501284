import { Feather } from '@expo/vector-icons';
import { fontSizes, colors } from '_/config/theme';
import { transparentize } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';

import Text from '../Text';

const { width } = Dimensions.get('window');

interface ModalCancelProps {
  isVisible: boolean;
  onHideModal: (value: boolean) => void;
  onCancel: () => void;
}

const ModalCancelVisit: React.FC<ModalCancelProps> = ({ isVisible, onHideModal, onCancel }) => {
  const { t: translate } = useTranslation();

  return (
    <Modal isVisible={isVisible} style={styles.container}>
      <View style={styles.content}>
        <Text fontType="medium" size={fontSizes.lg} color={colors.dark3}>
          {translate('visitor.cancelVisit')}
        </Text>

        <View style={styles.box}>
          <Feather name="help-circle" size={fontSizes.xl3} color={colors.warning} />
          <Text style={{ flex: 1, marginLeft: 12 }} size={15} color={colors.dark3}>
            {translate('visitor.cancelModalDescription')}
          </Text>
        </View>

        <View style={styles.buttonsContainer}>
          <TouchableOpacity style={styles.buttonSkip} onPress={() => onHideModal(false)}>
            <Text size={fontSizes.md} color={colors.dark3}>
              {translate('denyConfirmationModal.cancel')}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.button} onPress={onCancel}>
            <Text size={fontSizes.md} color={colors.white}>
              {translate('ratingModal.sure')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalCancelVisit;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 999,
  },
  content: {
    backgroundColor: colors.white,
    borderRadius: 4,
    width: width - 48,
    paddingVertical: 24,
    paddingHorizontal: 18,
  },
  box: {
    paddingTop: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 18,
  },
  button: {
    marginLeft: 10,
    borderRadius: 6,
    paddingVertical: 10,
    paddingHorizontal: 30,
    backgroundColor: colors.danger,
  },
  buttonSkip: {
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
});
