import { MeetingsModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';

import api from './base';
import { GetListType } from './types/GetListType';

class MeetingsApi {
  private meetingsUrl = 'meetings';

  public getMeeting = async (meetingId: string, config?: AxiosRequestConfig) => {
    const url = `${this.meetingsUrl}/${meetingId}`;

    const { data } = await api.get<MeetingsModel>(url, config);

    return data;
  };

  public getMeetings = async (params: any, config?: AxiosRequestConfig) => {
    const { data } = await api.get<GetListType<MeetingsModel>>(`/meetings`, {
      ...config,
      params,
    });

    return data;
  };

  public createNewMeeting = async (body: any) => {
    const { data } = await api.post<MeetingsModel>(`/meetings`, body);

    return data;
  };

  public patchCurrentMeeting = async (meeting: any) => {
    const { data } = await api.patch<MeetingsModel>(`/meetings/${meeting.id}`, meeting);

    return data;
  };

  public removeMeeting = async (meetingId: string) => {
    const { data } = await api.delete<MeetingsModel>(`/meetings/${meetingId}`);

    return data;
  };
}

export default new MeetingsApi();
