import { Feather } from '@expo/vector-icons';
import { WAccessIntegrationsModel } from '@w3lcome/types';
import { appConfig } from '_/config/app';
import themes from '_/constants/themes';
import logSentryException from '_/helpers/logSentryException';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, TouchableOpacity, ImageBackground } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import QRCode from 'react-native-qrcode-svg';
import { useSelector } from 'react-redux';

import { getTOTPQRCodeValue } from '_/helpers/totp';

import Spin from '../Spin';
import Text from '../Text';
import { qrCodeV1 } from './helpers';
import styles from './styles';

const { width } = Dimensions.get('screen');

interface HostInvenziQRCodeProps {
  waccessIntegration: WAccessIntegrationsModel;
  getWaccessIntegration: () => void;
}

export default function HostInvenziQRCode({
  waccessIntegration,
  getWaccessIntegration,
}: HostInvenziQRCodeProps) {
  const [seconds, setSeconds] = useState('-');
  const [loading, setLoading] = useState(false);
  const [errorQRCode, setErrorQRCode] = useState(false);
  const [currentQRCode, setCurrentQRCode] = useState(appConfig.company);
  const { t: translate } = useTranslation();
  const user = useSelector((state: any) => state.user);
  const company = useSelector((state: any) => state.company);
  const host = useSelector((state: any) => state.host);

  const timerId = useRef<NodeJS.Timeout>();
  const validUntilEndUnix = useRef(0);

  useEffect(() => {
    generateQRCode();

    return () => {
      clearInterval(timerId.current);
    };
  }, [waccessIntegration]);

  const generateQRCode = useCallback(() => {
    function stopTimer() {
      clearInterval(timerId.current);
    }

    function updateSeconds() {
      const now = +parseInt(`${new Date().getTime() / 1000}`, 10);
      const s = Math.round(validUntilEndUnix.current - now);
      setSeconds(`${s}`);
      return s;
    }

    function setTimer() {
      stopTimer();

      updateSeconds();

      timerId.current = setInterval(() => {
        if (validUntilEndUnix.current === 0) {
          stopTimer();
          return;
        }

        const s = updateSeconds();

        if (s < 1) {
          stopTimer();
          generateQRCode();
        }
      }, 1000);
    }

    try {
      const { aesKey: key, qrCodeDuration: duration, totpEnabled } = waccessIntegration;

      const cardNumber = host.metadata?.wAccessCardNumber;

      if (!key || !cardNumber || !duration) {
        throw new Error('fieldsError');
      }

      if (totpEnabled) {
        const { qrcodeValue, nextTick } = getTOTPQRCodeValue(key, cardNumber, duration);
        setErrorQRCode(false);
        setCurrentQRCode(qrcodeValue);
        const now = +parseInt(`${new Date().getTime() / 1000}`, 10);
        validUntilEndUnix.current = now + nextTick + 1;
        setTimer();
      } else {
        setErrorQRCode(false);
        const qrcodeValue = qrCodeV1(key, cardNumber);
        setCurrentQRCode(qrcodeValue);
        const now = +parseInt(`${new Date().getTime() / 1000}`, 10);
        validUntilEndUnix.current = now + duration;
        setTimer();
      }
    } catch (error) {
      stopTimer();
      setErrorQRCode(true);
      setLoading(false);
      if (error.message !== 'fieldsError') {
        showMessage({
          message: translate('general.error'),
          description: translate(`qrCodeDisplay.generatingQRCode`),
          backgroundColor: themes.colors.danger,
        });
        logSentryException({
          error,
          file: 'InvenziQRCodeDisplay.js',
          message: 'Error at generateQRCode function',
        });
      }
    }
  }, [host]);

  const fontSize = Math.max(Math.min(450 / user.name.length, 40), 20);

  function renderRemainingQRCodeTime() {
    return (
      <Text style={styles.subtitle}>
        {translate('qrCodeDisplay.validFor')}{' '}
        <Text style={[styles.bold, !errorQRCode && +seconds <= 3 && styles.red]}>
          {!errorQRCode && +seconds > 0 ? seconds : '-'} {translate('qrCodeDisplay.seconds')}
        </Text>
      </Text>
    );
  }

  function renderCompanyAndUser() {
    return (
      <>
        <Text style={[styles.name, { fontSize }]}>{user.name}</Text>
        <Text style={styles.subtitle}>
          {translate('general.company')} <Text style={styles.bold}>{company.name}</Text>
        </Text>
      </>
    );
  }

  function renderButtonOrWarning() {
    if (errorQRCode && !loading) {
      return (
        <TouchableOpacity onPress={getWaccessIntegration} style={styles.errorBtn}>
          <Feather name="refresh-cw" style={styles.icon} size={32} />
        </TouchableOpacity>
      );
    }

    return null;
  }

  return (
    <>
      {renderCompanyAndUser()}
      {renderRemainingQRCodeTime()}

      <ImageBackground style={styles.qrCode} source={themes.images.qrcodeFrame}>
        {renderButtonOrWarning()}
        <Spin visible={loading} overlayColor="transparent" />

        <QRCode
          size={width / 1.8}
          value={errorQRCode || loading ? `${appConfig.company}` : `${currentQRCode}`}
          color={errorQRCode || loading ? themes.colors.lightTertiary : themes.colors.darkPrimary}
          backgroundColor="transparent"
        />
      </ImageBackground>
    </>
  );
}
