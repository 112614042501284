import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React from 'react';
import { View, StyleSheet } from 'react-native';

interface LineItemProps {
  children: string;
}

export default function LineItem({ children }: LineItemProps) {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{children}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 12,
    marginBottom: 8,
    borderRadius: 4,
    alignItems: 'center',
    backgroundColor: '#333',
  },
  text: {
    fontSize: 14,
    fontFamily: fontFamily.regular,
    color: themes.colors.white,
  },
});
