import { Feather } from '@expo/vector-icons';
import { useNetInfo } from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';
import { HostsModel, Lang } from '@w3lcome/types';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { handleVerifyCardLocalDB } from '_/helpers/cardVerificationLocalDB';
import { showError } from '_/helpers/showError';
import axios from 'axios';
import { Buffer } from 'buffer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, Text, Modal, Platform } from 'react-native';
import NfcManager, { NfcTech } from 'react-native-nfc-manager';
import { useSelector } from 'react-redux';

import { HostQRcodeCreatedProps } from '../EntryReader';
import EmployeeModal from '../QRCodeReader/EmployeeModal';

if (appConfig.allowedHostZonesOfflineFlowEnabled === 'true' && Platform.OS !== 'web') {
  NfcManager.start();
}

export default function NFCReader() {
  const [hostQRcodeCreated, setHostQRcodeCreated] = useState<HostQRcodeCreatedProps>();
  const [reading, setReading] = useState(false);
  const host = useSelector((state: any) => state.host);

  const navigation = useNavigation();

  const { t: translate, i18n } = useTranslation();

  const netInfo = useNetInfo();

  const isZoneAuthorizationEnabled = useSelector(
    (state: any) => state.company?.customization?.isZoneAuthorizationEnabled
  );

  const handleReadNFC = async () => {
    if (reading) {
      return;
    }

    try {
      setReading(true);

      if (!host.zoneId || !isZoneAuthorizationEnabled) {
        return;
      }

      await NfcManager.requestTechnology(NfcTech.Ndef);

      const tag = await NfcManager.getTag();

      const payload = tag?.id || '';

      const cardNumber = hexConverter(payload);

      if (cardNumber) {
        await handleTagFound(cardNumber.toString());
      }
    } catch (error) {
      showError(error);
    } finally {
      NfcManager.cancelTechnologyRequest();
      setReading(false);
    }
  };

  function handleClose() {
    setHostQRcodeCreated(undefined);
  }

  const hexConverter = (hexString: string) => {
    if (!/^[0-9A-Fa-f]+$/.test(hexString)) {
      return;
    }

    if (hexString.length % 2 !== 0) {
      return;
    }

    if (host.zone.littleEndianFormatEnabled) {
      const bytes =
        hexString.match(/.{1,2}/g)?.map((byteHex) => Number.parseInt(byteHex, 16)) || [];

      return bytes.reduce((acc, byte, index) => acc + byte * 256 ** index, 0);
    }

    return Number.parseInt(hexString, 16);
  };

  async function handleTagFound(mifareCardNumber: string) {
    try {
      if (!mifareCardNumber || !host.zoneId || !isZoneAuthorizationEnabled) {
        return;
      }

      if (netInfo.type === 'wifi') {
        await handleVerifyMifareCardNumber(mifareCardNumber);
        return;
      }

      await handleVerifyCardLocalDB(mifareCardNumber, host, setHostQRcodeCreated, true);
    } catch (error) {
      showError(translate('qrCodeScan.readError'));
      handleClose();
    }
  }

  async function handleVerifyMifareCardNumber(mifareCardNumber: string) {
    try {
      const { username, password, endpointUrl, name } = host.zone;

      if (!endpointUrl || !password || !username) {
        return;
      }

      const credentials = Buffer.from(`${username}:${password}`, 'utf-8').toString('base64');

      const data = {
        cardnumber: mifareCardNumber,
        source_name: name,
      };

      const { data: userAllowed } = await axios.post(`${endpointUrl}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${credentials}`,
        },
        timeout: 2000,
      });

      setHostQRcodeCreated({
        data: { name: userAllowed.CHName },
        isZoneAuthorized: userAllowed.strlanguage1?.toLowerCase() === 'access authorized',
        allowedZones: [{ zone: host.zone }],
        message: i18n.language === Lang.EN ? userAllowed.strlanguage1 : userAllowed.strlanguage2,
      });
    } catch (error) {
      await handleVerifyCardLocalDB(mifareCardNumber, host, setHostQRcodeCreated, true);
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity
          hitSlop={{ top: 30, right: 30, bottom: 30, left: 30 }}
          onPress={() => navigation.goBack()}
        >
          <Feather name="arrow-left" style={styles.icon} />
        </TouchableOpacity>
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{translate('nfcReader.title')}</Text>
        <Text style={styles.instructions}>{translate('nfcReader.instructions')}</Text>
      </View>
      <View style={styles.content}>
        <TouchableOpacity
          style={{ alignItems: 'center', justifyContent: 'center' }}
          onPress={handleReadNFC}
          disabled={reading}
        >
          <Feather
            name="credit-card"
            size={92}
            style={{
              color: reading ? '#808080' : '#fff',
              transform: [{ rotate: '-35deg' }],
            }}
          />
        </TouchableOpacity>
      </View>
      <Modal animationType="slide" visible={!!hostQRcodeCreated} onRequestClose={handleClose}>
        {!!hostQRcodeCreated && (
          <EmployeeModal
            host={hostQRcodeCreated.data as HostsModel}
            handleClose={handleClose}
            zonesAllowed={hostQRcodeCreated.allowedZones}
            isZoneAuthorized={hostQRcodeCreated.isZoneAuthorized}
            message={hostQRcodeCreated.message}
          />
        )}
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.black,
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    padding: 24,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
  },
  content: {
    width: '100%',
    height: '100%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 24,
    color: themes.colors.white,
  },
  titleContainer: {
    alignItems: 'center',
    marginTop: 12,
  },
  title: {
    fontSize: 28,
    fontFamily: fontFamily.bold,
    color: themes.colors.white,
    marginTop: 48,
    marginBottom: 6,
  },
  instructions: {
    fontSize: 16,
    color: themes.colors.white,
  },
});
