import t from './theme.json';

const images = {
  brazilFlag: require('_/assets/flags/pt-BR.png'),
  usFlag: require('_/assets/flags/en.png'),
  esFlag: require('_/assets/flags/es.png'),
  deFlag: require('_/assets/flags/de.png'),
  frFlag: require('_/assets/flags/fr.png'),
  zhFlag: require('_/assets/flags/zhFlag.png'),
  picturePlaceholder: require('_/assets/picture-placeholder.png'),
};

const colors = {
  accent: t['color-basic-900'],
  primary: t['color-primary-500'],
  basic: t['color-basic-900'],
  basicLight: t['color-basic-700'],
  black: '#323643',
  white: '#FFFFFF',
  gray: '#9DA3B4',
  gray2: '#C5CCD6',
};

const sizes = {
  // global sizes
  base: 16,
  font: 14,
  radius: 6,
  padding: 25,

  // font sizes
  h1: 26,
  h2: 20,
  h3: 18,
  title: 18,
  header: 16,
  body: 14,
  caption: 12,
};

const fonts = {
  h1: {
    fontSize: sizes.h1,
  },
  h2: {
    fontSize: sizes.h2,
  },
  h3: {
    fontSize: sizes.h3,
  },
  header: {
    fontSize: sizes.header,
  },
  title: {
    fontSize: sizes.title,
  },
  body: {
    fontSize: sizes.body,
  },
  caption: {
    fontSize: sizes.caption,
  },
};

export { colors, sizes, fonts, images };
