import { appConfig } from '_/config/app';
import logSentryException from '_/helpers/logSentryException';
import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';

import { getStorageLocalDataLogin } from './auth';

const api = axios.create({
  baseURL: appConfig.apiUrl,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  async (config) => {
    const refreshPath = '/refresh-tokens';
    if (config.url !== refreshPath) {
      const dataStorage = await getStorageLocalDataLogin();

      const now = moment();
      const accessToken = dataStorage?.accessToken;
      const decodedToken = accessToken ? jwtDecode<JwtPayload>(accessToken) : { exp: 0 };

      const nowIsAfterTokenExpDate = now.isAfter(decodedToken.exp! * 1000);

      if (accessToken && nowIsAfterTokenExpDate) {
        const userId = dataStorage?.user.id;
        const refreshToken = dataStorage?.refreshToken;

        try {
          const { data } = await api.post<{ accessToken: string }>(refreshPath, {
            id: userId,
            refreshToken,
          });

          config.headers.Authorization = `Bearer ${data.accessToken}`;

          return config;
        } catch (error) {
          logSentryException(error);
        }
      } else {
        const dataStorage = await getStorageLocalDataLogin();

        if (dataStorage && dataStorage?.accessToken) {
          config.headers.Authorization = `Bearer ${dataStorage.accessToken}`;
        }
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

if (__DEV__) {
  api.interceptors.request.use((config) => {
    console.log(`${config.method} ${config.url} ${JSON.stringify(config.params || {})}`);
    return config;
  });
}

export default api;
