import Feather from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import { DeliveryPickupStatusType } from '@w3lcome/types';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { useDeliveriesContext } from '_/hooks/DeliveriesProvider';
import { FormatedDeliveriesModel } from '_/interfaces/FormatedDeliveriesModel';
import { AppRoute, DeliveryProps } from '_/routes/types';
import * as Localization from 'expo-localization';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, Keyboard, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import ReportModal from '../ReportModal';
import Text from '../Text';

interface DeliveryItemProps {
  delivery: FormatedDeliveriesModel;
}

export function DeliveryItem({ delivery }: DeliveryItemProps) {
  const navigation = useNavigation<DeliveryProps>();
  const host = useSelector((state: any) => state.host);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const { setSelectedDelivery, updateDelivery } = useDeliveriesContext();
  const { t: translate } = useTranslation();

  if (!delivery) {
    return null;
  }

  const waitingColor = themes.colors.warning;
  const allowedColor = themes.colors.success;
  const deniedColor = themes.colors.danger;

  function handleGetDetails() {
    setSelectedDelivery(delivery);
    navigation.navigate(AppRoute.DELIVERY);
  }

  function showReportModal() {
    setIsReportModalVisible(true);
  }

  function hideModal() {
    Keyboard.dismiss();
    setIsReportModalVisible(false);
  }

  function report(reportMessage: string) {
    updateDelivery(delivery.id, {
      reportMessage,
      pickupUpdatedAt: new Date(),
      pickupById: host.id,
    });

    hideModal();
  }

  function convertTime(time: Date) {
    const convertedTime = moment(time).tz(Localization.timezone || 'America/Sao_Paulo');
    return convertedTime;
  }

  function renderStatus() {
    const { pickupStatus = '' } = delivery;

    if (pickupStatus === DeliveryPickupStatusType.AWAITING) {
      return (
        <View style={[styles.status, styles.shadow, { backgroundColor: waitingColor }]}>
          <View style={styles.rowContainer}>
            <Feather name="alert-triangle" style={styles.iconTriangle} />
            <Text style={styles.statusText}>{translate('home.waiting')}</Text>
          </View>
        </View>
      );
    }

    return null;
  }

  function renderStatusIcon() {
    const { pickupStatus = '' } = delivery;

    switch (pickupStatus) {
      case DeliveryPickupStatusType.PROBLEM:
        return (
          <Feather name="x-square" size={12} style={[styles.iconStatus, { color: deniedColor }]} />
        );
      case DeliveryPickupStatusType.PICKEDUP:
        return (
          <Feather
            name="check-square"
            size={12}
            style={[styles.iconStatus, { color: allowedColor }]}
          />
        );
      default:
        return null;
    }
  }

  function renderAuthorizationButtons() {
    const { pickupStatus = '' } = delivery;

    if (pickupStatus === DeliveryPickupStatusType.AWAITING) {
      return (
        <View style={styles.rowContainer}>
          <TouchableOpacity onPress={showReportModal}>
            <Feather name="x-square" style={[styles.actionsButtons, { color: deniedColor }]} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              updateDelivery(delivery.id, {
                pickupUpdatedAt: new Date(),
                pickupById: host.id,
              })
            }
          >
            <Feather name="check-square" style={[styles.actionsButtons, { color: allowedColor }]} />
          </TouchableOpacity>
        </View>
      );
    }

    return null;
  }

  function renderImage() {
    return (
      <View style={styles.imageContainerShadow}>
        <Image
          style={[
            styles.imageContainer,
            !delivery.pictureUrl && {
              width: 36,
              height: 36,
            },
          ]}
          source={delivery.pictureUrl ? { uri: delivery.pictureUrl } : themes.images.packageImg}
        />
      </View>
    );
  }

  function renderInfoContainer() {
    return (
      <View style={styles.infoContainer}>
        <View style={styles.rowContainer}>
          <Text numberOfLines={1} style={styles.text}>
            {translate('delivery.delivery')}
          </Text>
          {renderStatusIcon()}
        </View>

        <Text style={styles.subText}>{convertTime(delivery.createdAt).fromNow()}</Text>
      </View>
    );
  }

  return (
    <TouchableOpacity delayPressIn={50} style={styles.container} onPress={handleGetDetails}>
      <View style={styles.content}>
        {renderImage()}
        {renderInfoContainer()}
        {renderAuthorizationButtons()}
        <View>
          <Feather name="chevron-right" style={styles.iconChevronRight} />
        </View>
      </View>
      {renderStatus()}
      <ReportModal
        handleReport={report}
        visible={isReportModalVisible}
        hideModal={() => setIsReportModalVisible(false)}
      />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    flex: 1,
    paddingHorizontal: 24,
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: themes.colors.lightPrimary,
  },
  imageContainer: {
    resizeMode: 'cover',
    width: 60,
    height: 60,
    borderRadius: 4,
    overflow: 'hidden',
  },
  imageContainerShadow: {
    shadowColor: themes.colors.black,
    alignItems: 'center',
    backgroundColor: themes.colors.lightQuaternary,
    justifyContent: 'center',
    width: 60,
    height: 60,
    elevation: 4,
    shadowRadius: 8,
    borderRadius: 4,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
  },
  infoContainer: {
    alignItems: 'flex-start',
    flex: 1,
    paddingHorizontal: 24,
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  text: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
    fontSize: 16,
  },
  subText: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.light,
    fontSize: 12,
  },
  status: {
    position: 'absolute',
    alignItems: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    bottom: 0,
    height: 18,
    paddingHorizontal: 24,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  statusText: {
    color: themes.colors.white,
    fontFamily: fontFamily.regular,
    fontSize: 12,
    marginLeft: 6,
    height: '100%',
  },
  shadow: {
    shadowColor: themes.colors.black,
    backgroundColor: themes.colors.white,
    elevation: 5,
    shadowRadius: 2,
    shadowOpacity: 0.8,
    shadowOffset: { width: 0, height: 12 },
  },
  iconStatus: {
    fontSize: 14,
    marginLeft: 6,
  },
  actionsButtons: {
    fontSize: 35,
    marginHorizontal: 6,
    opacity: 0.4,
  },
  iconTriangle: {
    fontSize: 14,
    color: themes.colors.white,
  },
  iconChevronRight: {
    fontSize: 18,
    color: themes.colors.lightPrimary,
  },
});
