import { UsersModel } from '@w3lcome/types';

import api from './base';

class UserApi {
  public getUser = async (id: string) => {
    const { data } = await api.get<UsersModel>(`/users/${id}`);
    return data;
  };

  public updateUser = async (userData: Partial<UsersModel>) => {
    await api.patch(`/users/${userData.id}`, userData);
  };

  public deleteUser = async (id: string) => {
    await api.delete(`/users/${id}`);
  };

  public uploadAvatar = async (file: any): Promise<string> => {
    const form = new FormData();
    form.append('file', file);
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const { data } = await api.post('uploads/avatars', form, config);
    return data;
  };
}

export default new UserApi();
