import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import company from './company/sagas';
import host from './host/sagas';
import meetingTypes from './meeting-types/sagas';
import meeting from './meeting/sagas';
import meetings from './meetings/sagas';
import personal from './personal/sagas';
import user from './user/sagas';

export default function* rootSaga() {
  return yield all([auth, user, company, host, meeting, personal, meetings, meetingTypes]);
}
