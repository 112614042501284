import { useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { PlanDetailsProps } from '_/routes/types';
import { differenceInDays, format, getUnixTime } from 'date-fns';
import { transparentize } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Linking, ScrollView, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { Header } from '../../components';

export default function PlanDetails() {
  const navigation = useNavigation<PlanDetailsProps>();
  const { t: translate } = useTranslation();

  const {
    trialUntil,
    planIsActive,
    planType,
    name: companyName,
  } = useSelector((state: any) => state.company);

  function daysRemaining() {
    const expireDate = trialUntil;
    const todayDate = new Date();
    return differenceInDays(expireDate, todayDate);
  }

  function renderView() {
    if (!planIsActive && trialUntil < getUnixTime(new Date())) {
      return (
        <View style={styles.planContainer}>
          <Text style={[styles.title, styles.error]}>{translate('plan.error')}</Text>

          <Text style={styles.expireTextInactive}>
            {translate('plan.companyPlan', { companyName })}{' '}
            <Text style={styles.daysRemainingText}>{translate('plan.expired')}</Text>
          </Text>

          <Text style={styles.instructionsText}>
            {translate('plan.instructions', {
              expireDate: format(trialUntil, 'PP'),
            })}
          </Text>
        </View>
      );
    }

    return (
      <>
        <View style={styles.planContainer}>
          <Text style={styles.title}>{translate('plan.currentPlan')}</Text>

          <View style={styles.planTypeContainer}>
            <Text style={styles.planText}>{planType}</Text>
          </View>

          {planType === 'trial' && (
            <>
              {daysRemaining() > 0 ? (
                <Text style={styles.expireText}>
                  {translate('plan.expiresIn')}
                  <Text style={styles.daysRemainingText}>
                    {daysRemaining()} {translate('plan.days')}
                  </Text>
                </Text>
              ) : (
                <Text style={styles.expireTextInactive}>
                  {translate('plan.companyPlan', { companyName })}{' '}
                  <Text style={styles.daysRemainingText}>{translate('plan.expired')}</Text>
                </Text>
              )}
              <Text style={styles.instructionsText}>
                {translate('plan.selectPlanMessage', {
                  expireDate: format(trialUntil, 'PP'),
                })}
              </Text>
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={styles.btn}
                  onPress={() => Linking.openURL(`${appConfig.dashboardUrl}/dashboard/billing`)}
                >
                  <Text style={styles.btnText}>{translate('plan.selectPlanButton')}</Text>
                </TouchableOpacity>
              </View>
            </>
          )}
        </View>
      </>
    );
  }

  return (
    <View style={styles.container}>
      <Header handleBack={() => navigation.goBack()} title={translate('plan.planTitle')} />

      <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
        <View style={styles.content}>{renderView()}</View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.lightQuaternary,
  },
  scrollView: {
    flex: 1,
  },
  content: {
    margin: 24,
    alignItems: 'center',
    padding: 24,
    borderRadius: 12,
    backgroundColor: themes.colors.white,
  },
  planContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 12,
  },
  btn: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    borderColor: transparentize(0.33, themes.colors.primary),
    justifyContent: 'center',
    backgroundColor: themes.colors.primary,
    height: 52,
    borderWidth: 1,
    marginBottom: -24,
    marginHorizontal: -24,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  btnText: {
    color: themes.colors.white,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginLeft: 16,
  },
  title: {
    textAlign: 'center',
    fontFamily: fontFamily.medium,
    padding: 16,
    fontSize: 20,
  },
  planTypeContainer: {
    borderColor: themes.colors.lightPrimary,
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  planText: {
    textAlign: 'center',
    fontFamily: fontFamily.regular,
    fontSize: 20,
  },
  expireText: {
    textAlign: 'center',
    paddingTop: 32,
  },
  expireTextInactive: {
    textAlign: 'center',
    paddingTop: 16,
  },
  instructionsText: {
    textAlign: 'center',
    paddingTop: 24,
    paddingBottom: 40,
    color: themes.colors.darkQuaternary,
    fontSize: 12,
    fontFamily: fontFamily.light,
  },
  daysRemainingText: {
    color: themes.colors.warning,
    fontFamily: fontFamily.bold,
    fontSize: 14,
  },
  error: {
    color: themes.colors.danger,
  },
});
