import { EventNotificationsModel } from '@w3lcome/types';

import api from './base';
import { GetListType } from './types/GetListType';

class NotificationEventsApi {
  public getNotification = async (companyId: string | null, userId: string, params?: any) => {
    const { data } = await api.get<GetListType<EventNotificationsModel>>(`/notification-events`, {
      params: { ...params, companyId, userId },
    });
    return data;
  };

  public patchtNotification = async (notification: EventNotificationsModel) => {
    const { data } = await api.patch<EventNotificationsModel>(
      `/notification-events/${notification.id}`,
      notification
    );

    return data;
  };

  public patchtNotificationsUnread = async (notifications: string[]) => {
    const { data } = await api.patch(`/notification-events/`, notifications);
    return data;
  };
}

export default new NotificationEventsApi();
