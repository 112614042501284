import { useState } from 'react';

export function useDropdownList() {
  const [toggleExpand, setToggleExpand] = useState(false);

  function handleTouchableOpacity() {
    setToggleExpand((oldState) => !oldState);
  }

  return {
    handleTouchableOpacity,
    toggleExpand,
  };
}
