import { Feather } from '@expo/vector-icons';
import { Spin, Text } from '_/components';
import Button from '_/components/Button';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import {
  signOut,
  verifyEmailRequest,
  sendVerificationEmailRequest,
} from '_/store/modules/auth/actions';
import { addSeconds, getUnixTime } from 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, SafeAreaView, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { navigate } from '_/services/navigation';

export default function EmailConfirmation() {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const { loading } = useSelector((state: any) => state.loading);
  const userEmail = useSelector((state: any) => state.user.email);
  const [btnSendEmailDisabled, setBtnSendEmailDisabled] = useState(false);
  const [countdown, setCountdown] = useState('-');

  const timer = useRef<number>();
  const validUntilEndUnix = useRef(0);

  useEffect(() => {
    function setTimer() {
      if (btnSendEmailDisabled) {
        validUntilEndUnix.current = getUnixTime(addSeconds(new Date(), 60));

        timer.current = setInterval(() => {
          if (validUntilEndUnix.current === 0) {
            clearInterval(timer.current);
            setBtnSendEmailDisabled(false);
            return;
          }

          const now = getUnixTime(new Date());
          const s = Math.round(validUntilEndUnix.current - now);
          setCountdown(`${s}`);

          if (s < 1) {
            setBtnSendEmailDisabled(false);
            clearInterval(timer.current);
          }
        }, 1000);
      }
    }

    setTimer();
    return () => clearInterval(timer.current);
  }, [btnSendEmailDisabled]);

  async function checkIfEmailIsVerified() {
    dispatch(verifyEmailRequest(userEmail));
  }

  async function sendEmailAgain() {
    setBtnSendEmailDisabled(true);
    dispatch(sendVerificationEmailRequest(userEmail));
  }

  function handleLogout() {
    navigate('Signin');
    dispatch(signOut());
  }

  return (
    <View style={{ flex: 1 }}>
      <Spin visible={loading} />
      <SafeAreaView style={styles.container}>
        <View style={styles.topContainer}>
          <View style={styles.btnLogout}>
            <Feather name="log-out" style={styles.icon} />
            <Text uppercase={false} style={{ marginLeft: 6, opacity: 0 }}>
              {translate('companyConfirmation.logout')}
            </Text>
          </View>

          <Image style={styles.logo} source={themes.images.logo} resizeMode="contain" />

          <TouchableOpacity style={styles.btnLogout} onPress={handleLogout}>
            <Feather name="log-out" size={18} />
            <Text uppercase={false} style={styles.btnLogoutText}>
              {translate('companyConfirmation.logout')}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={styles.middleContainer}>
          <View style={styles.imageContainer}>
            <Image
              style={styles.image}
              source={themes.images.emailVerification}
              resizeMode="contain"
            />
          </View>
          <Text fontType="medium" style={styles.title}>
            {translate('emailConfirmation.title')}
          </Text>
          <Text style={styles.description}>
            {translate('emailConfirmation.emailSent')}
            <Text style={{ fontSize: 14, fontFamily: fontFamily.medium }}> {userEmail}</Text>
          </Text>
          <Text style={styles.description}>{translate('emailConfirmation.emailCheck')}</Text>
        </View>

        <Button buttonStyles={styles.btn} onPress={checkIfEmailIsVerified}>
          <Text uppercase={false} style={styles.btnText}>
            {translate('emailConfirmation.alreadyConfirmed')}
          </Text>
        </Button>

        <TouchableOpacity
          style={[styles.btn, { flexDirection: 'row' }]}
          onPress={sendEmailAgain}
          disabled={btnSendEmailDisabled}
        >
          <Feather
            name="send"
            style={btnSendEmailDisabled ? styles.sendIconDisabled : styles.sendIcon}
          />
          <Text
            uppercase={false}
            style={btnSendEmailDisabled ? styles.btnSendTextDisabled : styles.btnSendText}
          >
            {translate('emailConfirmation.send')}
          </Text>
        </TouchableOpacity>
        {btnSendEmailDisabled && +countdown > 0 && (
          <Text style={styles.countdown}>
            {translate('emailConfirmation.sendAgain')}
            <Text>{countdown}</Text>
            {+countdown > 1
              ? translate('emailConfirmation.seconds')
              : translate('emailConfirmation.second')}
          </Text>
        )}
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 24,
  },
  topContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  middleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 96,
    marginBottom: 72,
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    width: '100%',
    height: 170,
  },
  title: {
    color: themes.colors.darkSecondary,
    textAlign: 'center',
    fontSize: 24,
    marginVertical: 12,
  },
  description: {
    color: themes.colors.darkQuaternary,
    textAlign: 'center',
    fontFamily: fontFamily.light,
    fontSize: 14,
    marginVertical: 8,
  },
  btnLogout: {
    color: themes.colors.darkQuaternary,
    alignItems: 'center',
    flexDirection: 'row',
  },
  btnLogoutText: {
    color: themes.colors.darkQuaternary,
    fontSize: 14,
    marginLeft: 5,
  },
  btn: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 32,
  },
  btnText: {
    color: themes.colors.white,
    fontFamily: fontFamily.medium,
    fontSize: 18,
  },
  btnSendText: {
    fontSize: 14,
    marginLeft: 8,
  },
  btnSendTextDisabled: {
    color: themes.colors.lightSecondary,
    fontSize: 14,
    marginLeft: 8,
  },
  logo: {
    height: 40,
    width: 150,
  },
  icon: {
    fontSize: 18,
    color: themes.colors.white,
  },
  sendIcon: {
    fontSize: 14,
    color: themes.colors.black,
  },
  sendIconDisabled: {
    color: themes.colors.lightSecondary,
  },
  countdown: {
    alignSelf: 'center',
    fontSize: 12,
    fontFamily: fontFamily.light,
    color: themes.colors.darkTertiary,
  },
});
