export function updatePersonalActiveInvites(activeInvites) {
  return {
    type: '@personal/UPDATE_PERSONAL_ACTIVE_INVITES',
    payload: { activeInvites },
  };
}

export function savePersonalInviteRequest(invite) {
  return {
    type: '@personal/SAVE_PERSONAL_INVITE_REQUEST',
    payload: { invite },
  };
}

export function savePersonalInviteSuccess() {
  return {
    type: '@personal/SAVE_PERSONAL_INVITE_SUCCESS',
  };
}

export function savePersonalInviteFailure() {
  return {
    type: '@personal/SAVE_PERSONAL_INVITE_FAILURE',
  };
}

export function handleOpenUrlRequest(id: string, isLink: boolean) {
  return {
    type: '@personal/HANDLE_OPEN_URL_REQUEST',
    payload: { id, isLink },
  };
}

export function handleOpenUrlSuccess() {
  return {
    type: '@personal/HANDLE_OPEN_URL_SUCCESS',
  };
}

export function handleOpenUrlFailure() {
  return {
    type: '@personal/HANDLE_OPEN_URL_FAILURE',
  };
}

export function addPersonalPastInvitesArray(pastInvites) {
  return {
    type: '@personal/ADD_PERSONAL_PAST_INVITES_ARRAY',
    payload: { pastInvites },
  };
}

export function resetPersonalPastInvitesArray() {
  return {
    type: '@personal/RESET_PERSONAL_PAST_INVITES_ARRAY',
  };
}
