import Feather from '@expo/vector-icons/Feather';
import { CompaniesModel } from '@w3lcome/types';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { setPersonalProfile } from '_/store/modules/auth/actions';
import { setUserCurrentCompanyRequest } from '_/store/modules/company/actions';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  ViewStyle,
  StyleProp,
} from 'react-native';
import Modal from 'react-native-modal';
import { useSelector, useDispatch } from 'react-redux';

import { SwitchCard } from './SwitchCard';
import Divider from '../../Divider';
import Text from '../../Text';

interface SwitchCompanyProps {
  content: JSX.Element;
  buttonStyles?: StyleProp<ViewStyle>;
}

export default function SwitchCompany({ content, buttonStyles }: SwitchCompanyProps) {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const { loading } = useSelector((state: any) => state.loading);
  const [visible, setVisible] = useState(false);
  const { t: translate } = useTranslation();

  const handleSwitchProfile = useCallback((data: CompaniesModel, type: 'company' | 'user') => {
    if (type === 'company') {
      dispatch(setUserCurrentCompanyRequest(data, true));
      dispatch(setPersonalProfile(false));

      setVisible(false);
      return;
    }

    if (type === 'user') {
      dispatch(setPersonalProfile(true));
    }
  }, []);

  const renderCompanies = useCallback(() => {
    if (loading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color={themes.colors.primary} />
        </View>
      );
    }

    if (!user?.companies?.length) {
      return (
        <View style={styles.noCompany}>
          <Text style={styles.noCompanyText}>{translate('switchCompany.noCompanies')}</Text>
        </View>
      );
    }

    return user.companies.map((company: CompaniesModel) => (
      <SwitchCard
        key={company.id}
        data={company}
        type="company"
        handleSwitchProfile={() => handleSwitchProfile(company, 'company')}
      />
    ));
  }, [user.companies]);

  return (
    <>
      <Modal
        isVisible={visible}
        style={styles.modal}
        onBackdropPress={() => setVisible(false)}
        onBackButtonPress={() => setVisible(false)}
      >
        <View style={{ flex: 1 }}>
          <View style={styles.modalTitle}>
            <View style={styles.modalTitleView}>
              <Feather name="x" style={[styles.modalIcon, { opacity: 0 }]} />
              <Text style={styles.modalTitleText}>{translate('general.switchProfile')}</Text>
              <TouchableOpacity
                onPress={() => setVisible(false)}
                hitSlop={{ top: 40, left: 40, bottom: 40, right: 40 }}
              >
                <Feather name="x" style={styles.modalIcon} />
              </TouchableOpacity>
            </View>
            <Divider />
          </View>

          <ScrollView contentContainerStyle={{ paddingBottom: 50 }}>
            <View>
              <Text style={styles.headerTitle}>{translate('switchCompany.visitorProfile')}</Text>

              <SwitchCard
                key={user.id}
                data={user}
                type="user"
                handleSwitchProfile={() => handleSwitchProfile(user, 'user')}
              />

              <Text style={[styles.headerTitle, { marginTop: 24 }]}>
                {translate('switchCompany.hostProfile')}
              </Text>

              {renderCompanies()}
            </View>
          </ScrollView>
        </View>
      </Modal>

      <TouchableOpacity style={buttonStyles} delayPressIn={50} onPress={() => setVisible(true)}>
        {content}
      </TouchableOpacity>
    </>
  );
}

const styles = StyleSheet.create({
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
    marginTop: 130,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#fcfcfc',
  },
  modalTitleView: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  modalTitle: {
    marginTop: 24,
  },
  modalTitleText: {
    fontFamily: fontFamily.medium,
    fontSize: 20,
  },
  modalIcon: {
    fontSize: 22,
    padding: 8,
    color: themes.colors.darkQuaternary,
  },
  headerTitle: {
    fontFamily: fontFamily.medium,
    fontSize: 18,
    paddingBottom: 18,
    paddingHorizontal: 24,
    color: themes.colors.darkPrimary,
  },
  noCompany: {
    paddingHorizontal: 24,
  },
  noCompanyText: {
    fontFamily: fontFamily.medium,
    fontSize: 14,
    color: themes.colors.darkQuaternary,
  },
  loadingContainer: {
    height: 150,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
