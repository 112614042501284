import { useNavigation } from '@react-navigation/native';
import { DeliveryPickupStatusType } from '@w3lcome/types';
import { Header, InfoCard, ReportModal, PictureContainer, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { useDeliveriesContext } from '_/hooks/DeliveriesProvider';
import { DeliveryProps } from '_/routes/types';
import * as Localization from 'expo-localization';
import moment from 'moment-timezone';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Keyboard,
  ScrollView,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
} from 'react-native';
import { useSelector } from 'react-redux';

export default function Delivery() {
  const navigation = useNavigation<DeliveryProps>();
  const { selectedDelivery: delivery, updateDelivery } = useDeliveriesContext();
  const { t: translate } = useTranslation();

  const host = useSelector((state: any) => state.host);
  const { timezone } = useSelector((state: any) => state.company);

  const [isReportModalVisible, setIsReportModalVisible] = useState(false);

  function pickedup() {
    if (delivery?.id) {
      updateDelivery(delivery?.id, {
        pickupUpdatedAt: new Date(),
        pickupById: host.id,
      });
    }
  }

  function report(reportMessage: string) {
    if (delivery?.id) {
      updateDelivery(delivery?.id, {
        reportMessage,
        pickupUpdatedAt: new Date(),
        pickupById: host.id,
      });

      hideModal();
    }
  }

  function showReportModal() {
    setIsReportModalVisible(true);
  }

  function hideModal() {
    Keyboard.dismiss();
    setIsReportModalVisible(false);
  }

  if (!delivery) {
    return null;
  }

  return (
    <ContainerWidthLimit>
      <SafeAreaView style={styles.container}>
        <Header handleBack={() => navigation.goBack()} title={translate('delivery.delivery')} />
        <ScrollView showsVerticalScrollIndicator={false}>
          <PictureContainer type="delivery" pictureUrl={delivery.pictureUrl} />
          <View style={styles.content}>
            {delivery.pickupStatus === DeliveryPickupStatusType.AWAITING && (
              <View style={styles.actionsView}>
                <TouchableOpacity onPress={showReportModal} style={[styles.btn, styles.btnReport]}>
                  <Text style={[styles.btnText, styles.btnTextReport]}>
                    {translate('delivery.reportButton')}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={pickedup} style={[styles.btn, styles.btnPickedup]}>
                  <Text style={[styles.btnText, styles.btnTextPickedup]}>
                    {translate('delivery.markPickedup')}
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            <InfoCard title={translate('delivery.message')} value={delivery.message} />
            <InfoCard
              title={translate('delivery.arrivedAt')}
              value={moment(delivery.createdAt)
                .tz(timezone || Localization.timezone || 'America/Bahia')
                .format('DD/MM/YYYY - HH:mm')}
            />

            <InfoCard title={translate('delivery.reportMessage')} value={delivery.reportMessage} />
          </View>
          <ReportModal
            handleReport={report}
            visible={isReportModalVisible}
            hideModal={() => setIsReportModalVisible(false)}
          />
        </ScrollView>
      </SafeAreaView>
    </ContainerWidthLimit>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    backgroundColor: themes.colors.white,
    flex: 1,
  },
  content: {
    marginTop: 16,
    paddingBottom: 24,
    paddingHorizontal: 24,
  },
  actionsView: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    marginVertical: 8,
  },
  btn: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 34,
    borderWidth: 1,
    width: width / 2 - 30,
  },
  btnText: {
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginRight: 8,
  },
  btnTextPickedup: {
    color: themes.colors.success,
  },
  btnTextReport: {
    color: themes.colors.danger,
  },
  btnPickedup: {
    borderColor: transparentize(0.2, themes.colors.success),
  },
  btnReport: {
    borderColor: transparentize(0.2, themes.colors.danger),
  },
});
