import { MeetingLocationsModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';

import api from './base';
import { GetListType } from './types/GetListType';

class MeetingLocationsApi {
  public getMeetingLocations = async (params: any, config?: AxiosRequestConfig) => {
    try {
      const { data } = await api.get<GetListType<MeetingLocationsModel>>(`/meeting-locations`, {
        ...config,
        params,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

export default new MeetingLocationsApi();
