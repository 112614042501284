import { CustomizationsModel, WAccessIntegrationsModel } from '@w3lcome/types';
import produce from 'immer';

import { AuthAction } from '../auth/types';
import { CompanyAction, CompanyState } from './types';

const INITIAL_STATE: CompanyState = {
  id: null,
  name: null,
  lang: null,
  planType: null,
  planIsActive: false,
  trialUntil: null,
  location: null,
  pictureUrl: null,
  address: null,
  city: null,
  complement: null,
  country: null,
  state: null,
  type: null,
  timezone: null,
  customization: {} as CustomizationsModel,
  waccessIntegration: {} as WAccessIntegrationsModel,
};

export default function company(
  state = INITIAL_STATE,
  action: CompanyAction | AuthAction
): CompanyState {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@company/SET_USER_CURRENT_COMPANY_SUCCESS': {
        draft.id = action.payload.company.id;
        draft.name = action.payload.company.name;
        draft.lang = action.payload.company.lang;
        draft.planType = action.payload.company.plan;
        draft.planIsActive = action.payload.company.planIsActive;
        draft.trialUntil = action.payload.company.trialUntil;
        draft.location = action.payload.company.location;
        draft.pictureUrl = action.payload.company.pictureUrl;
        draft.address = action.payload.company.address;
        draft.city = action.payload.company.city;
        draft.complement = action.payload.company.complement;
        draft.country = action.payload.company.country;
        draft.state = action.payload.company.state;
        draft.type = action.payload.company.type;
        draft.timezone = action.payload.company.timezone;
        draft.customization = action.payload.customization;
        draft.waccessIntegration = action.payload.waccessIntegration;
        break;
      }
      case '@company/GET_WACCESS_INTEGRATION_SUCCESS': {
        draft.waccessIntegration = action.payload.waccessIntegration;
        break;
      }
      // case '@company/SYNC_COMPANY_WITH_DATABASE_SUCCESS': {
      //   draft.id = action.payload.id;
      //   draft.name = action.payload.name;
      //   draft.planType = action.payload.planType;
      //   draft.pictureUrl = action.payload.pictureUrl;
      //   draft.trialUntil = action.payload.trialUntil;
      //   draft.planIsActive = action.payload.planIsActive;
      //   draft.waccessIntegration = action.payload.waccessIntegration;
      //   draft.waccessIntegrationOnlyQRCode =
      //     action.payload.waccessIntegrationOnlyQRCode;
      //   draft.isisZoneAuthorizationEnabled = action.payload.isisZoneAuthorizationEnabled;
      //   draft.hideExpiredQRCode = action.payload.hideExpiredQRCode;
      //   break;
      // }
      case '@auth/SIGN_OUT': {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
