import { UsersModel } from '@w3lcome/types';
import { all, put, call, take, select, takeLatest } from 'redux-saga/effects';

import { navigate } from '_/services/navigation';

import { meetingLinksApi } from '../../../services/api';
import { setPersonalProfile } from '../auth/actions';
import { setLoading } from '../loading/actions';
import {
  savePersonalInviteSuccess,
  savePersonalInviteFailure,
  handleOpenUrlFailure,
  handleOpenUrlSuccess,
} from './actions';
import { InvitePayload } from './types';

async function saveInvite({ invite, user }: { invite: InvitePayload; user: UsersModel }) {
  let result;
  try {
    const { id, isLink, visit, picture } = invite;

    result = await meetingLinksApi.createAppointment({
      id,
      isLink,
      visit,
      picture,
      lang: user.lang,
      userId: user.id,
    });
  } catch (error) {
    result = error;
  }
  return result;
}

export function* savePersonalInvite({ payload }: any) {
  try {
    yield put(setLoading());
    const { invite } = payload;
    const user: UsersModel = yield select((state) => state.user);

    const result = yield call(saveInvite, {
      invite,
      user,
    });
    if (!result.visit?.id) {
      yield put(setLoading());
      yield put(savePersonalInviteFailure());
      return;
    }

    yield put(setLoading());
    yield put(savePersonalInviteSuccess());
    navigate('PersonalInvite', { id: result.visit.id });
  } catch (error) {
    console.tron.log(error);
    yield put(setLoading());
    yield put(savePersonalInviteFailure());
  }
}

export function* handleOpenUrl({ payload }: any) {
  try {
    const { id, isLink } = payload;
    const { signed } = yield select((state) => state.auth);

    if (!signed) {
      // aguarda uma action @auth/SIGN_IN_SUCCESS
      yield take('@auth/SIGN_IN_SUCCESS');
    }

    // muda para o perfil pessoal
    yield put(setPersonalProfile(true));
    yield put(handleOpenUrlSuccess());
    navigate('PersonalMeeting', { id, isLink });
  } catch (error) {
    console.tron.log(error);
    yield put(handleOpenUrlFailure());
  }
}

export default all([
  takeLatest('@personal/SAVE_PERSONAL_INVITE_REQUEST', savePersonalInvite),
  takeLatest('@personal/HANDLE_OPEN_URL_REQUEST', handleOpenUrl),
]);
