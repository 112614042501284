import { Text } from '_/components';
import Button from '_/components/Button';
import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Dimensions, TextInput } from 'react-native';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';

import { createLinkRequest } from '_/store/modules/meeting/actions';

interface LinkModalProps {
  visible: boolean;
  hideModal: () => void;
}

export default function LinkModal({ visible = false, hideModal }: LinkModalProps) {
  const dispatch = useDispatch();
  const meetingId = useSelector((state: any) => state.meeting.meeting.id);
  const companyId = useSelector((state: any) => state.company.id);
  const [limit, setLimit] = useState('');
  const { t: translate } = useTranslation();

  function handleCreateLink() {
    if (+limit > 0) {
      dispatch(createLinkRequest(limit, meetingId, companyId));
      hideModal();
    }
  }

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text fontType="medium" style={styles.title}>
            {translate('linkModal.title')}
          </Text>
          <View>
            <TextInput
              keyboardType="numeric"
              placeholderTextColor="#999"
              maxLength={3}
              value={limit}
              onChangeText={(text) => setLimit(text)}
              placeholder={translate('linkModal.placeholder')}
            />
          </View>
          <Text style={styles.text}>{translate('linkModal.instructions')}</Text>
          <Button small buttonStyles={styles.button} onPress={handleCreateLink}>
            <Text style={styles.buttonText}>{translate('linkModal.create')}</Text>
          </Button>
        </View>
      </View>
    </Modal>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    margin: 0,
    backgroundColor: transparentize(0.5, themes.colors.black),
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: width - 80,
    padding: 20,
    borderRadius: 4,
    backgroundColor: themes.colors.white,
  },
  title: {
    alignSelf: 'center',
    marginBottom: 16,
    color: themes.colors.darkSecondary,
  },
  text: {
    fontSize: 12,
    color: themes.colors.grayPrimary,
    paddingHorizontal: 6,
    marginTop: 8,
  },
  button: {
    marginTop: 20,
    paddingHorizontal: 20,
  },
  buttonText: {
    color: themes.colors.white,
  },
});
