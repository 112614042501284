import { Text } from '_/components';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TextInput } from 'react-native';
import RNModalSelector from 'react-native-modal-selector';

interface ModalNumberSelectorProps {
  type: 'hours' | 'minutes' | 'numbers';
  value: string;
  onChange: (option: { key: string; section: boolean; label: any }) => void;
}

export default function ModalNumberSelector({ type, value, onChange }: ModalNumberSelectorProps) {
  const { t: translate } = useTranslation();

  function createHoursArray() {
    const arr = [
      {
        key: '99',
        section: true,
        label: translate('editMeeting.hours'),
      },
    ];
    for (let i = 0; i < 24; i += 1) {
      arr.push({
        key: `0${i}`.slice(-2),
        label: `0${i}`.slice(-2),
        section: false,
      });
    }
    return arr;
  }

  function createMinutesArray() {
    const arr = [
      {
        key: '99',
        section: true,
        label: translate('editMeeting.minutes'),
      },
    ];
    for (let i = 0; i < 60; i += 5) {
      arr.push({
        key: `0${i}`.slice(-2),
        label: `0${i}`.slice(-2),
        section: false,
      });
    }
    return arr;
  }

  function createNumbersArray() {
    const arr = [
      {
        key: '999',
        section: true,
        label: translate('editMeeting.invitees'),
      },
    ];
    for (let i = 0; i < 100; i += 1) {
      arr.push({
        key: `0${i}`.slice(-2),
        label: `0${i}`.slice(-2),
        section: false,
      });
    }
    return arr;
  }

  const data = useMemo(() => {
    if (type === 'hours') {
      return createHoursArray();
    }

    if (type === 'minutes') {
      return createMinutesArray();
    }

    return createNumbersArray();
  }, [type]);

  return (
    <RNModalSelector
      onChange={onChange}
      style={styles.container}
      cancelStyle={styles.cancelStyle}
      overlayStyle={styles.overlayStyle}
      cancelText={translate('general.cancel')}
      cancelTextStyle={styles.cancelTextStyle}
      optionTextStyle={styles.optionTextStyle}
      sectionTextStyle={styles.sectionTextStyle}
      optionContainerStyle={styles.optionContainerStyle}
      renderItem={({ item }) => item.label}
      data={data}
    >
      <View style={styles.innerContainer}>
        <TextInput style={styles.input} value={value} placeholder="0" />
        {type !== 'numbers' && <Text style={styles.text}>{type[0]}</Text>}
      </View>
    </RNModalSelector>
  );
}

const styles = StyleSheet.create({
  container: {
    borderColor: themes.colors.lightSecondary,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    borderRadius: 4,
    borderBottomWidth: 1,
    paddingHorizontal: 12,
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    height: 40,
    textAlign: 'center',
    color: themes.colors.darkSecondary,
  },
  text: {
    fontFamily: fontFamily.regular,
    color: themes.colors.lightTertiary,
    marginRight: 6,
  },
  cancelStyle: {
    backgroundColor: themes.colors.white,
    padding: 16,
  },
  cancelTextStyle: {
    fontFamily: fontFamily.semibold,
  },
  sectionTextStyle: {
    fontWeight: 'bold',
    fontFamily: fontFamily.bold,
    padding: 6,
  },
  optionTextStyle: {
    color: themes.colors.darkTertiary,
    fontFamily: fontFamily.regular,
    padding: 4,
  },
  optionContainerStyle: {
    backgroundColor: themes.colors.white,
    maxHeight: 360,
  },
  overlayStyle: {
    justifyContent: 'center',
    backgroundColor: transparentize(0.3, themes.colors.black),
    flex: 1,
    padding: 40,
  },
});
