import { UserCompanyRole } from '@w3lcome/types';
import produce from 'immer';

import { HostAction, HostState } from './types';

const INITIAL_STATE: HostState = {
  name: '',
  email: '',
  role: UserCompanyRole.HOST,
  isActive: false,
  preCheckinNotifications: false,
  isPhoneValid: false,
  sendInvitationEmail: false,
  additionalEmails: [],
  additionalPhones: [],
  pictureUrl: null,
  pictureThumbUrl: null,
  position: null,
  sector: null,
  phone: null,
  countryCode: null,
  message: null,
  deliveryNotification: false,
  metadata: null,
  userId: null,
  createdById: null,
  companyId: '',
  id: '',
  defaultMeetingLocationId: '',
  defaultMeetingTypeId: '',
  defaultMeetingType: null,
  defaultMeetingLocation: null,
  createdAt: new Date(),
  updatedAt: new Date(),
  zoneId: null,
  zone: null,
  hostZones: [],
  invenziId: null,
  mifareCardNumber: null,
};

export default function host(state = INITIAL_STATE, action: HostAction) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@host/GET_HOST_OF_THE_ACTUAL_COMPANY_SUCCESS': {
        draft.name = action.payload.host.name;
        draft.email = action.payload.host.email;
        draft.role = action.payload.host.role;
        draft.isActive = action.payload.host.isActive;
        draft.preCheckinNotifications = action.payload.host.preCheckinNotifications;
        draft.isPhoneValid = action.payload.host.isPhoneValid;
        draft.sendInvitationEmail = action.payload.host.sendInvitationEmail;
        draft.additionalEmails = action.payload.host.additionalEmails;
        draft.additionalPhones = action.payload.host.additionalPhones;
        draft.pictureUrl = action.payload.host.pictureUrl;
        draft.pictureThumbUrl = action.payload.host.pictureThumbUrl;
        draft.position = action.payload.host.position;
        draft.sector = action.payload.host.sector;
        draft.phone = action.payload.host.phone;
        draft.countryCode = action.payload.host.countryCode;
        draft.message = action.payload.host.message;
        draft.deliveryNotification = action.payload.host.deliveryNotification;
        draft.metadata = action.payload.host.metadata;
        draft.userId = action.payload.host.userId;
        draft.createdById = action.payload.host.createdById;
        draft.companyId = action.payload.host.companyId;
        draft.id = action.payload.host.id;
        draft.createdAt = action.payload.host.createdAt;
        draft.updatedAt = action.payload.host.updatedAt;
        draft.company = action.payload.host.company;
        draft.createdBy = action.payload.host.createdBy;
        draft.deletedAt = action.payload.host.deletedAt;
        draft.user = action.payload.host.user;
        draft.defaultMeetingLocation = action.payload.host.defaultMeetingLocation;
        draft.defaultMeetingLocationId = action.payload.host.defaultMeetingLocationId;
        draft.defaultMeetingTypeId = action.payload.host.defaultMeetingTypeId;
        draft.defaultMeetingType = action.payload.host.defaultMeetingType;
        draft.zoneId = action.payload.host.zoneId;
        draft.zone = action.payload.host.zone;
        draft.hostZones = action.payload.host.hostZones;
        draft.invenziId = action.payload.host.invenziId;
        draft.mifareCardNumber = action.payload.host.mifareCardNumber;
        break;
      }
      case '@host/GET_HOST_OF_THE_ACTUAL_COMPANY_FAILURE': {
        draft.name = INITIAL_STATE.name;
        draft.email = INITIAL_STATE.email;
        draft.role = INITIAL_STATE.role;
        draft.isActive = INITIAL_STATE.isActive;
        draft.preCheckinNotifications = INITIAL_STATE.preCheckinNotifications;
        draft.isPhoneValid = INITIAL_STATE.isPhoneValid;
        draft.sendInvitationEmail = INITIAL_STATE.sendInvitationEmail;
        draft.additionalEmails = INITIAL_STATE.additionalEmails;
        draft.additionalPhones = INITIAL_STATE.additionalPhones;
        draft.pictureUrl = INITIAL_STATE.pictureUrl;
        draft.pictureThumbUrl = INITIAL_STATE.pictureThumbUrl;
        draft.position = INITIAL_STATE.position;
        draft.sector = INITIAL_STATE.sector;
        draft.phone = INITIAL_STATE.phone;
        draft.countryCode = INITIAL_STATE.countryCode;
        draft.message = INITIAL_STATE.message;
        draft.deliveryNotification = INITIAL_STATE.deliveryNotification;
        draft.metadata = INITIAL_STATE.metadata;
        draft.userId = INITIAL_STATE.userId;
        draft.createdById = INITIAL_STATE.createdById;
        draft.companyId = INITIAL_STATE.companyId;
        draft.id = INITIAL_STATE.id;
        draft.createdAt = INITIAL_STATE.createdAt;
        draft.updatedAt = INITIAL_STATE.updatedAt;
        draft.company = INITIAL_STATE.company;
        draft.createdBy = INITIAL_STATE.createdBy;
        draft.deletedAt = INITIAL_STATE.deletedAt;
        draft.user = INITIAL_STATE.user;
        draft.defaultMeetingLocation = INITIAL_STATE.defaultMeetingLocation;
        draft.defaultMeetingLocationId = INITIAL_STATE.defaultMeetingLocationId;
        draft.defaultMeetingTypeId = INITIAL_STATE.defaultMeetingTypeId;
        draft.defaultMeetingType = INITIAL_STATE.defaultMeetingType;
        draft.zoneId = INITIAL_STATE.zoneId;
        draft.zone = INITIAL_STATE.zone;
        draft.invenziId = INITIAL_STATE.invenziId;
        draft.hostZones = INITIAL_STATE.hostZones;
        draft.mifareCardNumber = INITIAL_STATE.mifareCardNumber;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.name = INITIAL_STATE.name;
        draft.email = INITIAL_STATE.email;
        draft.role = INITIAL_STATE.role;
        draft.isActive = INITIAL_STATE.isActive;
        draft.preCheckinNotifications = INITIAL_STATE.preCheckinNotifications;
        draft.isPhoneValid = INITIAL_STATE.isPhoneValid;
        draft.sendInvitationEmail = INITIAL_STATE.sendInvitationEmail;
        draft.additionalEmails = INITIAL_STATE.additionalEmails;
        draft.additionalPhones = INITIAL_STATE.additionalPhones;
        draft.pictureUrl = INITIAL_STATE.pictureUrl;
        draft.pictureThumbUrl = INITIAL_STATE.pictureThumbUrl;
        draft.position = INITIAL_STATE.position;
        draft.sector = INITIAL_STATE.sector;
        draft.phone = INITIAL_STATE.phone;
        draft.countryCode = INITIAL_STATE.countryCode;
        draft.message = INITIAL_STATE.message;
        draft.deliveryNotification = INITIAL_STATE.deliveryNotification;
        draft.metadata = INITIAL_STATE.metadata;
        draft.userId = INITIAL_STATE.userId;
        draft.createdById = INITIAL_STATE.createdById;
        draft.companyId = INITIAL_STATE.companyId;
        draft.id = INITIAL_STATE.id;
        draft.createdAt = INITIAL_STATE.createdAt;
        draft.updatedAt = INITIAL_STATE.updatedAt;
        draft.company = INITIAL_STATE.company;
        draft.createdBy = INITIAL_STATE.createdBy;
        draft.deletedAt = INITIAL_STATE.deletedAt;
        draft.user = INITIAL_STATE.user;
        draft.defaultMeetingLocation = INITIAL_STATE.defaultMeetingLocation;
        draft.defaultMeetingLocationId = INITIAL_STATE.defaultMeetingLocationId;
        draft.defaultMeetingTypeId = INITIAL_STATE.defaultMeetingTypeId;
        draft.defaultMeetingType = INITIAL_STATE.defaultMeetingType;
        draft.zoneId = INITIAL_STATE.zoneId;
        draft.zone = INITIAL_STATE.zone;
        draft.hostZones = INITIAL_STATE.hostZones;
        draft.invenziId = INITIAL_STATE.invenziId;
        draft.mifareCardNumber = INITIAL_STATE.mifareCardNumber;
        break;
      }
      default:
        break;
    }
  });
}
