import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TextInput, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import Modal from 'react-native-modal';

import Text from '../Text';

interface ReportModalProps {
  visible?: boolean;
  hideModal: () => void;
  handleReport: (message: string) => void;
}

export default function ReportModal({ visible, hideModal, handleReport }: ReportModalProps) {
  const [reportMessage, setReportMessage] = useState('');
  const { t: translate } = useTranslation();

  return (
    <Modal
      isVisible={visible}
      style={styles.overlay}
      onBackdropPress={hideModal}
      onBackButtonPress={hideModal}
    >
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.formLabel}>{translate('reportDeliveryModal.title')}</Text>
          <View style={styles.inputGroup}>
            <TextInput
              autoFocus
              multiline
              returnKeyType="go"
              autoCapitalize="sentences"
              keyboardType="email-address"
              autoCorrect={false}
              value={reportMessage}
              style={styles.formInput}
              onChangeText={(text) => setReportMessage(text)}
              onSubmitEditing={() => handleReport(reportMessage)}
              placeholder={translate('reportDeliveryModal.placeholder')}
            />
          </View>
        </View>
        <View style={styles.buttonsContainer}>
          <TouchableOpacity style={styles.btn} onPress={hideModal}>
            <Text>{translate('general.cancel')}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.btn, styles.btnReport]}
            onPress={() => handleReport(reportMessage)}
          >
            <Text style={styles.btnText}>{translate('reportDeliveryModal.reportBtn')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: transparentize(0.5, themes.colors.black),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    zIndex: 1000,
  },
  container: {
    overflow: 'hidden',
    shadowColor: themes.colors.black,
    alignItems: 'flex-start',
    backgroundColor: themes.colors.white,
    justifyContent: 'flex-start',
    elevation: 2,
    borderRadius: 4,
    width: width - 48,
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    shadowOffset: {
      width: 0,
      height: 1,
    },
  },
  content: {
    width: '100%',
    padding: 24,
  },
  buttonsContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: themes.colors.lightPrimary,
    justifyContent: 'flex-end',
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  btn: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 12,
    paddingVertical: 8,
    paddingHorizontal: 24,
  },
  btnText: {
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginRight: 8,
    color: themes.colors.white,
  },
  btnReport: {
    backgroundColor: themes.colors.danger,
  },
  formLabel: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.medium,
  },
  formInput: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    flex: 1,
    fontSize: 14,
    paddingVertical: 8,
  },
  inputGroup: {
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: themes.colors.white,
    justifyContent: 'center',
    borderBottomColor: themes.colors.lightSecondary,
    marginBottom: 8,
    borderBottomWidth: 1,
  },
});
