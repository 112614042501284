import { HostsModel, MeetingHostsModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';

import { GetActualHostRequestInt } from '../../store/modules/host/types';
import api from './base';
import { GetListType } from './types/GetListType';

class HostsApi {
  getHost = async (id: string, config?: AxiosRequestConfig) => {
    const { data } = await api.get<HostsModel>(`/hosts/${id}`, config);
    return data;
  };

  public getActualHost = async ({ userId, companyId }: GetActualHostRequestInt) => {
    const params = {
      userId,
      companyId,
    };

    const { data } = await api.get<GetListType<HostsModel>>(`/hosts`, {
      params,
    });

    return data.data;
  };

  public getHosts = async ({ params }: any) => {
    const { data } = await api.get<GetListType<HostsModel>>(`/hosts`, {
      params,
    });

    return data.data;
  };

  public getMeetingHosts = async (meetingId: string, params: any = {}) => {
    const { data } = await api.get<GetListType<MeetingHostsModel>>(`/meeting-hosts`, {
      params: {
        ...params,
        meetingId,
      },
    });

    return data.data;
  };

  public deleteMeetingHosts = async (meetingId: string | null, params: any) => {
    const { data } = await api.delete<MeetingHostsModel>('/meeting-hosts', {
      params: {
        meetingId,
        ...params,
      },
    });
    return data;
  };
}

export default new HostsApi();
