import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  iconsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    marginRight: 8,
  },
  icons: {
    fontSize: 20,
    color: themes.colors.primary,
  },
  title: {
    color: themes.colors.darkSecondary,
    fontFamily: fontFamily.regular,
    fontSize: 12,
  },
  value: {
    color: themes.colors.darkQuaternary,
    fontFamily: fontFamily.regular,
    fontSize: 14,
  },
});
