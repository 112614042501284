import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import { Dimensions, Platform, StyleSheet } from 'react-native';

import { getWebMaxWidth } from '../ContainerWidthLimit';

const { width } = Dimensions.get('screen');

export default StyleSheet.create({
  name: {
    fontFamily: fontFamily.bold,
    fontSize: 30,
    marginBottom: 6,
    color: themes.colors.primary,
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: fontFamily.regular,
    fontSize: 14,
    color: themes.colors.lightBlue,
    marginBottom: 6,
  },
  bold: {
    fontFamily: fontFamily.bold,
    color: themes.colors.secondary,
  },
  qrCode: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 24,
    ...(Platform.OS === 'web'
      ? { width: getWebMaxWidth() * 0.8, height: getWebMaxWidth() * 0.8 }
      : { width: width / 1.4, height: width / 1.4 }),
  },
  red: {
    color: themes.colors.danger,
  },
  errorBtn: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 1003,
    width: 100,
    height: 100,
    borderRadius: 12,
    backgroundColor: themes.colors.white,
  },
  icon: {
    color: themes.colors.darkTertiary,
  },
  warningContainer: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: width * 0.85,
    padding: 20,
    zIndex: 1003,
    borderRadius: 6,
    backgroundColor: themes.colors.warning,
  },
  warningText: {
    color: themes.colors.white,
    textAlign: 'center',
  },
});
