import { Feather } from '@expo/vector-icons';
import { UserCompanyRole } from '@w3lcome/types';
import { TopMenu, Spin, QRCodeDisplay, Text, Divider } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { appConfig } from '_/config/app';
import { fontFamily } from '_/config/theme';
import themes from '_/constants/themes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ScrollView, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import Modal from 'react-native-modal';
import { useSelector } from 'react-redux';

import LinesDisplay from './LinesDisplay';

interface QRCodeProps {
  navigation: any;
}

export default function QRCode({ navigation }: QRCodeProps) {
  const { role } = useSelector((state: any) => state.host);
  const { loading } = useSelector((state: any) => state.loading);
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  const handleNavigate = (screen: string) => {
    navigation.navigate(screen);
    setVisible(false);
  };

  return (
    <ContainerWidthLimit>
      <View style={styles.container}>
        <TopMenu />
        <Spin visible={loading} />
        <Modal
          isVisible={visible}
          style={styles.modal}
          onBackdropPress={() => setVisible(false)}
          onBackButtonPress={() => setVisible(false)}
        >
          <View style={{ flex: 1 }}>
            <View style={styles.modalTitle}>
              <View style={styles.modalTitleView}>
                <Feather name="x" style={[styles.modalIcon, { opacity: 0 }]} />
                <Text style={styles.modalTitleText}>{t('chooseTheCredentialReader')}</Text>
                <TouchableOpacity
                  onPress={() => setVisible(false)}
                  hitSlop={{ top: 40, left: 40, bottom: 40, right: 40 }}
                >
                  <Feather name="x" style={styles.modalIcon} />
                </TouchableOpacity>
              </View>
              <Divider />
            </View>

            <ScrollView contentContainerStyle={{ paddingBottom: 50 }}>
              <View>
                <TouchableOpacity
                  style={styles.readerOptionButton}
                  onPress={() => handleNavigate('QRCodeReader')}
                >
                  <Text style={styles.readerOptionText}>QR Code</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.readerOptionButton}
                  onPress={() => handleNavigate('EntryReader')}
                >
                  <Text style={styles.readerOptionText}>{t('EntryReader')}</Text>
                </TouchableOpacity>
                {Platform.OS === 'android' && (
                  <TouchableOpacity
                    style={styles.readerOptionButton}
                    onPress={() => handleNavigate('NFCReader')}
                  >
                    <Text style={styles.readerOptionText}>NFC</Text>
                  </TouchableOpacity>
                )}
              </View>
            </ScrollView>
          </View>
        </Modal>

        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
          <View style={styles.content}>
            <View style={styles.qrCodeContainer}>
              <QRCodeDisplay />
            </View>

            {[
              UserCompanyRole.ADMIN,
              UserCompanyRole.MANAGER,
              UserCompanyRole.FRONTDESK,
              UserCompanyRole.SECURITY,
            ].includes(role) && (
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  delayPressIn={50}
                  style={styles.btn}
                  onPress={() =>
                    appConfig.allowedHostZonesOfflineFlowEnabled === 'true'
                      ? setVisible(true)
                      : navigation.navigate('QRCodeReader')
                  }
                >
                  <Feather name="maximize" style={styles.icon} />
                  <Text style={styles.btnText}>{t('qrcode.qrReader')}</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
          <LinesDisplay />
        </ScrollView>
      </View>
    </ContainerWidthLimit>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themes.colors.lightQuaternary,
  },
  scrollView: {
    flex: 1,
  },
  content: {
    margin: 24,
    alignItems: 'center',
    padding: 24,
    borderRadius: 12,
    backgroundColor: themes.colors.white,
  },
  qrCodeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 12,
    width: '100%',
  },
  btn: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: themes.colors.primary,
    height: 58,
    borderBottomRightRadius: 12,
    borderBottomLeftRadius: 12,
    marginBottom: -24,
    marginHorizontal: -24,
  },
  btnText: {
    color: themes.colors.white,
    fontFamily: fontFamily.medium,
    fontSize: 16,
    marginLeft: 16,
  },
  icon: {
    color: themes.colors.white,
    fontSize: 20,
  },
  modal: {
    justifyContent: 'flex-start',
    margin: 0,
    marginTop: 420,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#fcfcfc',
  },
  modalTitleView: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  modalTitle: {
    marginTop: 24,
  },
  modalTitleText: {
    fontFamily: fontFamily.medium,
    fontSize: 20,
  },
  modalIcon: {
    fontSize: 22,
    padding: 8,
    color: themes.colors.darkQuaternary,
  },
  readerOptionButton: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 18,
  },
  readerOptionText: {
    fontSize: 18,
    color: themes.colors.darkQuaternary,
  },
});
